import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonIconsModule } from '@common/common-icons/common-icons.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@NgModule({
  imports: [CommonModule, CommonIconsModule],
  exports: [FontAwesomeModule],
})
export class UsanaIconsModule {
  readonly USANA_ICONS: IconDefinition[] = [];

  constructor(faLibrary: FaIconLibrary) {
    faLibrary.addIcons(...this.USANA_ICONS);
  }
}
