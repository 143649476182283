import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, mergeMap } from 'rxjs/operators';
import { TaskTrackerResult } from '../models/task-tracker-result';
import { TaskTrackerService } from '../services/task-tracker.service';
import {
  LoadTaskTrackerProgramProgress,
  LoadTaskTrackerProgramProgressFailure,
  LoadTaskTrackerProgramProgressSuccess,
} from './task-tracker.actions';
import { TaskTrackerStateModel } from './task-tracker.state.model';

export const TASK_TRACKER_STATE_DEFAULTS: TaskTrackerStateModel = {
  taskTrackerResults: new Map<string, TaskTrackerResult>(),
};

@State<TaskTrackerStateModel>({
  name: 'taskTracker',
  defaults: TASK_TRACKER_STATE_DEFAULTS,
})
@Injectable()
export class TaskTrackerState {
  constructor(private taskTrackerService: TaskTrackerService) {}

  @Action(LoadTaskTrackerProgramProgress)
  loadTaskTrackerProgramProgress(ctx: StateContext<TaskTrackerStateModel>, action: LoadTaskTrackerProgramProgress) {
    const state = ctx.getState();

    if (action.programKey) {
      const updatedTaskTrackerResults = new Map(state.taskTrackerResults);

      updatedTaskTrackerResults.set(action.programKey, {
        isLoading: true,
        errorLoading: false,
        programProgress: null,
      });

      ctx.patchState({
        taskTrackerResults: updatedTaskTrackerResults,
      });

      return this.taskTrackerService.getTaskTrackerProgramProgress(action.programKey).pipe(
        mergeMap((programProgress) => ctx.dispatch(new LoadTaskTrackerProgramProgressSuccess(programProgress))),
        catchError(() => ctx.dispatch(new LoadTaskTrackerProgramProgressFailure(action.programKey)))
      );
    }
  }

  @Action(LoadTaskTrackerProgramProgressSuccess)
  loadTaskTrackerProgramProgressSuccess(
    ctx: StateContext<TaskTrackerStateModel>,
    action: LoadTaskTrackerProgramProgressSuccess
  ) {
    const state = ctx.getState();

    if (action.programProgress) {
      const updatedTaskTrackerResults = new Map(state.taskTrackerResults);
      updatedTaskTrackerResults.set(action.programProgress.programKey, {
        isLoading: false,
        errorLoading: false,
        programProgress: action.programProgress,
      });

      ctx.patchState({
        taskTrackerResults: updatedTaskTrackerResults,
      });
    }
  }

  @Action(LoadTaskTrackerProgramProgressFailure)
  loadTaskTrackerProgramProgressFailure(
    ctx: StateContext<TaskTrackerStateModel>,
    action: LoadTaskTrackerProgramProgressFailure
  ) {
    const state = ctx.getState();

    const updatedTaskTrackerResults = new Map(state.taskTrackerResults);

    updatedTaskTrackerResults.set(action.programKey, {
      isLoading: false,
      errorLoading: true,
      programProgress: null,
    });

    ctx.patchState({
      taskTrackerResults: updatedTaskTrackerResults,
    });
  }
}
