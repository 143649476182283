import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { CommonIconsModule } from '../../../../common/src/common-icons/common-icons.module';

@NgModule({
  imports: [CommonModule, CommonIconsModule],
  exports: [FontAwesomeModule],
})
export class BaoyingIconsModule {
  readonly BAOYING_ICONS: IconDefinition[] = [];

  constructor(faLibrary: FaIconLibrary) {
    faLibrary.addIcons(...this.BAOYING_ICONS);
  }
}
