import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CmsBaseIntervalContainerDirective } from '@common/cms/containers/cms-base-interval-container.directive';
import { WindowSizingService } from '@common/services/window-size.service';
import { Store } from '@ngxs/store';
import { WINDOW_TOKEN } from '@usana/ux/common';

@Component({
  selector: 'u-cms-hub-banner-slider-container',
  templateUrl: './cms-hub-banner-slider-container.component.html',
  styleUrls: ['./cms-hub-banner-slider-container.component.scss'],
})
export class CmsHubBannerSliderContainerComponent
  extends CmsBaseIntervalContainerDirective
  implements OnInit, OnDestroy
{
  constructor(
    private readonly windowSizingService: WindowSizingService,
    protected readonly store: Store,
    @Inject(WINDOW_TOKEN) readonly window: any
  ) {
    super(store, window);
  }

  isBannerDesktopSize(): boolean {
    return this.windowSizingService.isBannerDesktopSize();
  }
}
