import { Component, inject, Input } from '@angular/core';
import { Notification } from '@common/notifications/notification.model';
import { NotificationService } from '@common/notifications/notification.service';
import { ActionsExecuting } from '@ngxs-labs/actions-executing';
import { Store } from '@ngxs/store';
import { DropdownButtonMenuItem } from '@usana/ux/universal-components/button';
import { Observable, of } from 'rxjs';
import { NotificationSelectors } from '../state/notification.selectors';

@Component({
  selector: 'u-notification-widget',
  templateUrl: './notification-widget.component.html',
})
export class NotificationWidgetComponent {
  @Input() loadingNotifications: ActionsExecuting;
  @Input() limit: boolean = true;

  unreadNotificationCount$: Observable<number> = inject(Store).select(NotificationSelectors.getUnreadNotificationCount);

  displayedNotifications: Notification[] = [];
  dropdownMenuItems$: Observable<DropdownButtonMenuItem[]> = of([]);

  @Input() set notifications(notifications: Notification[]) {
    notifications = notifications || [];
    this.displayedNotifications = this.limit ? notifications.slice(0, 10) : notifications;
    this.dropdownMenuItems$ = this.notificationService.getDropdownMenuItems(this.limit, this.displayedNotifications);
  }

  constructor(private notificationService: NotificationService) {}
}
