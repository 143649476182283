import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { TaskTrackerPageTask } from '../models/task-tracker-page-task';
import { TaskTrackerService } from './task-tracker.service';

@Injectable({
  providedIn: 'root',
})
export class TaskTrackerNavigationTaskService {
  readonly HUB_NAVIGATION_TASKS: TaskTrackerPageTask[] = [
    {
      urlTest: (url: string) => url === '/followMeSettings',
      taskKey: 'PERSONALIZE_WEBSITE',
    },
    {
      urlTest: (url: string) => url.startsWith('/content/trainingCellSentials'),
      taskKey: 'VISIT_CELLSENTIALS_TRAINING',
    },
    {
      urlTest: (url: string) => url.startsWith('/content/TrainingProflavanolC100'),
      taskKey: 'VISIT_PROFLAVANOL_TRAINING',
    },
    {
      urlTest: (url: string) => url.startsWith('/content/TrainingBiOmega'),
      taskKey: 'VISIT_BIOMEGA_TRAINING',
    },
  ];

  constructor(router: Router, taskTrackerService: TaskTrackerService) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const matchingNavigationTask = this.HUB_NAVIGATION_TASKS.find((trackedPage) => trackedPage.urlTest(event.url));

      if (matchingNavigationTask) {
        taskTrackerService.completeTask(matchingNavigationTask.taskKey).pipe(take(1)).subscribe();
      }
    });
  }
}
