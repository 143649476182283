import { Component, Input } from '@angular/core';
import { BreadCrumbModel } from '@shared/bread-crumb/bread-crumb.model';

@Component({
  selector: 'u-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent {
  @Input() children: BreadCrumbModel[];

  trackByIndex(index) {
    return index;
  }
}
