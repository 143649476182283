import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsanaDatePipeModule } from '@common/custom-locale-pipes/usana-date-pipe/usana-date-pipe.module';
import { ImportantDatesService } from '@common/important-dates/important-dates.service';
import { TranslationModule } from '@common/translation/translation.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonIconsModule } from '../common-icons/common-icons.module';
import { ImportantDatesComponent } from './important-dates.component';

@NgModule({
  declarations: [ImportantDatesComponent],
  imports: [
    CommonModule,
    CarouselModule.forRoot(),
    TooltipModule.forRoot(),
    UsanaDatePipeModule,
    TranslationModule,
    CommonIconsModule,
  ],
  providers: [ImportantDatesService],
  exports: [ImportantDatesComponent],
})
export class ImportantDatesModule {}
