export enum InterruptType {
  KYC_DOC_UPLOAD,
  NOTIFICATION_GENERIC,
  NOTIFICATION_SMS,
}

export interface HubInterruptData {
  type: InterruptType;
  data?: any;
}
