import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslationService } from '@common/translation/translation.service';
import { Contest, ContestTranslations, GrowthType, StatLevels } from '@usana/ux/contest';
import {
  CONTEST_TRANSLATIONS,
  STAT_LEVEL_TRANSLATIONS,
} from '@usanaHub/app/contest-widget/constants/contest-widget.constant';

@Injectable({
  providedIn: 'root',
})
export class ContestWidgetTranslationService {
  constructor(private translateService: TranslationService, private number: DecimalPipe) {}

  async getBaseContestTranslations(
    contestData: Contest,
    contestTranslations: ContestTranslations
  ): Promise<ContestTranslations> {
    return this.translateService
      .getTranslationListPromise([
        CONTEST_TRANSLATIONS.viewContestReport,
        CONTEST_TRANSLATIONS.yourProgress,
        CONTEST_TRANSLATIONS.rewards,
        CONTEST_TRANSLATIONS.weeks,
        CONTEST_TRANSLATIONS.typeOfReward,
        CONTEST_TRANSLATIONS.learnMore,
        CONTEST_TRANSLATIONS.takeAction,
        CONTEST_TRANSLATIONS.onTrack,
        CONTEST_TRANSLATIONS.goal,
        CONTEST_TRANSLATIONS.contestDatesAndRules,
        CONTEST_TRANSLATIONS.dayRemaining,
        CONTEST_TRANSLATIONS.daysRemaining,
      ])
      .then((tags: { [tag: string]: any }): ContestTranslations => {
        const dayRemainingInterpolateMsg = tags[CONTEST_TRANSLATIONS.dayRemaining].value
          ? tags[CONTEST_TRANSLATIONS.dayRemaining].value.replace('{{daysRemaining}}', contestData.daysLeftInContest)
          : CONTEST_TRANSLATIONS.dayRemaining;
        const daysRemainingInterpolateMsg = tags[CONTEST_TRANSLATIONS.daysRemaining].value
          ? tags[CONTEST_TRANSLATIONS.daysRemaining].value.replace('{{daysRemaining}}', contestData.daysLeftInContest)
          : CONTEST_TRANSLATIONS.daysRemaining;

        contestTranslations.daysRemaining =
          contestData.daysLeftInContest === 1 ? dayRemainingInterpolateMsg : daysRemainingInterpolateMsg;
        contestTranslations.viewContestReport = tags[CONTEST_TRANSLATIONS.viewContestReport].value;
        contestTranslations.yourProgress = tags[CONTEST_TRANSLATIONS.yourProgress].value;
        contestTranslations.rewards = tags[CONTEST_TRANSLATIONS.rewards].value;
        contestTranslations.weeks = tags[CONTEST_TRANSLATIONS.weeks].value;
        contestTranslations.takeAction = tags[CONTEST_TRANSLATIONS.takeAction].value;
        contestTranslations.onTrack = tags[CONTEST_TRANSLATIONS.onTrack].value;
        contestTranslations.goal = tags[CONTEST_TRANSLATIONS.goal].value;
        contestTranslations.contestDatesAndRules = tags[CONTEST_TRANSLATIONS.contestDatesAndRules].value;
        contestTranslations.typeOfReward = tags[CONTEST_TRANSLATIONS.typeOfReward].value;
        contestTranslations.learnMore = tags[CONTEST_TRANSLATIONS.learnMore].value;

        return contestTranslations;
      });
  }

  async getSVPContestTranslations(contestData: Contest, contestTranslations: any): Promise<ContestTranslations> {
    return this.translateService
      .getTranslationListPromise([
        CONTEST_TRANSLATIONS.currentTotalSvp,
        CONTEST_TRANSLATIONS.totalSvp,
        CONTEST_TRANSLATIONS.svpFromPC,
        CONTEST_TRANSLATIONS.svpFromOthers,
        CONTEST_TRANSLATIONS.svpGrowthTitle,
        CONTEST_TRANSLATIONS.svpFromOthersMax,
        CONTEST_TRANSLATIONS.svpFromPCMax,
        CONTEST_TRANSLATIONS.svpPoints,
      ])
      .then((tags: { [tag: string]: any }): ContestTranslations => {
        const svpInterpolateMsg = tags[CONTEST_TRANSLATIONS.svpPoints]
          ? tags[CONTEST_TRANSLATIONS.svpPoints].value.replace('{{svp}}', this.getSvp(contestData.svp))
          : CONTEST_TRANSLATIONS.svpPoints;
        const svpFromPCMaxInterpolateMsg = tags[CONTEST_TRANSLATIONS.svpFromPCMax]
          ? tags[CONTEST_TRANSLATIONS.svpFromPCMax].value.replace(
              '{{svpFromPC}}',
              this.transformNumber(this.getSvpMax('PC', contestData))
            )
          : CONTEST_TRANSLATIONS.svpFromPCMax;
        const svpFromOthersMaxInterpolateMsg = tags[CONTEST_TRANSLATIONS.svpFromOthersMax]
          ? tags[CONTEST_TRANSLATIONS.svpFromOthersMax].value.replace(
              '{{svpFromOthers}}',
              this.transformNumber(this.getSvpMax('AM', contestData) + this.getSvpMax('A', contestData))
            )
          : CONTEST_TRANSLATIONS.svpFromOthersMax;

        contestTranslations.svpPoints = svpInterpolateMsg;
        contestTranslations.svpFromOthersMax = svpFromOthersMaxInterpolateMsg;
        contestTranslations.svpFromPCMax = svpFromPCMaxInterpolateMsg;
        contestTranslations.svpGrowthTitle = tags[CONTEST_TRANSLATIONS.svpGrowthTitle].value;
        contestTranslations.totalSvp = tags[CONTEST_TRANSLATIONS.totalSvp].value;
        contestTranslations.svpFromPC = tags[CONTEST_TRANSLATIONS.svpFromPC].value;
        contestTranslations.svpFromOthers = tags[CONTEST_TRANSLATIONS.svpFromOthers].value;
        contestTranslations.currentTotalSvp = tags[CONTEST_TRANSLATIONS.currentTotalSvp].value;

        return contestTranslations;
      });
  }

  async getCVPContestTranslations(contestData: Contest, contestTranslations: any): Promise<ContestTranslations> {
    return this.translateService
      .getTranslationListPromise([
        CONTEST_TRANSLATIONS.dataSeenRepresents,
        CONTEST_TRANSLATIONS.cvpGrowthTitle,
        CONTEST_TRANSLATIONS.currentCvpGrowth,
        CONTEST_TRANSLATIONS.cvpCurrentPeriod,
        CONTEST_TRANSLATIONS.cvpBasePeriod,
        CONTEST_TRANSLATIONS.cvpGrowth,
        CONTEST_TRANSLATIONS.growthPopoverTitle,
        CONTEST_TRANSLATIONS.growthPopoverMessage,
        CONTEST_TRANSLATIONS.weeklyGoal,
      ])
      .then((tags: { [tag: string]: any }): ContestTranslations => {
        const cvpGrowthInterpolateMsg = tags[CONTEST_TRANSLATIONS.cvpGrowth]
          ? tags[CONTEST_TRANSLATIONS.cvpGrowth].value.replace(
              '{{cvpGrowth}}',
              this.getCvpGrowth(contestData.cvpGrowth)
            )
          : CONTEST_TRANSLATIONS.cvpGrowth;

        contestTranslations.growthPopoverTitle = tags[CONTEST_TRANSLATIONS.growthPopoverTitle].value;
        contestTranslations.growthPopoverMessage = tags[CONTEST_TRANSLATIONS.growthPopoverMessage].value;
        contestTranslations.cvpGrowth = cvpGrowthInterpolateMsg;
        contestTranslations.cvpGrowthTitle = tags[CONTEST_TRANSLATIONS.cvpGrowthTitle].value;
        contestTranslations.currentCvpGrowth = tags[CONTEST_TRANSLATIONS.currentCvpGrowth].value;
        contestTranslations.cvpCurrentPeriod = tags[CONTEST_TRANSLATIONS.cvpCurrentPeriod].value;
        contestTranslations.cvpBasePeriod = tags[CONTEST_TRANSLATIONS.cvpBasePeriod].value;
        contestTranslations.dataSeenRepresents = tags[CONTEST_TRANSLATIONS.dataSeenRepresents].value;
        contestTranslations.weeklyGoal = tags[CONTEST_TRANSLATIONS.weeklyGoal].value;

        return contestTranslations;
      });
  }

  async getStatLevelTranslations(
    statLevel: StatLevels,
    growthType: string,
    statLevelTranslations: any
  ): Promise<ContestTranslations> {
    const rewardTitle = STAT_LEVEL_TRANSLATIONS.rewardTitle + growthType;
    return this.translateService
      .getTranslationListPromise([
        STAT_LEVEL_TRANSLATIONS.contestLevel,
        STAT_LEVEL_TRANSLATIONS.rewardDetails,
        statLevel.widgetTranslation.tag,
        rewardTitle,
      ])
      .then((tags: { [tag: string]: any }): ContestTranslations => {
        const rewardDetailsInterpolateMsg = tags[STAT_LEVEL_TRANSLATIONS.rewardDetails]
          ? tags[STAT_LEVEL_TRANSLATIONS.rewardDetails].value.replace('{{level}}', statLevel.contestLevel)
          : STAT_LEVEL_TRANSLATIONS.rewardDetails;
        const levelInterpolateMsg = tags[STAT_LEVEL_TRANSLATIONS.contestLevel]
          ? tags[STAT_LEVEL_TRANSLATIONS.contestLevel].value.replace('{{level}}', statLevel.contestLevel)
          : STAT_LEVEL_TRANSLATIONS.contestLevel;
        let rewardTitleInterpolateMsg = tags[rewardTitle]
          ? tags[rewardTitle].value.replace('{{rangeMin}}', this.transformNumber(statLevel.rangeMin))
          : rewardTitle;

        if (GrowthType.DVP === growthType && tags[rewardTitle]) {
          rewardTitleInterpolateMsg = rewardTitleInterpolateMsg.replace(
            '{{secondaryRangeMin}}',
            this.transformNumber(statLevel.secondaryRangeMin)
          );
        }

        statLevelTranslations.contestLevel = levelInterpolateMsg;
        statLevelTranslations.description = tags[statLevel.widgetTranslation.tag].value;
        statLevelTranslations.rewardDetails = rewardDetailsInterpolateMsg;
        statLevelTranslations.rewardTitle = rewardTitleInterpolateMsg;

        return statLevelTranslations;
      });
  }

  async getOptInTranslations(contestData: Contest, contestTranslations: any): Promise<ContestTranslations> {
    const titleInterpolate = CONTEST_TRANSLATIONS.title.replace('{{contestId}}', contestData.contestId.toString());
    const qualifySummaryInterpolate = CONTEST_TRANSLATIONS.qualifySummary.replace(
      '{{contestId}}',
      contestData.contestId.toString()
    );
    return this.translateService
      .getTranslationListPromise([
        CONTEST_TRANSLATIONS.optIn,
        CONTEST_TRANSLATIONS.optInButton,
        titleInterpolate,
        CONTEST_TRANSLATIONS.qualifyTitle,
        qualifySummaryInterpolate,
        CONTEST_TRANSLATIONS.seeContestDetails,
        CONTEST_TRANSLATIONS.doublePointsPeriod,
        CONTEST_TRANSLATIONS.cvp,
        CONTEST_TRANSLATIONS.svp,
        CONTEST_TRANSLATIONS.dvp,
      ])
      .then((tags: { [tag: string]: any }): ContestTranslations => {
        contestTranslations.title = tags[titleInterpolate].value;
        contestTranslations.optIn = tags[CONTEST_TRANSLATIONS.optIn].value;
        contestTranslations.optInButton = tags[CONTEST_TRANSLATIONS.optInButton].value;
        contestTranslations.qualifyTitle = tags[CONTEST_TRANSLATIONS.qualifyTitle].value;
        contestTranslations.qualifySummary = tags[qualifySummaryInterpolate].value;
        contestTranslations.seeContestDetails = tags[CONTEST_TRANSLATIONS.seeContestDetails].value;
        contestTranslations.doublePointsPeriod = tags[CONTEST_TRANSLATIONS.doublePointsPeriod].value;
        contestTranslations.cvp = tags[CONTEST_TRANSLATIONS.cvp].value;
        contestTranslations.svp = tags[CONTEST_TRANSLATIONS.svp].value;
        contestTranslations.dvp = tags[CONTEST_TRANSLATIONS.dvp].value;

        return contestTranslations;
      });
  }

  getCvpGrowth(growth: number): string {
    return growth > 0 ? this.transformNumber(growth) : '0';
  }

  getSvp(svp: number): string {
    return svp ? this.transformNumber(svp) : '0';
  }

  getSvpMax(customerType: string, contestData: Contest): number {
    return contestData.svpLimitedCustTypes?.includes(customerType) &&
      contestData.svpResults.hasOwnProperty(customerType)
      ? contestData.svpLimits[customerType] || 0
      : 0;
  }

  transformNumber(number: number): string {
    return this.number.transform(number);
  }
}
