import { Component } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Notification } from '../notification.model';

@Component({
  selector: 'u-notification-details-modal',
  templateUrl: './notification-details-modal.component.html',
  styleUrls: ['./notification-details-modal.component.scss'],
})
export class NotificationDetailsModalComponent {
  data: { notification: Notification; icon: IconDefinition };

  trackByIndex(index: number): number {
    return index;
  }
}
