<div class="subMenuHeaderBox">
  <div class="row headerTitleRow">
    <div class="col-10">
      <a href="#" (click)="onClose()" attr.aria-label="{{ 'close' | translate }}">
        <img
          src="/hub/assets/images/img_trans.gif"
          width="1"
          height="1"
          [ngClass]="!userProfile?.baoying ? 'logo-sidebar' : 'logo-sidebar-baoying'"
          alt=""
      /></a>
    </div>
    <div class="col-2 text-end">
      <fa-icon [icon]="['fas', 'times']" (click)="onClose()" class="close-icon"></fa-icon>
    </div>
  </div>
</div>
