import { Injectable } from '@angular/core';
import { PacesetterWidgetService } from '@common/pacesetter/pacesetter-widget.service';
import { LoadPacesetterWidget } from '@common/pacesetter/state/pacesetter-widget.actions';
import { PacesetterWidgetStateModel } from '@common/pacesetter/state/pacesetter-widget.model';
import { Action, State, StateContext } from '@ngxs/store';
import { filter, tap } from 'rxjs/operators';

@State<PacesetterWidgetStateModel>({
  name: 'pacesetterWidgetData',
  defaults: {
    pacesetterWidget: null,
  },
})
@Injectable()
export class PacesetterWidgetState {
  constructor(private pacesetterWidgetService: PacesetterWidgetService) {}

  @Action(LoadPacesetterWidget)
  loadPacesetterWidget(ctx: StateContext<PacesetterWidgetStateModel>) {
    return this.pacesetterWidgetService.getPacesetterWidgetData().pipe(
      filter((pacesetterWidget) => !!pacesetterWidget),
      tap((pacesetterWidget) => ctx.patchState({ pacesetterWidget }))
    );
  }
}
