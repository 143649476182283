import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';

@NgModule({
  declarations: [BreadCrumbComponent],
  exports: [BreadCrumbComponent],
  imports: [CommonModule, TranslationModule],
})
export class BreadCrumbModule {}
