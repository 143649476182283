import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CmsItemModel } from '@common/cms/cms.model';
import { CmsBaseIntervalContainerDirective } from '@common/cms/containers/cms-base-interval-container.directive';
import { TranslationService } from '@common/translation/translation.service';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngxs/store';
import { ToastService } from '@shared/toast/toast.service';
import { UsanaAnalyticsService } from '@usana/ux/analytics';
import { WINDOW_TOKEN } from '@usana/ux/common';
import { CartProduct } from '@usanaHub/app/usana-home/dashboard-widgets/product-spotlight-widget/models/product-spotlight-data';
import { ProductSpotlightService } from '@usanaHub/app/usana-home/dashboard-widgets/product-spotlight-widget/services/product-spotlight.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'u-cms-product-spotlight-container',
  templateUrl: './cms-product-spotlight-container.component.html',
  styleUrls: ['./cms-product-spotlight-container.component.scss'],
})
export class CmsProductSpotlightContainerComponent
  extends CmsBaseIntervalContainerDirective
  implements OnInit, OnChanges, OnDestroy
{
  loaded = false;
  products: CartProduct[] = [];
  shareableProductSub: Subscription;
  showCopyCheckmark = false;
  toastTranslations: { [tag: string]: any };

  protected readonly CHECK_ICON = faCheck;

  readonly TRANSLATION_TAGS = {
    copySuccessMsg: 'hub.productSpotlight.successMsg',
    copyErrorMsg: 'hub.productSpotlight.errorMsg',
  };

  constructor(
    protected readonly store: Store,
    @Inject(WINDOW_TOKEN) readonly window: any,
    private productSpotlightService: ProductSpotlightService,
    private toastService: ToastService,
    private translationService: TranslationService,
    private clipboard: Clipboard,
    private analyticsService: UsanaAnalyticsService
  ) {
    super(store, window);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onCmsItemObjLoaded();
    this.getTranslations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cmsItemObj && changes.cmsItemObj.currentValue) {
      this.onCmsItemObjLoaded();
    }
  }

  onCmsItemObjLoaded(): void {
    if (this.cmsItemObj.children) {
      this.productSpotlightService.extractUsanaIds(this.cmsItemObj.children);
      this.productSpotlightService.fetchProductsByUsanaIds().subscribe((products: CartProduct[]): void => {
        this.products = products;
        this.loaded = true;
        this.removeNonMatchingChildren();
      });
    }
  }

  removeNonMatchingChildren(): void {
    if (!this.requestAttrs.pageEdit) {
      this.cmsItemObj.children = this.cmsItemObj.children
        .filter((child: CmsItemModel): boolean => {
          const data = child.data?.productIdAutocomplete?.data;
          if (!data) {
            return false;
          }
          try {
            const dataObj = JSON.parse(data);
            return this.products.some((product: CartProduct): boolean => product.usanaId === dataObj?.usanaId);
          } catch (e) {
            console.error('Invalid JSON in productIdAutocomplete data', e);
            return false;
          }
        })
        .slice(0, 5);
    }
  }

  getTranslations(): void {
    this.translationService
      .getTranslationListPromise(Object.values(this.TRANSLATION_TAGS))
      .then((translations): void => {
        this.toastTranslations = translations;
      });
  }

  isProductsEmpty(): boolean {
    return this.products.length === 0;
  }

  goToShopUrl(slideIdx: number): string {
    if (this.loaded && !this.isProductsEmpty()) {
      const dataObj = JSON.parse(this.cmsItemObj.children[slideIdx].data.productIdAutocomplete.data);
      return '/ux/cart/product/' + dataObj.usanaId;
    }
  }

  getShareProductUrl(slideIdx: number): void {
    if (this.loaded && !this.isProductsEmpty()) {
      const dataObj = JSON.parse(this.cmsItemObj.children[slideIdx].data.productIdAutocomplete.data);
      this.shareableProductSub = this.productSpotlightService
        .getSharableProductUrl(dataObj.usanaId)
        .pipe(take(1))
        .subscribe({
          next: (url: string): void => {
            this.captureClick({ url: url, description: 'share product url' }, '');
            // have to set timeout for Safari to work
            setTimeout(() => this.copyToClipboard(url), 0);
          },
          error: () => {
            this.toastService.add('danger', this.toastTranslations[this.TRANSLATION_TAGS.copyErrorMsg].value, 2500);
          },
        });
    }
  }

  copyToClipboard(url: string): void {
    if (this.clipboard.copy(url)) {
      this.toastService.add('success', this.toastTranslations[this.TRANSLATION_TAGS.copySuccessMsg].value, 2500);
      this.onCopyClicked();
    } else {
      this.toastService.add('danger', this.toastTranslations[this.TRANSLATION_TAGS.copyErrorMsg].value, 2500);
    }
  }

  onCopyClicked(): void {
    this.showCopyCheckmark = true;

    setTimeout((): void => {
      this.showCopyCheckmark = false;
    }, 1000);
  }

  captureClick(event, eventType): void {
    const label = 'Product Spotlight widget';
    if (eventType === 'link') {
      this.analyticsService.sendEventToAllSystems('click_link', {
        url: event.target.closest('a').href,
        label: label,
        description: 'Go to product',
      });
    } else if (eventType === 'slideButton') {
      this.analyticsService.sendEventToAllSystems('click_link', {
        label: label,
        description: 'Product slide button ' + JSON.parse(event.productIdAutocomplete.data).usanaId,
      });
    } else {
      this.analyticsService.sendEventToAllSystems('click_link', {
        url: event.url,
        label: label,
        description: event.description,
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.shareableProductSub) {
      this.shareableProductSub.unsubscribe();
    }
  }
}
