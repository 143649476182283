import { Selector } from '@ngxs/store';
import { AppUser } from '../../authentication/model/user-profile';
import { UserProfileSelectors } from '../../authentication/state/user-profile.selectors';
import { CustomerTypeCodes } from '../../constants/customer-type-codes';
import { NavigationState } from './navigation.state';
import { NavigationStateModel } from './navigation.state.model';

export class NavigationSelectors {
  @Selector([NavigationState])
  static getNavLevel(state: NavigationStateModel) {
    return state.navLevelOpen;
  }

  @Selector([UserProfileSelectors.getUser])
  static getDisplayHubNavigation(user: AppUser): boolean {
    return Boolean(user && user.customerType !== CustomerTypeCodes.affiliate);
  }
}
