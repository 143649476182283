import { Component, Input } from '@angular/core';

@Component({
  selector: 'u-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class UsanaPopoverComponent {
  @Input() message: string;
  @Input() placement = 'left';

  isOpen = false;

  // this variable tells the popover not to open the next time it receives focus.
  // the reason for this is the ng-bootstrap modal now returns focus to the element after closing the modal.
  // our nav popovers will be open after the nav because of this.
  // we should revisit this when we replace this popover component with an ng-bootstrap shared-ux component.
  skipNextFocus = false;

  onBlur(): void {
    this.hidePopover();
  }

  onFocus(): void {
    if (!this.skipNextFocus) {
      this.showPopover();
    } else {
      this.skipNextFocus = false;
    }
  }

  onMouseEnter(): void {
    this.showPopover();
  }

  onMouseLeave(): void {
    this.hidePopover();
  }

  onClick(): void {
    this.skipNextFocus = true;
  }

  showPopover(): void {
    this.isOpen = true;
  }

  hidePopover(): void {
    this.isOpen = false;
  }
}
