<div class="baoyingVolume">
  <div data-automation="baoyingVolumeReportTitle" class="title mb-5">{{ 'hub.home.volume-report' | translate }}</div>
  <div *ngIf="(volumeReport$ | async)?.document as volumeReport">
    <div class="mb-5">
      {{ 'dlm.dlmReports.forPeriodEnding' | translate }}:
      <select
        #commissionWeeks
        (change)="changeCommissionWeek(commissionWeeks.value)"
        data-automation="baoyingVolumeCommissionWeeks"
      >
        <option *ngFor="let week of commissionWeeks$ | async; trackBy: trackByIndex" [value]="week">
          {{ week | date : 'mediumDate' }}
        </option>
      </select>
    </div>
    <div class="table-responsive">
      <table data-automation="baoyingVolumeReportTable" class="table table-hover">
        <thead>
          <tr>
            <th data-automation="marketTitle">{{ 'dlm.dlmReports.market' | translate }}</th>
            <th data-automation="personalCenterTitle">{{ 'dlm.dlmReports.personalCenter' | translate }}</th>
            <th data-automation="volume01Title">{{ '01 ' + ('dlm.dlmReports.volume' | translate) }}</th>
            <th data-automation="volume03Title">{{ '03 ' + ('dlm.dlmReports.volume' | translate) }}</th>
            <th data-automation="volume04Title">{{ '04 ' + ('dlm.dlmReports.volume' | translate) }}</th>
            <th data-automation="volume02Title">{{ '02 ' + ('dlm.dlmReports.volume' | translate) }}</th>

            <th data-automation="carryOver01Title">{{ '01 ' + ('dlm.dlmReports.carryover' | translate) }}</th>
            <th data-automation="carryOver03Title">{{ '03 ' + ('dlm.dlmReports.carryover' | translate) }}</th>
            <th data-automation="carryOver04Title">{{ '04 ' + ('dlm.dlmReports.carryover' | translate) }}</th>
            <th data-automation="carryOver02Title">{{ '02 ' + ('dlm.dlmReports.carryover' | translate) }}</th>

            <th data-automation="total01Title">{{ '01 ' + ('dlm-3087' | translate) }}</th>
            <th data-automation="total03Title">{{ '03 ' + ('dlm-3087' | translate) }}</th>
            <th data-automation="total04Title">{{ '04 ' + ('dlm-3087' | translate) }}</th>
            <th data-automation="total02Title">{{ '02 ' + ('dlm-3087' | translate) }}</th>

            <th data-automation="autoOrder01Title">{{ '01 ' + ('dlm.dlmReports.autoOrder' | translate) }}</th>
            <th data-automation="autoOrder03Title">{{ '03 ' + ('dlm.dlmReports.autoOrder' | translate) }}</th>
            <th data-automation="autoOrder04Title">{{ '04 ' + ('dlm.dlmReports.autoOrder' | translate) }}</th>
            <th data-automation="autoOrder02Title">{{ '02 ' + ('dlm.dlmReports.autoOrder' | translate) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of volumeReport; trackBy: trackByIndex">
            <td data-automation="market" class="bussinessCenter">
              {{
                row[2].v === 0
                  ? ('babycare.hub.parentCenter' | translate)
                  : ('babycare.hub.childCenter' | translate) + row[2].v
              }}
            </td>

            <td data-automation="personalCenter">{{ row[3].v | number : '1.2-2' }}</td>

            <td data-automation="volume01">{{ row[4].v | number : '1.2-2' }}</td>
            <td data-automation="volume04">{{ row[7].v | number : '1.2-2' }}</td>
            <td data-automation="volume03">{{ row[6].v | number : '1.2-2' }}</td>
            <td data-automation="volume02">{{ row[5].v | number : '1.2-2' }}</td>

            <td data-automation="carryOver01">{{ row[8].v | number : '1.2-2' }}</td>
            <td data-automation="carryOver04">{{ row[11].v | number : '1.2-2' }}</td>
            <td data-automation="carryOver03">{{ row[10].v | number : '1.2-2' }}</td>
            <td data-automation="carryOver02">{{ row[9].v | number : '1.2-2' }}</td>

            <td data-automation="total01">{{ row[12].v | number : '1.2-2' }}</td>
            <td data-automation="total04">{{ row[15].v | number : '1.2-2' }}</td>
            <td data-automation="total03">{{ row[14].v | number : '1.2-2' }}</td>
            <td data-automation="total02">{{ row[13].v | number : '1.2-2' }}</td>

            <td data-automation="autoOrder01">{{ row[16].v | number : '1.2-2' }}</td>
            <td data-automation="autoOrder04">{{ row[19].v | number : '1.2-2' }}</td>
            <td data-automation="autoOrder03">{{ row[18].v | number : '1.2-2' }}</td>
            <td data-automation="autoOrder02">{{ row[17].v | number : '1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="hasError" class="volume-report-loading-error" data-automation="baoyingVolumeReportErrorLoading">
    <h4>{{ 'hub.dlm.error-loading' | translate }}</h4>
  </div>
</div>
