<div class="elevation elevation4 card card-container">
  <a [href]="link" (click)="onNavClickEvent()" [target]="target ? target : '_self'" class="clickable-card">
    <div class="img-container">
      <img [src]="imgUrl" class="card-img-top img-card" [alt]="alt" />
    </div>
    <div class="card-body card-body-container">
      <h5 class="card-title">{{ title }}</h5>
      <p
        class="card-text"
        *ngIf="summary"
        [ngClass]="{ ellipsis: summary.length > 160 }"
        [innerHTML]="summary | slice : 0 : 160 | unsafe : 'html'"
      ></p>
      <div class="news-readmore link-text">
        <span>{{ linkText ? linkText : ('hub.news.read-more' | translate) }}</span>
        <fa-icon [icon]="['fas', 'chevron-right']" class="fontawesome-icons"></fa-icon>
      </div>
    </div>
  </a>
</div>
