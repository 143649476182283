import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsChildEditMenuComponent } from '@common/cms/cms-child-edit-menu/cms-child-edit-menu.component';
import { CmsLinkComponent } from '@common/cms/cms-link/cms-link.component';
import { ContainerEditMenuComponent } from '@common/cms/container-edit-menu/container-edit-menu.component';
import { CmsHubBannerSliderContainerComponent } from '@common/cms/containers/banner-slider/cms-hub-banner-slider-container.component';
import { CmsColumnedContainerComponent } from '@common/cms/containers/columned/cms-columned-container.component';
import { CmsGalleryContainerComponent } from '@common/cms/containers/gallery/cms-gallery-container.component';
import { CmsProductSpotlightContainerComponent } from '@common/cms/containers/product-spotlight/cms-product-spotlight-container.component';
import { CmsSlideContainerComponent } from '@common/cms/containers/slide/cms-slide-container.component';
import { CmsHubBannerSliderItemComponent } from '@common/cms/items/banner-item/cms-hub-banner-slider-item.component';
import { CmsItemComponent } from '@common/cms/items/cms-item.component';
import { CmsContestOptInComponent } from '@common/cms/items/contest-opt-in/cms-contest-opt-in.component';
import { MiniCardCarouselComponent } from '@common/cms/items/mini-card-carousel/mini-card-carousel.component';
import { CmsProductSpotlightItemComponent } from '@common/cms/items/product-spotlight-item/cms-product-spotlight-item.component';
import { CmsSlideItemComponent } from '@common/cms/items/slide/cms-slide-item.component';
import { CmsTitledItemComponent } from '@common/cms/items/titled-item/cms-titled-item.component';
import { CmsPageComponent } from '@common/cms/page/cms-page.component';
import { CmsLinkService } from '@common/cms/services/cms-link.service';
import { CmsState } from '@common/cms/state/cms.state';
import { ContestServiceModule } from '@common/contest/contest-service.module';
import { TranslationModule } from '@common/translation/translation.module';
import { NgxsModule } from '@ngxs/store';
import { BreadCrumbModule } from '@shared/bread-crumb/bread-crumb.module';
import { HtmlToPlainTextModule } from '@shared/pipes/html-to-plain-text/html-to-plain-text.module';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { UsanaPopoverModule } from '@shared/popover/popover.module';
import { UsanaAnalyticsModule } from '@usana/ux/analytics';
import { UniversalButtonModule, UniversalLoadingButtonModule } from '@usana/ux/universal-components/button';
import { UniversalLoadingSpinnerModule } from '@usana/ux/universal-components/loading-spinner';
import { UniversalModalModule } from '@usana/ux/universal-components/modal';
import { TaskTrackerWidgetModule } from '@usanaHub/app/task-tracker-widget/task-tracker-widget.module';
import { HomeWidgetComponent } from '@usanaHub/app/usana-home/components/home-widget/home-widget.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CommonIconsModule } from '../common-icons/common-icons.module';
import { CmsAddButtonComponent } from './cms-add-button/cms-add-button.component';
import { CmsBasicContainerComponent } from './cms-basic-container/cms-basic-container.component';
import { CmsBasicItemComponent } from './cms-basic-item/cms-basic-item.component';
import { CmsGroupContainerComponent } from './cms-group-container/cms-group-container.component';
import { CmsGroupPageComponent } from './cms-group-page/cms-group-page.component';
import { CmsHubNavBlockContainerComponent } from './cms-hub-nav-block-container/cms-hub-nav-block-container.component';
import { CmsHubSideNavModalComponent } from './cms-hub-nav-block-container/cms-hub-side-nav-modal/cms-hub-side-nav-modal.component';
import { CmsHubNavItemComponent } from './cms-hub-nav-item/cms-hub-nav-item.component';
import { CmsHubNavSubgroupContainerComponent } from './cms-hub-nav-subgroup-container/cms-hub-nav-subgroup-container.component';
import { CmsImageLinkItemComponent } from './cms-image-link-item/cms-image-link-item.component';
import { CmsLinkItemComponent } from './cms-link-item/cms-link-item.component';
import { CmsPanelContainerComponent } from './cms-panel-container/cms-panel-container.component';
import { CmsSideNavComponent } from './cms-side-nav/cms-side-nav.component';
import { CmsContestStatPageComponent } from './cms-stat-page/cms-contest-stat-page.component';
import { CmsTaskTrackerComponent } from './cms-task-tracker/cms-task-tracker.component';
import { CmsUsanaNewsContainerComponent } from './news/usana-news-container.component';
import { CmsUsanaNewsItemComponent } from './news/usana-news-item.component';
import { CmsUsanaNewsLandingItemComponent } from './news/usana-news-landing-item.component';
import { CmsUsanaNewsLandingComponent } from './news/usana-news-landing.component';

const components = [
  CmsItemComponent,
  CmsPageComponent,
  CmsAddButtonComponent,
  CmsBasicContainerComponent,
  CmsBasicItemComponent,
  CmsGroupContainerComponent,
  CmsGroupPageComponent,
  CmsImageLinkItemComponent,
  CmsLinkComponent,
  CmsLinkItemComponent,
  CmsChildEditMenuComponent,
  ContainerEditMenuComponent,
  CmsHubBannerSliderContainerComponent,
  CmsHubBannerSliderItemComponent,
  CmsHubNavBlockContainerComponent,
  CmsHubNavItemComponent,
  CmsHubNavSubgroupContainerComponent,
  CmsSideNavComponent,
  MiniCardCarouselComponent,
  CmsColumnedContainerComponent,
  CmsGalleryContainerComponent,
  CmsPanelContainerComponent,
  CmsSlideContainerComponent,
  CmsSlideItemComponent,
  CmsContestStatPageComponent,
  CmsTaskTrackerComponent,
  CmsTitledItemComponent,
  CmsUsanaNewsContainerComponent,
  CmsUsanaNewsItemComponent,
  CmsUsanaNewsLandingComponent,
  CmsUsanaNewsLandingItemComponent,
  CmsContestOptInComponent,
  CmsHubSideNavModalComponent,
  CmsProductSpotlightContainerComponent,
  CmsProductSpotlightItemComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
    CarouselModule.forRoot(),
    NgxsModule.forFeature([CmsState]),
    UsanaPopoverModule,
    UniversalModalModule,
    InterpolationPipeModule,
    HtmlToPlainTextModule,
    UnsafeModule,
    BreadCrumbModule,
    TranslationModule,
    UniversalLoadingSpinnerModule,
    UniversalLoadingButtonModule,
    ContestServiceModule,
    UsanaAnalyticsModule,
    CommonIconsModule,
    TaskTrackerWidgetModule,
    UniversalButtonModule,
    HomeWidgetComponent,
    ClipboardModule,
  ],
  exports: [...components],
  providers: [CmsLinkService],
})
export class CmsModule {}
