import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsBaseItemDirective } from '@common/cms/items/cms-item-base.directive';
import { Store } from '@ngxs/store';
import {
  CartProduct,
  ProductImage,
} from '@usanaHub/app/usana-home/dashboard-widgets/product-spotlight-widget/models/product-spotlight-data';
import { ProductSpotlightService } from '@usanaHub/app/usana-home/dashboard-widgets/product-spotlight-widget/services/product-spotlight.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'u-product-spotlight-item',
  templateUrl: './cms-product-spotlight-item.component.html',
  styleUrls: ['./cms-product-spotlight-item.component.scss'],
})
export class CmsProductSpotlightItemComponent extends CmsBaseItemDirective implements OnInit, OnDestroy {
  product: CartProduct;
  productSpotlightItemSub: Subscription;

  constructor(protected store: Store, private productSpotlightService: ProductSpotlightService) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    try {
      const productIdData = JSON.parse(this.cmsItemObj.data?.productIdAutocomplete?.data);
      if (productIdData && productIdData.usanaId) {
        this.productSpotlightItemSub = this.productSpotlightService
          .fetchProductsByUsanaIds()
          .pipe(take(1))
          .subscribe((products: CartProduct[]): void => {
            this.product = products.find((product: CartProduct): boolean => product.usanaId === productIdData.usanaId);
          });
      }
    } catch (error) {
      console.error('Invalid JSON in productIdAutocomplete data');
    }
  }

  isProductEmpty(): boolean {
    return !this.product;
  }

  getProductImage(): string {
    if (!this.product || this.product.productImages.length === 0) {
      return '';
    }

    const heights = [600, 400, 280];
    for (const height of heights) {
      const image = this.product.productImages.find(
        (img: ProductImage): boolean => img.type.height === height && img.imageOrder === 0
      );
      if (image) {
        return image.imagePath;
      }
    }

    return this.product.productImages[0].imagePath;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.productSpotlightItemSub) {
      this.productSpotlightItemSub.unsubscribe();
    }
  }
}
