import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { ReplaySubject } from 'rxjs';
import { Toast } from './toast.model';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private cnt: number;

  private toasts: Toast[];

  private readonly toastSubject: ReplaySubject<Toast[]>;

  constructor() {
    this.cnt = 0;
    this.toasts = [];
    this.toastSubject = new ReplaySubject(1);
  }

  getToastSubject(): ReplaySubject<Toast[]> {
    return this.toastSubject;
  }

  /**
   * add the specified toast
   * @param type - toast type, see toast types for bootstrap
   * @param msgTag - translation tag to display for message
   * @param duration - duration in ms, 0 means stay until manually closed, if none is provided 2500 will be used
   * @param title - translation tag to display for title
   * @param interpolateTitle - interpolate text for the title translation
   * @param interpolateMsg - interpolate text for the message translation
   */
  add(
    type: string,
    msgTag: string,
    duration: number = 2500,
    title?: string,
    interpolateTitle?: string,
    interpolateMsg?: string
  ): void {
    const internalId: number = this.cnt++;

    this.toasts.push(new Toast(internalId, type, msgTag, title, interpolateTitle, interpolateMsg));
    this.updateSubject();

    if (duration !== 0) {
      setTimeout(() => {
        this.removeWithInternalId(internalId);
      }, duration);
    }
  }

  removeWithInternalId(internalId: number): void {
    this.toasts = this.toasts.filter((alert) => alert.internalId !== internalId);
    this.updateSubject();
  }

  private updateSubject(): void {
    this.toastSubject.next(cloneDeep(this.toasts));
  }
}
