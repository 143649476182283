import { Component, inject } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CustomerTypeCodes } from '@common/constants/customer-type-codes';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngxs/store';
import {
  UniversalModalOptions,
  UniversalModalPosition,
  UniversalModalService,
} from '@usana/ux/universal-components/modal';
import { Observable } from 'rxjs';
import { ChangeMarketModalComponent } from '../change-market-modal/change-market-modal.component';
import { EURO_MARKETS, EURO_MARKETS_COUNTRIES } from '../change-market.constants';

@Component({
  selector: 'u-change-market-icon',
  templateUrl: './change-market-icon.component.html',
  styleUrls: ['./change-market-icon.component.scss'],
})
export class ChangeMarketIconComponent {
  readonly GLOBE_ICON = faGlobe;
  readonly CustomerTypeCodes = CustomerTypeCodes;

  userProfile$: Observable<UserProfile> = inject(Store).select(UserProfileSelectors.getUserProfile);

  constructor(private uModalService: UniversalModalService) {}

  openChangeMarketModal(): void {
    const modalOptions: UniversalModalOptions = {
      modalPosition: UniversalModalPosition.RIGHT,
    };

    this.uModalService.openCustom(modalOptions, ChangeMarketModalComponent);
  }

  euroMarkets(locale: string): boolean {
    return EURO_MARKETS.indexOf(locale) !== -1;
  }

  euroMarketsCountries(locale: string): boolean {
    return EURO_MARKETS_COUNTRIES.indexOf(locale) !== -1;
  }
}
