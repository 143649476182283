export const STAT_LEVEL_TRANSLATIONS: any = {
  contestLevel: 'contest.widget.level',
  description: '',
  rewardDetails: 'contest.widget.rewardDetails',
  rewardTitle: 'contest.widget.rewardTitle',
};
export const CONTEST_TRANSLATIONS: any = {
  optIn: 'contest.widget.optIn',
  viewContestReport: 'contest.widget.viewContestReport',
  svp: 'contest.widget.svp',
  dayRemaining: 'contest.widget.dayRemaining',
  daysRemaining: 'contest.widget.daysRemaining',
  yourProgress: 'contest.widget.yourProgress',
  rewards: 'contest.widget.rewards',
  title: 'contest{{contestId}}.widget.title',
  svpGrowthTitle: 'contest.widget.SVP.growthTitle',
  cvpGrowthTitle: 'contest.widget.CVP.growthTitle',
  cvpGrowth: 'contest.widget.cvpGrowth',
  totalSvp: 'contest.widget.totalSVP',
  svpFromPC: 'contest.widget.svpFromPC',
  svpFromOthers: 'contest.widget.svpFromOthers',
  currentTotalSvp: 'contest.widget.currentTotalSvp',
  currentCvpGrowth: 'contest.widget.currentCvpGrowth',
  cvpCurrentPeriod: 'contest.widget.cvpCurrentPeriod',
  cvpBasePeriod: 'contest.widget.cvpBasePeriod',
  weeks: 'contest.widget.weeks',
  weeklyGoal: 'contest.widget.weeklyGoal',
  takeAction: 'contest.widget.takeAction',
  onTrack: 'contest.widget.onTrack',
  goal: 'contest.widget.goal',
  doublePointsPeriod: 'contest.widget.doublePointsPeriod',
  optInButton: 'contest.widget.optInButton',
  qualifyTitle: 'contest.widget.qualifyTitle',
  qualifySummary: 'contest{{contestId}}.widget.qualifySummary',
  seeContestDetails: 'contest.widget.seeContestDetails',
  contestDatesAndRules: 'contest.widget.contestDatesAndRules',
  dataSeenRepresents: 'contest.widget.dataSeenRepresents',
  svpFromOthersMax: 'contest.widget.svpFromOthersMax',
  svpFromPCMax: 'contest.widget.svpFromPCMax',
  typeOfReward: 'contest.widget.typeOfRewards',
  learnMore: 'contest.widget.learnMore',
  rewardTitle: 'contest.widget.rewardTitle',
  growthPopoverTitle: 'contest.widget.growthPopoverTitle',
  growthPopoverMessage: 'contest.widget.growthPopoverMessage',
  svpPoints: 'contest.widget.svpPoints',
  cvp: 'contest.widget.cvp',
  dvp: 'contest.widget.dvp',
  statLevelTranslations: {},
};
