<div id="side-nav" class="col-side-nav" [class.show-mobile]="(navLevel$ | async) === NavigationLevel.FIRST">
  <span id="mobile-nav-button">
    <u-nav-header (closeNav)="onClose()" (window:resize)="onResize()"></u-nav-header>
  </span>

  <nav class="sidebar-nav">
    <ul id="nav" class="hub-nav" [class.visible]="displaySideNavigation$ | async" [class.pc-view]="(user$ | async)?.pc">
      <li class="pc-hidden">
        <u-cms-item itemId="business-hub-nav-block"></u-cms-item>
      </li>

      <li class="pc-hidden">
        <a
          *ngIf="!IS_BAOYING; else baoyingEnrollment"
          href="#/directEnrollment"
          class="nav-enroll-container"
          (click)="onClose()"
        >
          <u-cms-hub-nav-item
            title="{{ 'hub.enroll' | translate }}"
            svgPath1="M0 0h24v24H0z"
            svgPath2="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
            data-automation="teammanager.hub-enroll-icon"
          >
          </u-cms-hub-nav-item>
        </a>

        <ng-template #baoyingEnrollment>
          <a href="/frame/shopSecureForward.jsp?url=oe" class="nav-enroll-container" target="_blank">
            <u-cms-hub-nav-item
              title="{{ 'hub.enroll' | translate }}"
              svgPath1="M0 0h24v24H0z"
              svgPath2="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
              data-automation="teammanager.hub-enroll-icon"
            >
            </u-cms-hub-nav-item>
          </a>
        </ng-template>
      </li>

      <li>
        <u-cms-item itemId="shop-hub-nav-block"></u-cms-item>
      </li>

      <li class="pc-hidden">
        <u-cms-item itemId="events-hub-nav-block"></u-cms-item>
      </li>

      <li *ngIf="!IS_BAOYING" class="pc-hidden">
        <a href="/mvc/fuse/loginRedirect" target="_blank">
          <u-cms-hub-nav-item
            title="{{ 'hub.training' | translate }}"
            svgPath1="M0 0h24v24H0z"
            svgPath2="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"
            data-automation="teammanager.hub-lms-icon"
          >
          </u-cms-hub-nav-item>
        </a>
      </li>

      <li class="pc-hidden">
        <u-cms-item itemId="training-hub-nav-block"></u-cms-item>
      </li>

      <ng-container *ngIf="IS_BAOYING" class="pc-hidden">
        <li>
          <u-cms-item itemId="media-and-internet-hub-nav-block"></u-cms-item>
        </li>
        <li>
          <u-cms-item itemId="news-hub-nav-block"></u-cms-item>
        </li>
      </ng-container>

      <li *ngIf="!IS_BAOYING" class="pc-hidden">
        <u-cms-item itemId="recognition-hub-nav-block"></u-cms-item>
      </li>
    </ul>
  </nav>
</div>
