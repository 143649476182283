import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CmsEditService } from '@common/cms/services/cms-edit.service';
import { TopNavRender } from '@common/navigation/components/top-nav/top-nav-render.model';
import { ChangeOpenNavigationLevel } from '@common/navigation/state/navigation.actions';
import { NavigationLevel } from '@common/navigation/state/navigation.state.model';
import { SystemAlertsSelectors } from '@common/system-alerts/state/system-alerts.selectors';
import { SystemAlert } from '@common/system-alerts/state/system-alerts.state.model';
import { faBars, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngxs/store';
import { LOCATION_TOKEN, WINDOW_TOKEN } from '@usana/ux/common';
import { DropdownButtonMenuItem } from '@usana/ux/universal-components/button';
import { EURO_MARKETS, EURO_MARKETS_COUNTRIES } from '@usanaHub/app/change-market/change-market.constants';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { CustomerTypeCodes } from '../../../constants/customer-type-codes';
import { NavigationSelectors } from '../../state/navigation.selectors';

@Component({
  selector: 'u-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnDestroy, OnInit {
  readonly BARS_ICON = faBars;
  readonly EXCLAMATION_TRIANGLE_ICON = faExclamationTriangle;
  readonly CustomerTypeCodes = CustomerTypeCodes;
  readonly IS_BAOYING = environment.isBaoying;

  readonly editMenuItems: DropdownButtonMenuItem[] = [
    { text: 'Toggle Edit', action: () => this.TOGGLE_PAGE_EDIT_FN() },
    { text: 'Create Page', action: () => this.createNewCmsPage() },
    { text: 'Add Images', action: () => this.showFinder() },
    { text: 'Link Builder', action: () => this.showLinkBuilder() },
  ];

  alerts$: Observable<SystemAlert[]> = this.store.select(SystemAlertsSelectors.getAlerts);
  displayUserMenu$: Observable<boolean> = this.store.select(NavigationSelectors.getDisplayHubNavigation);

  @ViewChild('navHeader', { static: false }) navHeader: ElementRef<HTMLDivElement>;

  readonly destroy$ = new Subject<void>();

  requestAttrs: UserProfile;

  navRender: TopNavRender = {
    isSticky: false,
    isMobileHide: false,
    showConnectionBar: false,
    showLocalePicker: false,
  };

  currentScrollTop = 0;

  readonly TOGGLE_PAGE_EDIT_FN = () => this.cmsEditService.togglePageEdit();

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(_event: Event): void {
    const scrolledPastHeader = this.window.scrollY > this.navHeader.nativeElement.clientHeight;
    const scrolledDown = this.window.scrollY > this.currentScrollTop;

    // become sticky once we scroll past the header, and don't unstick until we're back at the top
    this.navRender.isSticky = scrolledPastHeader || (this.navRender.isSticky && this.window.scrollY > 0);
    this.navRender.isMobileHide = scrolledDown && scrolledPastHeader;

    this.currentScrollTop = window.scrollY;
  }

  constructor(
    private readonly cmsEditService: CmsEditService,
    private readonly store: Store,
    @Inject(WINDOW_TOKEN) private readonly window: Window,
    @Inject(LOCATION_TOKEN) private readonly location: Location,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.store
      .select(UserProfileSelectors.getUserProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile): void => {
        this.requestAttrs = userProfile;
        if (this.requestAttrs && (this.requestAttrs.contentEditor || this.requestAttrs.switchUserAuthority)) {
          this.navRender.showConnectionBar = true;
        }
        if (
          this.requestAttrs &&
          !this.requestAttrs.pc &&
          ((this.requestAttrs.baoying && this.requestAttrs.contentEditor) || !this.requestAttrs.baoying) &&
          ((this.euroMarketsCountries(this.requestAttrs.user.country) && this.requestAttrs.contentEditor) ||
            !this.euroMarketsCountries(this.requestAttrs.user.country))
        ) {
          this.navRender.showLocalePicker = true;
        }
      });
  }

  openFirstLevelNav(): void {
    if (this.requestAttrs?.user) {
      this.store.dispatch(new ChangeOpenNavigationLevel(NavigationLevel.FIRST));
    }
  }

  createNewCmsPage(): void {
    this.cmsEditService.cmPopup('/mvc/cm/Manager/CreatePage?site=' + this.cmsEditService.getSite());
  }

  showLinkBuilder(): void {
    this.cmsEditService.cmPopup('/mvc/cm/Manager/LinkBuilder');
  }

  showFinder(): void {
    this.window.open('/hub/#/imageUpload', 'Image Upload', 'height=800,width=1000');
  }

  euroMarkets(locale: string): boolean {
    return EURO_MARKETS.indexOf(locale) !== -1;
  }

  euroMarketsCountries(locale: string): boolean {
    return EURO_MARKETS_COUNTRIES.indexOf(locale) !== -1;
  }

  navigateHome(): void {
    if (this.requestAttrs) {
      if (this.requestAttrs.user.customerType === CustomerTypeCodes.affiliate) {
        this.location.assign(
          `https://${environment.STATIC_CART_ORIGIN_SUBDOMAIN}.${environment.DEPLOY_SITEDOMAIN}${environment.STATIC_CART_ORIGIN_ROOT_URI}`,
        );
      } else {
        this.location.assign('/hub/#/home');
      }
    }
  }

  get adminToolButtonData() {
    return {
      automationId: 'adminTools',
      type: 'secondary',
      size: 'small',
    };
  }
}
