import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationBellComponent } from '@common/notifications/notification-bell/notification-bell.component';
import { NotificationWidgetModule } from '@common/notifications/notification-widget/notification-widget.module';
import { TranslationModule } from '@common/translation/translation.module';
import { CommonIconsModule } from '../../common-icons/common-icons.module';

@NgModule({
  declarations: [NotificationBellComponent],
  imports: [CommonModule, NotificationWidgetModule, TranslationModule, CommonIconsModule],
  exports: [NotificationBellComponent],
})
export class NotificationBellModule {}
