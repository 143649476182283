import { AuthTimeoutHandler } from '@common/authentication/model/AuthTimeoutHandler';

export class AuthTimeoutStatus {
  private lastTime: number;
  private timeoutAlert: AuthTimeoutHandler;
  private interval: AuthTimeoutHandler;

  private timeoutAlertHandler;
  private intervalHandler;

  /**
   *
   * @param timeoutAlert - timeout alert definition
   * @param startTime - start time in milliseconds
   * @param interval - interval definition
   */
  constructor(timeoutAlert: AuthTimeoutHandler, startTime: number, interval: AuthTimeoutHandler) {
    this.timeoutAlert = timeoutAlert;
    this.interval = interval;
    this.lastTime = startTime;
  }

  cleanup(): void {
    clearTimeout(this.timeoutAlertHandler);
    clearInterval(this.intervalHandler);
  }

  start(): void {
    this.startTimeoutAlert();
    this.startInterval();
  }

  /**
   * was kept alive at this dt, this also resets the timeout alert
   * @param dt - the timestamp we kept alive at
   */
  keptAlive(dt: number): void {
    this.lastTime = dt;
    clearTimeout(this.timeoutAlertHandler);
    this.startTimeoutAlert();
  }

  /**
   * has enough time passed since the last time we kept alive
   * @param dt - the current time
   */
  afterInterval(dt: number): boolean {
    return dt > this.lastTime + this.interval.time;
  }

  private startTimeoutAlert(): void {
    this.timeoutAlertHandler = setTimeout(this.timeoutAlert.handler, this.timeoutAlert.time);
  }

  private startInterval(): void {
    this.intervalHandler = setInterval(this.interval.handler, this.interval.time);
  }
}
