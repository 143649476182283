import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { CmsCurrentPage } from '@common/cms/state/cms.state.model';
import { Store } from '@ngxs/store';
import { CmsBaseContainerDirective } from '../containers/cms-base-container.directive';

@Component({
  selector: 'u-cms-group-container',
  templateUrl: './cms-group-container.component.html',
  styleUrls: ['./cms-group-container.component.scss'],
})
export class CmsGroupContainerComponent extends CmsBaseContainerDirective implements OnInit, OnDestroy {
  currentItemIds: string;
  firstSubPage: string;
  showCmsItem: string | null;
  pageId: string;

  constructor(protected readonly store: Store) {
    super(store);
  }

  ngOnInit(): void {
    if (this.cmsItemObj.children.length > 0) {
      this.firstSubPage = this.cmsItemObj.children[0].item.id;
    }
    this.addSubscription(
      this.store.select(CmsSelectors.getCmsCurrentPage).subscribe((currentPage: CmsCurrentPage) => {
        // when the page changes update the selected subpage.
        if (currentPage.subPages.length > 0) {
          this.showCmsItem = currentPage.subPages[0];
          this.defaultIfSubPageDoesntExist();
        } else {
          this.showCmsItem = this.firstSubPage;
        }
        this.pageId = currentPage.pageId;
      })
    );
  }

  defaultIfSubPageDoesntExist() {
    // first if we have a null or empty id, don't do the rest of the work
    if (!this.showCmsItem) {
      this.showCmsItem = this.firstSubPage;
      return;
    }
    let childExists = false;
    for (const item of this.cmsItemObj.children) {
      if (item.item.id === this.showCmsItem) {
        childExists = true;
        break;
      }
    }
    if (!childExists) {
      this.showCmsItem = this.firstSubPage;
    }
  }
}
