import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FPVReportModel } from '@common/reports/reports.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaoyingVolumeReportService {
  private static handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  constructor(private http: HttpClient) {}

  getCommissionWeeks(): Observable<number[]> {
    const url = '/mvc/reporting/commissionWeeks';
    return this.http.get(url).pipe(
      map((commissionWeeks: number[]) => commissionWeeks),
      catchError(BaoyingVolumeReportService.handleError)
    );
  }

  getBaoyingVolumeReport(commissionWeek): Observable<FPVReportModel> {
    const url = '/dlm2/baoying/volume/' + commissionWeek;
    return this.http.get(url).pipe(
      map((baoyingVolumeReport: FPVReportModel) => baoyingVolumeReport),
      catchError(BaoyingVolumeReportService.handleError)
    );
  }
}
