import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardsHorizontalComponent } from './cards-horizontal/cards-horizontal.component';

@NgModule({
  declarations: [CardsHorizontalComponent],
  exports: [CardsHorizontalComponent],
  imports: [CommonModule],
})
export class CardsHorizontalModule {}
