import { gql } from '@apollo/client/core';

export const CONTEST_WIDGET_QUERY = {
  GET_ALL_CONTEST_STATS: gql`
    query getAllContestStats {
      getAllContestStats {
        message
        responseObject {
          contestId
          description
          contestantLevel
          lastUpdated
          weeksInContest
          weeksPassedInContest
          weeksLeftInContest
          daysLeftInContest
          pointsToNextLevel
          nextLevelPoints
          growthOnTrack
          optedIn
          statLevels {
            contestId
            contestLevel
            rangeMin
            rangeMax
            secondaryRangeMin
            secondaryRangeMax
            translation {
              tag
            }
            widgetTranslation {
              tag
            }
            lastModifiedBy
          }
          prizeLevel {
            tag
          }
          growthType
          svp
          svpResults {
            A
            AM
            PC
          }
          svpLimits {
            A
            AM
            PC
          }
          progressPercentage
          svpLimitedCustTypes
          numberSponsored
          pastCvpTotal
          currentCvpTotal
          currentCvpAverage
          cvpGrowth
          pointsNeededType
          svpNextLevelPoints
          cvpNextLevelPoints
          svpLevel
          cvpLevel
          svpLastUpdated
          cvpLastUpdated
          startDate
          endDate
          showRewardsSection
          displayReportLink
          widgetImageUrl
          url
          optInPageUrl
          infoPageUrl
          weeklyGoals
          sponsorId
          pointsNeeded
          widgetStartDate
          widgetEndDate
          isPromoPeriod
        }
        type
      }
    }
  `,

  OPT_INTO_CONTEST: gql`
    mutation optIntoContest($contestId: Int) {
      optIntoContest(contestId: $contestId) {
        message
        responseObject {
          contestId
          registered
          canRegister
          translation {
            tag
          }
        }
        type
      }
    }
  `,
};
