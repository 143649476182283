import { ChartData, ChartOptions, ChartType } from 'chart.js';

export const BAOYING_VOLUME_LABEL = ['01', '03', '04', '02'];

export const CHART_OPTIONS: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 30,
      bottom: 0,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        display: true,
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      min: 0,
      max: 5000,
      ticks: {
        stepSize: 1000,
      },
    },
  },
};

export const BAOYING_BAR_CHART_DATA: ChartData = {
  labels: ['01', '03', '04', '02'],
  datasets: [
    {
      data: [],
      label: 'dlm.dlmReports.volume',
      maxBarThickness: 10,
      backgroundColor: '#979797',
      borderColor: '#979797',
      hoverBackgroundColor: '#979797',
      hoverBorderColor: '#979797',
      borderWidth: 1,
    },
    {
      data: [],
      label: 'dlm.dlmReports.carryover',
      backgroundColor: '#408FDE',
      borderColor: '#408FDE',
      maxBarThickness: 10,
      hoverBackgroundColor: '#408FDE',
      hoverBorderColor: '#408FDE',
      borderWidth: 1,
    },
  ],
};

export const TITLE_TAG: string = 'hub.home.volume-report';
