import { Component } from '@angular/core';
import { QuickLinksModel } from './quick-links.model';
import { QuickLinksService } from './quick-links.service';

@Component({
  selector: 'u-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent {
  constructor(private quickLinksService: QuickLinksService) {}

  get quickLinksObj(): QuickLinksModel[] {
    return this.quickLinksService.quickLinks;
  }

  trackById(_index: number, quickLink: QuickLinksModel): string {
    return quickLink.id;
  }
}
