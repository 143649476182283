import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FpvReportService } from '@common/fpv-widget/fpv-report.service';
import { FpvReportState } from '@common/fpv-widget/state/fpv-report.state';
import { NgxsModule } from '@ngxs/store';
import { BaoyingFpvReportModule } from './baoying-fpv-report/baoying-fpv-report.module';
import { BaoyingFpvWidgetModule } from './baoying-fpv-widget/baoying-fpv-widget.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxsModule.forFeature([FpvReportState]), BaoyingFpvReportModule, BaoyingFpvWidgetModule],
  exports: [BaoyingFpvReportModule, BaoyingFpvWidgetModule],
  providers: [FpvReportService],
})
export class BaoyingFpvModule {}
