<ng-container *ngIf="requestAttrs">
  <u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
  <div class="cms-gallery-container">
    <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
      <div
        class="cms-gallery-slider"
        [hidden]="!(slideIdx === idx || (requestAttrs.pageEdit && activeEditItemId === cmsItemObj.item.id))"
      >
        <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
        <u-cms-item [itemId]="childItem.item.id"></u-cms-item>
      </div>
    </ng-container>
    <div class="cms-gallery-slide-chooser">
      <div class="cms-gallery-slide-buttons">
        <div
          *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId"
          class="cms-slide-select"
          [ngClass]="{ 'cms-current-slide': idx === slideIdx }"
          (click)="forceActiveSlide(idx)"
        ></div>
      </div>
      <span *ngIf="cmsItemObj.children[slideIdx].linkUrl" class="cms-gallery-overlay-link">
        <u-cms-link
          [cmsItemObj]="cmsItemObj.children[slideIdx].linkUrl"
          [cmsLinkClass]="'cms-gallery-overlay-link'"
        ></u-cms-link>
      </span>
    </div>
    <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
  </div>
</ng-container>
