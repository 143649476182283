import { Component } from '@angular/core';
import { CmsBaseContainerDirective } from '@common/cms/containers/cms-base-container.directive';
import { TRAINING_CARD_SIZE } from '@common/cms/training/training.constants';
import { Store } from '@ngxs/store';

@Component({
  selector: 'u-training-sub-category-container',
  templateUrl: './training-sub-category-container.component.html',
  styleUrls: ['./training-sub-category-container.component.scss'],
})
export class TrainingSubCategoryContainerComponent extends CmsBaseContainerDirective {
  vertical = TRAINING_CARD_SIZE.VERTICAL;

  constructor(protected readonly store: Store) {
    super(store);
  }
}
