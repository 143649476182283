<div class="notification-row" [ngClass]="notification.read === true ? 'read' : ''">
  <button class="notification-button" (click)="openNotificationDetailsModal()">
    <div class="notification-details">
      <div class="notification-category-icon">
        <fa-icon [icon]="icon"></fa-icon>
      </div>
      <div class="notification-subject-container">
        <h5 class="notification-title">{{ notification.category.displayText }}</h5>
        <ng-container *ngFor="let notificationContent of notification.content; trackBy: trackByIndex">
          <ng-container *ngIf="notificationContent.part === 'SUBJECT'">
            <div class="body-text-2 notification-subject text-start">
              <span
                [ngClass]="{ ellipsis: notificationContent.text && notificationContent.text.length > 100 }"
                [innerHTML]="'<span>' + notificationContent.text + '</span>' | unsafe : 'html'"
              >
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div [ngClass]="{ notificationTimeOnly: notification.deleted }" class="text-end">
      <button *ngIf="!notification.deleted" class="notification-more-icon" (click)="onDelete($event)">
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
      </button>

      <div class="notification-time">
        <span>{{ notification.notificationTime | usanaDate : 'medium' }}</span>
      </div>
    </div>
  </button>
</div>
