import { Injectable } from '@angular/core';

@Injectable()
export class WindowSizingService {
  readonly xsBreakpoint = 336;
  readonly mobileBreakpoint = 767;
  readonly tabletBreakpoint = 959;
  readonly hubBannerBreakPoint = 1365;
  readonly xxlBreakpoint = 1400;

  get width() {
    return document.body.offsetWidth;
  }

  isDesktopSize() {
    return this.width > this.tabletBreakpoint;
  }

  isBannerDesktopSize() {
    return this.width >= this.hubBannerBreakPoint;
  }

  isHubBannerBreakPointSize() {
    return this.width > this.mobileBreakpoint && this.width <= this.hubBannerBreakPoint;
  }

  isTabletSize() {
    return this.width > this.mobileBreakpoint && this.width <= this.tabletBreakpoint;
  }

  isMobileSize() {
    return this.width <= this.mobileBreakpoint;
  }

  isXSMobileSize() {
    return this.width <= this.xsBreakpoint;
  }

  isXxlDesktopSize() {
    return this.width >= this.xxlBreakpoint;
  }

  getElementOffset(element) {
    const documentElement = document.documentElement;
    const box = element[0].getBoundingClientRect();
    const top = box.top + window.pageYOffset - documentElement.clientTop;
    const left = box.left + window.pageXOffset - documentElement.clientLeft;
    return { top: top, left: left };
  }
}
