import { MyAccountListModel } from '@common/my-account/my-account.model';

export const BAOYING_MY_ACCOUNT_LIST: Array<MyAccountListModel> = [
  {
    id: 'aboutMe',
    url: '/hub/#/baoyingAboutMe',
    translationTag: 'hub.baoyingAboutMe',
    target: '',
    hideForPc: false,
    hideForCountries: { values: [] },
  },
  {
    id: 'profileManager',
    url: '/frame/shopSecureForward.jsp?url=profile',
    translationTag: 'hub.profileManager',
    target: '_blank',
    hideForPc: false,
    hideForCountries: { values: [] },
  },
  {
    id: 'myOrders',
    url: '/ux/mall/#/customer/orderHistory?source=HUB',
    translationTag: 'hub.myOrders',
    target: '_blank',
    hideForPc: false,
    hideForCountries: { values: [] },
  },
];
