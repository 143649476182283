import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InterpolatePipe } from '@shared/pipes/interpolate/interpolate.pipe';

@NgModule({
  declarations: [InterpolatePipe],
  imports: [CommonModule],
  exports: [InterpolatePipe],
})
export class InterpolationPipeModule {}
