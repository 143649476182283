import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlToPlainText',
})
export class HtmlToPlainTextPipe implements PipeTransform {
  transform(html: string) {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || '';
  }
}
