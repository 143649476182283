<div class="notification-widget-content">
  <ng-container *ngFor="let notification of displayedNotifications; trackBy: trackById">
    <u-notification-list-item
      (closeModal)="activeNotification = null"
      (viewNotification)="viewNotification($event)"
      (deleteNotification)="deleteNotification(notification)"
      [notification]="notification"
    ></u-notification-list-item>
  </ng-container>

  <div class="text-center no-notifications" *ngIf="!loadingNotifications && !displayedNotifications.length">
    {{ 'notifications.landing.noNewNotifications' | translate }}
  </div>

  <div *ngIf="loadingNotifications">
    <u-loading-spinner></u-loading-spinner>
  </div>

  <div class="notification-row notification-footer" *ngIf="displayedNotifications.length && limit">
    <div class="col-12 text-center">
      <universal-button
        (click)="navigateToNotificationPage()"
        [data]="{ type: 'tertiary', id: 'view-all-notifications-button' }"
      >
        {{ 'notifications.viewAll' | translate }}
      </universal-button>
    </div>
  </div>
</div>
