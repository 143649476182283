import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsItemService } from '@common/cms/cms-item.service';
import { CmsItemModel } from '@common/cms/cms.model';
import { LoadCmsItem } from '@common/cms/state/cms.actions';
import { CMS_KEY_DATA, CMS_KEY_SITE, CMS_KEY_TARGET, CMS_TARGET, CMS_URL } from '@common/cms/state/cms.constants';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { CmsItemEntry } from '@common/cms/state/cms.state.model';
import { USANA_NEWS_ROOT_ID } from '@common/usana-news/usana-news.constants';
import { UsanaNewsItem } from '@common/usana-news/usana-news.model';
import { Store } from '@ngxs/store';
import {
  UniversalModalOptions,
  UniversalModalPosition,
  UniversalModalService,
} from '@usana/ux/universal-components/modal';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { UsanaNewsModalComponent } from '../usana-news-modal/usana-news-modal.component';

@Component({
  selector: 'u-usana-news-widget',
  templateUrl: './usana-news-widget.component.html',
  styleUrls: ['./usana-news-widget.component.scss'],
})
export class UsanaNewsWidgetComponent implements OnInit, OnDestroy {
  cmsItemByIdFn$: Observable<(cmsItemId: string) => CmsItemEntry> = this.store.select(CmsSelectors.getCmsItemByIdFn);
  linkedCmsItemByIdFn$: Observable<(cmsItemId: string) => CmsItemEntry> = this.store.select(
    CmsSelectors.getCmsItemByIdFn
  );

  loadingUsanaNews: boolean;
  usanaNews: CmsItemModel[];
  usanaNewsModalItem: CmsItemModel;
  subscription = new Subscription();
  cmsItemById$: Observable<CmsItemEntry>;
  linkedCmsItemById$: Observable<CmsItemEntry>;

  constructor(
    private uModalService: UniversalModalService,
    private cmsItemService: CmsItemService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadCmsItem(USANA_NEWS_ROOT_ID));
    this.getUsanaNews();
  }

  getUsanaNews() {
    this.cmsItemById$ = this.cmsItemByIdFn$.pipe(map((lookupFn) => lookupFn(USANA_NEWS_ROOT_ID))).pipe(delay(50));
    this.subscription.add(
      this.cmsItemById$.subscribe((usanaNews) => {
        if (usanaNews) {
          this.loadingUsanaNews = usanaNews.isLoading;
          if (usanaNews.data) {
            this.usanaNews = cloneDeep(usanaNews.data.children.slice(0, 10));
            for (const item of this.usanaNews) {
              // convert each news item to a stricter UsanaNewsItem object that will provide defaults for missing data
              item.data = new UsanaNewsItem(item.data);
            }
          }
        } else {
          this.usanaNews = [];
          this.store.dispatch(new LoadCmsItem(USANA_NEWS_ROOT_ID));
        }
      })
    );
  }

  openUsanaNewsDetails(usanaNewsItem: CmsItemModel): void {
    if (usanaNewsItem.data.linkType.data === 'internal') {
      const childItemId = this.cmsItemService.getValueFromLinkData(usanaNewsItem, CMS_KEY_DATA);

      this.store.dispatch(new LoadCmsItem(childItemId)).subscribe(() => {
        this.observeLinkedUsanaNews(childItemId);

        if (this.usanaNewsModalItem.type.id === 'usanaNewsItem') {
          this.openUsanaNewsModal(this.usanaNewsModalItem);
        } else {
          const itemUrl = CMS_URL + this.usanaNewsModalItem.parent.id;
          this.openUrl(itemUrl, CMS_TARGET.SELF);
        }
      });
    } else if (usanaNewsItem.data.linkType.data === 'none') {
      // do nothing, it is not a link
    } else {
      const itemUrl = this.cmsItemService.getValueFromLinkData(usanaNewsItem, CMS_KEY_SITE);
      const target = this.cmsItemService.getValueFromLinkData(usanaNewsItem, CMS_KEY_TARGET);
      this.openUrl(itemUrl, target);
    }
  }

  observeLinkedUsanaNews(childItemId) {
    this.linkedCmsItemById$ = this.linkedCmsItemByIdFn$.pipe(map((lookupFn) => lookupFn(childItemId)));
    this.subscription.add(
      this.linkedCmsItemById$.subscribe((linkedUsanaNews) => {
        if (linkedUsanaNews && linkedUsanaNews.data) {
          this.usanaNewsModalItem = linkedUsanaNews.data.children[0];
        }
      })
    );
  }

  openUsanaNewsModal(usanaNewsItem): void {
    const modalOptions: UniversalModalOptions = {
      modalPosition: UniversalModalPosition.RIGHT,
      data: { usanaNewsItem },
    };

    this.uModalService.openCustom(modalOptions, UsanaNewsModalComponent);
  }

  openUrl(itemUrl, target) {
    window.open(itemUrl, target);
  }

  trackByIndex(index) {
    return index;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
