import { faCheck, faExclamation, faExclamationTriangle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

export class Toast {
  internalId: number;
  type: string;
  msgTag: string;
  title?: string;
  interpolateTitle?: string;
  interpolateMsg?: string;

  constructor(
    internalId: number,
    type: string,
    msgTag: string,
    title?: string,
    interpolateTitle?: string,
    interpolateMsg?: string
  ) {
    this.internalId = internalId;
    this.type = type;
    this.msgTag = msgTag;
    this.title = title || null;
    this.interpolateTitle = interpolateTitle || null;
    this.interpolateMsg = interpolateMsg || null;
  }

  getIcon(): IconDefinition {
    switch (this.type) {
      case 'success':
        return faCheck;
      case 'danger':
        return faExclamation;
      case 'warning':
        return faExclamationTriangle;
      default:
        return undefined;
    }
  }
}
