<div class="changeMarketTitleBox cmT">
  <accordion *ngIf="markets && markets.length > 1">
    <accordion-group
      *ngFor="let country of markets; trackBy: trackByCountry"
      [isOpen]="selectedCountry === country.country"
    >
      <button accordion-heading class="market-accordion-header">
        <div class="panel-default">
          <div class="panel-heading">
            <span
              class="body-text-2 flag-icon {{ removeSpaces(country.country) | lowercase }}"
              *ngIf="removeSpaces(country.country) !== 'Europe'"
              >&nbsp;</span
            >
            <span *ngIf="removeSpaces(country.country) === 'Europe'" class="market-change-list-icon world-icon">
              <fa-icon [icon]="GLOBE_ICON" class="fontawesome-icons"></fa-icon>
            </span>
            {{ 'hub.changeMarket.country.' + country.country | translate }}
            <span *ngIf="country.localesInCountry.length" class="market-change-list-icon arrowdown">
              <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
            </span>
            <span class="market-change-list-icon arrowup">
              <fa-icon [icon]="['fas', 'chevron-up']"></fa-icon>
            </span>
          </div>
          <div
            class="panel-body collapse"
            [ngClass]="{ selectedLang: selectedCountry === country.country, show: selectedLocale === country.country }"
          ></div>
        </div>
      </button>

      <button
        class="body-text-2 cntryLang"
        [ngClass]="{ selectedLang: selectedLocale === loc.locale }"
        *ngFor="let loc of country.localesInCountry; trackBy: trackByLocale"
        (click)="selectMarket(loc.locale); $event.stopPropagation()"
      >
        <span
          *ngIf="removeSpaces(country.country) !== 'Europe'"
          class="body-text-2 flag-icon {{ removeSpaces(country.country) | lowercase }}"
          >&nbsp;</span
        >
        <span *ngIf="removeSpaces(country.country) === 'Europe'" class="market-change-list-icon world-icon">
          <fa-icon [icon]="GLOBE_ICON"></fa-icon>
        </span>
        {{ 'hub.changeMarket.locale.' + loc.locale | translate }}
      </button>
    </accordion-group>
  </accordion>

  <accordion *ngIf="markets && markets.length === 1">
    <accordion-group
      class="locale-list-item"
      *ngFor="let loc of markets[0].localesInCountry; trackBy: trackByLocale"
      (click)="selectMarket(loc.locale); $event.stopPropagation()"
      tabindex="0"
      (keydown.enter)="selectMarket(loc.locale); $event.stopPropagation()"
    >
      <div accordion-heading>
        <div class="panel-default">
          <div class="panel-heading" [ngClass]="{ selectedLang: selectedLocale === loc.locale }">
            <span *ngIf="!loc.pc" class="body-text-2 flag-icon {{ removeSpaces(loc.country) | lowercase }}"
              >&nbsp;</span
            >
            <span *ngIf="loc.pc" class="market-change-list-icon pc world-icon">
              <fa-icon [icon]="GLOBE_ICON"></fa-icon>
            </span>
            {{ 'hub.changeMarket.locale.' + loc.locale | translate }}
          </div>
        </div>
      </div>
    </accordion-group>
  </accordion>
</div>
