import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DeleteSystemAlert } from './state/system-alerts.actions';
import { SystemAlertsSelectors } from './state/system-alerts.selectors';
import { SystemAlert } from './state/system-alerts.state.model';

@Component({
  selector: 'u-system-alert',
  templateUrl: './system-alert.component.html',
  styleUrls: ['./system-alert.component.scss'],
})
export class SystemAlertComponent {
  alerts$: Observable<SystemAlert[]> = this.store.select(SystemAlertsSelectors.getAlerts);

  constructor(private store: Store) {}

  closeAlert(index: number): void {
    this.store.dispatch(new DeleteSystemAlert(index));
  }

  trackById(_index: number, alert: SystemAlert) {
    return alert.alertId;
  }
}
