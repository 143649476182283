import { Component, OnInit } from '@angular/core';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { LoadReportData } from '@common/reports/state/reports.actions';
import {
  BUSINESS_CENTER_1,
  UPLINE_LEADER_DEFAULT_VALUES,
  UPLINE_LEADER_REPORT_NAME,
} from '@common/upline-leader-placement/upline-leader-placement.constants';
import { UplineLeaderPlacementReport } from '@common/upline-leader-placement/upline-leader-placement.model';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UsanaUplineReportSelectors } from '../state/usana-upline-report.selectors';

@Component({
  selector: 'u-upline-leader-placement-report',
  templateUrl: './usana-upline-leader-report.component.html',
})
export class UsanaUplineLeaderReportComponent implements OnInit {
  uplineLeaderReport$: Observable<UplineLeaderPlacementReport> = this.store.select(
    UsanaUplineReportSelectors.mapUsanaUplineReport
  );

  constructor(private store: Store) {}

  ngOnInit(): void {
    const user = this.store.selectSnapshot(UserProfileSelectors.getUser);

    const reportRequestBody = {
      ...UPLINE_LEADER_DEFAULT_VALUES,
      businessCenter: user.username + BUSINESS_CENTER_1,
    };

    this.store.dispatch(new LoadReportData(UPLINE_LEADER_REPORT_NAME, reportRequestBody));
  }
}
