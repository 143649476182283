<u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
<div *ngFor="let childItem of cmsItemObj.children" class="pb-5">
  <h5 class="hub-side-nav-title">
    {{ childItem.data.label ? childItem.data.label.data : cmsItemObj.children[0].data.label?.data }}
  </h5>

  <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
  <u-cms-item [itemId]="childItem.item.id"></u-cms-item>
  <div style="display: none" class="usanaSearchTags">{{ childItem.data.searchTags?.data }}</div>
</div>
