import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsanaDatePipeModule } from '@common/custom-locale-pipes/usana-date-pipe/usana-date-pipe.module';
import { NotificationListItemComponent } from '@common/notifications/notification-list-item/notification-list-item.component';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { CommonIconsModule } from '../../common-icons/common-icons.module';

@NgModule({
  declarations: [NotificationListItemComponent],
  imports: [CommonModule, UnsafeModule, UsanaDatePipeModule, CommonIconsModule],
  exports: [NotificationListItemComponent],
})
export class NotificationListItemModule {}
