<div class="OptIn">
  <div [class]="getDataForAttribute('style')">
    <ng-container *ngIf="contestRegDetail && !contestRegDetail.loading; else loading">
      <form class="optInForm" name="optInForm" [hidden]="!contestRegDetail.response.responseObject.canRegister">
        <div>
          <input type="checkbox" name="contestId" id="contestId" CHECKED #optInCheck />

          <strong>{{ getDataForAttribute('Title') }}</strong
          >: {{ 'frame.cm.items.OptInItem.jsp.checkBox.instruction' | translate }}
        </div>

        <universal-loading-button
          [data]="{ type: 'primary', size: 'mini', disabled: !optInChecked() }"
          [loading]="saving"
          (click)="optIn()"
          >{{ 'myUsana.contestOptIn.register' | translate }}</universal-loading-button
        >
      </form>
      <div *ngIf="contestRegDetail.response.message">
        {{ contestRegDetail.response.message | translate }}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <universal-circle-loading-spinner [style]="{ width: '32px' }"></universal-circle-loading-spinner>
</ng-template>
