<div class="home-widget">
  <div class="widget-header">
    <ng-content select="[widgetHeader]"></ng-content>
  </div>

  <div class="widget-body">
    <ng-content select="[widgetBody]"></ng-content>
  </div>

  <div class="widget-footer">
    <ng-content select="[widgetFooter]"></ng-content>
  </div>
</div>
