import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonIconsModule } from '@common/common-icons/common-icons.module';
import { NgxsModule } from '@ngxs/store';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { SystemAlertsState } from './state/system-alerts.state';
import { SystemAlertComponent } from './system-alert.component';

@NgModule({
  declarations: [SystemAlertComponent],
  imports: [CommonModule, NgxsModule.forFeature([SystemAlertsState]), UnsafeModule, CommonIconsModule],
  exports: [SystemAlertComponent],
})
export class SystemAlertsModule {}
