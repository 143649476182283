import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class ImportantDatesService {
  constructor(private http: HttpClient) {}

  getImportantDates() {
    return this.http.get('/dlm2/rest/importantDates').pipe(map((importantDates) => importantDates));
  }
}
