import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationService } from '../translation/translation.service';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  private readonly PAGE_TITLE_TAG = 'hub.title.display';

  constructor(private titleService: Title, private translationService: TranslationService) {}

  setTitle(): void {
    this.translationService.getTranslationPromise(this.PAGE_TITLE_TAG).then((translation) => {
      if (translation?.value) {
        this.titleService.setTitle(translation.value);
      }
    });
  }
}
