import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { UsanaPopoverComponent } from './popover/popover.component';

@NgModule({
  declarations: [UsanaPopoverComponent],
  imports: [CommonModule, PopoverModule.forRoot()],
  exports: [PopoverModule, UsanaPopoverComponent],
})
export class UsanaPopoverModule {}
