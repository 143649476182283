import { Component, OnDestroy, OnInit } from '@angular/core';
import { DAY_REMAINING_TRANSLATION_TAG, IMG_URL } from '@common/pacesetter/pacesetter-widget.constants';
import { PacesetterWidgetModel } from '@common/pacesetter/pacesetter-widget.model';
import { LoadPacesetterWidget } from '@common/pacesetter/state/pacesetter-widget.actions';
import { PacesetterWidgetSelectors } from '@common/pacesetter/state/pacesetter-widget.selectors';
import { faCircleCheck, faInfoCircle, faUser } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngxs/store';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'u-pacesetter-widget',
  templateUrl: './pacesetter-widget.component.html',
  styleUrls: ['./pacesetter-widget.component.scss'],
})
export class PacesetterWidgetComponent implements OnInit, OnDestroy {
  readonly INFO_CIRCLE_ICON = faInfoCircle;
  readonly USER_ICON = faUser;
  readonly CIRCLE_CHECK_ICON = faCircleCheck;

  pacesetterData$: Observable<PacesetterWidgetModel> = this.store.select(
    PacesetterWidgetSelectors.getHomePaceSetterWidgetData
  );

  isWidgetHidden = true;
  showPacesetterTab: boolean;
  showPlatinumTab: boolean;

  readonly destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadPacesetterWidget());

    this.pacesetterData$
      .pipe(
        filter((pacesetter) => !!pacesetter),
        takeUntil(this.destroy$)
      )
      .subscribe((pacesetterData) => {
        this.showPacesetterTab = pacesetterData.pacesetter.defaultTo;
        this.showPlatinumTab = pacesetterData.platinum.defaultTo;
        this.isWidgetHidden = pacesetterData.pacesetter.isHidden && pacesetterData.platinum.isHidden;
      });
  }

  switchPaceSetterView(id) {
    if (id === 'pacesetter') {
      this.showPlatinumTab = false;
      this.showPacesetterTab = true;
    } else {
      this.showPlatinumTab = true;
      this.showPacesetterTab = false;
    }
  }

  changePeopleBlue(index, numberSponsored) {
    return index < numberSponsored;
  }

  imgPicker(pacesetterType) {
    if (pacesetterType.isFinalized || pacesetterType.isQualified) {
      return IMG_URL.QUALIFIED_OR_FINALIZED;
    }
    if (pacesetterType.isInProgress) {
      return IMG_URL.IN_PROGRESS;
    }
  }

  getDaysRemainingTranslation(pacesetterType) {
    if (pacesetterType.daysRemaining === 1) {
      return DAY_REMAINING_TRANSLATION_TAG.DAY;
    } else {
      return DAY_REMAINING_TRANSLATION_TAG.DAYS;
    }
  }

  trackByIndex(index) {
    return index;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
