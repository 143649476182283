import { Component } from '@angular/core';
import { CmsBaseContainerDirective } from '@common/cms/containers/cms-base-container.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

@Component({
  selector: 'u-cms-hub-nav-subgroup-container',
  templateUrl: './cms-hub-nav-subgroup-container.component.html',
  styleUrls: ['./cms-hub-nav-subgroup-container.component.scss'],
})
export class CmsHubNavSubgroupContainerComponent extends CmsBaseContainerDirective {
  constructor(protected readonly store: Store, private modal: NgbActiveModal) {
    super(store);
  }

  close(): void {
    this.modal.close();
  }
}
