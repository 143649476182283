import { Component, OnInit } from '@angular/core';
import { LoadFpvReport } from '@common/fpv-widget/state/fpv-report.actions';
import { FpvReportModel } from '@common/fpv-widget/state/fpv-report.model';
import { FpvReportSelectors } from '@common/fpv-widget/state/fpv-report.selectors';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BAOYING_FPV_WIDGET_TAGS } from '../../constants/baoying-fpv-widget.constants';

@Component({
  selector: 'u-baoying-fpv-widget',
  templateUrl: './baoying-fpv-widget.component.html',
  styleUrls: ['./baoying-fpv-widget.component.scss'],
})
export class BaoyingFpvWidgetComponent implements OnInit {
  fpvReport$: Observable<FpvReportModel> = this.store.select(FpvReportSelectors.getFpvReport);
  loadFpvReport$: Observable<ActionsExecuting> = this.store.select(actionsExecuting([LoadFpvReport]));

  readonly baoyingFpvWidgetTags = BAOYING_FPV_WIDGET_TAGS;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new LoadFpvReport());
  }
}
