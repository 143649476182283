import { Injectable } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { Action, State, StateContext, Store } from '@ngxs/store';
import cloneDeep from 'lodash/cloneDeep';
import { map } from 'rxjs/operators';
import { SystemAlertService } from '../system-alert.service';
import { DeleteSystemAlert, LoadSystemAlerts, SetSystemAlerts } from './system-alerts.actions';
import { SystemAlertsStateModel } from './system-alerts.state.model';

@State<SystemAlertsStateModel>({
  name: 'systemAlerts',
  defaults: {
    alerts: [],
  },
})
@Injectable()
export class SystemAlertsState {
  constructor(private sysAlertSvc: SystemAlertService, private store: Store) {}

  @Action(SetSystemAlerts)
  setAlerts({ patchState }: StateContext<SystemAlertsStateModel>, action: SetSystemAlerts) {
    patchState(action);
  }

  @Action(LoadSystemAlerts)
  getAlerts({ dispatch }: StateContext<UserProfile>) {
    const userProfileState: UserProfile = this.store.snapshot().userProfile;
    if (userProfileState && userProfileState.user) {
      return this.sysAlertSvc
        .getSystemAlerts(userProfileState.user, userProfileState.locale)
        .pipe(map((alerts) => dispatch(new SetSystemAlerts(alerts))));
    }
  }

  @Action(DeleteSystemAlert)
  deleteAlert({ patchState, getState }: StateContext<SystemAlertsStateModel>, action: DeleteSystemAlert) {
    const alerts = cloneDeep(getState().alerts);
    alerts.splice(action.index, 1);
    return patchState({ alerts });
  }
}
