import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { NgxsModule } from '@ngxs/store';
import { BarChartModule } from '@shared/charts/bar-chart/bar-chart.module';
import { DataViewModule } from '@shared/data-view/data-view.module';
import { LoadingSpinnerModule } from '@shared/loading-spinner/loading-spinner.module';
import { WidgetModule } from '@usana/ux/widget';
import { BarController, BarElement, CategoryScale, Chart, LinearScale, Tooltip } from 'chart.js';
import { BaoyingIconsModule } from '../../baoying-icons/baoying-icons.module';
import { BaoyingVolumeReportState } from '../state/baoying-volume-report.state';
import { BaoyingVolumeWidgetComponent } from './baoying-volume-widget.component';

@NgModule({
  declarations: [BaoyingVolumeWidgetComponent],
  exports: [BaoyingVolumeWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    LoadingSpinnerModule,
    NgxsModule.forFeature([BaoyingVolumeReportState]),
    DataViewModule,
    BarChartModule,
    TranslationModule,
    BaoyingIconsModule,
  ],
})
export class BaoyingVolumeWidgetModule {
  constructor() {
    Chart.register(LinearScale, Tooltip, CategoryScale, BarController, BarElement);
  }
}
