// eslint-disable-next-line max-classes-per-file
import { TaskTrackerProgramProgress } from '../models/task-tracker-program-progress';

export class LoadTaskTrackerProgramProgress {
  static readonly type = '[Task Tracker] LOAD TASK TRACKER PROGRAM PROGRESS';
  constructor(public programKey: string) {}
}

export class LoadTaskTrackerProgramProgressSuccess {
  static readonly type = '[Task Tracker API] LOAD TASK TRACKER PROGRAM PROGRESS SUCCESS';
  constructor(public programProgress: TaskTrackerProgramProgress) {}
}

export class LoadTaskTrackerProgramProgressFailure {
  static readonly type = '[Task Tracker API] LOAD TASK TRACKER PROGRAM PROGRESS FAILURE';
  constructor(public programKey: string) {}
}
