import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsBaseDirective } from '@common/cms/cms-base.directive';
import { CmsLinkService } from '@common/cms/services/cms-link.service';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { CmsItemEntry } from '@common/cms/state/cms.state.model';
import { TRAINING_CARD_SIZE } from '@common/cms/training/training.constants';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'u-training-sub-category-item',
  templateUrl: './training-sub-category-item.component.html',
})
export class TrainingSubCategoryItemComponent extends CmsBaseDirective implements OnInit, OnDestroy {
  cmsItemByIdFn$: Observable<(cmsItemId: string) => CmsItemEntry> = this.store.select(CmsSelectors.getCmsItemByIdFn);

  target: string;
  cmsItemById$: Observable<CmsItemEntry>;
  trainingCardSize: string;
  cardSize = TRAINING_CARD_SIZE;

  destroy$ = new Subject<void>();

  constructor(protected readonly store: Store, private readonly cmsLinkService: CmsLinkService) {
    super(store);
  }

  ngOnInit(): void {
    // yes we pass in the same object twice, its what we used to do.
    this.target = this.cmsLinkService.getTargetFromLinkData(this.cmsItemObj, this.cmsItemObj);
    if (this.cmsItemObj) {
      this.getParentData();
    }
  }

  getParentData() {
    this.cmsItemByIdFn$
      .pipe(
        takeUntil(this.destroy$),
        map((lookupFn) => lookupFn(this.cmsItemObj.parent.id))
      )
      .subscribe((parent) => {
        if (parent && parent.data && parent.data.data.trainingCardSize) {
          this.trainingCardSize = parent.data.data.trainingCardSize.data;
        }
      });
  }
}
