import { Selector } from '@ngxs/store';
import { SystemAlertsState } from './system-alerts.state';
import { SystemAlertsStateModel } from './system-alerts.state.model';

export class SystemAlertsSelectors {
  @Selector([SystemAlertsState])
  static getAlerts(state: SystemAlertsStateModel) {
    return state.alerts;
  }
}
