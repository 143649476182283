<div *ngIf="userProfile$ | async as userProfile">
  <button
    [title]="buttonText"
    type="button"
    class="cms-btn cms-btn-add"
    *ngIf="userProfile.pageEdit && (activeEditItemId$ | async) === cmsItemObj.item.id && userProfile.editorForLocale"
    (click)="showAddButton(cmsItemObj, $event)"
  >
    <span class="cms-icon-btn cms-add-icon"></span>
    <span style="vertical-align: text-bottom">{{ buttonText }}</span>
  </button>
</div>
