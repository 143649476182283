<section class="earning-opt-in-container">
  <div class="row">
    <div class="col-md-10 col-md-offset-1">
      <div [innerHTML]="cmsItemObj.data.fpvEarning?.data"></div>
    </div>
  </div>
  <div class="row current-earnings-container" *ngIf="(optInStatus$ | async)?.earningType === 'FPV'">
    <div class="col-md-10 col-md-offset-1">
      <div>
        <fa-icon [icon]="CHECK_ICON" class="current-earnings-icons"></fa-icon>
        <span class="current-earnings-label">{{ 'hub.earnings.currentEarningsPlan' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row horizontal-row">
    <div class="col-md-10 col-md-offset-1">
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 col-md-offset-1">
      <div [innerHTML]="cmsItemObj.data.commissionEarning?.data"></div>
    </div>
  </div>
  <div class="row current-earnings-container" *ngIf="(optInStatus$ | async)?.earningType === 'COMMISSION'">
    <div class="col-md-10 col-md-offset-1">
      <div>
        <fa-icon [icon]="CHECK_ICON" class="current-earnings-icons"></fa-icon>
        <span class="current-earnings-label">{{ 'hub.earnings.currentEarningsPlan' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row opt-in-button-container" *ngIf="(optInStatus$ | async)?.earningType === 'FPV'">
    <div class="col-md-10 col-md-offset-1">
      <button class="btn btn-primary opt-in-button" (click)="confirmChoice()">
        {{ cmsItemObj.data.buttonLabel?.data }}
      </button>
    </div>
  </div>
</section>
