<div class="row no-margin space-b-16">
  <div class="col-12 space-16 pb-0">
    <div class="dashboard-breadcrumbs">
      <a href="#/home"
        ><span class="dashboard-breadcrumbs-first">{{ 'usana.hub.home' | translate }}</span>
      </a>
      <span *ngFor="let child of children; trackBy: trackByIndex">
        <span class="dashboard-breadcrumbs-divider">/</span>
        <a *ngIf="child.path" [href]="child.path">
          <span class="dashboard-breadcrumbs-first">{{
            child.isTranslationTag ? (child.name | translate) : child.name
          }}</span>
        </a>
        <span *ngIf="!child.path">{{ child.isTranslationTag ? (child.name | translate) : child.name }}</span>
      </span>
    </div>
  </div>
</div>
