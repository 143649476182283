<u-cards
  *ngIf="trainingCardSize === cardSize.VERTICAL"
  [attr.cmsItemId]="cmsItemObj.item.id"
  [imgUrl]="cmsItemObj.data.imageSrc575x323?.data"
  [alt]="cmsItemObj.data.ImageAlt?.data"
  [title]="cmsItemObj.data.title?.data"
  [summary]="cmsItemObj.data.summaryArea160Count?.data"
  [linkText]="cmsItemObj.data.linkText?.data"
  [link]="cmsItemObj.linkUrl"
  [target]="target"
  [navClickEvent]="navClickEvent"
></u-cards>

<u-related-stories-item
  *ngIf="trainingCardSize === cardSize.HORIZONTAL"
  [cmsItemObj]="cmsItemObj"
  [target]="target"
></u-related-stories-item>
