import { Injectable } from '@angular/core';
import { ImportantDatesService } from '@common/important-dates/important-dates.service';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ForceLoadUserProfile } from '../../../../common/src/authentication/state/user-profile.actions';
import { ScrollTopOnNavService } from '../../../../shared/src/services/scroll-top-on-nav/scroll-top-on-nav.service';
import { SupportedMarkets } from '../change-market/change-market.model';
import { AvailableLocalesService } from '../services/available-locales.service';
import {
  DecrementLoading,
  IncrementLoading,
  InitializeApp,
  LoadImportantDates,
  SetSupportedMarkets,
} from './app.actions';
import { AppStateModel } from './app.state.model';

export const APP_STATE_DEFAULTS: AppStateModel = {
  isBaoying: environment.isBaoying,
  isLoading: false,
  numLoading: 0,
  supportedMarkets: null,
  importantDates: null,
};

@State<AppStateModel>({
  name: 'hub',
  defaults: APP_STATE_DEFAULTS,
})
@Injectable()
export class AppState {
  constructor(
    private store: Store,
    private importantDatesService: ImportantDatesService,
    private availableLocalesService: AvailableLocalesService,
    private scrollTopOnNavService: ScrollTopOnNavService,
  ) {}

  @Action(DecrementLoading)
  decrementLoading({ getState, patchState }: StateContext<AppStateModel>) {
    const state = { ...getState() };

    state.numLoading--;
    // just to be safe
    if (state.numLoading < 0) {
      state.numLoading = 0;
    }
    state.isLoading = !!state.numLoading;

    patchState(state);
  }

  @Action(IncrementLoading)
  incrementLoading({ getState, patchState }: StateContext<AppStateModel>) {
    const state = { ...getState() };

    if (!state.isLoading) {
      state.isLoading = true;
    }

    state.numLoading++;
    patchState(state);
  }

  @Action(SetSupportedMarkets)
  setMarkets(ctx: StateContext<AppStateModel>) {
    return this.availableLocalesService.getAvailableLocales().pipe(
      map((supportedMarkets: SupportedMarkets) => {
        ctx.patchState({ supportedMarkets });
      }),
    );
  }

  @Action(LoadImportantDates)
  loadImportantDates({ patchState }: StateContext<AppStateModel>, action: LoadImportantDates) {
    return this.importantDatesService.getImportantDates().pipe(
      map((importantDates) => {
        patchState({ importantDates });
      }),
    );
  }

  @Action(InitializeApp)
  initializeApp(_ctx: StateContext<AppStateModel>, _action: InitializeApp) {
    this.scrollTopOnNavService.scrollTopOnNav();
    this.store.dispatch(new ForceLoadUserProfile({}));
  }
}
