<div class="card elevation elevation4">
  <a [href]="link" (click)="onNavClickEvent()" [target]="target ? target : '_self'" class="clickable-horizontal-card">
    <div class="cardWrapper">
      <div class="img-card-wrapper">
        <img [src]="imgUrl" class="img-card" [alt]="alt" />
      </div>
      <div class="card-body">
        <h5 class="card-title cards-horizontal-title">{{ title }}</h5>
        <p class="card-text cards-horizontal-summary">{{ summary }}</p>
      </div>
    </div>
  </a>
</div>
