export const SAVE_CONSENT_MUTATION = `
  mutation saveConsent($input: SaveConsentInput!) {
    saveConsent(input: $input) {
        success
        customer {
            id
        }
        consent {
            id
            country
            customerType
            code
            version
            required
            signed
            signedDate
            tag
        }
        errors {
            field
            message
        }
    }
}`;
