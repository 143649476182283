export const UNSUPPORTED_MARKETS: string[] = ['Italy', 'Germany', 'Spain', 'Romania'];
export const EURO_MARKETS: string[] = [
  'en_UK',
  'fr_FR',
  'en_FR',
  'de_DE',
  'en_DE',
  'it_IT',
  'en_IT',
  'ro_RO',
  'en_RO',
  'es_ES',
  'en_ES',
];

export const EURO_MARKETS_COUNTRIES: string[] = [
  'AT',
  'BE',
  'CH',
  'DE',
  'ES',
  'FR',
  'IE',
  'IT',
  'LU',
  'NL',
  'RO',
  'UK',
];
