import { Injectable } from '@angular/core';
import { SetHomeWidgets } from '@common/home/state/home.actions';
import { HomeStateModel } from '@common/home/state/home.state.model';
import { Action, State, StateContext } from '@ngxs/store';

@State<HomeStateModel>({
  name: 'home',
  defaults: {
    configuration: {
      widgets: [],
    },
  },
})
@Injectable()
export class HomeState {
  @Action(SetHomeWidgets)
  setHomeWidgets({ patchState }: StateContext<HomeStateModel>, { widgets }: SetHomeWidgets) {
    const configuration = { widgets };
    return patchState({ configuration });
  }
}
