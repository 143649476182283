import { Selector } from '@ngxs/store';
import { AppUser } from '../../../../../common/src/authentication/model/user-profile';
import { UserProfileSelectors } from '../../../../../common/src/authentication/state/user-profile.selectors';
import { TaskTrackerProgramKey } from '../constants/task-tracker-program-key.enum';
import { PRODUCTS_TRAINING_MARKETS } from '../constants/task-tracker.constants';
import { TaskTrackerResult } from '../models/task-tracker-result';
import { TaskTrackerState } from './task-tracker.state';
import { TaskTrackerStateModel } from './task-tracker.state.model';

export class TaskTrackerSelectors {
  @Selector([TaskTrackerState])
  static getTaskTrackerResults(state: TaskTrackerStateModel) {
    return state?.taskTrackerResults;
  }

  @Selector([TaskTrackerSelectors.getTaskTrackerResults])
  static getTaskTrackerResultLookupFn(
    taskTrackerResults: Map<string, TaskTrackerResult>
  ): (programKey: string) => TaskTrackerResult {
    return (programKey: string) => taskTrackerResults?.get(programKey);
  }

  @Selector([TaskTrackerSelectors.getTaskTrackerResultLookupFn])
  static getOnboardingTrainingResult(
    taskTrackerResultLookupFn: (programKey: string) => TaskTrackerResult
  ): TaskTrackerResult {
    return taskTrackerResultLookupFn(TaskTrackerProgramKey.ONBOARDING);
  }

  /**
   * the following must be true:
   * 1. they are NOT a PC
   * 2. their application date is <= 90 days from today
   * @param userProfile
   */
  @Selector([TaskTrackerSelectors.getOnboardingTrainingResult, UserProfileSelectors.getUser])
  static getUserHasOnboardingWidget(onboardingTrainingResult: TaskTrackerResult, userProfile: AppUser): boolean {
    let userHasOnboardingWidget = false;

    const userHasCompletedOnboardingTraining: boolean = onboardingTrainingResult?.programProgress?.isCompleted;

    if (userProfile && !userProfile.pc && !userHasCompletedOnboardingTraining) {
      const onboardingWidgetCutoffDate = this.getDateXDaysAfterDate(userProfile.applicationDate, 90).getTime();

      const today = TaskTrackerSelectors.getCurrentDate().getTime();
      userHasOnboardingWidget = today <= onboardingWidgetCutoffDate;
    }

    return userHasOnboardingWidget;
  }

  @Selector([TaskTrackerSelectors.getOnboardingTrainingResult])
  static getOnboardingTrainingCompletedDate(onboardingTrainingResult: TaskTrackerResult): number {
    let onboardingCompletedDate: number;

    if (onboardingTrainingResult?.programProgress?.isCompleted) {
      const lastCompletedTaskDateStr = onboardingTrainingResult.programProgress.tasks
        .map((task) => task.completedDate)
        .filter((date) => date)
        .sort()
        .reverse()[0];

      onboardingCompletedDate = new Date(lastCompletedTaskDateStr).getTime();
    }

    return onboardingCompletedDate;
  }

  @Selector([TaskTrackerSelectors.getTaskTrackerResultLookupFn])
  static getProductsTrainingResult(
    taskTrackerResultLookupFn: (programKey: string) => TaskTrackerResult
  ): TaskTrackerResult {
    return taskTrackerResultLookupFn(TaskTrackerProgramKey.STARTSHARINGPRODUCTS);
  }

  /**
   * getUserHasProductsTrainingWidget
   *
   * We want to show the Products Training widget:
   *  For 90 days after the user completed Onboarding training
   *  OR
   *  For 90 days after the Onboarding period expires without completion
   *
   * @param onboardingCompletedDate the date the onboarding training was completed (if applicable)
   * @param onboardingTrainingResult  the onboarding training result
   * @param user the user profile
   * @returns if the user should see the Products Training widget
   */
  @Selector([
    TaskTrackerSelectors.getOnboardingTrainingCompletedDate,
    TaskTrackerSelectors.getOnboardingTrainingResult,
    TaskTrackerSelectors.getProductsTrainingResult,
    UserProfileSelectors.getUser,
  ])
  static getUserHasProductsTrainingWidget(
    onboardingCompletedDate: number,
    onboardingTrainingResult: TaskTrackerResult,
    productsTrainingResult: TaskTrackerResult,
    user: AppUser
  ): boolean {
    let userHasProductsTrainingWidget = false;
    const today = TaskTrackerSelectors.getCurrentDate().getTime();

    if (this.userMarketHasProductTraining(user) && !productsTrainingResult?.programProgress?.isCompleted) {
      const onboardingWidgetCutoffDate = this.getDateXDaysAfterDate(user.applicationDate, 90).getTime();

      if (today < onboardingWidgetCutoffDate) {
        if (
          onboardingTrainingResult &&
          !onboardingTrainingResult.isLoading &&
          onboardingTrainingResult.programProgress?.isCompleted
        ) {
          // check if user completed Onboarding within last 90 days
          const productsTrainingWidgetCutoffDate = this.getDateXDaysAfterDate(onboardingCompletedDate, 90).getTime();
          userHasProductsTrainingWidget = today <= productsTrainingWidgetCutoffDate;
        }
      } else {
        const onboardingEndedDate = onboardingCompletedDate || onboardingWidgetCutoffDate;
        const productsTrainingWidgetCutoffDate = this.getDateXDaysAfterDate(onboardingEndedDate, 90).getTime();
        userHasProductsTrainingWidget = today <= productsTrainingWidgetCutoffDate;
      }
    }

    return userHasProductsTrainingWidget;
  }

  static getCurrentDate(): Date {
    return new Date();
  }

  static getDateXDaysAfterDate(dateMillis: number, days: number): Date {
    const dateCopy = new Date(dateMillis);
    return new Date(dateCopy.setDate(dateCopy.getDate() + days));
  }

  private static userMarketHasProductTraining(user: AppUser): boolean {
    return user && PRODUCTS_TRAINING_MARKETS.includes(user.locale) && !user.pc;
  }
}
