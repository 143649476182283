import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SessionTimeoutModalComponent } from '@common/authentication/components/session-timeout-modal/session-timeout-modal.component';
import { SwitchUserComponent } from '@common/authentication/components/switch-user/switch-user.component';
import { UserProfileState } from '@common/authentication/state/user-profile.state';
import { TranslationModule } from '@common/translation/translation.module';
import { NgxsModule } from '@ngxs/store';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { DropdownButtonComponent, UniversalButtonModule } from '@usana/ux/universal-components/button';
import { UniversalFormInputTextModule } from '@usana/ux/universal-components/input/text';
import { UniversalModalModule } from '@usana/ux/universal-components/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieModule } from 'ngx-cookie';
import { ConsentDialogComponent } from './components/consent-dialog/consent-dialog.component';

@NgModule({
  declarations: [SwitchUserComponent, SessionTimeoutModalComponent, ConsentDialogComponent],
  exports: [SwitchUserComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    InterpolationPipeModule,
    UnsafeModule,
    NgxsModule.forFeature([UserProfileState]),
    CookieModule.forRoot(),
    ReactiveFormsModule,
    TranslationModule,
    UniversalButtonModule,
    UniversalModalModule,
    UniversalFormInputTextModule,
    DropdownButtonComponent,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AuthenticationModule {}
