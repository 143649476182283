<u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>

<ul class="cms-usana-landing">
  <li>
    <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
      <u-cms-item [itemId]="childItem.item.id"></u-cms-item>
      <div class="row newsLandingMiddleWrapper" *ngIf="idx === 3">
        <div class="text-center newsLandingContentTitle">
          {{ 'hub.news.landing.content.title' | translate }}
        </div>
      </div>
    </ng-container>
  </li>
</ul>

<u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
