import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Notification } from '@common/notifications/notification.model';
import { LoadSingleNotification } from '@common/notifications/state/notification.actions';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NOTIFICATION_ICON_MAP } from '../notification.constants';
import { NotificationSelectors } from '../state/notification.selectors';

@Component({
  selector: 'u-notification-single-item',
  templateUrl: './notification-single-item.component.html',
  styleUrls: ['./notification-single-item.component.scss'],
})
export class NotificationSingleItemComponent implements OnInit {
  singleNotification$: Observable<Notification> = inject(Store).select(NotificationSelectors.getSingleNotification);

  readonly NOTIFICATION_ICON_MAP = NOTIFICATION_ICON_MAP;
  readonly ENVELOPE_ICON = faEnvelope;

  constructor(private store: Store, private route: ActivatedRoute) {}

  ngOnInit() {
    const keyParam = this.route.snapshot.queryParamMap.get('key');

    if (keyParam) {
      this.store.dispatch(new LoadSingleNotification(keyParam));
    }
  }

  trackByIndex(index: number): number {
    return index;
  }
}
