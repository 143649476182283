import { Directive, Input } from '@angular/core';
import { UsanaAnalyticsService } from '@usana/ux/analytics';

@Directive()
export class CardsDirective {
  @Input() imgUrl: string;
  @Input() alt: string;
  @Input() title: string;
  @Input() summary: string;
  @Input() linkText: string;
  @Input() link: string;
  @Input() target: string;
  @Input() navClickEvent: string;

  constructor(private readonly analyticsService: UsanaAnalyticsService) {}

  onNavClickEvent() {
    if (this.navClickEvent) {
      this.analyticsService.sendEventToAllSystems(this.navClickEvent, {
        navUrl: this.link.split('?')[0],
      });
    }
  }
}
