<div class="progress-percent-container" style="width: 100%; height: 100%">
  <!-- 
    Progress Percent Indicator SVG Explaination

    The radius of the circle is chosen to produce a circumference of 100.
    circumference | 100
    radius        | 15.9155 = circumference / 2 / pi
    diameter      | 31.831 = radius * 2

    The circle's strokeDasharray is set to 100 segments long. This makes
    each 1 progressPercentage draw 1% of the circumference.
  -->
  <svg class="progress-indicator-graphic centered" viewBox="0 0 36 36">
    <path
      class="progress-indicator-track"
      d="
      M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831
    "
    />

    <path
      *ngIf="progressPercentage > 0"
      ngClass="progress-indicator-circle color-{{ color }}"
      d="
      M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831
    "
      style.strokeDasharray="{{ progressPercentage }}, 100"
    />
  </svg>

  <div #customContent class="centered">
    <ng-content></ng-content>
  </div>

  <div *ngIf="!customContent.children?.length" class="progress-percent-text centered">
    {{ progressPercentage | number : '1.0-0' }}%
  </div>
</div>
