import { ReportParam } from '@common/reports/reports.model';
import { DropdownButtonMenuItem } from '@usana/ux/universal-components/button';
import { ChartConfiguration } from 'chart.js';

export class ChartMetaData {
  options: Partial<ChartConfiguration>;
}

export interface BarChartMetadata extends ChartMetaData {
  categories: string[];
  hasNextPage: boolean;
  actionItems?: DropdownButtonMenuItem[];
  filterValues: Partial<ReportParam>;
}

export const DEFAULT_BAR_CHART_OPTIONS = {
  type: 'bar',
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: 'rgb(255, 99, 132)',
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(3,3,3,0.5)',
        titleFont: {
          size: 12,
        },
        titleColor: '#fff',
        bodyFont: {
          size: 11,
        },
        bodyColor: '#fff',
        cornerRadius: 7,
        callbacks: {
          // whatever we did before doesn't work so just return no title since that is what happens today
          title: () => '',
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 30,
        bottom: 0,
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          display: true,
        },
      },
      y: {
        stacked: false,
        beginAtZero: true,
        min: 0,
        max: 5000,
        ticks: {
          stepSize: 500,
        },
      },
    },
  },
};
