<universal-modal-base>
  <ng-container modalHeader>
    <h2>{{ 'notifications.landing-widget-title' | translate }} ({{ unreadNotificationCount$ | async }})</h2>
  </ng-container>

  <ng-container modalHeaderButtons>
    <u-dropdown-button
      buttonId="notification-options-menu"
      [menuItems]="dropdownMenuItems$ | async"
      [isEllipsis]="true"
      menuPlacement="bottom-right"
    ></u-dropdown-button>
  </ng-container>

  <div modalBody id="notification-modal-body">
    <u-notification-widget-content
      [limit]="true"
      [displayedNotifications]="displayedNotifications$ | async"
      [loadingNotifications]="loadCurrentNotifications$ | async"
      (viewAllClicked)="close()"
    ></u-notification-widget-content>
  </div>
</universal-modal-base>
