import { CmsBasicContainerComponent } from '@common/cms/cms-basic-container/cms-basic-container.component';
import { CmsBasicItemComponent } from '@common/cms/cms-basic-item/cms-basic-item.component';
import { CmsGroupContainerComponent } from '@common/cms/cms-group-container/cms-group-container.component';
import { CmsGroupPageComponent } from '@common/cms/cms-group-page/cms-group-page.component';
import { CmsHubNavBlockContainerComponent } from '@common/cms/cms-hub-nav-block-container/cms-hub-nav-block-container.component';
import { CmsHubNavItemComponent } from '@common/cms/cms-hub-nav-item/cms-hub-nav-item.component';
import { CmsHubNavSubgroupContainerComponent } from '@common/cms/cms-hub-nav-subgroup-container/cms-hub-nav-subgroup-container.component';
import { CmsImageLinkItemComponent } from '@common/cms/cms-image-link-item/cms-image-link-item.component';
import { CmsLinkItemComponent } from '@common/cms/cms-link-item/cms-link-item.component';
import { CmsPanelContainerComponent } from '@common/cms/cms-panel-container/cms-panel-container.component';
import { CmsBindingDefinition } from '@common/cms/cms.model';
import { CmsHubBannerSliderContainerComponent } from '@common/cms/containers/banner-slider/cms-hub-banner-slider-container.component';
import { CmsColumnedContainerComponent } from '@common/cms/containers/columned/cms-columned-container.component';
import { CmsGalleryContainerComponent } from '@common/cms/containers/gallery/cms-gallery-container.component';
import { CmsProductSpotlightContainerComponent } from '@common/cms/containers/product-spotlight/cms-product-spotlight-container.component';
import { CmsSlideContainerComponent } from '@common/cms/containers/slide/cms-slide-container.component';
import { EarningsOptInItemComponent } from '@common/cms/earnings-opt-in/earnings-opt-in-item/earnings-opt-in-item.component';
import { CmsHubBannerSliderItemComponent } from '@common/cms/items/banner-item/cms-hub-banner-slider-item.component';
import {
  CmsComponentBindingDefinition,
  CmsPurplePageBindingDefinition,
} from '@common/cms/items/cms-item-binding-definitions.model';
import { CmsContestOptInComponent } from '@common/cms/items/contest-opt-in/cms-contest-opt-in.component';
import { CmsProductSpotlightItemComponent } from '@common/cms/items/product-spotlight-item/cms-product-spotlight-item.component';
import { CmsSlideItemComponent } from '@common/cms/items/slide/cms-slide-item.component';
import { CmsTitledItemComponent } from '@common/cms/items/titled-item/cms-titled-item.component';
import { CmsUsanaNewsContainerComponent } from '@common/cms/news/usana-news-container.component';
import { CmsUsanaNewsItemComponent } from '@common/cms/news/usana-news-item.component';
import { CmsUsanaNewsLandingItemComponent } from '@common/cms/news/usana-news-landing-item.component';
import { RelatedStoriesContainerComponent } from '@common/cms/training/related-stories/related-stories-container/related-stories-container.component';
import { RelatedStoriesItemComponent } from '@common/cms/training/related-stories/related-stories-item/related-stories-item.component';
import { TrainingSubCategoryContainerComponent } from '@common/cms/training/training-sub-category/training-sub-category-container/training-sub-category-container.component';
import { TrainingSubCategoryItemComponent } from '@common/cms/training/training-sub-category/training-sub-category-item/training-sub-category-item.component';
import { PurplePageComponent } from '@shared/purple-pages/purple-page/purple-page.component';
import { CmsContestStatPageComponent } from '../cms-stat-page/cms-contest-stat-page.component';
import { CmsTaskTrackerComponent } from '../cms-task-tracker/cms-task-tracker.component';

export class CmsComponentService {
  getComponentFromType(type: string): Promise<CmsBindingDefinition> {
    return Promise.resolve(this.getComponentFromTypeImm(type));
  }

  protected getComponentFromTypeImm(type: string): CmsBindingDefinition {
    switch (type) {
      case 'basic-container':
        return new CmsComponentBindingDefinition(CmsBasicContainerComponent);
      case 'basic-item':
        return new CmsComponentBindingDefinition(CmsBasicItemComponent);
      case 'card-item':
        return new CmsComponentBindingDefinition(TrainingSubCategoryItemComponent);
      case 'columned-container':
        return new CmsComponentBindingDefinition(CmsColumnedContainerComponent);
      case 'earnings-opt-in-item':
        return new CmsComponentBindingDefinition(EarningsOptInItemComponent);
      case 'gallery-container':
        return new CmsComponentBindingDefinition(CmsGalleryContainerComponent);
      case 'group-container':
        return new CmsComponentBindingDefinition(CmsGroupContainerComponent);
      case 'group-page':
        return new CmsComponentBindingDefinition(CmsGroupPageComponent);
      case 'Hub-Banner-Slider-Container':
        return new CmsComponentBindingDefinition(CmsHubBannerSliderContainerComponent);
      case 'Hub-Banner-Slider-Item':
        return new CmsComponentBindingDefinition(CmsHubBannerSliderItemComponent);
      case 'hub-nav-block-container':
        return new CmsComponentBindingDefinition(CmsHubNavBlockContainerComponent);
      case 'hub-nav-item':
        return new CmsComponentBindingDefinition(CmsHubNavItemComponent);
      case 'hub-nav-subgroup-container':
        return new CmsComponentBindingDefinition(CmsHubNavSubgroupContainerComponent);
      case 'image-link-item':
        return new CmsComponentBindingDefinition(CmsImageLinkItemComponent);
      case 'link-item':
        return new CmsComponentBindingDefinition(CmsLinkItemComponent);
      case 'OptIn':
        return new CmsComponentBindingDefinition(CmsContestOptInComponent);
      case 'panel-container':
        return new CmsComponentBindingDefinition(CmsPanelContainerComponent);
      case 'PCInvite':
        return new CmsPurplePageBindingDefinition(PurplePageComponent);
      case 'related-stories-container':
        return new CmsComponentBindingDefinition(RelatedStoriesContainerComponent);
      case 'related-stories-item':
        return new CmsComponentBindingDefinition(RelatedStoriesItemComponent);
      case 'slide-container':
        return new CmsComponentBindingDefinition(CmsSlideContainerComponent);
      case 'slide-item':
        return new CmsComponentBindingDefinition(CmsSlideItemComponent);
      case 'StatPage':
        return new CmsComponentBindingDefinition(CmsContestStatPageComponent);
      case 'titled-item':
        return new CmsComponentBindingDefinition(CmsTitledItemComponent);
      case 'training-sub-category-container':
        return new CmsComponentBindingDefinition(TrainingSubCategoryContainerComponent);
      case 'training-task-tracker-widget':
        return new CmsComponentBindingDefinition(CmsTaskTrackerComponent);
      case 'usana-news-container':
        return new CmsComponentBindingDefinition(CmsUsanaNewsContainerComponent);
      case 'usana-news-landing-item':
        return new CmsComponentBindingDefinition(CmsUsanaNewsLandingItemComponent);
      case 'usanaNewsItem':
        return new CmsComponentBindingDefinition(CmsUsanaNewsItemComponent);
      case 'product-spotlight-item':
        return new CmsComponentBindingDefinition(CmsProductSpotlightItemComponent);
      case 'product-spotlight-container':
        return new CmsComponentBindingDefinition(CmsProductSpotlightContainerComponent);
      default:
        console.log('could not find cms type', type);
        return null;
    }
  }
}
