import { Component } from '@angular/core';

@Component({
  selector: 'u-change-market-warning',
  templateUrl: './change-market-warning.component.html',
  styleUrls: ['./change-market-warning.component.scss'],
})
export class ChangeMarketWarningComponent {
  showMore = false;

  toggleMarketNotification() {
    this.showMore = !this.showMore;
  }
}
