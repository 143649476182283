import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { CommonIconsModule } from '../common-icons/common-icons.module';
import { Error401Component } from './error401.component';
import { Error404Component } from './error404.component';

@NgModule({
  declarations: [Error401Component, Error404Component],
  imports: [TranslationModule, CommonIconsModule],
  exports: [Error401Component, Error404Component],
})
export class ErrorPageModule {}
