/* eslint-disable max-classes-per-file */
export class LoadCommissionWeeks {
  static readonly type = '[baoyingVolumeReport] LOAD COMMISSION WEEKS';
}

export class LoadBaoyingVolumeReport {
  static readonly type = '[baoyingVolumeReport] LOAD VOLUME';
}

export class SetCommissionWeek {
  static readonly type = '[baoyingVolumeReport] SET COMMISSION WEEK';
  constructor(public commissionWeek: number) {}
}
