import { Component } from '@angular/core';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'u-error404',
  styleUrls: ['./error-page.component.scss'],
  templateUrl: './error404.component.html',
})
export class Error404Component {
  readonly HOME_ICON = faHome;
}
