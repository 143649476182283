import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CmsConstants } from '@common/cms/cms.constants';
import { CmsItemModel } from '@common/cms/cms.model';
import { CmsEditService } from '@common/cms/services/cms-edit.service';
import {
  CopyItemToLocale,
  CopyParentToLocale,
  DeleteCmsItem,
  MoveCmsItem,
  PublishItem,
} from '@common/cms/state/cms.actions';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'u-cms-child-edit-menu',
  templateUrl: './cms-child-edit-menu.component.html',
  styleUrls: ['./cms-child-edit-menu.component.scss'],
})
export class CmsChildEditMenuComponent implements OnInit, OnDestroy {
  activeEditItemId$: Observable<string> = this.store.select(CmsSelectors.getActiveEditItemId);

  @Input() cmsItemObj: CmsItemModel;
  @Input() rootId: string;

  readonly CMS_DIRECTIONS = CmsConstants.DIRECTIONS;
  readonly CMS_PUBLISH_STATUSES = CmsConstants.PUBLISH_STATUS;

  publishStatus: number;
  requestAttrs: UserProfile;

  private storeSubs: Subscription;

  constructor(private cmsEditService: CmsEditService, private store: Store) {
    this.storeSubs = store.select(UserProfileSelectors.getUserProfile).subscribe((userProfile) => {
      this.requestAttrs = userProfile;
    });
  }

  ngOnInit(): void {
    this.publishStatus =
      !!this.cmsItemObj.relationship && !!this.cmsItemObj.relationship.publishedBy
        ? CmsConstants.PUBLISH_STATUS.PUBLISHED
        : CmsConstants.PUBLISH_STATUS.NONE;
  }

  moveThis(direction: string): void {
    this.store.dispatch(new MoveCmsItem(this.cmsItemObj.relationship.id.toString(), direction, this.rootId));
  }

  edit(): void {
    this.cmsEditService.editChild(this.cmsItemObj.relationship.id, 'reloadCmsRootItem', this.rootId);
  }

  deleteItem(): void {
    this.store.dispatch(new DeleteCmsItem(this.cmsItemObj.relationship.id.toString(), this.rootId));
  }

  copyableToLocale(): boolean {
    return !!this.cmsItemObj.relationship && this.requestAttrs && this.requestAttrs.locale !== this.cmsItemObj.locale;
  }

  parentCopyableToLocale(): boolean {
    // todo I'm not 100% sure why we check locale differing on the child, but locale matching on the parent
    return (
      !!this.cmsItemObj.relationship &&
      this.requestAttrs &&
      (this.requestAttrs.locale === this.cmsItemObj.relationship.locale ||
        this.cmsItemObj.relationship.locale !== this.cmsItemObj.locale)
    );
  }

  copyToLocale(): void {
    if (this.cmsItemObj.type.container) {
      this.store.dispatch(
        new CopyParentToLocale(
          this.cmsItemObj.item.id,
          this.cmsItemObj.locale,
          this.requestAttrs.locale,
          false,
          this.rootId
        )
      );
    } else {
      this.store.dispatch(
        new CopyItemToLocale(
          this.cmsItemObj.relationship.id.toString(),
          this.cmsItemObj.locale,
          this.requestAttrs.locale,
          this.rootId
        )
      );
    }
  }

  publish(): void {
    this.store.dispatch(new PublishItem(this.cmsItemObj, this.rootId)).subscribe(
      () => {
        this.publishStatus = CmsConstants.PUBLISH_STATUS.SUCCESS_PUBLISHING;
      },
      () => {
        this.publishStatus = CmsConstants.PUBLISH_STATUS.ERROR_PUBLISHING;
      }
    );
  }

  isPublished(): boolean {
    return this.cmsItemObj.relationship && !!this.cmsItemObj.relationship.publishedBy;
  }

  ngOnDestroy(): void {
    if (this.storeSubs) {
      this.storeSubs.unsubscribe();
      delete this.storeSubs;
    }
  }
}
