import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { ReportsService } from '@common/reports/reports.service';
import {
  ClearSavedReportParams,
  LoadReportData,
  LoadReportDataFailure,
  LoadReportDataSuccess,
  LoadReportParams,
  LoadReportParamsFailure,
  LoadReportParamsSuccess,
  LoadSavedReportParams,
  LoadSavedReportParamsFailure,
  LoadSavedReportParamsSuccess,
} from '@common/reports/state/reports.actions';
import {
  handleReportLoadError,
  initializeReportLoad,
  setReportDataToState,
} from '@common/reports/state/reports.operators';
import { ReportsStateModel } from '@common/reports/state/reports.state.model';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { ParamsList } from '@usanaHub/app/team-manager/models/params-list';
import { catchError, mergeMap } from 'rxjs/operators';
import { ReportViewTypes } from '../reports.enum';
import { SavedReportParams } from '../reports.model';
import { ReportEntryTypes } from './report-collections';
import { clearReportParams } from './reports.operators';

export const REPORT_STATE_DEFAULTS = {
  reportEntriesLocale: undefined,
  dataEntries: {},
  paramEntries: {},
};

@State<ReportsStateModel>({
  name: 'reports',
  defaults: REPORT_STATE_DEFAULTS,
})
@Injectable()
export class ReportsState {
  constructor(private reportService: ReportsService, private store: Store, private router: Router) {}

  @Action(LoadReportData)
  loadReportData(ctx: StateContext<ReportsStateModel>, action: LoadReportData) {
    ctx.setState(initializeReportLoad(ReportEntryTypes.DataEntries, action.reportName));

    let requestBody = action.requestBody;

    if (action.requestBody?.defaultView === ReportViewTypes.Tree) {
      requestBody = this.modifyTreeParams(requestBody);
    }

    return this.reportService.getReport(action.reportName, this.locale, action.reportVersion, requestBody).pipe(
      mergeMap((reportData: { [key: string]: any }) =>
        ctx.dispatch(new LoadReportDataSuccess(action.reportName, reportData))
      ),
      catchError((reportError) => ctx.dispatch(new LoadReportDataFailure(action.reportName, reportError?.error)))
    );
  }

  @Action(LoadReportDataSuccess)
  loadReportDataSuccess(ctx: StateContext<ReportsStateModel>, action: LoadReportDataSuccess) {
    const newState = setReportDataToState(
      ReportEntryTypes.DataEntries,
      action.reportName,
      action.reportData
    )(ctx.getState());
    newState.reportEntriesLocale = this.locale;
    ctx.patchState(newState);
  }

  @Action(LoadReportDataFailure)
  loadReportDataFailure(ctx: StateContext<ReportsStateModel>, action: LoadReportDataFailure) {
    ctx.setState(handleReportLoadError(ReportEntryTypes.DataEntries, action.reportName));
  }

  @Action(LoadReportParams)
  loadReportParams(ctx: StateContext<ReportsStateModel>, action: LoadReportParams) {
    let state = ctx.getState();
    if (state.reportEntriesLocale && this.locale !== state.reportEntriesLocale) {
      ctx.setState(REPORT_STATE_DEFAULTS);
    }

    state = ctx.getState();
    const existingReportParams = state.paramEntries[action.reportName];

    if (!existingReportParams) {
      ctx.setState(initializeReportLoad(ReportEntryTypes.ParamEntries, action.reportName));

      return this.reportService.getReportParams(action.reportName, this.locale).pipe(
        mergeMap((reportParams: ParamsList) =>
          ctx.dispatch(new LoadReportParamsSuccess(action.reportName, reportParams))
        ),
        catchError(() => ctx.dispatch(new LoadReportParamsFailure(action.reportName, action.redirect)))
      );
    }
  }

  @Action(LoadReportParamsSuccess)
  loadReportParamsSuccess(ctx: StateContext<ReportsStateModel>, action: LoadReportParamsSuccess) {
    const newState = setReportDataToState(
      ReportEntryTypes.ParamEntries,
      action.reportName,
      action.reportParams
    )(ctx.getState());
    ctx.patchState(newState);
  }

  @Action(LoadReportParamsFailure)
  loadReportParamsFailure(ctx: StateContext<ReportsStateModel>, action: LoadReportParamsFailure) {
    ctx.setState(handleReportLoadError(ReportEntryTypes.ParamEntries, action.reportName));

    if (action.redirect) {
      this.router.navigate(['/teamManager']);
    }
  }

  @Action(LoadSavedReportParams)
  loadSavedReportParams(ctx: StateContext<ReportsStateModel>, action: LoadSavedReportParams) {
    let state = ctx.getState();
    if (state.reportEntriesLocale && this.locale !== state.reportEntriesLocale) {
      ctx.setState(REPORT_STATE_DEFAULTS);
    }

    state = ctx.getState();
    const existingReportParams = state.paramEntries[action.reportId];

    if (!existingReportParams) {
      ctx.setState(initializeReportLoad(ReportEntryTypes.ParamEntries, action.reportId));

      return this.reportService.getSavedReportParams(action.reportId, this.locale).pipe(
        mergeMap((reportParams: SavedReportParams) =>
          ctx.dispatch(new LoadSavedReportParamsSuccess(action.reportId, reportParams))
        ),
        catchError(() => ctx.dispatch(new LoadSavedReportParamsFailure(action.reportId)))
      );
    }
  }

  @Action(LoadSavedReportParamsSuccess)
  loadSavedReportParamsSuccess(ctx: StateContext<ReportsStateModel>, action: LoadSavedReportParamsSuccess) {
    const newState = setReportDataToState(
      ReportEntryTypes.ParamEntries,
      action.reportId,
      action.reportParams
    )(ctx.getState());
    ctx.patchState(newState);

    const baseReportName = action.reportParams.reportName;
    const state = ctx.getState();
    if (!state.paramEntries[baseReportName]) {
      ctx.dispatch(new LoadReportParams(baseReportName, true));
    }
  }

  @Action(LoadSavedReportParamsFailure)
  loadSavedReportParamsFailure(ctx: StateContext<ReportsStateModel>, action: LoadSavedReportParamsFailure) {
    ctx.setState(handleReportLoadError(ReportEntryTypes.ParamEntries, action.reportId));

    this.router.navigate(['/teamManager']);
  }

  @Action(ClearSavedReportParams)
  clearSavedReportParams(ctx: StateContext<ReportsStateModel>, action: ClearSavedReportParams) {
    const existingReportParams = ctx.getState().paramEntries[action.reportId];

    if (existingReportParams) {
      const newState = clearReportParams(ReportEntryTypes.ParamEntries, action.reportId)(ctx.getState());
      ctx.patchState(newState);
    }
  }

  modifyTreeParams(runParams: ParamsList): ParamsList {
    const treeParams = { ...runParams };

    treeParams.visibleColumns = runParams.visibleTreeColumns;
    delete treeParams.visibleTreeColumns;

    return treeParams;
  }

  get locale() {
    return this.store.selectSnapshot(UserProfileSelectors.getLocale);
  }
}
