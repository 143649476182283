import { Component, OnInit } from '@angular/core';
import { FPVReportModel } from '@common/reports/reports.model';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  LoadBaoyingVolumeReport,
  LoadCommissionWeeks,
  SetCommissionWeek,
} from '../state/baoying-volume-report.actions';
import { BaoyingVolumeReportSelectors } from '../state/baoying-volume-report.selectors';

@Component({
  selector: 'u-baoying-volume-report',
  templateUrl: './baoying-volume-report.component.html',
  styleUrls: ['./baoying-volume-report.component.scss'],
})
export class BaoyingVolumeReportComponent implements OnInit {
  commissionWeeks$: Observable<number[]> = this.store.select(BaoyingVolumeReportSelectors.getCommissionWeeks);
  volumeReport$: Observable<FPVReportModel> = this.store.select(BaoyingVolumeReportSelectors.getVolume);

  hasError;

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new LoadCommissionWeeks()).subscribe(
      () => {
        this.store.dispatch(new LoadBaoyingVolumeReport()).subscribe(
          () => {},
          (error) => {
            this.hasError = error;
          }
        );
      },
      (error) => {
        this.hasError = error;
      }
    );
  }

  changeCommissionWeek(commissionWeek) {
    return this.store.dispatch(new SetCommissionWeek(commissionWeek));
  }

  trackByIndex(index) {
    return index;
  }
}
