import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UplineLeaderPlacementModule } from '@common/upline-leader-placement/upline-leader-placement.module';
import { UsanaUplineLeaderReportComponent } from './usana-upline-leader-report/usana-upline-leader-report.component';

@NgModule({
  declarations: [UsanaUplineLeaderReportComponent],
  imports: [CommonModule, UplineLeaderPlacementModule],
  exports: [UsanaUplineLeaderReportComponent],
})
export class UsanaUplineLeaderReportModule {}
