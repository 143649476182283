import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { CmsItemModel } from '@common/cms/cms.model';
import { GET_SHAREABLE_PRODUCT_URL_QUERY } from '@usanaHub/app/direct-enrollment/constants/get-shareable-url-query';
import { ShareableUrl } from '@usanaHub/app/direct-enrollment/models/get-shareable-url-response';
import { PRODUCT_SPOTLIGHT_QUERY } from '@usanaHub/app/usana-home/dashboard-widgets/product-spotlight-widget/constants/product-spotlight.graph';
import { CartProduct } from '@usanaHub/app/usana-home/dashboard-widgets/product-spotlight-widget/models/product-spotlight-data';
import { Apollo } from 'apollo-angular';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductSpotlightService {
  private usanaIds: string[] = [];
  private products: CartProduct[] = [];

  constructor(private apollo: Apollo) {}

  extractUsanaIds(children: CmsItemModel[]): void {
    this.usanaIds = children
      .map((child) => {
        if (child.data?.productIdAutocomplete?.data) {
          const dataObj = JSON.parse(child.data?.productIdAutocomplete?.data);
          return dataObj?.usanaId;
        }
      })
      .filter((id) => id);
  }

  fetchProductsByUsanaIds(): Observable<CartProduct[]> {
    if (this.usanaIds.length > 0) {
      return this.apollo
        .query<{ getProductByUsanaId: CartProduct[] }>({
          query: PRODUCT_SPOTLIGHT_QUERY,
          variables: { usanaId: this.usanaIds },
        })
        .pipe(
          map((result: ApolloQueryResult<{ getProductByUsanaId: CartProduct[] }>) => {
            this.products = result.data?.getProductByUsanaId || [];
            return this.products;
          })
        );
    }
    return new Observable<CartProduct[]>((observer: Subscriber<CartProduct[]>): void => {
      observer.next([]);
      observer.complete();
    });
  }

  getProducts(): CartProduct[] {
    return this.products;
  }

  getSharableProductUrl(usanaId: string): Observable<string> {
    return this.apollo
      .query<{ getShareableProductUrl: ShareableUrl }>({
        query: GET_SHAREABLE_PRODUCT_URL_QUERY,
        variables: {
          shareId: usanaId,
          shareType: 'shareOne',
          shopperSource: 'HUB',
        },
      })
      .pipe(
        map(
          (response: ApolloQueryResult<{ getShareableProductUrl: ShareableUrl }>) =>
            response.data.getShareableProductUrl.message.shortenedUrl
        )
      );
  }
}
