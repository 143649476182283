import { Component } from '@angular/core';
import { faTh } from '@fortawesome/pro-solid-svg-icons';
import { CmsBaseContainerDirective } from '../containers/cms-base-container.directive';

@Component({
  selector: 'u-cms-panel-container',
  templateUrl: './cms-panel-container.component.html',
})
export class CmsPanelContainerComponent extends CmsBaseContainerDirective {
  readonly TABLE_HEADER_ICON = faTh;
}
