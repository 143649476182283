<div *ngIf="cmsItemObj">
  <carousel
    [itemsPerSlide]="itemsPerSlide"
    [singleSlideOffset]="true"
    [startFromIndex]="0"
    [interval]="0"
    [showIndicators]="false"
  >
    <slide
      *ngFor="let childItem of cmsItemObj.children; let index = index; trackBy: trackByIndex"
      class="elevation elevation4 cms-banner-slide-column-mini"
    >
      <u-cms-link [cmsItemObj]="childItem">
        <img
          width="164"
          height="154"
          class="cms-banner-slide-img-mini"
          [alt]="childItem.data?.Title?.data"
          [src]="childItem.data.slideImage?.data"
        />
      </u-cms-link>
      <div class="cms-banner-display-column">
        <div class="cms-banner-display-left">
          <h4 class="cms-banner-mini-cards-title" *ngIf="childItem.placeholder || !!childItem.data.Title?.data">
            {{ childItem.data.Title?.data }}
          </h4>
          <u-cms-link [cmsItemObj]="childItem" cmsLinkClass="body-text-3 btn btn-primary cms-banner-button-mini">
            {{ childItem.data.buttonLabel?.data }}
          </u-cms-link>
        </div>
      </div>
    </slide>
  </carousel>
</div>
