import { Component, Input } from '@angular/core';
import { CmsItemModel } from '@common/cms/cms.model';

@Component({
  selector: 'u-cms-side-nav',
  templateUrl: './cms-side-nav.component.html',
})
export class CmsSideNavComponent {
  @Input() cmsItemObj: CmsItemModel;
  @Input() rootId: string;
}
