import { Component, OnInit } from '@angular/core';
import { LoadFpvReport } from '@common/fpv-widget/state/fpv-report.actions';
import { FpvReportModel } from '@common/fpv-widget/state/fpv-report.model';
import { FpvReportSelectors } from '@common/fpv-widget/state/fpv-report.selectors';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'u-baoying-fpv-report',
  templateUrl: './baoying-fpv-report.component.html',
  styleUrls: ['./baoying-fpv-report.component.scss'],
})
export class BaoyingFpvReportComponent implements OnInit {
  fpvReport$: Observable<FpvReportModel> = this.store.select(FpvReportSelectors.getFpvReport);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new LoadFpvReport());
  }

  trackByIndex(index) {
    return index;
  }
}
