import { Component, OnInit } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Store } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ContestTypes } from '../../constants/contest-types';
import { ContestStatsResponse } from '../../models/contest-stats';
import { CmsBaseDirective } from '../cms-base.directive';
import { GET_CONTEST_STATS_QUERY } from '../constants/get-contest-stats-query';

@Component({
  selector: 'u-cms-contest-stat-page',
  templateUrl: './cms-contest-stat-page.component.html',
  styleUrls: ['./cms-contest-stat-page.component.scss'],
})
export class CmsContestStatPageComponent extends CmsBaseDirective implements OnInit {
  readonly CONTEST_TYPES = ContestTypes;

  contestStatsResponse$: Observable<ApolloQueryResult<ContestStatsResponse>>;

  constructor(protected store: Store, private apollo: Apollo) {
    super(store);
  }

  ngOnInit(): void {
    this.contestStatsResponse$ = this.apollo.query<ContestStatsResponse>({
      query: GET_CONTEST_STATS_QUERY,
      variables: { contestId: +(this.cmsItemObj.data as any).contestId.data },
    });
  }
}
