<!--NOTE: we mark this as non dismissible because we handle it ourselves-->
<alert
  [dismissible]="false"
  [type]="toast.type"
  *ngFor="let toast of toasts; trackBy: trackByInternalId"
  (click)="close(toast)"
>
  <div class="hub-alert-fontawesome">
    <fa-icon [icon]="toast.getIcon()"></fa-icon>
  </div>
  <div class="alert-middle-col">
    <span class="hub-alert-title" *ngIf="toast.title">{{
      toast.interpolateTitle
        ? (toast.title | translate | interpolate : { interpolateTitle: toast.interpolateTitle })
        : (toast.title | translate)
    }}</span>
    <span>{{
      toast.interpolateMsg
        ? (toast.msgTag | translate | interpolate : { interpolateMsg: toast.interpolateMsg })
        : (toast.msgTag | translate)
    }}</span>
  </div>
  <div class="hub-alert-fontawesome hub-alert-close">
    <fa-icon [icon]="['fas', 'times']"></fa-icon>
  </div>
</alert>
