import { gql } from 'apollo-angular';

export const GET_CONTEST_STATS_QUERY = gql`
  query getContestStats($contestId: Int) {
    contestStats: getContestStats(contestId: $contestId) {
      message
      responseObject {
        contestId
        description
        startDate
        endDate
        widgetStartDate
        widgetEndDate
        sponsorId
        contestantLevel
        lastUpdated
        weeksLeftInContest
        daysLeftInContest
        pointsNeeded
        weeklyGoal
        optedIn
        statLevels {
          contestId
          contestLevel
          rangeMin
          rangeMax
          lastModifiedBy
        }
        prizeLevel {
          tag
        }
        svp
        numberSponsored
        pastCvpTotal
        currentCvpTotal
      }
      type
    }
  }
`;
