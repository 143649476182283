import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CmsItemModel } from '@common/cms/cms.model';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

@Directive()
export class CmsBaseDirective implements OnInit, OnDestroy {
  @Input() cmsItemObj: CmsItemModel;
  @Input() navClickEvent: string;

  requestAttrs: UserProfile;

  protected storeSubs: Subscription;

  constructor(protected readonly store: Store) {}

  ngOnInit(): void {
    // we have to do it this way rather than a selector because of the interpolate pipe
    this.addSubscription(
      this.store.select(UserProfileSelectors.getUserProfile).subscribe((userProfile: UserProfile) => {
        this.requestAttrs = userProfile;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.storeSubs) {
      this.storeSubs.unsubscribe();
    }
  }

  hasDataForAttribute(attr: string): boolean {
    return !!this.cmsItemObj.data[attr] && !!this.cmsItemObj.data[attr].data;
  }

  getDataForAttribute(attr: string): string {
    if (this.hasDataForAttribute(attr)) {
      return this.cmsItemObj.data[attr].data;
    }
  }

  addSubscription(sub: Subscription) {
    if (!this.storeSubs) {
      this.storeSubs = sub;
    } else {
      this.storeSubs.add(sub);
    }
  }

  trackByIndexAndItemId(index, item) {
    return `${index}_${item.item.id}`;
  }
}
