<div class="system-alert-container" *ngFor="let alert of alerts$ | async; let i = index; trackBy: trackById">
  <div
    class="system-alert-display"
    [ngClass]="{
      'alert-level-info': alert.level === 0,
      'alert-level-warning': alert.level === 2,
      'alert-level-danger': alert.level === 1
    }"
  >
    <span [innerHTML]="alert.message | unsafe : 'html'"> </span>
    <fa-icon [icon]="['fas', 'times']" (click)="closeAlert(i)" class="system-alert-close pull-right"></fa-icon>
  </div>
</div>
