import { Injectable } from '@angular/core';
import { MyAccountListModel } from './my-account.model';

@Injectable({
  providedIn: 'root',
})
export class MyAccountService {
  private MY_ACCOUNT_LIST: MyAccountListModel[] = [];

  get myAccount() {
    return this.MY_ACCOUNT_LIST;
  }

  set myAccount(myAccount: MyAccountListModel[]) {
    this.MY_ACCOUNT_LIST = myAccount;
  }

  constructor() {}
}
