<div class="product-spotlight-item-container">
  <div class="admin-data" *ngIf="requestAttrs.pageEdit">{{ cmsItemObj.data?.productIdAutocomplete?.data }}</div>
  <div *ngIf="!isProductEmpty()" class="row no-margin">
    <div class="col-6">
      <img [src]="getProductImage()" alt="'product image'" class="product-image" id="spotlightProductImage" />
    </div>
    <div class="col-6">
      <div class="product-details-container">
        <div class="spotlight-product-name" id="spotlightProductName">{{ product?.productDescription?.name }}</div>
        <div id="spotlightProductPrice">{{ product?.price?.priceDisplay }}</div>
        <div class="product-volume-label" id="spotlightProductVolumeLabel">
          {{ 'hub.productSpotlight.volume' | translate }}:
        </div>
        <div class="product-volume" id="spotlightProductVolume">{{ product?.price?.volume }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="isProductEmpty()" class="row no-margin">
    <div class="col-12">
      <div class="product-unavailable" id="spotlightProductUnavailable">
        This product is either not available in this market, inactive or the product id is invalid
      </div>
    </div>
  </div>
</div>
