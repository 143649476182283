import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { DataViewerComponent } from '@shared/data-view/data-view.decorator';
import { UsanaLoggingService } from '@usana/ux/common/logging';
import { CommonIconsModule } from '../../../../common/src/common-icons/common-icons.module';
import { BarChartComponent } from './bar-chart.component';

@NgModule({
  declarations: [BarChartComponent],
  exports: [BarChartComponent],
  imports: [CommonModule, TranslationModule, CommonIconsModule],
})
export class BarChartModule {
  constructor() {
    if (DataViewerComponent('stackedBar', BarChartComponent)) {
      // if this triggers it
      UsanaLoggingService.logMsg('stackedBar data view was defined multiple times', true);
    }
  }
}
