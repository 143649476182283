<div class="p-5">
  <div class="row">
    <div
      *ngFor="let quickLinks of quickLinksObj; trackBy: trackById"
      class="col-quick-links"
      [ngClass]="'col-quick-links-' + quickLinksObj.length"
    >
      <div class="home-quick-link-container">
        <a id="{{ quickLinks.id }}" href="{{ quickLinks.url }}" target="{{ quickLinks.target }}">
          <div class="homepage-quick-link-circle">
            <ng-container *ngIf="quickLinks.iconClass; else fontAwesomeIcon">
              <div class="quick-link-icon {{ quickLinks.iconClass }} centered"></div>
            </ng-container>

            <ng-template #fontAwesomeIcon>
              <fa-icon [icon]="quickLinks.fontAwesomeIcons" class="quick-link-icon centered"></fa-icon>
            </ng-template>
          </div>
          <div class="body-text-2 homepage-quick-link-text">{{ quickLinks.translationTag | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
