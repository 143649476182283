import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationWidgetModule } from '@common/notifications/notification-widget/notification-widget.module';
import { TranslationModule } from '@common/translation/translation.module';
import { BreadCrumbModule } from '@shared/bread-crumb/bread-crumb.module';
import { NotificationDashboardComponent } from './notification-dashboard.component';

@NgModule({
  declarations: [NotificationDashboardComponent],
  imports: [CommonModule, NotificationWidgetModule, BreadCrumbModule, TranslationModule],
  exports: [NotificationDashboardComponent],
})
export class NotificationDashboardModule {}
