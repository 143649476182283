import { Inject, Injectable } from '@angular/core';
import { AppUser } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { Store } from '@ngxs/store';
import { WINDOW_TOKEN } from '@usana/ux/common';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class CbecRedirectionService {
  constructor(private readonly store: Store, @Inject(WINDOW_TOKEN) protected readonly window: any) {
    this.window.redirectToCN_CBEC = () => this.redirectToCbec();
  }

  isInitialized(): boolean {
    return !!this.window.redirectToCN_CBEC;
  }

  redirectToCbec(): void {
    const redirectUrl = `https://${environment.STATIC_CART_ORIGIN_SUBDOMAIN}.${environment.CBEC_DEPLOY_SITEDOMAIN}${environment.STATIC_CART_ORIGIN_ROOT_URI}/#!/zh-CN/category/-1?shopperSource=CN_CBEC&shop=nfr`;
    this.store.select(UserProfileSelectors.getUser).subscribe((user: AppUser) => {
      const doc = this.window.document;
      const form = doc.createElement('form');
      const tokenElement = doc.createElement('input');
      const idTokenElement = doc.createElement('input');
      const refreshTokenElement = doc.createElement('input');
      const redirectUrlElement = doc.createElement('input');

      form.method = 'POST';
      form.action = `https://${environment.CART_ORIGIN_SUBDOMAIN}.${environment.CBEC_DEPLOY_SITEDOMAIN}${environment.CART_ORIGIN_ROOT_URI}/customer/exchangeToken`;
      form.target = '_blank';

      tokenElement.value = user.coreSecureToken;
      tokenElement.name = 'token';
      tokenElement.type = 'hidden';
      form.appendChild(tokenElement);

      idTokenElement.value = user.ssoIdToken;
      idTokenElement.name = 'id-token';
      idTokenElement.type = 'hidden';
      form.appendChild(idTokenElement);

      refreshTokenElement.value = user.ssoRefreshToken;
      refreshTokenElement.name = 'refresh-token';
      refreshTokenElement.type = 'hidden';
      form.appendChild(refreshTokenElement);

      redirectUrlElement.value = redirectUrl;
      redirectUrlElement.name = 'redirectUrl';
      redirectUrlElement.type = 'hidden';
      form.appendChild(redirectUrlElement);

      doc.body.appendChild(form);

      form.submit();

      doc.body.removeChild(form);
    });
  }
}
