<div
  [ngClass]="{
    'row cms-child-edit-mode':
      requestAttrs.pageEdit &&
      requestAttrs.editorForLocale &&
      ((activeEditItemId$ | async) === cmsItemObj.parent.id || !cmsItemObj.type.container)
  }"
>
  <div class="usanaNewsBox">
    <u-cms-child-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="cmsItemObj.parent.id"></u-cms-child-edit-menu>
    <div class="card elevation elevation4" [attr.cmsItemId]="cmsItemObj.item.id">
      <div class="card-body p-0">
        <div class="news-inner-wrapper">
          <div class="news-thumbnail">
            <u-cms-link [cmsItemObj]="cmsItemObj">
              <img src="{{ cmsItemObj.data.imageSrc775x775?.data }}" alt="{{ cmsItemObj.data.ImageAlt?.data }}" />
            </u-cms-link>
          </div>
          <div class="news-body-content">
            <div class="news-title">{{ cmsItemObj.data.title?.data }}</div>
            <div
              *ngIf="cmsItemObj.data.summary?.data"
              class="news-body"
              [ngClass]="{ ellipsis: (cmsItemObj.data.summary?.data).length > 180 }"
              [innerHTML]="cmsItemObj.data.summary?.data | htmlToPlainText | slice : 0 : 180 | unsafe : 'html'"
            ></div>

            <div class="news-readmore">
              <u-cms-link [cmsItemObj]="cmsItemObj">
                @if(cmsItemObj.data.linkText?.data?.length > 0) {
                {{ cmsItemObj.data.linkText?.data }}
                } @else {
                {{ 'hub.news.read-more' | translate }}
                }
                <fa-icon [icon]="['fas', 'arrow-right']" class="fontawesome-icons"></fa-icon>
              </u-cms-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
