import { Component, Input } from '@angular/core';
import { UsanaAnalyticsService } from '@usana/ux/analytics';
import { TaskTrackerTask } from '../../models/task-tracker-task';

@Component({
  selector: 'u-task-tracker-item',
  templateUrl: './task-tracker-item.component.html',
  styleUrls: ['./task-tracker-item.component.scss'],
})
export class TaskTrackerItemComponent {
  @Input() taskCompletion: TaskTrackerTask;

  constructor(private readonly analyticsService: UsanaAnalyticsService) {}

  onNavClickEvent(): void {
    this.analyticsService.sendEventToAllSystems('nav_task_tracker', {
      navUrl: this.taskCompletion.actionUrl.split('?')[0],
    });
  }
}
