import { Injectable } from '@angular/core';
import { CmsConstants } from '@common/cms/cms.constants';
import { CmsEditService } from '@common/cms/services/cms-edit.service';
import { CmsService } from '@common/cms/services/cms.service';
import {
  CopyItemToLocale,
  CopyParentToLocale,
  DeleteCmsItem,
  LoadCmsItem,
  MoveCmsItem,
  PublishItem,
  ReloadCmsItem,
  RemoveCmsItem,
  ResetCmsDataEntries,
  SetActiveEditItemId,
  UpdateCmsCurrentPage,
} from '@common/cms/state/cms.actions';
import {
  getCmsItemIdsFromFlatMap,
  setCmsItemDataToState,
  setCmsItemEntryToState,
} from '@common/cms/state/cms.operators';
import { CmsItemEntry, CmsStateModel } from '@common/cms/state/cms.state.model';
import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  UniversalConfirmationModalOptions,
  UniversalConfirmationModalService,
} from '@usana/ux/universal-components/modal';
import { DecrementLoading, IncrementLoading } from '@usanaHub/app/state/app.actions';
import cloneDeep from 'lodash/cloneDeep';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { firstValueFrom, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { TranslationService } from '../../translation/translation.service';
import { CMS_CANCEL_BUTTON_TAG, CMS_CONFIRM_BUTTON_TAG } from './cms.constants';

@State<CmsStateModel>({
  name: 'cms',
  defaults: {
    activeEditItemId: '',
    cmsDataEntries: {},
    updatedCmsIds: [],
    currentPage: {
      pageId: undefined,
      subPages: [],
    },
  },
})
@Injectable()
export class CmsState {
  modalRef: BsModalRef;

  constructor(
    private cmsService: CmsService,
    private store: Store,
    private cmsEditService: CmsEditService,
    private translationService: TranslationService,
    private uConfirmationModalService: UniversalConfirmationModalService
  ) {}

  @Action(SetActiveEditItemId)
  setActiveEditItemId({ patchState }: StateContext<CmsStateModel>, action: SetActiveEditItemId) {
    return patchState({ activeEditItemId: action.activeEditItemId });
  }

  @Action(LoadCmsItem)
  loadCmsItem(ctx: StateContext<CmsStateModel>, action: LoadCmsItem) {
    const state = ctx.getState();

    if (!state.cmsDataEntries[action.itemId]) {
      ctx.setState(
        setCmsItemEntryToState(action.itemId, {
          isLoading: true,
          error: false,
          data: null,
        })
      );
      return this.cmsService.getCmsItem(action.itemId).pipe(
        map((cmsItem: { [key: string]: any[] }) => {
          const cmsState = setCmsItemDataToState(action.itemId, cmsItem)(ctx.getState());
          ctx.patchState({ cmsDataEntries: cmsState.cmsDataEntries, updatedCmsIds: cmsState.updatedCmsIds });
        }),
        catchError(() =>
          of(
            ctx.setState(
              setCmsItemEntryToState(action.itemId, {
                isLoading: false,
                error: true,
                data: null,
              })
            )
          )
        )
      );
    } else {
      return ctx.patchState({ updatedCmsIds: [action.itemId] });
    }
  }

  @Action(RemoveCmsItem)
  removeCmsItem(ctx: StateContext<CmsStateModel>, action: RemoveCmsItem) {
    const state = ctx.getState();
    const newState = {
      cmsDataEntries: cloneDeep(state.cmsDataEntries),
    };

    for (const key of Object.keys(newState.cmsDataEntries)) {
      newState.cmsDataEntries[key] = { ...newState.cmsDataEntries[key] };
    }
    getCmsItemIdsFromFlatMap(state, action.itemId).forEach((itemId) => {
      delete newState.cmsDataEntries[itemId];
    });
    ctx.patchState({ cmsDataEntries: newState.cmsDataEntries });
  }

  @Action(ReloadCmsItem)
  reloadCmsItem(ctx: StateContext<CmsStateModel>, action: ReloadCmsItem) {
    // usage of concat map ensures that each dispatch is called in sequence after the previous completes
    return this.store.dispatch(new IncrementLoading()).pipe(
      concatMap(() => this.store.dispatch(new RemoveCmsItem(action.itemId))),
      concatMap(() => this.store.dispatch(new LoadCmsItem(action.itemId))),
      concatMap(() => this.store.dispatch(new DecrementLoading()))
    );
  }

  @Action(ResetCmsDataEntries)
  resetCmsDataEntries(ctx: StateContext<CmsStateModel>) {
    const state = ctx.getState();
    const newCmsDataEntries: { [key: string]: CmsItemEntry } = {};
    // todo when we upgrade cmsItem see if there is a way to make this simpler
    // if it removes the race condition of ctx.patchState({ cmsDataEntries: {} });
    const cmsKeys = Object.keys(state.cmsDataEntries);
    for (const key of cmsKeys) {
      // there are a few options here:
      // a) cms item entry parent is null, this means its a root cms item we want to REMOVE it so it gets cleanly reloaded
      // b) cms item entry is loading, lets REMOVE it and just have it get reloaded as needed
      // c) cms item entry has a parent, lets KEEP it but change its data to be null and mark it as loading so it doesn't reload (parent handles)
      const item = state.cmsDataEntries[key];

      if (!item.isLoading && item.data && item.data.parent != null) {
        newCmsDataEntries[key] = {
          isLoading: true,
          error: false,
          data: null,
        };
      }
    }

    ctx.patchState({ cmsDataEntries: newCmsDataEntries, updatedCmsIds: cmsKeys });
  }

  @Action(MoveCmsItem)
  moveCmsItem(ctx: StateContext<CmsStateModel>, action: MoveCmsItem) {
    let validDirection = false;
    const dirs = Object.keys(CmsConstants.DIRECTIONS);
    for (const dir in dirs) {
      if (action.direction === CmsConstants.DIRECTIONS[dirs[dir]]) {
        validDirection = true;
      }
    }

    if (!validDirection) {
      throw new Error('Direction must be one of CmsService.DIRECTIONS');
    }
    return this.cmsEditService.moveCmsItem(action.relationshipId, action.direction).pipe(
      map(() => {
        this.store.dispatch(new ReloadCmsItem(action.rootId));
      })
    );
  }

  @Action(CopyParentToLocale)
  copyParentToLocale(_ctx: StateContext<CmsStateModel>, action: CopyParentToLocale) {
    this.openConfirmationModal('hub.cms.copyParentToLocale', 'hub.cms.copyParentToLocaleMessage').then((confirmed) => {
      if (confirmed) {
        firstValueFrom(
          this.cmsEditService.copyParentToLocale(
            action.parentId,
            action.fromLocale,
            action.toLocale,
            action.withChildData
          )
        ).then(() => {
          this.store.dispatch(new ReloadCmsItem(action.rootId));
        });
      }
    });
  }

  @Action(CopyItemToLocale)
  copyItemToLocale(_ctx: StateContext<CmsStateModel>, action: CopyItemToLocale) {
    this.openConfirmationModal('hub.cms.copyItemToLocale', 'hub.cms.copyItemToLocaleMessage').then((confirmed) => {
      if (confirmed) {
        firstValueFrom(
          this.cmsEditService.copyItemToLocale(action.relationshipId, action.fromLocale, action.targetLocale)
        ).then(() => {
          this.store.dispatch(new ReloadCmsItem(action.rootId));
        });
      }
    });
  }

  @Action(DeleteCmsItem)
  deleteCmsItem(_ctx: StateContext<CmsStateModel>, action: DeleteCmsItem) {
    this.openConfirmationModal('hub.cms.deleteItem', 'hub.cms.deleteItemMessage').then((confirmed) => {
      if (confirmed) {
        firstValueFrom(this.cmsEditService.removeCm(action.relationshipId)).then(() => {
          this.store.dispatch(new ReloadCmsItem(action.rootId));
        });
      }
    });
  }

  @Action(PublishItem)
  publishCmsItem(ctx: StateContext<CmsStateModel>, action: PublishItem) {
    return this.cmsEditService.publishItem(action.cmsItem).subscribe(() => {
      this.store.dispatch(new ReloadCmsItem(action.rootId));
    });
  }

  @Action(UpdateCmsCurrentPage)
  updateCmsCurrentPage({ patchState }: StateContext<CmsStateModel>, action: UpdateCmsCurrentPage) {
    return patchState({
      currentPage: {
        pageId: action.pageId,
        subPages: action.subPages || [],
      },
    });
  }

  openConfirmationModal(title: string, message: string): Promise<boolean> {
    const translationTags = [title, message, CMS_CONFIRM_BUTTON_TAG, CMS_CANCEL_BUTTON_TAG];

    return new Promise<boolean>((resolve) => {
      this.translationService.getTranslationListPromise(translationTags).then((translations) => {
        const modalOptions: UniversalConfirmationModalOptions = {
          title: translations[title].value,
          confirmationMessage: translations[message].value,
          confirmButton: {
            text: translations[CMS_CONFIRM_BUTTON_TAG].value,
            size: 'grow',
          },
          cancelButton: {
            text: translations[CMS_CANCEL_BUTTON_TAG].value,
            size: 'grow',
          },
        };

        this.uConfirmationModalService.open(modalOptions).then((confirmed) => {
          resolve(confirmed);
        });
      });
    });
  }
}
