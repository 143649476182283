import { FpvWidgetTagsModel } from '@common/fpv-widget/fpvWidgetTags.model';

export const BAOYING_FPV_WIDGET_TAGS: FpvWidgetTagsModel = {
  fpvWidgetTitleTag: 'baoying.fpv.widget.title',
  totalFpvLabelTag: 'baoying.fpv.widget.total.points.label',
  expiresLabelTag: 'baoying.fpv.widget.expires.label',
  expiresSoonLabelTag: 'baoying.fpv.widget.expires-soon.label',
  pointsLabelTag: 'baoying.fpv.widget.points.label',
  errorLoadingTag: 'baoying.fpv.widget.no-points.label',
  imageTag: 'baoying.fpv.widget.image',
  cartUrlTag: 'baoying.fpv.cart.url',
};
