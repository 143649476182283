<u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
<ul *ngFor="let linkItem of cmsItemObj.children">
  <u-cms-child-edit-menu [cmsItemObj]="linkItem" [rootId]="rootId"></u-cms-child-edit-menu>
  <div class="hub-nav-item-link">
    <u-cms-link [cmsItemObj]="linkItem">
      <li class="hub-nav-item" (click)="close()">
        <div class="hub-nav-list-label" [attr.fullstory]="linkItem.item.name">
          {{ linkItem.data.label?.data }}
        </div>
      </li>
    </u-cms-link>
  </div>
</ul>
