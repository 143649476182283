import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { AuthenticationService } from '@common/authentication/services/authentication.service';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { MyAccountListModel } from '../my-account.model';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'u-my-account-list',
  templateUrl: './my-account-list.component.html',
  styleUrls: ['./my-account-list.component.scss'],
})
export class MyAccountListComponent implements OnInit, OnDestroy {
  userProfile: UserProfile;
  private sub: Subscription;

  constructor(
    private myAccountService: MyAccountService,
    private authenticationService: AuthenticationService,
    private modal: NgbActiveModal,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.sub = this.store.select(UserProfileSelectors.getUserProfile).subscribe((userProfile: UserProfile): void => {
      this.userProfile = userProfile;
    });
  }

  get myAccount(): MyAccountListModel[] {
    return this.myAccountService.myAccount;
  }

  logout(): void {
    this.close();
    this.authenticationService.startLogout();
  }

  close(): void {
    this.modal.close();
  }

  showLink(myAccount: MyAccountListModel): boolean {
    if (myAccount.hideForCountries?.inverse === true) {
      return (!this.userProfile.pc || !myAccount.hideForPc) && this.hideForCountry(myAccount.hideForCountries.values);
    } else {
      return (!this.userProfile.pc || !myAccount.hideForPc) && !this.hideForCountry(myAccount.hideForCountries.values);
    }
  }

  hideForCountry(countries: string[]): boolean {
    return countries.includes(this.userProfile.user.country);
  }

  trackById(index: number, account: MyAccountListModel): string {
    return account.id;
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
      delete this.sub;
    }
  }
}
