import { gql } from 'apollo-angular';

export const GET_SHAREABLE_URL_QUERY = `
    query getShareableUrl($sharePlacementId: String, $sharePlacementSide: String, $sharePlacementBc: String, $sponsorId: String, $shareId: String, $shareListId: String, $shareType: String, $shopperSource: String) {

        shareableUrl: getShareableUrl(
            sharePlacementId: $sharePlacementId,
            sharePlacementSide: $sharePlacementSide,
            sharePlacementBc: $sharePlacementBc,
            sponsorId: $sponsorId,
            shareId: $shareId,
            shareListId: $shareListId,
            shareType: $shareType,
            shopperSource: $shopperSource) {
            success
            message {
                shortenedUrl
            }
        }
    }`;

export const GET_SHAREABLE_PRODUCT_URL_QUERY = gql`
  query getShareableProductUrl($shareId: String, $shareType: String, $shopperSource: String) {
    getShareableProductUrl(shareId: $shareId, shareType: $shareType, shopperSource: $shopperSource) {
      success
      message {
        shortenedUrl
      }
    }
  }
`;
