<div
  *ngIf="taskTrackerResult && !taskTrackerResult.errorLoading"
  class="task-tracker-widget-container elevation elevation4"
>
  <ng-container *ngIf="taskTrackerResult.isLoading; else taskTrackerWidget">
    <div class="task-tracker-widget-section">
      <u-task-tracker-widget-skeleton-loader></u-task-tracker-widget-skeleton-loader>
    </div>
  </ng-container>

  <ng-template #taskTrackerWidget>
    <ng-container *ngIf="taskTrackerResult.programProgress">
      <div class="task-tracker-widget-content task-tracker-widget-section">
        <div class="task-tracker-header">
          <div
            *ngIf="taskTrackerResult.programProgress.percentComplete > 0"
            class="task-tracker-progress-percent-container"
          >
            <u-progress-percent-indicator
              [progressPercentage]="taskTrackerResult.programProgress.percentComplete"
            ></u-progress-percent-indicator>
          </div>

          <div class="task-tracker-program-title-container">
            <h5 class="task-tracker-program-title">
              {{ taskTrackerResult.programProgress.headingTag | translate }}
            </h5>

            <div class="start-here-badge">
              {{ 'hub.task_tracker.start_here' | translate }}
            </div>

            <ng-container *ngIf="!taskTrackerResult.programProgress.isCompleted; else completedTaskMessage">
              <div
                *ngIf="taskTrackerResult.programProgress.percentComplete === 0"
                class="task-tracker-program-subtext task-tracker-program-subtext-initial"
              >
                {{ taskTrackerResult.programProgress.subTextInitialTag | translate }}
              </div>

              <div
                *ngIf="taskTrackerResult.programProgress.percentComplete > 0"
                class="task-tracker-program-subtext task-tracker-program-subtext-inprogress"
                innerHtml="{{
                  taskTrackerResult.programProgress.subTextInProgressTag
                    | translate
                    | enhancedReplace
                      : progressPercentReplaceToken
                      : (taskTrackerResult.programProgress.percentComplete | number : '1.0-0') + '%'
                      : 'progress-percentage-emphasis'
                }}"
              ></div>
            </ng-container>
            <ng-template #completedTaskMessage>
              <div class="task-tracker-program-subtext task-tracker-program-subtext-completed">
                {{ taskTrackerResult.programProgress.subTextCompletedTag | translate }}
              </div>
            </ng-template>
          </div>

          <div *ngIf="canShowLink && currentColumnCount > 1" class="task-tracker-completed-link">
            <a [href]="taskTrackerResult.programProgress.completedLinkUrl">
              {{ taskTrackerResult.programProgress.completedLinkTextTag | translate }}
            </a>
          </div>
        </div>

        <div class="task-tracker-tasks-list-container" *ngIf="!taskTrackerResult.programProgress.isCompleted">
          <div
            *ngFor="
              let taskCompletion of taskTrackerResult.programProgress.tasks
                | orderTasks
                | slice : 0 : (!showMore ? defaultVisibleTaskCount : taskListLength);
              trackBy: trackByIndex
            "
            class="task-tracker-task-item-container"
            [style.minWidth.%]="100 / currentColumnCount"
          >
            <u-task-tracker-item [taskCompletion]="taskCompletion"></u-task-tracker-item>
          </div>
        </div>
        <div
          *ngIf="!taskTrackerResult.programProgress.isCompleted && taskListLength > defaultVisibleTaskCount"
          class="task-tracker-widget-expand task-tracker-widget-section"
        >
          <div class="show-more-container">
            <a class="show-more-link" href="javascript:void(0);" (click)="onShowMoreToggleClicked()">
              <ng-container *ngIf="!showMore; else showLessLink">
                {{ 'hub.task_tracker.show_more' | translate }}
              </ng-container>

              <ng-template #showLessLink>
                {{ 'hub.task_tracker.show_less' | translate }}
              </ng-template>
            </a>
          </div>
        </div>
      </div>

      <div
        class="task-tracker-widget-section task-tracker-widget-completed-link-section"
        *ngIf="canShowLink && currentColumnCount <= 1"
      >
        <div class="task-tracker-completed-link">
          <a [href]="taskTrackerResult.programProgress.completedLinkUrl" class="centered">
            {{ taskTrackerResult.programProgress.completedLinkTextTag | translate }}
          </a>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
