import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { CommonIconsModule } from '../../../common/src/common-icons/common-icons.module';
import { QuickLinksComponent } from './quick-links.components';

@NgModule({
  declarations: [QuickLinksComponent],
  imports: [CommonModule, TranslationModule, CommonIconsModule],
  exports: [QuickLinksComponent],
})
export class QuickLinksModule {}
