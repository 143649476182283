import { Selector } from '@ngxs/store';
import { FpvReportStateModel } from './fpv-report.model';
import { FpvReportState } from './fpv-report.state';

export class FpvReportSelectors {
  @Selector([FpvReportState])
  public static getFpvReport(state: FpvReportStateModel) {
    return state.fpvReport;
  }
}
