import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TASK_TRACKER_COLUMN_BREAKPOINTS } from '../../constants/task-tracker-widget-column-breakpoints.constants';
import { TaskTrackerResult } from '../../models/task-tracker-result';
import { LoadTaskTrackerProgramProgress } from '../../state/task-tracker.actions';
import { TaskTrackerSelectors } from '../../state/task-tracker.selectors';

@Component({
  selector: 'u-task-tracker-widget-container',
  templateUrl: './task-tracker-widget-container.component.html',
  styleUrls: ['./task-tracker-widget-container.component.scss'],
})
export class TaskTrackerWidgetContainerComponent implements OnInit {
  @Input() programKey: string;
  @Input() hideCompletedLink = false;

  readonly pageSize$ = this.breakpointObserver
    .observe(TASK_TRACKER_COLUMN_BREAKPOINTS.map((b) => `(max-width: ${b.minPageWidth}px)`))
    .pipe(map((_result: BreakpointState) => window.innerWidth));

  taskTrackerResultLookupFn$: Observable<(programKey: string) => TaskTrackerResult> = this.store.select(
    TaskTrackerSelectors.getTaskTrackerResultLookupFn
  );

  taskTrackerResult$: Observable<TaskTrackerResult>;

  showMore = false;

  constructor(private store: Store, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.taskTrackerResult$ = this.taskTrackerResultLookupFn$.pipe(map((lookupFn) => lookupFn(this.programKey)));

    if (this.programKey) {
      this.store.dispatch(new LoadTaskTrackerProgramProgress(this.programKey));
    }
  }

  onShowMoreToggleClicked(): void {
    this.showMore = !this.showMore;
  }
}
