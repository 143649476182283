<div *ngIf="requestAttrs && requestAttrs.user" [style]="{ 'margin-top': (navOffset$ | async) + 'px' }">
  <div class="row no-margin pb-4">
    <div class="col-12 col-md-5 col-lg-6 ps-0 py-3">
      <div class="dashboard-breadcrumbs">
        <span *ngIf="!requestAttrs.user.pc" class="px-2">{{
          'hub.home.welcomeMessage' | translate | interpolate : this
        }}</span>
      </div>
    </div>
    <div class="col-12 col-md-7 col-lg-6 pe-0 py-3 text-end">
      <u-important-dates></u-important-dates>
    </div>
  </div>
  <div class="row pb-4">
    <div class="col-12 d-flex flex-wrap">
      <div class="cms-banner-slide-container-row cms-banner-slide-col">
        <u-home-banner></u-home-banner>
      </div>
      <div class="usana-news-widget-col">
        <u-usana-news-widget></u-usana-news-widget>
      </div>
    </div>
  </div>
  <div class="row quick-links-row">
    <u-quick-links></u-quick-links>
  </div>
  <div class="row widget-container no-margin">
    <div class="col-12 col-md-6 pt-4" *ngIf="!requestAttrs.pc">
      <u-pacesetter-widget></u-pacesetter-widget>
      <u-baoying-fpv-widget></u-baoying-fpv-widget>
      <u-baoying-volume-widget></u-baoying-volume-widget>
    </div>
    <div class="col-12 col-md-6 pt-4" [ngClass]="{ 'col-md-12 col-lg-6': requestAttrs.pc }">
      <u-notification-widget [notifications]="notifications$ | async" [limit]="true"></u-notification-widget>
    </div>
  </div>
</div>
