import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AppUser, UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { NavigationSelectors } from '@common/navigation/state/navigation.selectors';
import { Notification } from '@common/notifications/notification.model';
import { NotificationSelectors } from '@common/notifications/state/notification.selectors';
import { Select, Store } from '@ngxs/store';
import { UniversalModalOptions, UniversalModalService } from '@usana/ux/universal-components/modal';
import { Observable, Subject, takeUntil } from 'rxjs';
import { PcBlockedModalComponent } from './pc-blocked-modal/pc-blocked-modal.component';

@Component({
  selector: 'u-home-page',
  templateUrl: './baoying-home-page.component.html',
  styleUrls: ['./baoying-home-page.component.scss'],
})
// NOTE: see UsanaHomePageComponent for usana version.
export class BaoyingHomePageComponent implements AfterViewInit, OnDestroy {
  // normally I would so a @Select async like we do with everything else
  // but because this component contains a interpolate translation
  // that makes this difficult
  requestAttrs: UserProfile;

  notifications$: Observable<Notification[]> = this.store.select(NotificationSelectors.getCurrentNotifications);

  destroy$ = new Subject<void>();

  constructor(private store: Store, private uModalService: UniversalModalService) {}

  readonly PC_BLOCKED_MODAL_OPTIONS: UniversalModalOptions = {
    removeXButton: true,
    keyboard: false,
    backdrop: 'static',
    beforeDismiss: () => false,
  };

  ngAfterViewInit(): void {
    this.store
      .select(UserProfileSelectors.getUserProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile) => {
        this.requestAttrs = userProfile;

        this.blockIfPc(userProfile?.user);
      });
  }

  blockIfPc(user: AppUser): void {
    if (user?.pc) {
      this.uModalService.open(this.PC_BLOCKED_MODAL_OPTIONS, PcBlockedModalComponent);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
