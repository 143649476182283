import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FpvReportModel } from './state/fpv-report.model';

@Injectable({
  providedIn: 'root',
})
export class FpvReportService {
  constructor(private http: HttpClient) {}

  getFpvReport(): Observable<FpvReportModel> {
    const url = '/dlm2/rest/fpv/widget';
    return this.http.get(url).pipe(map((fpvReport: FpvReportModel) => fpvReport));
  }
}
