import { Component, Input } from '@angular/core';
import { CmsBaseDirective } from '../cms-base.directive';
import { CmsItemModel } from '../cms.model';

@Component({
  selector: 'u-cms-task-tracker',
  templateUrl: './cms-task-tracker.component.html',
  styleUrls: ['./cms-task-tracker.component.scss'],
})
export class CmsTaskTrackerComponent extends CmsBaseDirective {
  @Input() cmsItemObj: CmsItemModel;
}
