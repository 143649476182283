import { UplineLeaderPlacementReportData } from '@common/upline-leader-placement/upline-leader-placement.model';
import { ReportCellContent } from '@usanaHub/app/team-manager/models/report-cell-content';

export const MAP_BAOYING_UPLINE_REPORT_FN = (
  uplineReportDocument: ReportCellContent[],
): UplineLeaderPlacementReportData[] => [
  {
    groupTitle: 'hub.uplineLeaderReport.sponsorInfo',
    data: [
      {
        label: 'hub.uplineLeaderReport.associateId',
        value: uplineReportDocument[4].v,
      },
      {
        label: 'hub.uplineLeaderReport.firstName',
        value: uplineReportDocument[6].v,
      },
      {
        label: 'hub.uplineLeaderReport.sponsorBranch',
        value: uplineReportDocument[12].v,
      },
    ],
  },
  {
    groupTitle: 'hub.uplineLeaderReport.placementInfo',
    data: [
      {
        label: 'hub.uplineLeaderReport.businessCenter',
        value: uplineReportDocument[14].v,
      },
      {
        label: 'hub.uplineLeaderReport.firstName',
        value: uplineReportDocument[16].v,
      },
      {
        label: 'hub.uplineLeaderReport.placementBranch',
        value: uplineReportDocument[18].v,
      },
    ],
  },
];
