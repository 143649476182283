import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CmsConstants } from '@common/cms/cms.constants';
import { CmsBindingDefinition } from '@common/cms/cms.model';
import { CmsComponentService } from '@common/cms/services/cms-component.service';
import { LoadCmsItem } from '@common/cms/state/cms.actions';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { CmsItemEntry } from '@common/cms/state/cms.state.model';
import { Store } from '@ngxs/store';
import { PerformanceService } from '@shared/performance/performance.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'u-cms-item',
  templateUrl: './cms-item.component.html',
  styleUrls: [],
  // changeDetection: ChangeDete,
})
export class CmsItemComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() itemId;
  @Input() placeholderData: any;
  @Input() navClickEvent: string;

  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef;

  cmsItemObj;

  private itemSubscription;
  private profileSubscription;
  component;
  private componentType: string;

  constructor(private readonly store: Store, private readonly cmsComponentService: CmsComponentService) {
    this.profileSubscription = this.store.select(UserProfileSelectors.getUserProfile).subscribe(() => {
      this.reloadThis();
    });
  }

  ngAfterViewInit(): void {
    if (this.cmsItemObj) {
      this.renderCmsItem(this.cmsItemObj);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemId) {
      this.cleanupItemSub();
      this.itemSubscription = this.store
        .select(CmsSelectors.cmsItemIsUpdatedFn)
        .pipe(filter((fn: (itemId: string) => boolean) => fn(this.itemId)))
        .subscribe(() => {
          // when our itemId is listed as an item that is changed
          // get the item from the store and rerender it
          // NOTE: we do it this way because it is cheaper co compare string arrays and then only build the cms object
          // if it has changed
          const cmsItem = this.store.selectSnapshot(CmsSelectors.getCmsItemByIdFn)(this.itemId);
          if (cmsItem && cmsItem.data) {
            if (CmsConstants.DEBUG) {
              console.log(cmsItem);
            }
          }
          this.renderCmsItem(cmsItem);
        });
      // render placeholder data
      if (this.placeholderData) {
        this.renderCmsItem({
          isLoading: false,
          data: {
            ...this.placeholderData,
            placeholder: true,
          },
          error: false,
        });
      }
      this.reloadThis();
    }
  }

  ngOnDestroy(): void {
    this.profileSubscription.unsubscribe();
    this.cleanupItemSub();
  }

  // prettier-ignore
  private renderCmsItem(cmsItem: CmsItemEntry): void { // NOSONAR
    if (cmsItem && !cmsItem.isLoading) {
      const newType = cmsItem.data.type.id;
      if (this.vc) {
        if (!this.componentType || this.componentType !== newType) {
          // only if the type changed.
          this.updateVc(cmsItem).then((compRef) => {
            if (compRef != null) {
              this.componentType = newType;
              this.component = compRef;
              PerformanceService.mark(`CMS:${newType}:${cmsItem.data.item.id}`);
            }
          });
        } else {
          // we are the same type so just update our data.
          // calling the setInput method makes sure change detection gets invoked on our component ref component
          this.component.setInput('cmsItemObj', cmsItem.data);
        }
      } else {
        // vc isn't set yet so save the cms data
        this.cmsItemObj = cmsItem;
      }
    }
  }

  /**
   * update the View child, wrapped in a promise for when we have lazy loading
   * @private a component reference from componentFactoryResolver if once exists (null if no type was found)
   */
  private updateVc(cmsItem: CmsItemEntry): Promise<any> {
    return this.cmsComponentService
      .getComponentFromType(cmsItem.data.type.id)
      .then((compToUse: CmsBindingDefinition) => {
        if (compToUse != null) {
          this.vc.clear();

          const createdComponent = this.vc.createComponent(compToUse.componentClass);
          compToUse.bindData(createdComponent, cmsItem, this.navClickEvent);

          return createdComponent;
        }

        return null;
      });
  }

  private reloadThis(): void {
    if (this.vc) {
      this.vc.clear();
    }
    this.componentType = undefined;
    if (this.itemId) {
      this.store.dispatch(new LoadCmsItem(this.itemId));
    }
  }

  private cleanupItemSub(): void {
    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe();
      delete this.itemSubscription;
    }
  }
}
