import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EnhancedReplacePipe } from './enhanced-replace.pipe';

@NgModule({
  declarations: [EnhancedReplacePipe],
  imports: [CommonModule],
  exports: [EnhancedReplacePipe],
})
export class EnhancedReplaceModule {}
