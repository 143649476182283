<div class="task-tracker-skeleton-loader">
  <svg class="loading-widget-skeleton-circle">
    <path
      d="
            M32 2
            a 30 30 0 0 1 0 60
            a 30 30 0 0 1 0 -60
          "
    />
  </svg>

  <svg class="loading-widget-skeleton-bars">
    <rect x="0" y="4px" rx="4px" ry="4px" width="50%" height="20px" />
    <rect x="0" y="34px" rx="4px" ry="4px" width="90%" height="20px" />
  </svg>
</div>
