import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationBellModule } from '@common/notifications/notification-bell/notification-bell.module';
import { NotificationDashboardModule } from '@common/notifications/notification-dashboard/notification-dashboard.module';
import { NotificationSettingsModule } from '@common/notifications/notification-settings/notification-settings.module';
import { NotificationSingleItemModule } from '@common/notifications/notification-single-item/notification-single-item.module';
import { NotificationWidgetModule } from '@common/notifications/notification-widget/notification-widget.module';
import { NotificationService } from '@common/notifications/notification.service';
import { NotificationState } from '@common/notifications/state/notification.state';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxsModule } from '@ngxs/store';
import { DropdownButtonComponent } from '@usana/ux/universal-components/button';
import { UniversalModalModule } from '@usana/ux/universal-components/modal';
import { UnsafeModule } from '../../../shared/src/pipes/unsafe/unsafe.module';
import { CommonIconsModule } from '../common-icons/common-icons.module';
import { UsanaDatePipeModule } from '../custom-locale-pipes/usana-date-pipe/usana-date-pipe.module';
import { TranslationModule } from '../translation/translation.module';
import { NotificationDetailsModalComponent } from './notification-details-modal/notification-details-modal.component';
import { NotificationListModalComponent } from './notification-list-modal/notification-list-modal.component';

@NgModule({
  declarations: [NotificationListModalComponent, NotificationDetailsModalComponent],
  imports: [
    CommonModule,
    CommonIconsModule,
    TranslationModule,
    NgxsModule.forFeature([NotificationState]),
    NotificationWidgetModule,
    NotificationBellModule,
    NotificationDashboardModule,
    NotificationSettingsModule,
    NgxsActionsExecutingModule.forRoot(),
    NotificationSingleItemModule,
    DropdownButtonComponent,
    UniversalModalModule,
    UnsafeModule,
    UsanaDatePipeModule,
  ],
  exports: [
    NotificationWidgetModule,
    NotificationDashboardModule,
    NotificationBellModule,
    NotificationSettingsModule,
    NotificationSingleItemModule,
  ],
  providers: [NotificationService],
})
export class NotificationsModule {}
