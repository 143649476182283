import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Toast } from './toast.model';
import { ToastService } from './toast.service';

@Component({
  selector: 'toast-display',
  templateUrl: './toast-display.component.html',
  styleUrls: ['./toast-display.component.scss'],
})
export class ToastDisplayComponent implements OnInit, OnDestroy {
  toasts: Toast[];

  private toastSubscription: Subscription;

  constructor(private toastService: ToastService, private changeDetectorRef: ChangeDetectorRef) {
    this.toasts = [];
  }

  ngOnInit(): void {
    this.toastSubscription = this.toastService.getToastSubject().subscribe((toasts: Toast[]) => {
      this.toasts = toasts;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.toastSubscription) {
      this.toastSubscription.unsubscribe();
      delete this.toastSubscription;
    }
  }

  close(toast: Toast) {
    this.toastService.removeWithInternalId(toast.internalId);
  }

  trackByInternalId(index, toast) {
    return toast.internalId;
  }
}
