import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CmsItemService {
  getValueFromLinkData(item, key) {
    const linkData = item.data.linkData.data.replace(/\s/g, '');
    return linkData.split(key).pop().split('"').shift();
  }
}
