<div class="notification-container pb-5">
  <div class="row no-margin pb-5">
    <div class="col-12 col-md-12 col-lg-5 ps-0">
      <u-bread-crumb [children]="[{ name: 'notifications.landing.notificationCenter', isTranslationTag: true}]"></u-bread-crumb>
    </div>
    <div class="col-12 col-md-12 col-lg-7 pe-0">
      <div class="notification-title-switch-box">
        <div
          id="notificationTab"
          (click)="switchNotificationView('notification')"
          class="notification-title-switch"
          [ngClass]="{ active: !isArchivedView }"
        >
          {{ 'notifications.landing-title-notifications' | translate }}
        </div>
        <div
          id="archivedTab"
          [ngClass]="{ active: isArchivedView }"
          (click)="switchNotificationView('archived')"
          class="notification-title-switch"
        >
          {{ 'notifications.landing-title-archived' | translate }}
        </div>
      </div>
    </div>
  </div>

  <u-notification-widget
    [limit]="false"
    [notifications]="isArchivedView ? (archivedNotifications$ | async) : (currentNotifications$ | async)"
    [loadingNotifications]="isArchivedView ? (loadArchivedNotifications$ | async) : (loadCurrentNotifications$ | async)"
  ></u-notification-widget>
</div>
