import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthenticationModule } from '@common/authentication/authentication.module';
import { TranslationModule } from '@common/translation/translation.module';
import { NgxsModule } from '@ngxs/store';
import { LoadingSpinnerModule } from '@shared/loading-spinner/loading-spinner.module';
import { EnhancedReplaceModule } from '@shared/pipes/enhanced-replace/enhanced-replace.module';
import { ReplaceModule } from '@shared/pipes/replace/replace.module';
import { UsanaAnalyticsModule } from '@usana/ux/analytics';
import { environment } from '../../../../../environments/environment';
import { CommonIconsModule } from '../../../../common/src/common-icons/common-icons.module';
import { ProgressPercentIndicatorComponent } from '../../../../shared/src/progress-percent-indicator/progress-percent-indicator.component';
import { TaskTrackerItemComponent } from './components/task-tracker-item/task-tracker-item.component';
import { TaskTrackerWidgetContainerComponent } from './components/task-tracker-widget-container/task-tracker-widget-container.component';
import { TaskTrackerWidgetSkeletonLoaderComponent } from './components/task-tracker-widget/task-tracker-widget-skeleton-loader/task-tracker-widget-skeleton-loader.component';
import { TaskTrackerWidgetComponent } from './components/task-tracker-widget/task-tracker-widget.component';
import { OrderTasksPipe } from './pipes/order-tasks.pipe';
import { TaskTrackerNavigationTaskService } from './services/task-tracker-navigation-task.service';
import { TaskTrackerState } from './state/task-tracker.state';
import { TASK_TRACKER_SERVICE_URL } from './task-tracker-widget.tokens';

@NgModule({
  declarations: [
    TaskTrackerWidgetComponent,
    TaskTrackerWidgetContainerComponent,
    TaskTrackerItemComponent,
    OrderTasksPipe,
    TaskTrackerWidgetSkeletonLoaderComponent,
  ],
  imports: [
    CommonModule,
    NgxsModule.forFeature([TaskTrackerState]),
    LoadingSpinnerModule,
    ReplaceModule,
    EnhancedReplaceModule,
    TranslationModule,
    UsanaAnalyticsModule,
    CommonIconsModule,
    ProgressPercentIndicatorComponent,
  ],
  providers: [
    {
      provide: TASK_TRACKER_SERVICE_URL,
      useValue: environment.taskTrackerServiceUrl,
    },
  ],
  exports: [TaskTrackerWidgetContainerComponent],
})
export class TaskTrackerWidgetModule {
  constructor(_navigationTaskService: TaskTrackerNavigationTaskService) {}
}
