import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Store } from '@ngxs/store';
import { DropdownButtonMenuItem } from '@usana/ux/universal-components/button';
import { Observable, mergeMap } from 'rxjs';
import { Notification } from '../notification.model';
import { NotificationService } from '../notification.service';
import { LoadCurrentNotifications } from '../state/notification.actions';
import { NotificationSelectors } from '../state/notification.selectors';

@Component({
  selector: 'u-notification-list-modal',
  templateUrl: './notification-list-modal.component.html',
  styleUrls: ['./notification-list-modal.component.scss'],
})
export class NotificationListModalComponent {
  loadCurrentNotifications$: Observable<ActionsExecuting> = this.store.select(
    actionsExecuting([LoadCurrentNotifications]),
  );
  displayedNotifications$: Observable<Notification[]> = this.store.select(
    NotificationSelectors.getFirstTenNotifications,
  );
  unreadNotificationCount$: Observable<number> = this.store.select(NotificationSelectors.getUnreadNotificationCount);

  dropdownMenuItems$: Observable<DropdownButtonMenuItem[]>;

  constructor(
    private store: Store,
    private modal: NgbActiveModal,
    private notificationsService: NotificationService,
  ) {
    this.dropdownMenuItems$ = this.displayedNotifications$.pipe(
      mergeMap((notifications) => this.notificationsService.getDropdownMenuItems(true, notifications)),
    );
  }

  close(): void {
    this.modal.close();
  }
}
