<u-widget [widgetData]="{ title: fpvWidgetTags.fpvWidgetTitleTag | translate }">
  <div class="card-body">
    <div class="row" *ngIf="fpvReport.summary.totalAvailable > 0">
      <div class="col-12 col-lg-12">
        <div class="row pb-5">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="fpv-image-container">
              <img [src]="fpvWidgetTags.imageTag | translate" width="100%" height="100%" class="fpv-lg-dis" />
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12">
            <div class="product-desc-total-points text-center">
              {{ fpvWidgetTags.totalFpvLabelTag | translate }}
            </div>
            <div class="product-points-container">
              <div class="product-total-points">
                {{
                  fpvWidgetTags.pointsLabelTag
                    ? fpvReport.summary.totalAvailable
                    : (fpvReport.summary.totalAvailable | currency : fpvReport.summary.currencyCode : 'symbol-narrow')
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-12">
        <div class="fpv-dates-container">
          <div class="d-flex flex-wrap align-center" *ngFor="let value of fpvReport.document; trackBy: trackByIndex">
            <div
              class="fpv-expire-row"
              [ngClass]="{ 'fpv-expire-row-soon': value[0].v > fpvReport.endTime && value[0].v < fpvReport.maxDays }"
            >
              <a class="row fpv-card-label" [href]="fpvWidgetTags.cartUrlTag | translate" target="_blank">
                <div class="col-7">
                  <span *ngIf="value[0].v > fpvReport.endTime && value[0].v < fpvReport.maxDays">{{
                    fpvWidgetTags.expiresSoonLabelTag | translate
                  }}</span>
                  <span *ngIf="!(value[0].v > fpvReport.endTime && value[0].v < fpvReport.maxDays)">{{
                    fpvWidgetTags.expiresLabelTag | translate
                  }}</span>
                  &nbsp;
                  <span>{{ value[0].v | usanaDate : 'shortDate' }}</span>
                </div>
                <div class="col-5 text-right">
                  {{
                    fpvWidgetTags.pointsLabelTag ? value[1].v : (value[1].v | currency : value[2].v : 'symbol-narrow')
                  }}
                  {{ fpvWidgetTags.pointsLabelTag ? (fpvWidgetTags.pointsLabelTag | translate) : '' }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!(fpvReport.summary.totalAvailable > 0)">
      <div class="col-12 widget-blank-container">
        <h4 class="widget-blank-text widget-loading-error">
          {{ fpvWidgetTags.errorLoadingTag | translate }}
        </h4>
      </div>
    </div>
    <div *ngIf="loadingFpvReport">
      <u-loading-spinner></u-loading-spinner>
    </div>
  </div>
</u-widget>
