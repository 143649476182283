import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FPVReportModel } from '@common/reports/reports.model';
import { TranslationService } from '@common/translation/translation.service';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Select, Store } from '@ngxs/store';
import { DataViewTypes } from '@shared/data-view/data-view.types';
import { SelectOption } from '@usana/ux/universal-components/input/select';
import { WidgetData } from '@usana/ux/widget';
import { ChartData } from 'chart.js';
import { Observable, Subscription } from 'rxjs';
import {
  LoadBaoyingVolumeReport,
  LoadCommissionWeeks,
  SetCommissionWeek,
} from '../state/baoying-volume-report.actions';
import { BaoyingVolumeReportSelectors } from '../state/baoying-volume-report.selectors';
import { BAOYING_BAR_CHART_DATA, CHART_OPTIONS, TITLE_TAG } from './baoying-volume-widget.constants';

@Component({
  selector: 'u-baoying-volume-widget',
  templateUrl: './baoying-volume-widget.component.html',
  styleUrls: ['./baoying-volume-widget.component.scss'],
})
export class BaoyingVolumeWidgetComponent implements OnInit, OnDestroy {
  loadBaoyingVolumeReport$: Observable<ActionsExecuting> = this.store.select(
    actionsExecuting([LoadBaoyingVolumeReport])
  );

  volumeReport: FPVReportModel;
  hasError;

  barChartData: ChartData = BAOYING_BAR_CHART_DATA;
  metadata;
  volume;
  hasVolumeToShow: boolean;
  commissionWeeks: SelectOption[] = [];

  readonly dataViewName: DataViewTypes = 'stackedBar';

  private volumeIndex: number;
  private volumeReportSub: Subscription;

  constructor(private store: Store, private translateService: TranslationService) {}

  ngOnInit(): void {
    this.loadWidgetData();
    this.getLabelTranslation(0);
    this.getLabelTranslation(1);
  }

  get title(): string {
    return this.translateService.getTranslationForFilter(TITLE_TAG);
  }

  loadWidgetData(): void {
    this.store.dispatch(new LoadCommissionWeeks()).subscribe(
      () => {
        this.store.dispatch(new LoadBaoyingVolumeReport()).subscribe(
          () => {
            this.volumeReportSub = this.store
              .select(BaoyingVolumeReportSelectors.getVolume)
              .subscribe((volumeReport) => {
                if (volumeReport) {
                  this.volumeReport = volumeReport;
                  this.volumeIndex = 0;
                  this.buildVolumeChartData();
                }
              });
            this.mapCommissionWeeksSelect();
          },
          (error): void => {
            this.hasError = error;
          }
        );
      },
      (error): void => {
        this.hasError = error;
      }
    );
  }

  mapCommissionWeeksSelect(): void {
    this.volumeReportSub = this.store
      .select(BaoyingVolumeReportSelectors.getCommissionWeeks)
      .subscribe((commissionWeeks: number[]): void => {
        if (commissionWeeks) {
          const typeList = [];
          for (const commissionWeek of commissionWeeks) {
            typeList.push({
              key: commissionWeek,
              value: new DatePipe('zh_CN').transform(commissionWeek, 'mediumDate'),
            });
          }
          this.commissionWeeks = typeList;
        }
      });
  }

  moveVolume(amountToChange: number): void {
    if (this.validNewIndex(amountToChange)) {
      this.volumeIndex = this.volumeIndex + amountToChange;
      this.buildVolumeChartData();
    }
  }

  validNewIndex(amountToChange: number): boolean {
    return this.volumeReport && !!this.volumeReport.document[this.volumeIndex + amountToChange];
  }

  private buildVolumeChartData(): void {
    this.volume = this.volumeReport.document[this.volumeIndex];
    this.barChartData.datasets[0].data = [this.volume[4].v, this.volume[7].v, this.volume[6].v, this.volume[5].v];
    this.barChartData.datasets[1].data = [this.volume[8].v, this.volume[11].v, this.volume[10].v, this.volume[9].v];

    this.barChartData.datasets = [].concat(this.barChartData.datasets);
    this.hasVolumeToShow = !(
      !this.volume[4].v &&
      !this.volume[7].v &&
      !this.volume[6].v &&
      !this.volume[5].v &&
      !this.volume[8].v &&
      !this.volume[11].v &&
      !this.volume[10].v &&
      !this.volume[9].v
    );

    this.metadata = {
      options: CHART_OPTIONS,
      hasNextPage: false,
      filterValues: [],
    };
  }

  private getLabelTranslation(index: number): void {
    this.translateService.getTranslationPromise(this.barChartData.datasets[index].label).then((translation) => {
      this.barChartData.datasets[index].label = translation.value;
    });
  }

  changeCommissionWeek(commissionWeek: number): void {
    this.store.dispatch(new SetCommissionWeek(commissionWeek));
  }

  getWidgetData(): WidgetData {
    return {
      title: this.title,
      selectOptions: {
        selectMenuItems: this.commissionWeeks,
        callbackFunction: (arg: number): void => {
          this.changeCommissionWeek(arg);
        },
      },
    };
  }

  ngOnDestroy(): void {
    if (this.volumeReportSub) {
      this.volumeReportSub.unsubscribe();
    }
  }
}
