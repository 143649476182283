import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSelectors } from '../../../usana/src/app/state/app.selectors';

@Component({
  selector: 'u-important-dates',
  templateUrl: './important-dates.component.html',
  styleUrls: ['./important-dates.component.scss'],
})
export class ImportantDatesComponent implements OnInit, OnDestroy {
  importantDates: any;
  importantDateKeys: string[] = [];
  subscription = new Subscription();

  constructor(private store: Store) {}

  ngOnInit() {
    this.observeImportantDates();
  }

  observeImportantDates() {
    this.subscription = this.store
      .select(AppSelectors.getImportantDates)
      .pipe(
        map((importantDates) => {
          if (importantDates) {
            this.importantDates = importantDates;
            this.importantDateKeys = Object.keys(importantDates);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  trackByImportantDateKey(index, key) {
    return key;
  }
}
