import { Selector } from '@ngxs/store';
import { SupportedMarkets } from '../change-market/change-market.model';
import { AppState } from './app.state';
import { AppStateModel } from './app.state.model';

export class AppSelectors {
  @Selector([AppState])
  static getIsBaoying(state: AppStateModel): boolean {
    return state?.isBaoying;
  }

  @Selector([AppState])
  static getIsLoading(state: AppStateModel): boolean {
    return state?.isLoading;
  }

  @Selector([AppState])
  static getSupportedMarkets(state: AppStateModel): SupportedMarkets {
    return state?.supportedMarkets;
  }

  @Selector([AppState])
  static getImportantDates(state: AppStateModel): any {
    return state?.importantDates;
  }
}
