import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContestRegistration, ContestResponse } from '@common/contest/state/contest-service.state.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContestApiService {
  constructor(private readonly httpClient: HttpClient) {}

  isRegistered(contestId: number): Observable<ContestResponse<ContestRegistration>> {
    return this.httpClient.get<ContestResponse<ContestRegistration>>(
      `${environment.apiDomain}/contest/registration/${contestId}`,
      {
        withCredentials: true,
      }
    );
  }

  registerForContest(contestId: number): Observable<ContestResponse<ContestRegistration>> {
    return this.httpClient.put<ContestResponse<ContestRegistration>>(
      `${environment.apiDomain}/contest/registration/${contestId}/reg/1`,
      {
        withCredentials: true,
      }
    );
  }
}
