import { Component, inject, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NavigationSelectors } from '../../navigation/state/navigation.selectors';
import { NavigationLevel } from '../../navigation/state/navigation.state.model';

@Component({
  selector: 'u-cms-hub-nav-item',
  templateUrl: './cms-hub-nav-item.component.html',
  styleUrls: ['./cms-hub-nav-item.component.scss'],
})
export class CmsHubNavItemComponent {
  navLevel$: Observable<NavigationLevel> = inject(Store).select(NavigationSelectors.getNavLevel);

  NavigationLevel = NavigationLevel;

  @Input() title: string;
  @Input() svgPath1: string;
  @Input() svgPath2: string;
}
