import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WINDOW_TOKEN } from '@usana/ux/common';

@Injectable({
  providedIn: 'root',
})
export class ScrollTopOnNavService {
  constructor(private router: Router, @Inject(WINDOW_TOKEN) protected readonly window: Window) {}

  scrollTopOnNav() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // safari needs a timeout to scroll to top
        setTimeout(() => {
          this.window.scrollTo(0, 0);
        });
      }
    });
  }
}
