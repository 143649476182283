import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { UplineLeaderPlacementComponent } from './upline-leader-placement/upline-leader-placement.component';

@NgModule({
  declarations: [UplineLeaderPlacementComponent],
  imports: [CommonModule, TranslationModule],
  exports: [UplineLeaderPlacementComponent],
})
export class UplineLeaderPlacementModule {}
