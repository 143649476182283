import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enhancedReplace',
})
/**
 * EnhancedReplacePipe
 * This pipe replaces some template text with another.
 * In addition, it allows us to enhance the text with CSS by wrapping it in a span.
 */
export class EnhancedReplacePipe implements PipeTransform {
  transform(inputText: string, findText: string, replaceText: string, addClasses: string[]): unknown {
    let outputText = inputText;

    if (findText) {
      const openTag = `<span class="${addClasses}">`;
      const closeTag = '</span>';
      const fullReplaceText = `${openTag}${replaceText}${closeTag}`;

      outputText = inputText.replace(findText, fullReplaceText);
    }

    return outputText;
  }
}
