<universal-modal-base>
  <ng-container modalHeader>
    <h2 class="usana-news-title">{{ 'hub.usana-news.title' | translate }}</h2>
  </ng-container>

  <ng-container modalBody>
    <div *ngIf="data.usanaNewsItem as usanaNewsItem" class="news-item-modal-body" (click)="captureClick($event)">
      <h3>{{ usanaNewsItem.data.title.data }}</h3>

      <div>
        <img
          *ngIf="usanaNewsItem.data.imageSrc240x240"
          [alt]="usanaNewsItem.data.ImageAlt ? usanaNewsItem.data.ImageAlt.data : ''"
          [src]="usanaNewsItem.data.imageSrc240x240.data"
          class="usana-news-thumbnails-modal"
        />

        <div *ngIf="usanaNewsItem.data.author" [innerHTML]="usanaNewsItem.data.author.data | unsafe : 'html'"></div>
      </div>

      <div *ngIf="usanaNewsItem.data.content1">
        <p [innerHTML]="usanaNewsItem.data.content1.data | interpolate : this | unsafe : 'html'"></p>
      </div>

      <div *ngIf="usanaNewsItem.data.imageSrc300x200" class="news-image-container">
        <img
          [alt]="usanaNewsItem.data.ImageAlt ? usanaNewsItem.data.ImageAlt.data : ''"
          [src]="usanaNewsItem.data.imageSrc300x200.data"
          class="img-responsive news-image-large"
        />
      </div>

      <div *ngIf="usanaNewsItem.data.content2">
        <p [innerHTML]="usanaNewsItem.data.content2.data | interpolate : this | unsafe : 'html'"></p>
      </div>

      <div class="usana-news-link text-center">
        <a (click)="captureClickExpandView()" [routerLink]="['/content/' + usanaNewsItem.parent.id]">
          {{ 'hub-usana-news.expandView' | translate }}
        </a>
      </div>
    </div>
  </ng-container>
</universal-modal-base>
