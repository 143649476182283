import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { Store } from '@ngxs/store';

/**
 * This is to be used instead of the built in date pipe for displaying dates, the built in one cannot switch locales
 * on the fly since setting the LOCALE_ID is done at bootstrapping. The only way to use the built in date pipe is to
 * reload the app every time the locale is changed which is undesired
 *
 * Example html would be
 * <span>date {{currentDate | usanadate :'mediumDate'}}</span>
 */
@Pipe({
  name: 'usanaDate',
  pure: false, // :(
})
export class UsanaDatePipe extends DatePipe implements PipeTransform {
  constructor(protected store: Store) {
    super('en');
  }

  transform(value: any, format?: string, timezone?: string, locale?: string): any {
    const userLocale = this.store.selectSnapshot(UserProfileSelectors.getI18NLocale);
    return super.transform(value, format, timezone, userLocale);
  }
}
