import { Injectable } from '@angular/core';
import { EarningsOptInItemService } from '@common/cms/earnings-opt-in/earnings-opt-in-item.service';
import { LoadEarningsOptIn, LoadEarningsOptInStatus } from '@common/cms/earnings-opt-in/state/earnings-opt-in.actions';
import { EarningsOptInStateModel } from '@common/cms/earnings-opt-in/state/earnings-opt-in.model';
import { Action, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';

@State<EarningsOptInStateModel>({
  name: 'earningsOptIn',
  defaults: {
    earningType: null,
  },
})
@Injectable()
export class EarningsOptInState {
  constructor(private earningsOptInService: EarningsOptInItemService) {}

  @Action(LoadEarningsOptInStatus)
  LoadEarningsOptInStatus({ patchState }: StateContext<EarningsOptInStateModel>, action: LoadEarningsOptInStatus) {
    return this.earningsOptInService.getOptInStatus().pipe(
      map((earningType) => {
        if (earningType) {
          patchState({ earningType });
        }
      })
    );
  }

  @Action(LoadEarningsOptIn)
  EarningsOptIn(ctx: StateContext<EarningsOptInStateModel>, action: LoadEarningsOptIn) {
    return this.earningsOptInService.earningsOptIn().pipe(
      map((earningType) => {
        if (earningType) {
          ctx.patchState({ earningType });
        }
      })
    );
  }
}
