/* eslint-disable max-classes-per-file */

export class LoadContestServiceRegistration {
  static readonly type = '[CONTEST-SERVICE] LOAD REGISTRATION';
  constructor(public contestId: number) {}
}

export class CompleteContestServiceRegistration {
  static readonly type = '[CONTEST-SERVICE] COMPLETE REGISTRATION';
  constructor(public contestId: number) {}
}
