import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationConstants } from '@common/authentication/authentication.constants';
import { SessionTimeoutModalComponent } from '@common/authentication/components/session-timeout-modal/session-timeout-modal.component';
import { AuthTimeoutHandler } from '@common/authentication/model/AuthTimeoutHandler';
import { AuthTimeoutStatus } from '@common/authentication/model/auth-timeout-status';
import { ICreateSession, ICreateSessionResponse } from '@common/authentication/model/create-session-response';
import { HubParams } from '@common/authentication/model/hub-params';
import { ISaveConsent, ISaveConsentResponse } from '@common/authentication/model/save-consent-response';
import { AppUser, UserProfile } from '@common/authentication/model/user-profile';
import { EditUserProfile, NavigateToLogin, StartUserTimeout } from '@common/authentication/state/user-profile.actions';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CREATE_SESSION_WITH_CONSENT_MUTATION } from '@common/constants/create-session-with-consent-mutation';
import { SAVE_CONSENT_MUTATION } from '@common/constants/save-consent-mutation';
import { Actions, Select, Store, ofActionDispatched } from '@ngxs/store';
import { LOCATION_TOKEN } from '@usana/ux/common';
import { UrlService } from '@usana/ux/common/url';
import { UsanaFullStoryService } from '@usana/ux/fullstory';
import { UniversalModalOptions, UniversalModalService } from '@usana/ux/universal-components/modal';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, filter, finalize, map, take, timeout } from 'rxjs/operators';
import { HUB_GATEWAY_URL } from '../../tokens/hub-gateway-url.token';

declare let dataLayer;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  @Select(UserProfileSelectors.getUserProfile) userProfile$: Observable<UserProfile>;

  private loginRedirectURL: string;

  private hubParams: HubParams;
  private timeoutStatus: AuthTimeoutStatus;

  readonly AUTH_KEEPALIVE_EVENTS = ['mousemove', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart'];

  constructor(
    private uModalService: UniversalModalService,
    private cookieService: CookieService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private usanaFsService: UsanaFullStoryService,
    private urlService: UrlService,
    actions$: Actions,
    @Inject(LOCATION_TOKEN) private location: Location,
    @Inject(DOCUMENT) private document: Document,
    @Inject(HUB_GATEWAY_URL) private hubGatewayUrl: string
  ) {
    this.AUTH_KEEPALIVE_EVENTS.forEach((eventName) => {
      this.document.body.addEventListener(eventName, (_event) => {
        const eventTimestamp = new Date().getTime();

        if (this.shouldKeepAliveAtTime(eventTimestamp)) {
          this.keepAlive(true);
        }
      });
    });

    actions$.pipe(ofActionDispatched(StartUserTimeout)).subscribe(() => this.startTimeout());

    actions$.pipe(ofActionDispatched(NavigateToLogin)).subscribe((action) => {
      if (action.redirectUrl) {
        this.setRedirectURL(this.router.url);
      }

      this.handleAuthRedirect();
    });
  }

  handleAuthRedirect(): void {
    this.userProfile$
      .pipe(
        filter((userProfile) => !!userProfile && userProfile.user !== undefined),
        take(1)
      )
      .subscribe((userProfile) => {
        if (userProfile?.user?.username) {
          this.defaultRedirect();
        } else {
          this.redirectToSso(userProfile.fullLocale, this.getFullRedirectUrl());
        }
      });
  }

  private defaultRedirect(): void {
    this.router.navigate(['/home']);
  }

  private redirectToSso(market: string, redirectUri: string): void {
    const isKiosk = this.route.snapshot.queryParamMap.get('kiosk') === 'true';
    const kioskParam = isKiosk ? '?kiosk=true' : '';

    let ssoEnv = environment.DEPLOY_SITEDOMAIN;

    const isWebContent = this.urlService.getHostParts().subdomain === 'webcontent';
    if (isWebContent) {
      ssoEnv = environment.isBaoying ? 'baoying.com' : 'usana.com';
    }

    this.location.assign(`https://sso.${ssoEnv}/${market}/login/${encodeURIComponent(redirectUri)}${kioskParam}`);
  }

  // legacy template lookup (currently only password reset)
  getTemplateUrl(template: string): string {
    return AuthenticationConstants.TEMPLATE_PREFIX + template + '.html';
  }

  /**
   * saves the url to redirect to if its NOT in the blacklist of urls
   * @param url the url to attempt to save
   */
  setRedirectURL(url: string): void {
    if (!this.urlInBlacklist(url)) {
      this.loginRedirectURL = url;
    }
  }

  getRedirectURL(): string {
    return this.loginRedirectURL;
  }

  /**
   * helper method to see if enough time has passed where we could send a keep alive
   * @param timestamp
   */
  shouldKeepAliveAtTime(timestamp: number): boolean {
    return !!this.timeoutStatus && this.timeoutStatus.afterInterval(timestamp);
  }

  startLogout(): void {
    let host = this.location.hostname;
    host = host.replace(/.*?\./, 'shop.');

    let redirectUrl: string;

    // wait for both of our requests to finish
    forkJoin([
      this.http
        .get('https://' + host + '/shop/spring/logout?redirect=false', {
          withCredentials: true,
        })
        .pipe(
          timeout(500),
          catchError((e) =>
            // shop request timed out
            of(null)
          )
        ),
      // todo when portal moves off core tokens we should invoke
      // the ssoLogoutService.logout() instead, but at the moment
      // that call will do extra work that isn't valuable.
      // we also will want a new endpoint to get the logout url from portal
      // so we can add it to the post logout callback
      this.http.get('/mvc/auth/logout', {
        withCredentials: true,
        responseType: 'text',
      }),
    ])
      .pipe(
        finalize(() => {
          this.usanaFsService.anonymizeSessionWithFullStory();
          if (!!Storage) {
            sessionStorage.clear();
            this.hubParams = undefined;
          }
          this.removeUser();
          this.timeoutStatus?.cleanup();

          if (redirectUrl) {
            this.location.href = redirectUrl;
          } else {
            this.handleAuthRedirect();
          }
        })
      )
      .subscribe({
        next: ([_shopLogoutData, portalLogoutData]) => {
          redirectUrl = portalLogoutData;
        },
        error: () => {
          console.log('There was a problem logging out. Most likely a problem with the connection to the shop server.');
        },
      });
  }

  getHubParams(): Promise<HubParams> {
    return new Promise((resolve, reject) => {
      const currAttrs = this.store.selectSnapshot(UserProfileSelectors.getUserProfile);
      const newLogin: boolean =
        !!currAttrs && !!currAttrs.user
          ? this.doLoginAnalytics(currAttrs.user.title, currAttrs.analyticsCustomerId)
          : false;
      if (!this.hubParams || newLogin) {
        this.http.get('/mvc/auth/hubParams').subscribe((data: HubParams) => {
          this.hubParams = data;
          resolve(this.hubParams);
        });
      } else {
        resolve(this.hubParams);
      }
    });
  }

  /**
   * start the timeout countdown for the given seconds, if the timeout is not provided Hub Params will be used
   * @param timeoutSeconds the number of seconds before timeout.
   */
  startTimeout(timeoutSeconds?: number): void {
    if (!timeoutSeconds) {
      this.getHubParams().then((data: HubParams) => {
        this.doStartTimeout(data.timeout);
      });
    } else {
      this.doStartTimeout(timeoutSeconds);
    }
  }

  /**
   * returns if a user currently exists
   * NOTE: this gets the raw value from ngxs
   */
  hasUser(): boolean {
    const userSnap: AppUser = this.store.selectSnapshot(UserProfileSelectors.getUser);
    return !!userSnap && !!userSnap.username;
  }

  /**
   * removes just the user from the currentAttrs
   */
  removeUser(): void {
    // remove the user
    this.store.dispatch(new EditUserProfile({ user: null }));
  }

  /**
   * Checks a list of roles and returns true if the user is in the list
   * @param rolesToCheck
   * @returns
   */
  hasUserRole(rolesToCheck: string[]): boolean {
    if (!this.hasUser()) {
      return false;
    }
    // fancy filters
    const userAuthorities = this.store
      .selectSnapshot(UserProfileSelectors.getUser)
      .authorities.map((pa) => pa.authority);

    return rolesToCheck.filter((r) => userAuthorities.includes(r)).length > 0;
  }

  createSessionWithConsent(username: string, password: string): Observable<ICreateSession> {
    const createSessionWithConsentMutation = {
      operationName: 'createSessionWithConsent',
      query: CREATE_SESSION_WITH_CONSENT_MUTATION,
      variables: {
        input: {
          username: username,
          password: password,
        },
      },
    };
    return this.http
      .post<ICreateSessionResponse>(this.hubGatewayUrl, createSessionWithConsentMutation)
      .pipe(map((response) => response.data?.createSessionWithConsent));
  }

  saveConsent(username: string, consentIdArray: string[], idToken: string): Observable<ISaveConsent> {
    const saveConsentMutation = {
      operationName: 'saveConsent',
      query: SAVE_CONSENT_MUTATION,
      variables: {
        input: {
          username: username,
          ids: consentIdArray,
        },
      },
    };
    return this.http
      .post<ISaveConsentResponse>(this.hubGatewayUrl, saveConsentMutation, {
        headers: {
          'id-token': idToken,
        },
      })
      .pipe(map((response) => response.data?.saveConsent));
  }

  /**
   * starts the timout with the given number of seconds
   * @param timeoutSeconds the number of seconds till timeout
   */
  private doStartTimeout(timeoutSeconds: number): void {
    if (!this.timeoutStatus) {
      // we can only do this once
      this.timeoutStatus = new AuthTimeoutStatus(
        // alert 62 seconds before they time out. So they get a chance to redeem their evil doings.
        new AuthTimeoutHandler(() => {
          this.showTimeoutAlert();
        }, (timeoutSeconds - 62) * 1000),
        new Date().getTime(),
        new AuthTimeoutHandler(() => {
          this.keepAlive(false);
        }, AuthenticationConstants.DEFAULT_KEEP_ALIVE_INTERVAL)
      );

      this.timeoutStatus.start();
    }
  }

  /**
   * show the timeout alert
   * NOTE: if edit is enabled the timeout won't be triggered and instead we will send a keep alive request
   */
  private showTimeoutAlert(): void {
    if (this.store.selectSnapshot(UserProfileSelectors.getUserProfile).editEnabled) {
      this.keepAlive(true);
    } else if (this.hasUser()) {
      const modalOptions: UniversalModalOptions = {
        backdrop: 'static',
        keyboard: false,
      };

      this.uModalService
        .openCustom(modalOptions, SessionTimeoutModalComponent)
        .closed.pipe(take(1))
        .subscribe((keepAlive: boolean) => {
          if (keepAlive) {
            this.keepAlive(true);
          } else {
            this.timeoutStatus.cleanup();
            this.startLogout();
          }
        });
    }
  }

  private getFullRedirectUrl(): string {
    const queryParams: any = {};
    this.route.snapshot.queryParamMap.keys.forEach((key: string) => {
      queryParams[key] = this.route.snapshot.queryParamMap.get(key);
    });

    let redirectUri = '';

    if (this.getRedirectURL()) {
      redirectUri = this.getParameterizeRedirectUrl(this.getRedirectURL(), queryParams);
    }

    return redirectUri;
  }

  private getParameterizeRedirectUrl(redirectUri: string, queryParams: any): string {
    let parameterizedRedirectUri = `${this.urlService.getCurrentHost()}/hub/#${redirectUri}`;

    // add back on the parameters to the url if there are any
    for (const [key, value] of Object.entries(queryParams)) {
      // not the key we already grabbed
      if (['kiosk'].indexOf(key) < 0) {
        if (Array.isArray(value)) {
          for (const arrayValue of value) {
            parameterizedRedirectUri += '&' + key + '=' + arrayValue;
          }
        } else {
          parameterizedRedirectUri += '&' + key + '=' + String(value);
        }
      }
    }

    return parameterizedRedirectUri;
  }

  /**
   * is the given url in the blacklist
   * @param url the url to check
   */
  private urlInBlacklist(url: string): boolean {
    return AuthenticationConstants.BLACKLISTED_REDIRECT_URLS.indexOf(url) >= 0;
  }

  /**
   * analytics if it exists
   * @param title
   * @param analyticsCustomerId
   */
  private pushLoginAnalyticsToDataLayer(title: string, analyticsCustomerId: string): void {
    // if dataLayer exists
    if (typeof dataLayer !== 'undefined') {
      // logged in event
      dataLayer.push({
        event: 'CDUserEvent',
        membershipLeVal: title,
        userStatus: 'Logged in',
      });
      if (!!analyticsCustomerId && analyticsCustomerId.length > 0) {
        dataLayer.push({ event: 'identify_user', analyticsCustomerId: analyticsCustomerId });
      }
    }
  }

  private doLoginAnalytics(title: string, analyticsCustomerId: string): boolean {
    const sessionIdKey = 'sessionId';
    let newLogin = false;
    if (!!Storage) {
      const session = sessionStorage.getItem(sessionIdKey);
      const cookie = this.cookieService.get(AuthenticationConstants.LEGACY_SESSION_ID_COOKIE_NAME);
      if (!!session) {
        if (cookie !== session) {
          this.pushLoginAnalyticsToDataLayer(title, analyticsCustomerId);
          newLogin = true;
        }
      } else {
        this.pushLoginAnalyticsToDataLayer(title, analyticsCustomerId);
        newLogin = true;
      }
      sessionStorage.setItem(sessionIdKey, cookie);
    }
    return newLogin;
  }

  /**
   * sends a keep alive request to the backend if currently editing or the override is set to true
   * @param ignoreEditingOn override the editing status to force a keep alive
   */
  private keepAlive(ignoreEditingOn: boolean) {
    if (ignoreEditingOn || this.store.selectSnapshot(UserProfileSelectors.getUserProfile).editEnabled) {
      this.http
        .head('/mvc/auth/keepAlive')
        .toPromise()
        .then(() => {
          // make sure we let our status know we were kept alive.
          this.timeoutStatus.keptAlive(new Date().getTime());
        });
    }
  }
}
