import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CommonIconsModule } from '../../../common/src/common-icons/common-icons.module';
import { UsanaPipesModule } from '../pipes/pipes.module';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    UsanaPipesModule,
    InterpolationPipeModule,
    TranslationModule,
    CollapseModule.forRoot(),
    CommonIconsModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
