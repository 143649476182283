<div class="fpv" *ngIf="fpvReport$ | async as fpvReport">
  <h1>{{ 'china.ds.header' | translate }}</h1>
  <br />
  <h3>{{ 'china.ds.subHeader' | translate }}</h3>
  <br />
  <div><p [innerHTML]="'china.ds.firstParagraph' | translate | unsafe: 'html'"></p></div>
  <br />
  <div><p [innerHTML]="'china.ds.secondParagraph' | translate | unsafe: 'html'"></p></div>
  <br />
  <div><p [innerHTML]="'china.ds.thirdParagraph' | translate | unsafe: 'html'"></p></div>
  <br />
  <div><p [innerHTML]="'china.ds.fourthParagraph' | translate | unsafe: 'html'"></p></div>
  <br />
  <div class="container" *ngIf="fpvReport.show">
    <table class="table table-bordered table-sm table-white">
      <tbody>
        <tr>
          <td>{{ 'china.ds.fpv.lastMonth' | translate }}</td>
          <td>{{ fpvReport.pagedDocument.summary.totalNewThisMonth }}</td>
        </tr>
        <tr>
          <td>{{ 'china.ds.fpv.total' | translate }}</td>
          <td>{{ fpvReport.pagedDocument.summary.totalAvailable }}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <table class="table table-bordered table-sm table-white">
      <thead>
        <tr>
          <th>{{ 'china.ds.fpv.date' | translate }}</th>
          <th>{{ 'china.ds.fpv.amount' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of fpvReport.pagedDocument.document; trackBy: trackByIndex">
          <td>
            {{ row[0].v | date: 'mediumDate' }}
          </td>
          <td>
            {{ row[1].v }}
          </td>
        </tr>
      </tbody>
    </table>
    <div>{{ 'china.ds.footer' | translate }}</div>
  </div>
</div>
