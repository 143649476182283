import { Component, Input } from '@angular/core';
import { CmsItemModel } from '@common/cms/cms.model';

@Component({
  selector: 'u-related-stories-item',
  templateUrl: './related-stories-item.component.html',
  styleUrls: ['./related-stories-item.component.scss'],
})
export class RelatedStoriesItemComponent {
  @Input() cmsItemObj: CmsItemModel;
  @Input() target: string;
}
