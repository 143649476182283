import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EarningsOptInStatus } from '@common/cms/earnings-opt-in/state/earnings-opt-in.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EarningsOptInItemService {
  constructor(private http: HttpClient) {}

  private static handleError(error: HttpErrorResponse) {
    return throwError(error.error.message);
  }

  getOptInStatus(): Observable<EarningsOptInStatus> {
    return this.http.get('/mvc/fpvCommissionOptIn/status').pipe(map((optInStatus: EarningsOptInStatus) => optInStatus));
  }

  earningsOptIn(): Observable<EarningsOptInStatus> {
    return this.http.put('/mvc/fpvCommissionOptIn/status/commission', '').pipe(
      map((optInStatus: EarningsOptInStatus) => optInStatus),
      catchError(EarningsOptInItemService.handleError)
    );
  }
}
