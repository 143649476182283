import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { BaoyingVolumeReportService } from '../baoying-volume-report.service';
import { LoadBaoyingVolumeReport, LoadCommissionWeeks, SetCommissionWeek } from './baoying-volume-report.actions';
import { BaoyingVolumeReportStateModel } from './baoying-volume-report.state.model';

@State<BaoyingVolumeReportStateModel>({
  name: 'baoyingVolumeReport',
  defaults: {
    commissionWeeks: [],
    commissionWeek: 0,
    volume: {},
  },
})
@Injectable()
export class BaoyingVolumeReportState {
  constructor(private baoyingReportService: BaoyingVolumeReportService) {}

  @Action(LoadCommissionWeeks)
  loadCommissionWeeks({ patchState }: StateContext<BaoyingVolumeReportStateModel>) {
    return this.baoyingReportService.getCommissionWeeks().pipe(
      map((commissionWeeks) => {
        if (commissionWeeks) {
          patchState({ commissionWeeks: commissionWeeks, commissionWeek: commissionWeeks[0] });
        }
      })
    );
  }

  @Action(SetCommissionWeek)
  setCommissionWeek(
    { patchState, dispatch }: StateContext<BaoyingVolumeReportStateModel>,
    { commissionWeek }: SetCommissionWeek
  ) {
    patchState({ commissionWeek });
    return dispatch(new LoadBaoyingVolumeReport());
  }

  @Action(LoadBaoyingVolumeReport)
  loadBaoyingVolumeReport({ patchState, getState }: StateContext<BaoyingVolumeReportStateModel>) {
    const state = getState();
    return this.baoyingReportService.getBaoyingVolumeReport(state.commissionWeek).pipe(
      map((volume) => {
        if (volume) {
          patchState({ volume });
        }
      })
    );
  }
}
