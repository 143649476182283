import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProgressPercentIndicatorColors } from './progress-percent-indicator-colors';

@Component({
  selector: 'u-progress-percent-indicator',
  templateUrl: './progress-percent-indicator.component.html',
  styleUrls: ['./progress-percent-indicator.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ProgressPercentIndicatorComponent {
  @Input() progressPercentage: number;
  @Input() color = ProgressPercentIndicatorColors.teal;
  @Input() strokeWidth = 3;
}
