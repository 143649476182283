import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, map, tap } from 'rxjs/operators';
import { UserProfileSelectors } from '../../../../common/src/authentication/state/user-profile.selectors';

export const nonPcOnlyCanActivate: CanActivateFn = (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(UserProfileSelectors.getUserProfile).pipe(
    filter((userProfile) => !!userProfile),
    map((userProfile) => userProfile?.user && !userProfile.user.pc),
    tap((canActivate) => {
      if (!canActivate) {
        router.navigate(['/home']);
      }
    })
  );
};
