import { TaskTrackerColumnBreakpoint } from '../models/task-tracker-column-breakpoint';

export const TASK_TRACKER_COLUMN_BREAKPOINTS: TaskTrackerColumnBreakpoint[] = [
  {
    columnCount: 1,
    defaultVisibleTaskCount: 0,
    minPageWidth: 0,
  },
  {
    columnCount: 2,
    defaultVisibleTaskCount: 5,
    minPageWidth: 576,
  },
  {
    columnCount: 3,
    defaultVisibleTaskCount: 5,
    minPageWidth: 767,
  },
  {
    columnCount: 5,
    defaultVisibleTaskCount: 5,
    minPageWidth: 992,
  },
];
