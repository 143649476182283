import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'u-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
})
export class SessionTimeoutModalComponent {
  countDown: number;
  private readonly countdownInterval;

  constructor(private modal: NgbActiveModal) {
    this.countDown = 60;
    this.countdownInterval = setInterval(() => {
      this.countDown--;
      if (this.countDown <= 0) {
        this.logout();
      }
    }, 1000);
  }

  ok(): void {
    window.clearInterval(this.countdownInterval);
    this.modal.close(true);
  }

  logout(): void {
    window.clearInterval(this.countdownInterval);
    this.modal.close(false);
  }
}
