import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsModule } from '@common/cms/cms.module';
import { TranslationModule } from '@common/translation/translation.module';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { UsanaAnalyticsModule } from '@usana/ux/analytics';
import { CommonIconsModule } from '../../../common/src/common-icons/common-icons.module';
import { CardsComponent } from './cards/cards.component';

@NgModule({
  declarations: [CardsComponent],
  exports: [CardsComponent],
  imports: [CommonModule, UnsafeModule, CmsModule, TranslationModule, UsanaAnalyticsModule, CommonIconsModule],
})
export class CardsModule {}
