import { Component, Inject, OnInit } from '@angular/core';
import { LOCATION_TOKEN } from '@usana/ux/common';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'u-notification-settings-container',
  templateUrl: './notification-settings-container.component.html',
})
export class NotificationSettingsContainerComponent implements OnInit {
  constructor(@Inject(LOCATION_TOKEN) protected readonly location: any) {}

  ngOnInit() {
    this.location.assign(`https://sso.${environment.DEPLOY_SITEDOMAIN}/account/settings/notifications`);
  }
}
