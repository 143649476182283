<div class="usana-news-widget-container">
  <div id="usanaNewsWidgetBox" class="elevation4 card dlm-widget-box d-flex flex-column">
    <div class="card-header">
      <div class="row no-margin">
        <div class="col-6 col-lg-6 ps-0">
          <h4 class="usana-news-header-left">
            {{ 'hub.usana-news.title' | translate }}
          </h4>
        </div>
        <div class="col-6 col-lg-6 pe-0">
          <div class="body-text-2 usana-news-header-right text-end usana-news-link">
            <a href="/hub/#/usanaNews">{{ 'hub.usana-news.viewAll' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="inner-card-body-container">
        <div
          class="clearfix usana-news-row"
          *ngFor="let usanaNewsItem of usanaNews; let index = index; trackBy: trackByIndex"
        >
          <div class="usana-news-content-wrapper" (click)="openUsanaNewsDetails(usanaNewsItem)">
            <div class="usana-news-image-column">
              <img
                width="75"
                height="75"
                class="usana-news-thumbnails-widget"
                [src]="usanaNewsItem.data.imageSrc775x775?.data"
              />
            </div>
            <div class="usana-news-content-column">
              <h6 class="usana-news-title">{{ usanaNewsItem.data.title?.data }}</h6>
              <p
                class="body-text-2 usana-news-content"
                [ngClass]="{ ellipsis: usanaNewsItem.data.summary?.data.length > 75 }"
                [innerHTML]="usanaNewsItem.data.summary?.data | htmlToPlainText | slice : 0 : 75 | unsafe : 'html'"
              ></p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center no-usana-news" *ngIf="!usanaNews && !loadingUsanaNews">
        {{ 'hub.usana-news.noUsanaNews' | translate }}
      </div>
      <div class="widget-spinner" *ngIf="loadingUsanaNews">
        <img src="/images/loading-spinner-black.gif" alt="loading spinner" class="ajax-loader" />
      </div>
    </div>
  </div>
</div>
