/* eslint-disable max-classes-per-file */
import { CmsItemModel } from '@common/cms/cms.model';

export class SetActiveEditItemId {
  static readonly type = '[Cms] SET CURRENT EDIT ITEM ID';
  constructor(public activeEditItemId: string) {}
}

export class LoadCmsItem {
  static readonly type = '[Cms] LOAD CMS ITEM';
  constructor(public itemId: string) {}
}

export class RemoveCmsItem {
  static readonly type = '[Cms] REMOVE CMS ITEM';
  constructor(public itemId: string) {}
}

export class ReloadCmsItem {
  static readonly type = '[Cms] RELOAD CMS ITEM';
  constructor(public itemId: string) {}
}

export class MoveCmsItem {
  static readonly type = '[Cms] MOVE CMS ITEM';
  constructor(public relationshipId: string, public direction: string, public rootId: string) {}
}

export class CopyParentToLocale {
  static readonly type = '[Cms] COPY PARENT TO LOCALE';

  constructor(
    public parentId: string,
    public fromLocale: string,
    public toLocale: string,
    public withChildData: boolean,
    public rootId: string
  ) {}
}

export class CopyItemToLocale {
  static readonly type = '[Cms] COPY ITEM TO LOCALE';

  constructor(
    public relationshipId: string,
    public fromLocale: string,
    public targetLocale: string,
    public rootId: string
  ) {}
}

export class DeleteCmsItem {
  static readonly type = '[Cms] DELETE CMS ITEM';
  constructor(public relationshipId: string, public rootId: string) {}
}

export class PublishItem {
  static readonly type = '[Cms] PUBLISH CMS ITEM';
  constructor(public cmsItem: CmsItemModel, public rootId: string) {}
}

export class ResetCmsDataEntries {
  static readonly type = '[Cms] RESET CMS STATE';
}

export class UpdateCmsCurrentPage {
  static readonly type = '[Cms] UPDATE CURRENT PAGE';
  constructor(public pageId: string, public subPages?: string[]) {}
}
