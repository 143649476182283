import { Injectable } from '@angular/core';
import { ChangeOpenNavigationLevel } from '@common/navigation/state/navigation.actions';
import { NavigationLevel, NavigationStateModel } from '@common/navigation/state/navigation.state.model';
import { Action, State, StateContext } from '@ngxs/store';

@State<NavigationStateModel>({
  name: 'navigation',
  defaults: {
    navLevelOpen: NavigationLevel.NONE,
  },
})
@Injectable()
export class NavigationState {
  @Action(ChangeOpenNavigationLevel)
  changeOpenNavigationLevel({ patchState }: StateContext<NavigationStateModel>, action: ChangeOpenNavigationLevel) {
    return patchState({ navLevelOpen: action.level });
  }
}
