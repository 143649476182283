import { Component } from '@angular/core';
import { CmsBaseItemDirective } from '@common/cms/items/cms-item-base.directive';

@Component({
  selector: 'u-cms-hub-banner-slider-item',
  templateUrl: './cms-hub-banner-slider-item.component.html',
  styleUrls: ['./cms-hub-banner-slider-item.component.scss'],
})
export class CmsHubBannerSliderItemComponent extends CmsBaseItemDirective {
  trackByIndex(index) {
    return index;
  }
}
