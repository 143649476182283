import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@common/translation/translation.module';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { UsanaPipesModule } from '@shared/pipes/pipes.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

@NgModule({
  declarations: [ConfirmationModalComponent],
  imports: [
    CommonModule,
    UsanaPipesModule,
    InterpolationPipeModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    TranslationModule,
  ],
  exports: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}
