<ng-container *ngIf="requestAttrs">
  <u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>

  <div class="row ps-4">
    <h2 class="related-stories-title">{{ cmsItemObj.data.title?.data }}</h2>
  </div>

  <div class="cms-related-stories row">
    <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
      <div
        class="related-stories-container"
        [ngClass]="{ 'cms-child-edit-mode': requestAttrs.pageEdit }"
        id="{{ cmsItemObj.item.id }}"
      >
        <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
        <u-cms-item [itemId]="childItem.item.id"></u-cms-item>
      </div>
    </ng-container>
  </div>

  <div class="related-stories-link-container" *ngIf="cmsItemObj.data.linkText?.data">
    <a class="related-stories-link" [href]="cmsItemObj.linkUrl">
      <fa-icon [icon]="['fas', 'chevron-left']" class="fontawesome-icons"></fa-icon>
      {{ cmsItemObj.data.linkText?.data }}
    </a>
  </div>

  <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
</ng-container>
