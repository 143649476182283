<div class="page-err">
  <div class="text-center">
    <div class="err-status">
      <h1>404</h1>
    </div>
    <div class="err-message">
      <h2>{{ 'hub.pageNotFound' | translate }}</h2>
    </div>
    <div class="err-body">
      <a href="#" class="btn btn-lg btn-goback">
        <fa-icon [icon]="HOME_ICON"></fa-icon>

        <span class="space"></span>
        {{ 'hub.goBackHome' | translate }}
      </a>
    </div>
  </div>
</div>
