import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CmsItemModel } from '@common/cms/cms.model';
import { CmsEditService } from '@common/cms/services/cms-edit.service';
import { CopyParentToLocale, SetActiveEditItemId } from '@common/cms/state/cms.actions';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'u-container-edit-menu',
  templateUrl: './container-edit-menu.component.html',
  styleUrls: ['./container-edit-menu.component.scss'],
})
export class ContainerEditMenuComponent implements OnInit, OnDestroy {
  userProfile$: Observable<UserProfile> = this.store.select(UserProfileSelectors.getUserProfile);
  activeEditItemId$: Observable<string> = this.store.select(CmsSelectors.getActiveEditItemId);

  @Input() rootId;
  @Input() cmsItemObj: CmsItemModel;

  storeSubs = new Subscription();
  activeEditItemId: string;
  userProfile: UserProfile;

  constructor(private store: Store, private cmsEditService: CmsEditService) {}

  ngOnInit(): void {
    this.storeSubs.add(
      this.activeEditItemId$.subscribe((activeEditItemId) => (this.activeEditItemId = activeEditItemId))
    );
    this.storeSubs.add(this.userProfile$.subscribe((userProfile) => (this.userProfile = userProfile)));
  }

  copyableToLocale(): boolean {
    return this.userProfile.locale !== this.cmsItemObj.locale;
  }

  parentCopyableToLocale(): boolean {
    if (this.cmsItemObj.relationship) {
      const parentNotCopyable = this.userProfile.locale === this.cmsItemObj.relationship.locale;
      return this.cmsItemObj.relationship.locale !== this.cmsItemObj.locale || parentNotCopyable;
    } else {
      return true;
    }
  }

  hasPublishedChildren(): boolean {
    return this.cmsItemObj.children?.some((child) => child.relationship?.publishedBy);
  }

  editContainer(event): void {
    event.stopPropagation();
    if (this.cmsItemObj.relationship) {
      this.cmsEditService.editChild(this.cmsItemObj.relationship.id, 'reloadCmsRootItem', this.rootId);
    } else {
      this.cmsEditService.editItem(this.cmsItemObj.item.id, this.userProfile.locale, 'reloadCmsRootItem', this.rootId);
    }
  }

  toggleEdit(event): void {
    event.stopPropagation();
    const editItem = this.activeEditItemId !== this.cmsItemObj.item.id ? this.cmsItemObj.item.id : '';
    this.store.dispatch(new SetActiveEditItemId(editItem));
  }

  copyToLocale(cmsItem, event): void {
    event.stopPropagation();
    this.store.dispatch(
      new CopyParentToLocale(cmsItem.item.id, cmsItem.locale, this.userProfile.locale, false, this.rootId)
    );
  }

  copyChildrenToLocale(cmsItem, event): void {
    event.stopPropagation();
    this.store.dispatch(
      new CopyParentToLocale(cmsItem.item.id, cmsItem.children[0].locale, cmsItem.locale, true, this.rootId)
    );
  }

  ngOnDestroy(): void {
    if (this.storeSubs) {
      this.storeSubs.unsubscribe();
    }
  }
}
