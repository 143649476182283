import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { UpdateCmsCurrentPage } from '@common/cms/state/cms.actions';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { CmsCurrentPage, CmsItemEntry } from '@common/cms/state/cms.state.model';
import { Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'u-cms-page',
  templateUrl: './cms-page.component.html',
})
export class CmsPageComponent implements OnInit, OnDestroy {
  pageId: string = null;

  private itemSubscription: Subscription;

  private destroy$ = new Subject<void>();

  readonly ANGULAR_JS_ESCAPED_SLASH = '~2F';

  constructor(private readonly store: Store, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.renderActivePage();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.startsWith('/content/')) {
        this.renderActivePage();
      }
    });
  }

  ngOnDestroy(): void {
    this.cleanupItemSub();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private renderActivePage() {
    const itemIds = this.route.snapshot.url.map((u) => u.path);

    const cmsPageData = this.extractCmsPageData(itemIds);
    this.updatePageData(cmsPageData);
  }

  private updatePageData(currentPage: CmsCurrentPage): void {
    this.store.dispatch(new UpdateCmsCurrentPage(currentPage.pageId, currentPage.subPages));
    this.pageId = currentPage.pageId;

    // subscribe to make sure the item isn't disabled
    this.cleanupItemSub(); // cleanup any existing subscriptions.
    this.itemSubscription = this.store
      .select(CmsSelectors.cmsItemIsUpdatedFn)
      .pipe(filter((fn: (itemId: string) => boolean) => fn(this.pageId)))
      .subscribe(() => {
        const cmsItem: CmsItemEntry = this.store.selectSnapshot(CmsSelectors.getCmsItemByIdFn)(this.pageId);
        if (
          cmsItem &&
          cmsItem.data &&
          cmsItem.data.item.disabled &&
          !this.store.selectSnapshot(UserProfileSelectors.getUserProfile).cmAdmin
        ) {
          // if the item is disabled and we aren't an admin we are going to disable the page
          // we could show a 401 page but that isn't what we did before, we would just show nothing
          this.pageId = null;
        }
      });
  }

  private extractCmsPageData(itemIds: string[]): CmsCurrentPage {
    let correctedItemIds = itemIds;

    const isLegacyCmsUrl = itemIds[0].includes('/');
    const isLegacyAngularJsCmsUrl = itemIds[0].includes(this.ANGULAR_JS_ESCAPED_SLASH);

    if (isLegacyCmsUrl) {
      correctedItemIds = itemIds[0].split('/');
    } else if (isLegacyAngularJsCmsUrl) {
      correctedItemIds = itemIds[0].split(this.ANGULAR_JS_ESCAPED_SLASH);
    }

    return {
      pageId: correctedItemIds[0],
      subPages: correctedItemIds.slice(1), // the rest
    };
  }

  private cleanupItemSub(): void {
    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe();
      delete this.itemSubscription;
    }
  }
}
