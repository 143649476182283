import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { USANA_DATA_VIEWS } from '@shared/data-view/data-view.decorator';
import { DataView } from '@shared/data-view/data-view.model';
import { DataViewTypes } from '@shared/data-view/data-view.types';

@Component({
  selector: 'u-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.scss'],
})
export class DataViewComponent implements AfterViewInit, OnChanges, DataView {
  @ViewChild('dataViewEl', { read: ViewContainerRef, static: false }) dataView: ViewContainerRef;
  @Input() name: DataViewTypes;
  @Input() metadata: any;
  @Input() data: any;

  @Output() readonly paginate = new EventEmitter();

  componentInstance: DataView;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngAfterViewInit(): void {
    this.renderDataView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.metadata || changes.data) && this.componentInstance) {
      this.setMetadata(this.metadata);
      this.setData(this.data);
    }
  }

  renderDataView() {
    const componentClass = USANA_DATA_VIEWS[this.name];
    if (componentClass) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
      this.dataView.clear();
      this.componentInstance = this.dataView.createComponent(componentFactory).instance;
      this.componentInstance.data = this.data;
      this.componentInstance.metadata = this.metadata;
      this.componentInstance.paginate.subscribe((pageNum) => this.paginate.emit(pageNum));
    } else {
      console.log('NO DATA VIEW FOUND WITH NAME:' + this.name, +' , PLEASE PROVIDE A VALID NAME');
    }
  }

  setData(data: any): void {
    this.componentInstance.setData(data);
  }

  setMetadata(metadata: any): void {
    this.componentInstance.setMetadata(metadata);
  }
}
