import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsanaDatePipeModule } from '@common/custom-locale-pipes/usana-date-pipe/usana-date-pipe.module';
import { NotificationSingleItemComponent } from '@common/notifications/notification-single-item/notification-single-item.component';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { CommonIconsModule } from '../../common-icons/common-icons.module';
import { TranslationModule } from '../../translation/translation.module';

@NgModule({
  declarations: [NotificationSingleItemComponent],
  imports: [CommonModule, UnsafeModule, UsanaDatePipeModule, TranslationModule, CommonIconsModule],
  exports: [NotificationSingleItemComponent],
})
export class NotificationSingleItemModule {}
