import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { FpvReportService } from '../fpv-report.service';
import { LoadFpvReport } from './fpv-report.actions';
import { FpvReportStateModel } from './fpv-report.model';

@State<FpvReportStateModel>({
  name: 'usanaFpvReport',
  defaults: {
    fpvReport: {
      show: false,
      pagedDocument: {},
    },
  },
})
@Injectable()
export class FpvReportState {
  monthInMillis = 1000 * 60 * 60 * 24 * 30;

  constructor(private usanaFpvReportService: FpvReportService) {}

  @Action(LoadFpvReport)
  loadUsanaFpvReport({ patchState }: StateContext<FpvReportStateModel>) {
    return this.usanaFpvReportService.getFpvReport().pipe(
      map((fpvReport) => {
        if (fpvReport) {
          fpvReport.pagedDocument.maxDays = fpvReport.pagedDocument.endTime + this.monthInMillis;
          patchState({ fpvReport });
        }
      })
    );
  }
}
