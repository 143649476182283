import { Component, Input } from '@angular/core';
import { AppUser } from '../../../../../common/src/authentication/model/user-profile';
import {
  BAOYING_FOOTER_COLUMNS,
  BAOYING_FOOTER_DISCLAIMERS,
  BAOYING_FOOTER_IMAGE,
  BAOYING_SOCIAL_MEDIA_LINKS,
} from '../../constants/baoying-footer.constants';

@Component({
  selector: 'u-baoying-hub-layout',
  templateUrl: './baoying-hub-layout.component.html',
  styleUrls: ['./baoying-hub-layout.component.scss'],
})
export class BaoyingHubLayoutComponent {
  @Input() isLoading: boolean;
  @Input() user: AppUser;

  readonly footerColumns = BAOYING_FOOTER_COLUMNS;
  readonly disclaimers = BAOYING_FOOTER_DISCLAIMERS;
  readonly footerImg = BAOYING_FOOTER_IMAGE;
  readonly socialMediaLinks = BAOYING_SOCIAL_MEDIA_LINKS;
}
