<div
  *ngIf="
    requestAttrs &&
    requestAttrs.pageEdit &&
    requestAttrs.editorForLocale &&
    ((cmsItemObj.parent && (activeEditItemId$ | async) === cmsItemObj.parent.id) || !cmsItemObj.type.container)
  "
  class="cms-item-edit-menu"
>
  <!--header information -->
  <div *ngIf="!cmsItemObj.type.container"><label>Item Name</label> {{ cmsItemObj.item.name }}</div>
  <div *ngIf="cmsItemObj.parent && (activeEditItemId$ | async) === cmsItemObj.parent.id && parentCopyableToLocale()">
    <div>
      <span *ngIf="cmsItemObj.type.container"> <label>Item Name</label> {{ cmsItemObj.item.name }} </span>
      <br />
      <label>Item Locale</label> {{ cmsItemObj.localeName }}<br />
      <label>Type</label> {{ cmsItemObj.type.name }}<br />
      <label>Start Date</label> {{ cmsItemObj.relationship.startDate | date : 'dd-MMM-yyyy HH:mm' }}
      <br />
      <label>End Date</label> {{ cmsItemObj.relationship.endDate | date : 'dd-MMM-yyyy HH:mm' }}
      <span *ngIf="publishStatus === CMS_PUBLISH_STATUSES.SUCCESS_PUBLISHING" class="label label-success"
        >Successfully Published!</span
      >
      <span *ngIf="publishStatus === CMS_PUBLISH_STATUSES.ERROR_PUBLISHING" class="label label-danger"
        >Failed to publish item!</span
      >
    </div>

    <div class="cms-controls">
      <ng-container *ngIf="copyableToLocale(); else editOptions">
        <button
          title="Copy To {{ requestAttrs.localeName }}"
          type="button"
          class="cms-btn cms-btn-edit"
          (click)="copyToLocale()"
        >
          <span class="cms-icon-btn cms-copy-icon"></span>
        </button>
      </ng-container>

      <ng-template #editOptions>
        <div
          title="Move Item to Top"
          class="cms-arrow-button cms-arrow-button-top"
          *ngIf="cmsItemObj.relationship.yCoord > 0"
          (click)="moveThis(CMS_DIRECTIONS.TOP)"
        ></div>
        <div
          title="Move Item Up"
          class="cms-arrow-button cms-arrow-button-up"
          *ngIf="cmsItemObj.relationship.yCoord > 0"
          (click)="moveThis(CMS_DIRECTIONS.UP)"
        ></div>
        <div
          title="Move Item Left"
          class="cms-arrow-button cms-arrow-button-left"
          *ngIf="cmsItemObj.relationship.xCoord > 0"
          (click)="moveThis(CMS_DIRECTIONS.LEFT)"
        ></div>
        <div
          title="Move Item Right"
          class="cms-arrow-button cms-arrow-button-right"
          *ngIf="cmsItemObj.relationship.xCoord < cmsItemObj.parentWidth"
          (click)="moveThis(CMS_DIRECTIONS.RIGHT)"
        ></div>
        <div
          title="Move Item Down"
          class="cms-arrow-button cms-arrow-button-down"
          *ngIf="cmsItemObj.relationship.yCoord < cmsItemObj.columnHeight - 1"
          (click)="moveThis(CMS_DIRECTIONS.DOWN)"
        ></div>
        <div
          title="Move Item to Bottom"
          class="cms-arrow-button cms-arrow-button-bottom"
          *ngIf="cmsItemObj.relationship.yCoord < cmsItemObj.columnHeight - 1"
          (click)="moveThis(CMS_DIRECTIONS.BOTTOM)"
        ></div>
        <button title="Edit" type="button" class="cms-btn cms-btn-edit" (click)="edit()">
          <span class="cms-edit-icon cms-icon-btn"></span>
        </button>
      </ng-template>

      <button
        title="Publish"
        type="button"
        *ngIf="!isPublished()"
        value="Publish"
        class="cms-btn cms-btn-edit"
        (click)="publish()"
      >
        <span class="cms-publish-icon cms-icon-btn"></span>
      </button>

      <button title="Delete" type="button" class="cms-btn cms-btn-edit" (click)="deleteItem()">
        <span class="cms-trash-icon cms-icon-btn"></span>
      </button>
    </div>
  </div>
</div>
