import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  constructor(private http: HttpClient) {}

  getCmsItem(itemId): Observable<any> {
    return this.http.get('/mvc/cm/GetTree?rootId=' + itemId, this.getHeaders());
  }

  getHeaders() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    };
  }
}
