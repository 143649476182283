import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskTrackerProgramProgress } from '../models/task-tracker-program-progress';
import { TaskTrackerTask } from '../models/task-tracker-task';
import { TASK_TRACKER_SERVICE_URL } from '../task-tracker-widget.tokens';

@Injectable({
  providedIn: 'root',
})
export class TaskTrackerService {
  constructor(
    @Inject(TASK_TRACKER_SERVICE_URL) private readonly taskTrackerServiceUrl: string,
    private http: HttpClient
  ) {}

  getTaskTrackerProgramProgress(programKey: string): Observable<TaskTrackerProgramProgress> {
    return this.http.get<TaskTrackerProgramProgress>(`${this.taskTrackerServiceUrl}/program/${programKey}/progress`);
  }

  completeTask(taskKey: string): Observable<TaskTrackerTask> {
    return this.http.post<TaskTrackerTask>(`${this.taskTrackerServiceUrl}/task/${taskKey}`, {});
  }
}
