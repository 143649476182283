<div #navHeader id="nav-header" [ngClass]="{ 'sticky-nav': navRender.isSticky, 'mobile-hide': navRender.isMobileHide }">
  @if (requestAttrs && navRender.showConnectionBar) {
    <div class="topnav-user-connected">
      <div [ngClass]="requestAttrs.switchedUser ? 'switch-user-connected' : 'admin-connected'" class="connected">
        <span class="connected-as">
          <span class="text-end pe-2"><fa-icon [icon]="EXCLAMATION_TRIANGLE_ICON"></fa-icon></span>

          @if (requestAttrs.switchedUser) {
            <span>{{ 'hub.navbar.loggedAsUser' | translate | interpolate: this }}</span>
          } @else {
            <span>{{ 'hub.navbar.loggedAsAdmin' | translate }}</span>
          }
        </span>
        <span class="connected-to">{{ 'hub.navbar.connectedTo' | translate | interpolate: this }}</span>
      </div>
    </div>
  }

  <u-system-alert></u-system-alert>

  <div class="top-nav-container">
    <div class="top-nav-start"></div>

    <div class="top-nav">
      <div class="top-nav-column logo">
        <button [class.hidden]="!requestAttrs?.user" class="topNavMenu" (click)="openFirstLevelNav()">
          <fa-icon [icon]="BARS_ICON" data-automation="mobileMenu"></fa-icon>
        </button>

        <img
          (click)="navigateHome()"
          src="/hub/assets/images/img_trans.gif"
          width="1"
          height="1"
          alt="logo"
          [ngClass]="IS_BAOYING ? 'logo-sidebar-baoying' : 'logo-sidebar'"
        />
      </div>

      @if (requestAttrs && navRender.showConnectionBar) {
        <div class="top-nav-column admin-buttons">
          <nav role="navigation" [ngClass]="{ 'with-user-alert': requestAttrs.switchedUser }">
            @if (requestAttrs.contentEditor || requestAttrs.switchUserAuthority) {
              <div class="topnav-tool">
                @if (requestAttrs.contentEditor) {
                  <u-dropdown-button
                    buttonId="toggleEdit"
                    buttonSize="small"
                    [buttonStyle]="requestAttrs.pageEdit ? 'warning' : 'secondary'"
                    [clickAction]="TOGGLE_PAGE_EDIT_FN"
                    [menuItems]="editMenuItems"
                  >
                    Edit Is {{ requestAttrs.pageEdit ? 'On' : 'Off' }}
                  </u-dropdown-button>
                }
                @if (requestAttrs.showAdmin) {
                  <universal-button [data]="adminToolButtonData" (click)="location.href = '/Main/adminSysIndex'">
                    <span>Admin Tools</span>
                  </universal-button>
                }

                <div>
                  <u-switch-user></u-switch-user>
                </div>
              </div>
            }
          </nav>
        </div>
      }
      @if (requestAttrs?.user) {
        <div class="icon-menu picker top-nav-column">
          @if (navRender.showLocalePicker) {
            <u-change-market-icon></u-change-market-icon>
          }
        </div>
      }
      @if (displayUserMenu$ | async) {
        <div id="user-button-menu" class="icon-menu top-nav-column">
          <u-notification-bell></u-notification-bell>
          <u-my-account-icon></u-my-account-icon>
        </div>
      }
    </div>
  </div>
</div>
