import { Component, Input, OnChanges } from '@angular/core';
import { CmsItemModel } from '@common/cms/cms.model';
import { CmsLinkService } from '@common/cms/services/cms-link.service';
import { UsanaAnalyticsService } from '@usana/ux/analytics';

@Component({
  selector: 'u-cms-link',
  templateUrl: './cms-link.component.html',
})
export class CmsLinkComponent implements OnChanges {
  @Input() cmsItemObj: CmsItemModel;

  @Input() cmsLinkClass = '';
  @Input() navClickEvent: string;

  linkTarget: string;

  constructor(
    private readonly cmsLinkService: CmsLinkService,
    private readonly analyticsService: UsanaAnalyticsService
  ) {}

  // this is onChanges now rather than onInit because of how placeholder data works
  // we need to recalculate the link when the data is no longer placeholder data
  ngOnChanges(): void {
    this.calculateLink();
  }

  onNavClickEvent() {
    if (this.navClickEvent) {
      this.analyticsService.sendEventToAllSystems(this.navClickEvent, {
        navUrl: this.cmsItemObj.linkUrl.split('?')[0],
      });
    }
  }

  private calculateLink(): void {
    this.linkTarget = this.cmsLinkService.getTargetFromLinkData(this.cmsItemObj, this.cmsItemObj);
  }
}
