import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerTypes } from '@common/constants/customer-types';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { EURO_MARKETS, UNSUPPORTED_MARKETS } from '../change-market.constants';
import { Market } from '../change-market.model';

@Component({
  selector: 'u-market-selector',
  templateUrl: './market-selector.component.html',
  styleUrls: ['./market-selector.component.scss'],
})
export class MarketSelectorComponent implements OnInit {
  readonly GLOBE_ICON = faGlobe;

  @Input() locale: string;
  @Input() localeName: string;
  @Input() isPc: boolean;
  @Input() markets: { [key: string]: Market[] } = {};
  @Output() readonly marketSelection = new EventEmitter();

  filteredMarkets: Market[] = [];
  selectedType: CustomerTypes;
  asscViewIsActive: boolean;
  pcViewIsActive: boolean;
  customerTypes = CustomerTypes;

  ngOnInit() {
    this.asscViewIsActive = !this.isPc;
    this.pcViewIsActive = this.isPc;
    this.selectedType = this.isPc ? CustomerTypes.preferredCustomer : CustomerTypes.associate;
    this.filterMarketList();
  }

  changeAssocTypeMarketDisplay(type: CustomerTypes) {
    this.asscViewIsActive = type === CustomerTypes.associate;
    this.pcViewIsActive = type === CustomerTypes.preferredCustomer;
    this.selectedType = type;
    this.filterMarketList();
  }

  filterMarketList() {
    this.filteredMarkets = this.markets[this.selectedType].filter(
      (market) => UNSUPPORTED_MARKETS.indexOf(market.country) === -1
    );
  }

  selectMarket(locale: string) {
    this.marketSelection.emit(locale);
  }

  euroMarkets(locale: string) {
    return EURO_MARKETS.indexOf(locale) !== -1;
  }
}
