import { Type } from '@angular/core';
import { DataView } from '@shared/data-view/data-view.model';
import { DataViewTypes } from '@shared/data-view/data-view.types';

export const USANA_DATA_VIEWS: { [key: string]: Type<DataView> } = {};

export const DataViewer = (name: DataViewTypes) => (component) => DataViewerComponent(name, component);

export function DataViewerComponent(name: DataViewTypes, component: any): boolean {
  const existed = !!USANA_DATA_VIEWS[name];
  USANA_DATA_VIEWS[name] = component;
  return existed;
}
