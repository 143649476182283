<u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
<div class="container-fluid" *ngIf="columns">
  <div class="cms-columned-container row">
    <div
      *ngFor="let col of columns; let idx = index; trackBy: trackByColumn"
      class="cms-columned-cont-column {{ col.cssClass }}"
    >
      <ng-container *ngFor="let itemId of col.itemIds">
        <u-cms-item [itemId]="itemId"></u-cms-item>
      </ng-container>
      <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId" [column]="idx"></u-cms-add-button>
    </div>
  </div>
</div>
