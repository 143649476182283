import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsanaDatePipeModule } from '@common/custom-locale-pipes/usana-date-pipe/usana-date-pipe.module';
import { TranslationModule } from '@common/translation/translation.module';
import { LoadingSpinnerModule } from '@shared/loading-spinner/loading-spinner.module';
import { WidgetModule } from '@usana/ux/widget';
import { FpvWidgetComponent } from './fpv-widget/fpv-widget.component';

@NgModule({
  declarations: [FpvWidgetComponent],
  exports: [FpvWidgetComponent],
  imports: [CommonModule, WidgetModule, LoadingSpinnerModule, UsanaDatePipeModule, TranslationModule],
})
export class FpvWidgetModule {}
