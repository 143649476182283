import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification, ViewNotificationArgs } from '@common/notifications/notification.model';
import { NotificationService } from '@common/notifications/notification.service';
import { DeleteNotification } from '@common/notifications/state/notification.actions';
import { ActionsExecuting } from '@ngxs-labs/actions-executing';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { NotificationDetailsModalComponent } from '../../notification-details-modal/notification-details-modal.component';
import { NotificationModalService } from '../../services/notification-modal.service';

@Component({
  selector: 'u-notification-widget-content',
  templateUrl: './notification-widget-content.component.html',
  styleUrls: ['./notification-widget-content.component.scss'],
})
export class NotificationWidgetContentComponent {
  @Input() loadingNotifications: ActionsExecuting;
  @Input() limit: boolean;
  @Input() displayedNotifications: Notification[] = [];

  @Output() readonly viewAllClicked = new EventEmitter<void>();

  activeNotification: Notification;

  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private notificationModalService: NotificationModalService
  ) {}

  viewNotification(args: ViewNotificationArgs): void {
    this.notificationModalService.openNotificationModal<NotificationDetailsModalComponent>(
      NotificationDetailsModalComponent,
      args
    );

    this.notificationService
      .markAsRead([args.notification])
      .pipe(take(1))
      .subscribe(() => {
        this.activeNotification = args.notification;
      });
  }

  deleteNotification(notification: Notification): void {
    this.store.dispatch(new DeleteNotification(notification));
  }

  navigateToNotificationPage() {
    this.viewAllClicked.emit();
    this.notificationService.navigateToNotificationPage();
  }

  trackById(_index: number, notification: Notification): number {
    return notification.id;
  }
}
