<div class="row no-margin cms-group-container cms-gp-cont">
  <div class="col-5 col-lg-3 cms-gp-cont-bg">
    <nav class="cms-group-menu">
      <ul>
        <li *ngFor="let item of cmsItemObj.children; trackBy: trackByIndexAndItemId">
          <a
            [routerLink]="['/content', pageId, item.item.id]"
            [ngClass]="{ active: showCmsItem === item.item.id }"
            [attr.data-automation]="item.item.id + '-menuLabel'"
          >
            <div class="group-page-nav-button">
              {{ item.data.menuLabel?.data }}
            </div>
          </a>

          <u-cms-child-edit-menu [cmsItemObj]="item" [rootId]="rootId"></u-cms-child-edit-menu>
        </li>
      </ul>

      <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
    </nav>
  </div>
  <div class="col-7 col-lg-9 px-md-0 cms-gp-lt-border cms-gp-cont-bg">
    <div class="cms-gp-main-cont">
      <u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
      <div class="cms-group-container-pages group-child">
        <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
          <div class="cms-group-container-page" *ngIf="childItem.item.id === showCmsItem">
            <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
            <u-cms-item [itemId]="childItem.item.id"></u-cms-item>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
