import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class LoggerService {
  constructor(private logger: NGXLogger) {}

  getLogger(className): Logger {
    const DEBUG = '[' + className + ']:DEBUG:';
    const WARN = '[' + className + ']:WARN:';
    const ERROR = '[' + className + ']:ERROR:';

    const logger: Logger = {
      debug: (...args) => this.logger.debug(DEBUG, ...args),
      error: (...args) => this.logger.error(ERROR, ...args),
      warn: (...args) => this.logger.warn(WARN, ...args),
    };

    return logger;
  }
}

export interface Logger {
  debug: (...args) => void;
  warn: (...args) => void;
  error: (...args) => void;
}
