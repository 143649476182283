<button
  type="button"
  class="usana-popover"
  boundariesElement="viewport"
  [attr.data-automation]="message"
  [popover]="message"
  [placement]="placement"
  [isOpen]="isOpen"
  (focus)="onFocus()"
  (blur)="onBlur()"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  (click)="onClick()"
>
  <ng-content></ng-content>
</button>
