import { Component, Input } from '@angular/core';
import { UplineLeaderPlacementReport } from '@common/upline-leader-placement/upline-leader-placement.model';

@Component({
  selector: 'u-upline-leader-placement',
  templateUrl: './upline-leader-placement.component.html',
  styleUrls: ['./upline-leader-placement.component.scss'],
})
export class UplineLeaderPlacementComponent {
  @Input() upLinePlacementReport: UplineLeaderPlacementReport;
  @Input() title: string;
}
