<a
  *ngIf="taskCompletion"
  [href]="taskCompletion.actionUrl"
  (click)="onNavClickEvent()"
  class="task-tracker-task-progress"
  [attr.fullstory]="taskCompletion.taskKey"
  [attr.data-automation]="taskCompletion.taskKey + '-task'"
>
  <div class="task-tracker-task-progress-title">
    {{ taskCompletion.headingTag | translate }}
  </div>
  <div class="task-tracker-task-progress-subtext">
    {{ taskCompletion.subTextTag | translate }}
  </div>

  <div class="task-tracker-completion-indicator centered-vertical">
    <ng-container *ngIf="!taskCompletion.isCompleted; else completedIndicator">
      <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
    </ng-container>
    <ng-template #completedIndicator>
      <fa-icon
        [icon]="['fas', 'check-circle']"
        [attr.data-automation]="taskCompletion.taskKey + '-completed'"
      ></fa-icon>
    </ng-template>
  </div>
</a>
