import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationModule } from '@common/authentication/authentication.module';
import { CmsModule } from '@common/cms/cms.module';
import { MyAccountModule } from '@common/my-account/my-account.module';
import { TopNavComponent } from '@common/navigation/components/top-nav/top-nav.component';
import { NavigationState } from '@common/navigation/state/navigation.state';
import { NotificationBellModule } from '@common/notifications/notification-bell/notification-bell.module';
import { SystemAlertsModule } from '@common/system-alerts/system-alerts.module';
import { TranslationModule } from '@common/translation/translation.module';
import { NgxsModule } from '@ngxs/store';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { DropdownButtonComponent, UniversalButtonModule } from '@usana/ux/universal-components/button';
import { ChangeMarketModule } from '@usanaHub/app/change-market/change-market.module';
import { CommonIconsModule } from '../common-icons/common-icons.module';
import { NavigationHeaderComponent } from './components/navigation-header/navigation-header.component';
import { NavigationComponent } from './components/navigation/navigation.component';

@NgModule({
  declarations: [TopNavComponent, NavigationComponent, NavigationHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    CmsModule,
    SystemAlertsModule,
    AuthenticationModule,
    InterpolationPipeModule,
    NotificationBellModule,
    ChangeMarketModule,
    MyAccountModule,
    NgxsModule.forFeature([NavigationState]),
    TranslationModule,
    CommonIconsModule,
    DropdownButtonComponent,
    UniversalButtonModule,
  ],
  exports: [TopNavComponent, NavigationComponent, NavigationHeaderComponent],
})
export class NavigationModule {}
