import { Component } from '@angular/core';
import { PerformanceService } from '@shared/performance/performance.service';

@Component({
  selector: 'u-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss'],
})
export class HomeBannerComponent {
  itemId = 'hub-home-banner-slider-container';

  placeholderData = {
    type: {
      id: 'Hub-Banner-Slider-Container',
    },
    item: {
      id: 'hub-home-banner-slider-container-placeholder',
    },
    children: [],
  };

  constructor() {
    PerformanceService.mark('Home:HomeBannerIncluded');
    this.placeholderData.children = [
      this.placeholderItem('hub-home-banner-placeholder-slide-1'),
      this.placeholderItem('hub-home-banner-placeholder-slide-2'),
    ];
  }

  private placeholderItem(itemId: string) {
    return {
      placeholder: true,
      item: {
        id: itemId,
      },
      data: {
        slideImage: {
          data: 'assets/placeholder.jpeg',
        },
        Title: {
          data: '',
        },
        buttonLabel: {
          data: '',
        },
        linkData: {
          data: {
            target: '_self',
          },
        },
      },
      type: {
        id: 'Hub-Banner-Slider-Item',
      },
    };
  }
}
