<ng-container *ngIf="requestAttrs">
  <div *ngIf="isBannerDesktopSize()">
    <u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
    <div class="desktop-slider" [ngClass]="{ 'active-edit': activeEditItemId === cmsItemObj.item.id }">
      <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
        <div
          [hidden]="!(slideIdx === idx || (requestAttrs.pageEdit && activeEditItemId === cmsItemObj.item.id))"
          [ngClass]="{ 'cms-child-edit-mode': requestAttrs.pageEdit }"
          id="{{ cmsItemObj.item.id }}"
        >
          <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
          <u-cms-item
            [itemId]="childItem.item.id"
            [placeholderData]="cmsItemObj.placeholder ? childItem : undefined"
          ></u-cms-item>
        </div>
      </ng-container>

      <div class="cms-banner-thumbnails-container">
        <div class="cms-banner-thumbnails" *ngIf="!cmsItemObj.placeholder">
          <img
            *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId"
            class="cms-banner-thumbnail img-responsive"
            [ngClass]="{ active: slideIdx === idx }"
            [src]="childItem.data.slideImage?.data"
            [alt]="childItem.data?.Title?.data"
            [attr.idx]="idx"
            (click)="forceActiveSlide(idx)"
          />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isBannerDesktopSize()" class="container cms-banner-mini-cards-container">
    <u-mini-card-carousel [cmsItemObj]="cmsItemObj"></u-mini-card-carousel>
  </div>
  <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
</ng-container>
