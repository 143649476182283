@if (userProfile$ | async; as userProfile) {
  <div>
    @if (userProfile.switchUserAuthority) {
      @if (userProfile.switchedUser) {
        <universal-button
          elementId="switch-back-button"
          data-automation="SwitchBackToAdminBtn"
          (click)="switchBack()"
          [data]="{ isEllipsis: false, disabled: false, size: 'small', type: 'secondary' }"
        >
          Back To Your Role
        </universal-button>
      } @else {
        <u-dropdown-button
          buttonId="switch-user-button"
          buttonStyle="secondary"
          buttonSize="small"
          (opened)="switchUser.focus()"
        >
          Switch User

          <span dropdownContent>
            <form id="switch-user-form" (ngSubmit)="switchToUser()">
              <input #switchUser id="switchUserId" type="text" name="user" class="form-control" [(ngModel)]="userId" />

              <universal-button
                elementId="switch-user-go-button"
                [data]="{ buttonType: 'submit', type: 'primary', size: 'full' }"
              >
                {{ 'switchUser.Go' | translate }}
              </universal-button>
            </form>
          </span>
        </u-dropdown-button>
      }
    }
    @if (switchUserAlert$ | async; as switchUserAlert) {
      <div class="switch-user-alerts centered-horizontal">
        @if (switchUserAlert.errorMessage) {
          <div id="switch-user-error-alert" class="switch-user-alert">
            <div class="alert alert-danger alert-dismissible" role="alert">
              <button
                id="dismiss-switch-user-error-button"
                type="button"
                class="btn-close"
                (click)="dismissSwitchUserAlert()"
                aria-label="Close"
              ></button>

              <div class="alert-text">
                {{ switchUserAlert.errorMessage }}
              </div>
            </div>
          </div>
        }
        @if (switchUserAlert.loginResponse?.interrupts?.length) {
          <div id="switch-user-interrupts-alert" class="switch-user-alert">
            <div class="alert alert-danger alert-dismissible" role="alert">
              <button
                id="dismiss-switch-user-interrupt-button"
                type="button"
                class="btn-close"
                (click)="dismissSwitchUserAlert()"
                aria-label="Close"
              ></button>

              <div class="alert-text">
                {{ 'masquerade.switchUserSuccess' | translate }} <br />
                {{ 'masquerade.userHasInterrupts' | translate }}:

                <ul id="login-interrupt-list">
                  @for (loginInterrupt of switchUserAlert.loginResponse.interrupts; track $index) {
                    <li>
                      {{ 'masquerade.interrupt.' + loginInterrupt.name | translate }}
                    </li>
                  }
                </ul>

                <button id="switch-user-alert-reload-button" class="btn btn-primary" (click)="onReloadPageClicked()">
                  Reload page
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    }
  </div>
}
