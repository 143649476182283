// eslint-disable-next-line max-classes-per-file
import { ParamMap } from '@angular/router';
import { ReportRequestBody, SavedReportParams } from '@common/reports/reports.model';
import { ParamsList } from '@usanaHub/app/team-manager/models/params-list';
import { ReportViewTypes } from '../reports.enum';

export class LoadReportData {
  static readonly type = '[Reports] LOAD REPORT DATA';
  constructor(public reportName: string, public requestBody?: ReportRequestBody, public reportVersion?: string) {}
}

export class LoadReportDataSuccess {
  static readonly type = '[Reports] LOAD REPORT DATA SUCCESS';
  constructor(public reportName: string, public reportData: { [key: string]: any }) {}
}

export class LoadReportDataFailure {
  static readonly type = '[Reports] LOAD REPORT DATA FAILURE';
  constructor(public reportName: string, public reportError: any) {}
}

export class ReloadReportData {
  static readonly type = '[Reports] RELOAD REPORT DATA';
  constructor(public reportName: string, public requestBody?: ReportRequestBody) {}
}

export class LoadAllReportParams {
  static readonly type = '[Reports] LOAD ALL REPORT PARAMS';
  constructor(public reportId: string, public isSavedReport: boolean, public viewType: ReportViewTypes) {}
}

export class LoadReportParams {
  static readonly type = '[Reports] LOAD REPORT PARAMS';
  constructor(public reportName: string, public redirect: boolean) {}
}

export class LoadReportParamsSuccess {
  static readonly type = '[Reports] LOAD REPORT PARAMS SUCCESS';
  constructor(public reportName: string, public reportParams: ParamsList) {}
}

export class LoadReportParamsFailure {
  static readonly type = '[Reports] LOAD REPORT PARAMS FAILURE';
  constructor(public reportName: string, public redirect: boolean) {}
}

export class LoadSavedReportParams {
  static readonly type = '[Reports] LOAD SAVED REPORT PARAMS';
  constructor(public reportId: string) {}
}

export class ClearSavedReportParams {
  static readonly type = '[Reports] CLEAR SAVED REPORT PARAMS';
  constructor(public reportId: string) {}
}

export class LoadSavedReportParamsSuccess {
  static readonly type = '[Reports] LOAD SAVED REPORT PARAMS SUCCESS';
  constructor(public reportId: string, public reportParams: SavedReportParams) {}
}

export class LoadSavedReportParamsFailure {
  static readonly type = '[Reports] LOAD SAVED REPORT PARAMS FAILURE';
  constructor(public reportId: string) {}
}
