import { Component } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { ResetCmsDataEntries, SetActiveEditItemId } from '@common/cms/state/cms.actions';
import { UsanaLocaleService } from '@common/services/locale.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ContestWidgetService } from '../../contest-widget/services/contest-widget.service';
import { AppSelectors } from '../../state/app.selectors';
import { SupportedMarkets } from '../change-market.model';

@Component({
  selector: 'u-change-market-modal',
  templateUrl: './change-market-modal.component.html',
  styleUrls: ['./change-market-modal.component.scss'],
})
export class ChangeMarketModalComponent {
  userProfile$: Observable<UserProfile> = this.store.select(UserProfileSelectors.getUserProfile);
  supportedMarkets$: Observable<SupportedMarkets> = this.store.select(AppSelectors.getSupportedMarkets);

  constructor(
    private localeService: UsanaLocaleService,
    private contestService: ContestWidgetService,
    private store: Store
  ) {}

  changeMarket(locale: string) {
    this.localeService.setLocale(locale);
    this.store.dispatch([new ResetCmsDataEntries(), new SetActiveEditItemId('')]);
    if (this.contestService.contestDataValue) {
      // wait for a second for the locale to be set
      setTimeout(async () => {
        await this.contestService.getContestDataTranslations();
      }, 1000);
    }
  }
}
