export const CREATE_SESSION_WITH_CONSENT_MUTATION = `mutation createSessionWithConsent($input: CreateSessionInput!) {
        createSessionWithConsent(input: $input) {
            success
            session{
                coreToken
                idToken
                refreshToken
                customer {
                    id
                }
            }
            consent{
                id
                country
                customerType
                code
                version
                required
                signed
                signedDate
                tag
            }
            errors {
                field
                message
            }
        }
}`;
