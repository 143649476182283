import { Component, Input, OnInit } from '@angular/core';
import { CmsConstants } from '@common/cms/cms.constants';
import { CmsItemModel } from '@common/cms/cms.model';
import { Store } from '@ngxs/store';
import {
  UniversalModalOptions,
  UniversalModalPosition,
  UniversalModalService,
} from '@usana/ux/universal-components/modal';
import { ChangeOpenNavigationLevel } from '../../navigation/state/navigation.actions';
import { NavigationLevel } from '../../navigation/state/navigation.state.model';
import { CmsHubSideNavModalComponent } from './cms-hub-side-nav-modal/cms-hub-side-nav-modal.component';

@Component({
  selector: 'u-cms-hub-nav-block-container',
  templateUrl: './cms-hub-nav-block-container.component.html',
  styleUrls: ['./cms-hub-nav-block-container.component.scss'],
})
export class CmsHubNavBlockContainerComponent implements OnInit {
  @Input() cmsItemObj: CmsItemModel;
  @Input() rootId: string;
  svgPath1: string;
  svgPath2: string;

  constructor(private uModalService: UniversalModalService, private store: Store) {}

  ngOnInit(): void {
    // I don't love that we do this here, but I like it more than
    // having an edge case in cms-item to bind 2 input variables
    // that are only used in 1 item type.
    const menuPath = CmsConstants.MENU_SVG_PATHS[this.rootId];
    if (menuPath) {
      this.svgPath1 = menuPath.svgPath1;
      this.svgPath2 = menuPath.svgPath2;
    }
  }

  openSideModalDetails() {
    this.store.dispatch(new ChangeOpenNavigationLevel(NavigationLevel.NONE));

    const modalOptions: UniversalModalOptions = {
      modalPosition: UniversalModalPosition.LEFT,
      data: {
        rootId: this.rootId,
        cmsItemObj: this.cmsItemObj,
      },
    };

    this.uModalService.openCustom(modalOptions, CmsHubSideNavModalComponent);
  }
}
