import { Component, Input, OnInit } from '@angular/core';
import { CmsItemModel } from '@common/cms/cms.model';
import { LoadEarningsOptIn, LoadEarningsOptInStatus } from '@common/cms/earnings-opt-in/state/earnings-opt-in.actions';
import { EarningsOptInStatus } from '@common/cms/earnings-opt-in/state/earnings-opt-in.model';
import { EarningsOptInSelectors } from '@common/cms/earnings-opt-in/state/earnings-opt-in.selectors';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngxs/store';
import { ToastService } from '@shared/toast/toast.service';
import {
  UniversalConfirmationModalOptions,
  UniversalConfirmationModalService,
} from '@usana/ux/universal-components/modal';
import { Observable } from 'rxjs';
import { TranslationService } from '../../../translation/translation.service';

@Component({
  selector: 'u-earnings-opt-in',
  templateUrl: './earnings-opt-in-item.component.html',
  styleUrls: ['./earnings-opt-in-item.component.scss'],
})
export class EarningsOptInItemComponent implements OnInit {
  readonly CHECK_ICON = faCheck;

  optInStatus$: Observable<EarningsOptInStatus> = this.store.select(EarningsOptInSelectors.getEarningsOptInStatus);

  @Input() cmsItemObj: CmsItemModel;

  readonly TRANSLATION_TAGS = {
    title: 'hub.earnings.optInConfirmationTitle',
    message: 'hub.earnings.optInConfirmationMessage',
    confirmButton: 'hub.earnings.optInConfirmationConfirmButton',
    cancelButton: 'hub.earnings.optInConfirmationCancelButton',
    successMessage: 'hub.earnings.toastMsg.successMessage',
    successTitle: 'hub.earnings.toastMsg.successTitle',
    optInError: 'hub.earnings.toastTitle.optInError',
  };

  constructor(
    private uConfirmationModalService: UniversalConfirmationModalService,
    private translationService: TranslationService,
    private toastService: ToastService,
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadEarningsOptInStatus());
  }

  confirmChoice() {
    this.translationService.getTranslationListPromise(Object.values(this.TRANSLATION_TAGS)).then((translations) => {
      const modalOptions: UniversalConfirmationModalOptions = {
        title: translations[this.TRANSLATION_TAGS.title],
        confirmationMessage: translations[this.TRANSLATION_TAGS.message],
        confirmButton: {
          text: translations[this.TRANSLATION_TAGS.confirmButton],
        },
        cancelButton: {
          text: translations[this.TRANSLATION_TAGS.cancelButton],
        },
      };

      this.uConfirmationModalService.open(modalOptions).then((confirmed) => {
        if (confirmed) {
          this.store.dispatch(new LoadEarningsOptIn()).subscribe({
            next: () =>
              this.toastService.add(
                'success',
                translations[this.TRANSLATION_TAGS.successMessage],
                2500,
                translations[this.TRANSLATION_TAGS.successTitle]
              ),
            error: (error) => {
              this.toastService.add('danger', error, 2500, translations[this.TRANSLATION_TAGS.optInError]);
            },
          });
        }
      });
    });
  }
}
