import { NgModule } from '@angular/core';
import { AuthenticationModule } from '@common/authentication/authentication.module';
import { EarningsOptInItemModule } from '@common/cms/earnings-opt-in/earnings-opt-in-item.module';
import { TrainingCardsModule } from '@common/cms/training/training-cards.module';
import { MyAccountModule } from '@common/my-account/my-account.module';
import { NotificationsModule } from '@common/notifications/notifications.module';
import { ReportsModule } from '@common/reports/reports.module';
import { StateHelperService } from '@common/services/state-helper.service';
import { WindowSizingService } from '@common/services/window-size.service';
import { SystemAlertsModule } from '@common/system-alerts/system-alerts.module';
import { TranslationModule } from '@common/translation/translation.module';
import { UplineLeaderPlacementModule } from '@common/upline-leader-placement/upline-leader-placement.module';
import { CardsHorizontalModule } from '@shared/cards-horizontal/cards-horizontal.module';
import { CardsModule } from '@shared/cards/cards.module';
import { UsanaAnalyticsModule } from '@usana/ux/analytics';
import { FULLSTORY_WINDOW_OBJ_POLL_RATE, UsanaFullStoryModule } from '@usana/ux/fullstory';
import { UsanaGtmModule } from '@usana/ux/gtm';
import { ID_TOKEN_NAME, LOGIN_SERVICE_URL } from '@usana/ux/sso-auth';
import {
  BACKEND_LOADER_ON_INIT,
  SsoAuthBackendLoaderModule,
  URL_ON_TOKEN_CHANGE,
} from '@usana/ux/sso-auth/backend-loader';
import { SsoSwitchUserModule } from '@usana/ux/sso-auth/switch-user';
import { DropdownButtonComponent } from '@usana/ux/universal-components/button';
import { environment } from '../../../environments/environment';
import { ErrorPageModule } from './error-pages/error-page.module';
import { NavigationModule } from './navigation/navigation.module';
import { HUB_GATEWAY_URL } from './tokens/hub-gateway-url.token';
import { WindowToken, windowProvider } from './utils/window';

@NgModule({
  declarations: [],
  imports: [
    AuthenticationModule,
    SystemAlertsModule,
    NotificationsModule,
    MyAccountModule,
    ReportsModule,
    EarningsOptInItemModule,
    UplineLeaderPlacementModule,
    CardsModule,
    CardsHorizontalModule,
    // these 3 analytics modules are all safe to import here
    // if they aren't used by baoying they will not error out
    UsanaAnalyticsModule,
    UsanaGtmModule,
    UsanaFullStoryModule,
    SsoSwitchUserModule,
    NavigationModule,
    TrainingCardsModule,
    ErrorPageModule,
    TranslationModule,
    DropdownButtonComponent,
    SsoAuthBackendLoaderModule,
  ],
  providers: [
    StateHelperService,
    WindowSizingService,
    { provide: WindowToken, useFactory: windowProvider },
    { provide: ID_TOKEN_NAME, useValue: 'id-token' },
    { provide: FULLSTORY_WINDOW_OBJ_POLL_RATE, useValue: 1500 },
    { provide: LOGIN_SERVICE_URL, useValue: `${environment.apiDomain}/auth/ws` },
    { provide: URL_ON_TOKEN_CHANGE, useValue: `https://sso.${environment.DEPLOY_SITEDOMAIN}/login` },
    { provide: BACKEND_LOADER_ON_INIT, useValue: false },
    { provide: HUB_GATEWAY_URL, useValue: environment.hubGatewayUrl },
  ],
  exports: [NavigationModule, NotificationsModule, EarningsOptInItemModule, ErrorPageModule],
})
export class HubCommonModule {}
