<div class="row cms-banner-row">
  <div class="cms-banner-image cms-banner-slide-column">
    <u-cms-link [cmsItemObj]="cmsItemObj">
      <img [src]="cmsItemObj.data.slideImage?.data" [alt]="cmsItemObj.data.Title?.data" />
    </u-cms-link>
  </div>
  <div class="cms-banner-display-column">
    <div class="cms-banner-display-left">
      <h1 class="cms-banner-label" *ngIf="cmsItemObj.placeholder || !!cmsItemObj.data.Title?.data">
        {{ cmsItemObj.data.Title?.data }}
      </h1>
      <u-cms-link [cmsItemObj]="cmsItemObj" cmsLinkClass="body-text-2 btn btn-primary cms-banner-button">
        {{ cmsItemObj.data.buttonLabel?.data }}
      </u-cms-link>
    </div>
  </div>
</div>
