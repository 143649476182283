<div class="market-selector-wrapper">
  <div class="space-b-16">
    <div class="w-100">
      <span *ngIf="!euroMarkets(locale) && !isPc" class="modal-flag-header flag-icon {{ locale }} current-market-flag">
        &nbsp;
      </span>
      <span *ngIf="euroMarkets(locale) || isPc" class="currentMarket">
        <fa-icon [icon]="GLOBE_ICON"></fa-icon>
      </span>
      <h6 class="d-inline-block">
        <div class="pt-2">{{ 'hub.changeMarket.currentMarketAndLanguage' | translate }}</div>
        <div class="change-market-locale-name">{{ localeName }}</div>
      </h6>
    </div>
  </div>

  <u-change-market-warning></u-change-market-warning>

  <div class="d-flex">
    <button
      class="market-type-button border-bottom-color"
      (click)="changeAssocTypeMarketDisplay(customerTypes.associate)"
      [class.active]="asscViewIsActive"
    >
      <h6 [ngClass]="{ active: asscViewIsActive }" id="assocView" class="assocViewTitle">
        {{ 'hub.changeMarket.associate' | translate }}
      </h6>
    </button>

    <button
      class="market-type-button border-bottom-color"
      [class.active]="pcViewIsActive"
      (click)="changeAssocTypeMarketDisplay(customerTypes.preferredCustomer)"
    >
      <h6 [ngClass]="{ active: pcViewIsActive }" id="pcView" class="assocViewTitle">
        {{ 'hub.changeMarket.prefcust' | translate }}
      </h6>
    </button>
  </div>

  <div class="text-center lh-0">
    <div class="col-6 px-0">
      <div [ngClass]="{ active: asscViewIsActive }" class="assocView assocViewTitleBottom">&nbsp;</div>
    </div>
    <div class="col-6 px-0">
      <div [ngClass]="{ active: pcViewIsActive }" class="pcView assocViewTitleBottom">&nbsp;</div>
    </div>
  </div>

  <div>
    <u-market-list
      (marketSelection)="selectMarket($event)"
      [markets]="filteredMarkets"
      [selectedLocale]="locale"
    ></u-market-list>
  </div>
</div>
