import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DecrementLoading, IncrementLoading } from '@usanaHub/app/state/app.actions';

@Injectable()
export class StateHelperService {
  constructor(public store: Store) {}

  incrementLoading(): void {
    this.store.dispatch(new IncrementLoading());
  }

  decrementLoading(): void {
    this.store.dispatch(new DecrementLoading());
  }

  ignoreUrlForLoadingSpinner(url: string): boolean {
    let toTest: string = url;
    const possibleHost = `${location.protocol}//${location.hostname}`;

    if (toTest.startsWith(possibleHost)) {
      toTest = toTest.substring(possibleHost.length);
    }

    return (
      toTest.startsWith('/mvc/translation') ||
      toTest.startsWith('/mvc/widget') ||
      toTest.startsWith('/mvc/auth/hubParams') ||
      toTest.startsWith('/mvc/cm/GetTree') ||
      toTest.startsWith('/mvc/cm/RequestAttrs') ||
      toTest.startsWith('/mvc/cm/AvailableLocales') ||
      toTest.startsWith('cmsmodule/items') ||
      toTest.startsWith('/dlm2') ||
      toTest.startsWith('/sockjs-node/') ||
      toTest.startsWith('/image-service/') ||
      toTest.startsWith('/mvc/Calendar/Approver/') ||
      toTest.startsWith('/mvc/alerts/system') ||
      toTest.startsWith('/mvc/auth/keepAlive') ||
      toTest.includes('/task-tracker') ||
      /^\/mvc\/Calendar\/Event(\/([0-9]+))?$/.test(toTest) ||
      toTest === '/hub/' ||
      toTest.startsWith('/mvc/socialSharing') ||
      toTest.match(/\/notification\/message/) != null ||
      toTest.match(/\/notification\/archivedMessagesForHub/) != null ||
      toTest.match(/\/notification\/subscription\/en/) != null ||
      toTest.match(/\/contest\/stats/) != null ||
      toTest.match(/\/mvc\/paperwork/) != null ||
      toTest.match(/\/mvc\/fpvCommissionOptIn/) != null ||
      toTest.match(/\/mvc\/cm\/GetTree\?rootId=usana-news-landing-container/) != null ||
      toTest.match(/\/mvc\/cm\/GetTree\?rootId=hub-home-banner-slider-container/) != null ||
      toTest.match(/hub\-gateway/) != null ||
      toTest.match(/cart\/short/) != null ||
      toTest.match(/\/i18n\/graphql/) != null ||
      possibleHost.match(/saas-usn.userreplay/) != null ||
      possibleHost.match(/blob:/) != null ||
      possibleHost.match(/googletagmanager/) != null ||
      possibleHost.match(/qualtrics/) != null ||
      possibleHost.match(/google-analytics/) != null ||
      possibleHost.match(/stats.g.doubleclick/) != null ||
      possibleHost.match(/col.eum-appdynamics.com/) != null
    );
  }
}
