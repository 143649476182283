<div *ngIf="user.coreSecureToken" class="footer">
  <div class="clearfix">
    <div class="col-12 text-center">
      <div [ngClass]="{ imageLarge: footerImg.isLarge }" class="image-box">
        <img [src]="footerImg.path" height="100%" width="100%" [alt]="footerImg.alt" />
      </div>
    </div>
  </div>
  <div class="clearfix">
    <div class="container pl-md-8">
      <div class="row">
        <div
          *ngFor="let column of columns; trackBy: trackById"
          [ngClass]="showForCountry(socialMediaLinks.hideCountry) ? 'col-md-3' : 'col-md-4'"
          class="footerColumn"
        >
          <button
            (click)="footerSectionExpanded[column.id] = !footerSectionExpanded[column.id]"
            [attr.aria-controls]="column.id"
            aria-expanded="footerSectionExpanded[column.id]"
            class="body-text-1 footer-title"
            role="button"
            type="button"
          >
            {{ column.title | translate }}
            <fa-icon [icon]="['fas', 'chevron-down']" class="fontawesome-icons"></fa-icon>
          </button>

          <div
            [collapse]="!footerSectionExpanded[column.id] && isMobileSize()"
            [isAnimated]="true"
            id="{{ column.id }}"
          >
            <div *ngFor="let link of column.links; trackBy: trackById">
              <div *ngIf="showForCountry(link.hideCountry)" class="submenu body-text-1">
                <a
                  [href]="link.url | translate | interpolate : { locale: locale }"
                  data-automation="teammanager.footer-home-button"
                  target="_blank"
                  rel="noopener"
                  >{{ link.title | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showForCountry(socialMediaLinks.hideCountry)" class="col-12 col-md-3">
          <div class="footer-title">
            {{ socialMediaLinks.title | translate }}
          </div>
          <div id="footerFollow">
            <span *ngFor="let socialMediaLink of socialMediaLinks.links; trackBy: trackById" class="social-media-icon">
              <ng-container
                *ngIf="showForCountry(socialMediaLink.hideCountry)"
                [ngTemplateOutletContext]="socialMediaLink"
                [ngTemplateOutlet]="socialMediaTemplate"
              ></ng-container>
            </span>
          </div>
          <div *ngIf="footerGovLogos && showForCountry(footerGovLogos.hideCountry)" class="govLogo">
            <img height="100%" [src]="footerGovLogos.img | translate" width="100%" [alt]="footerGovLogos.alt" />
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="showForCountry(['!', 'MY'])">
      <div class="container pt-5">
        <div class="row address-container">
          <p class="col-12 col-lg-6">
            {{ 'footer.registration.title' | translate }}<br />
            {{ 'footer.registration.number' | translate }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showForCountry(['!', 'IN'])">
      <div class="container pt-5">
        <div class="row address-container">
          <p class="col-12 col-lg-6">
            {{ 'footer.address.line1' | translate }}<br />
            {{ 'footer.address.line2' | translate }}<br />
            {{ 'footer.address.line3' | translate }}
          </p>

          <p class="col-12 col-lg-6">
            <a
              *ngIf="'footer.contact.email' | translate as footerEmail"
              data-automation="footer-email"
              href="mailto:{{ footerEmail }}"
              target="_blank"
            >
              <span class="fal fa-envelope"></span>{{ footerEmail }}
            </a>

            <br />

            <a
              *ngIf="'footer.contact.phone' | translate as footerPhone"
              href="tel:+{{ footerPhone }}"
              rel="noopener"
              target="_blank"
            >
              <span class="fal fa-phone"></span>{{ footerPhone }}
            </a>
          </p>
        </div>
      </div>
    </ng-container>

    <div class="container p3 space-t-32 pl-md-8 text-center">
      <div
        *ngFor="let disclaimer of disclaimers; trackBy: trackByDisclaimer"
        [innerHTML]="disclaimer | translate"
        class="footer-desc space-t-32 px-4 px-lg-0"
      ></div>
    </div>
  </div>
</div>
