<u-home-widget *ngIf="requestAttrs.pageEdit || !isProductsEmpty()">
  <div id="product-spotlight-widget" widgetHeader>
    <div id="business-center-snapshot-header">
      <h3 id="business-center-snapshot-title">
        {{ 'hub.productSpotlight.title' | translate }}
      </h3>
    </div>
  </div>
  <div id="product-spotlight-body" widgetBody>
    <ng-container>
      <u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
      <div [ngClass]="{ 'active-edit': activeEditItemId === cmsItemObj.item.id }" class="desktop-slider">
        <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
          <div
            [hidden]="!(slideIdx === idx || (requestAttrs.pageEdit && activeEditItemId === cmsItemObj.item.id))"
            [ngClass]="{ 'cms-child-edit-mode': requestAttrs.pageEdit }"
            id="{{ cmsItemObj.item.id }}"
          >
            <div id="{{ cmsItemObj.item.id }}">
              <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
              <u-cms-item
                [itemId]="childItem.item.id"
                [placeholderData]="cmsItemObj.placeholder ? childItem : undefined"
              ></u-cms-item>
            </div>
          </div>
        </ng-container>

        <div *ngIf="!requestAttrs.pageEdit">
          <div *ngIf="cmsItemObj.children.length > 1" id="slide-indicators">
            <universal-button
              (click)="previousSlide(slideIdx); captureClick(cmsItemObj.children[slideIdx].data, 'slideButton')"
              [data]="{ type: 'close' }"
              id="arrow-left"
            >
              <fa-icon [icon]="['fas', 'arrow-left']" class="arrow arrow-left"></fa-icon>
            </universal-button>
            <div class="dots-container">
              <div *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
                <universal-button
                  (click)="forceActiveSlide(idx); captureClick(cmsItemObj.children[idx].data, 'slideButton')"
                  [data]="{ type: 'close', id: childItem.item.id }"
                >
                  <fa-icon [icon]="['fas', 'circle']" [ngClass]="{ active: slideIdx === idx }" class="dot"></fa-icon>
                </universal-button>
              </div>
            </div>
            <universal-button
              (click)="nextSlide(slideIdx); captureClick(cmsItemObj.children[slideIdx].data, 'slideButton')"
              [data]="{ type: 'close', id: 'arrow-right' }"
            >
              <fa-icon [icon]="['fas', 'arrow-right']" class="arrow arrow-right"></fa-icon>
            </universal-button>
          </div>
          <div class="button-container">
            <universal-button
              (click)="getShareProductUrl(slideIdx)"
              [data]="{
                type: 'shop',
                id: 'share-product-button',
                size: 'grow'
              }"
              class="product-spotlight-button"
            >
              <span [style.visibility]="showCopyCheckmark ? 'hidden' : 'visible'"
                >{{ 'hub.productSpotlight.buttonText' | translate }}
              </span>
              <fa-icon
                [icon]="CHECK_ICON"
                [style.visibility]="showCopyCheckmark ? 'visible' : 'hidden'"
                class="centered"
                id="product-spotlight-copy-check"
              ></fa-icon>
            </universal-button>
          </div>
          <div class="button-container">
            <a
              [href]="goToShopUrl(slideIdx)"
              class="product-spotlight-button"
              id="goToShopLink"
              (click)="this.captureClick($event, 'link')"
            >
              <universal-button
                [data]="{ type: 'tertiary', id: 'go-to-shop-link', size: 'grow' }"
                class="product-spotlight-button"
              >
                {{ 'hub.productSpotlight.linkText' | translate }}
              </universal-button>
            </a>
          </div>
        </div>
      </div>
      <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
    </ng-container>
  </div>
</u-home-widget>
