import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { Market } from '../change-market.model';

@Component({
  selector: 'u-market-list',
  templateUrl: './market-list.component.html',
  styleUrls: ['./market-list.component.scss'],
})
export class MarketListComponent implements OnInit {
  readonly GLOBE_ICON = faGlobe;

  @Input() markets: Market[];
  @Input() selectedLocale: string;
  @Output() readonly marketSelection = new EventEmitter();

  selectedCountry: string;

  ngOnInit() {
    this.setSelectedCountry();
  }

  setSelectedCountry() {
    this.markets.forEach((country) => {
      country.localesInCountry.forEach((locale) => {
        if (locale.locale === this.selectedLocale) {
          this.selectedCountry = locale.country;
        }
      });
    });
  }

  removeSpaces(st) {
    return st.replace(/[\s]/g, '');
  }

  selectMarket(locale) {
    this.marketSelection.emit(locale);
  }

  trackByCountry(index, countryObj) {
    return countryObj.country;
  }

  trackByLocale(index, localeObj) {
    return localeObj.locale;
  }
}
