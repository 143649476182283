import { Component } from '@angular/core';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'u-error401',
  styleUrls: ['./error-page.component.scss'],
  templateUrl: './error401.component.html',
})
export class Error401Component {
  readonly HOME_ICON = faHome;
}
