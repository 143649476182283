import { Injectable } from '@angular/core';

@Injectable()
export class CmsLinkService {
  private readonly linkUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

  getTargetFromLinkData(cmsItem, linkCmsItem) {
    let linkData;

    try {
      // we don't care about the error sometimes this isn't json, and that is fine
      linkData = JSON.parse(cmsItem.data.linkData.data);
    } catch (e) {}
    if (linkData && linkData.target) {
      return linkData.target;
    } else if (linkCmsItem.linkUrl && linkCmsItem.linkUrl.match(this.linkUrlRegex)) {
      // if we start with a scheme (http://, https://, file://, etc) then we open in a new window
      return '_blank';
    }
    return '_self';
  }
}
