import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EarningsOptInItemService } from '@common/cms/earnings-opt-in/earnings-opt-in-item.service';
import { EarningsOptInState } from '@common/cms/earnings-opt-in/state/earnings-opt-in.state';
import { NgxsModule } from '@ngxs/store';
import { EarningsOptInItemComponent } from './earnings-opt-in-item/earnings-opt-in-item.component';

@NgModule({
  declarations: [EarningsOptInItemComponent],
  imports: [CommonModule, NgxsModule.forFeature([EarningsOptInState])],
  exports: [EarningsOptInItemComponent],
  providers: [EarningsOptInItemService],
})
export class EarningsOptInItemModule {}
