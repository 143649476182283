import { UplineLeaderPlacementReportData } from '@common/upline-leader-placement/upline-leader-placement.model';
import { ReportCellContent } from '@usanaHub/app/team-manager/models/report-cell-content';

export const MAP_USANA_UPLINE_REPORT_FN = (
  uplineReportDocument: ReportCellContent[],
): UplineLeaderPlacementReportData[] => [
  {
    groupTitle: 'hub.uplineLeaderReport.sponsorInfo',
    data: [
      {
        label: 'hub.uplineLeaderReport.titleCode',
        value: uplineReportDocument[5].v,
      },
      {
        label: 'hub.uplineLeaderReport.associateId',
        value: uplineReportDocument[4].v,
      },
      {
        label: 'hub.uplineLeaderReport.firstName',
        value: uplineReportDocument[6].v,
      },
      {
        label: 'hub.uplineLeaderReport.lastName',
        value: uplineReportDocument[7].v,
      },
      {
        label: 'hub.uplineLeaderReport.stateProvince',
        value: uplineReportDocument[8].v,
      },
      {
        label: 'hub.uplineLeaderReport.country',
        value: uplineReportDocument[9].v,
      },
      {
        label: 'hub.uplineLeaderReport.phoneDay',
        value: uplineReportDocument[10].v,
      },
      {
        label: 'hub.uplineLeaderReport.phoneNight',
        value: uplineReportDocument[11].v,
      },
    ],
  },
  {
    groupTitle: 'hub.uplineLeaderReport.placementInfo',
    data: [
      {
        label: 'hub.uplineLeaderReport.businessCenter',
        value: uplineReportDocument[14].v,
      },
      {
        label: 'hub.uplineLeaderReport.side',
        value: uplineReportDocument[15].v,
      },
      {
        label: 'hub.uplineLeaderReport.firstName',
        value: uplineReportDocument[16].v,
      },
      {
        label: 'hub.uplineLeaderReport.lastName',
        value: uplineReportDocument[17].v,
      },
    ],
  },
  {
    groupTitle: 'hub.uplineLeaderReport.uplineLeaderPlacement',
    data: [
      {
        label: 'hub.uplineLeaderReport.titleCode',
        value: uplineReportDocument[21].v,
      },
      {
        label: 'hub.uplineLeaderReport.associateId',
        value: uplineReportDocument[22].v,
      },
      {
        label: 'hub.uplineLeaderReport.firstName',
        value: uplineReportDocument[23].v,
      },
      {
        label: 'hub.uplineLeaderReport.lastName',
        value: uplineReportDocument[24].v,
      },
      {
        label: 'hub.uplineLeaderReport.stateProvince',
        value: uplineReportDocument[25].v,
      },
      {
        label: 'hub.uplineLeaderReport.country',
        value: uplineReportDocument[26].v,
      },
      {
        label: 'hub.uplineLeaderReport.phoneDay',
        value: uplineReportDocument[27].v,
      },
      {
        label: 'hub.uplineLeaderReport.phoneNight',
        value: uplineReportDocument[28].v,
      },
    ],
  },
];
