<div
  class="cms-item-edit-menu"
  *ngIf="userProfile && userProfile.pageEdit && userProfile.editorForLocale && parentCopyableToLocale()"
>
  <strong>Item Name: </strong>{{ cmsItemObj.item.name }}
  <br />
  <strong>Item Locale: </strong> {{ cmsItemObj.localeName }}<br />
  <strong>Type: </strong> {{ cmsItemObj.type.name }}<br />
  <button
    title="Copy To {{ userProfile.localeName }}"
    type="button"
    class="cms-btn cms-btn-edit"
    *ngIf="copyableToLocale()"
    (click)="copyToLocale(cmsItemObj, $event)"
  >
    <span class="cms-icon-btn cms-copy-icon"></span>
  </button>

  <div *ngIf="!hasPublishedChildren()" class="text-danger">
    There are no published child containers that match this locale. Nothing will show in this container until either:
    <ul>
      <li>New content is created and published for this locale.</li>
      <li>The content from the default locale is published for this locale.</li>
      <li>The content from the default locale is copied over.</li>
    </ul>
  </div>

  <div *ngIf="!copyableToLocale()">
    <div class="cms-toggle-switch" title="Toggle Edit" (click)="toggleEdit($event)">
      <input
        type="checkbox"
        name="cms-toggle-switch"
        class="cms-toggle-switch-checkbox"
        id="{{ cmsItemObj.item.id }}ToggleEdit"
      />
      <label
        class="cms-toggle-switch-label {{
          cmsItemObj.item.id !== activeEditItemId ? 'cms-toggle-switch-inner-on cms-toggle-switch-on' : ''
        }}"
      >
        <span
          class="cms-toggle-switch-inner {{
            cmsItemObj.item.id !== activeEditItemId ? 'cms-toggle-switch-inner-on' : ''
          }}"
        ></span>
        <span
          class="cms-toggle-switch-switch {{ cmsItemObj.item.id !== activeEditItemId ? 'cms-toggle-switch-on' : '' }}"
        ></span>
      </label>
    </div>
    <div
      class="cms-container-edit-buttons"
      *ngIf="userProfile.editorForLocale && cmsItemObj.item.id === activeEditItemId"
    >
      <button title="Edit" type="button" class="cms-btn cms-btn-edit" (click)="editContainer($event)">
        <span class="cms-edit-icon cms-icon-btn"></span>
      </button>
      <button
        title="Copy Direct Children To {{ cmsItemObj.localeName }}"
        type="button"
        class="cms-btn cms-btn-edit"
        *ngIf="!!cmsItemObj.children && !!cmsItemObj.children[0] && cmsItemObj.children[0].locale !== cmsItemObj.locale"
        (click)="copyChildrenToLocale(cmsItemObj, $event)"
      >
        <span class="cms-icon-btn cms-copy-children-icon"></span>
      </button>
    </div>
    <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId" [hideLabel]="true"></u-cms-add-button>
  </div>
</div>
