<div>
  <div id="loaderDiv" [hidden]="!isLoading">
    <img src="/images/loading-spinner-black.gif" alt="loading spinner" class="ajax-loader" />
  </div>

  <div class="main-hub main-hub-design">
    <u-top-nav></u-top-nav>

    <u-hub-interrupt></u-hub-interrupt>
    <toast-display></toast-display>

    <div id="content" class="hub-content homepage-design">
      <u-navigation></u-navigation>

      <div class="col-main-content">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div id="footerContent" *ngIf="user">
      <u-footer
        [user]="user"
        [footerImg]="footerImg"
        [disclaimers]="disclaimers"
        [columns]="footerColumns"
        [socialMediaLinks]="socialMediaLinks"
        [socialMediaTemplate]="socialMediaTemplate"
      >
      </u-footer>
    </div>

    <ng-template #socialMediaTemplate let-qrImage="qrImage" let-qrText="qrText" let-svg="svg" let-iconClass="iconClass">
      <a href="javascript:void(0);" class="icon_social">
        <svg
          [attr.class]="iconClass"
          aria-hidden="true"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path fill="currentColor" [attr.d]="svg"></path>
        </svg>
        <div class="qr-container">
          <div class="link-menu-item">
            <span class="arrow"></span>
            <img alt="beian" height="100" [src]="qrImage" />
            <p>{{ qrText | translate }}</p>
          </div>
        </div>
      </a>
    </ng-template>
  </div>
</div>
