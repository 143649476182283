import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FpvWidgetModule } from '@common/fpv-widget/fpv-widget.module';
import { BaoyingFpvWidgetComponent } from './baoying-fpv-widget.component';

@NgModule({
  declarations: [BaoyingFpvWidgetComponent],
  imports: [CommonModule, FpvWidgetModule],
  exports: [BaoyingFpvWidgetComponent],
})
export class BaoyingFpvWidgetModule {}
