<universal-modal-base>
  <ng-container modalHeader>
    <h2>
      {{ 'Temporary Market Change' | translate }}

      <span
        class="market-change-icons"
        tooltip="{{
          'changeMarket.p1.hub' | translate | interpolate : { localeName: (userProfile$ | async)?.localeName }
        }} 
            {{ 'myUsana.changeCountry.p3' | translate }}"
        placement="bottom"
      >
        <fa-icon [icon]="['fas', 'info-circle']" class="fontawesome-icons info-icon"></fa-icon>
      </span>
    </h2>
  </ng-container>

  <ng-container modalBody>
    <u-market-selector
      (marketSelection)="changeMarket($event)"
      [markets]="(supportedMarkets$ | async)?.countryLanguageMap"
      [locale]="(userProfile$ | async)?.locale"
      [isPc]="(userProfile$ | async)?.pc"
      [localeName]="(userProfile$ | async)?.localeName"
    ></u-market-selector>
  </ng-container>
</universal-modal-base>
