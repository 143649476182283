<u-widget
  [widgetData]="{
    title: ('notifications.landing-widget-title' | translate) + '(' + (unreadNotificationCount$ | async) + ')',
    dropDownButtonOptions: {
      dropdownMenuItems: (dropdownMenuItems$ | async),
    },
  }"
>
  <u-notification-widget-content
    [limit]="limit"
    [loadingNotifications]="loadingNotifications"
    [displayedNotifications]="displayedNotifications"
  ></u-notification-widget-content>
</u-widget>
