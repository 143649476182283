<ng-container *ngIf="requestAttrs">
  <u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
  <div class="cms-slide-container">
    <ul class="cms-hero-slider">
      <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
        <li
          [hidden]="!(slideIdx === idx || (requestAttrs.pageEdit && activeEditItemId === cmsItemObj.item.id))"
          id="{{ cmsItemObj.item.id }}"
        >
          <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
          <u-cms-item [itemId]="childItem.item.id"></u-cms-item>
        </li>
      </ng-container>
    </ul>
    <div class="cms-banner-caption cms-slide-container-slides">
      <div
        *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId"
        class="cms-slide-select"
        [ngClass]="{ 'cms-current-slide': idx === slideIdx }"
        (click)="forceActiveSlide(idx)"
      ></div>
    </div>
  </div>
  <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
</ng-container>
