import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByProperty',
})
export class OrderByPropertyPipe implements PipeTransform {
  transform(input: any[], sortByField: string, sortOrder = 'asc'): any[] {
    const sortedOutput = input ? [...input] : [];

    if (sortByField && sortedOutput?.length) {
      const sortFn = (objA: any, objB: any) => {
        const objAValue = objA[sortByField];
        const objBValue = objB[sortByField];

        return this.compareValues(objAValue, objBValue);
      };

      sortedOutput.sort(sortFn);

      if (sortOrder !== 'asc') {
        sortedOutput.reverse();
      }
    }

    return sortedOutput;
  }

  compareValues(valA: any, valB: any): number {
    let result: number;

    if (valA === undefined || valB === undefined) {
      // sort undefined as lowest value
      result = valA === undefined ? -1 : 1;
    } else {
      if (typeof valA === 'string' && typeof valB === 'string') {
        result = valA.localeCompare(valB);
      } else {
        result = valA < valB ? -1 : 1;
      }
    }

    return result;
  }
}
