<div class="clearfix pt-2 my-account-detail-box" *ngIf="userProfile">
  <div class="col-3 col-md-3 col-lg-2">
    <img
      class="my-account-detail-thumbnails-modal"
      [src]="'/mvc/image/profileImage/' + userProfile.user.username"
      data-automation="teammanager.profile-image"
      [alt]="userProfile.user.fullName"
    />
  </div>

  <div class="col-9 col-lg-10">
    <h5 class="pt-2 my-account-detail-fullname">{{ userProfile.user.fullName }}</h5>
    <div *ngIf="!userProfile.baoying">
      <div class="body-text-2 my-account-detail-id" *ngIf="userProfile.user.title">
        {{ 'Rank' | translate }}:
        {{ 'profile.title.' + userProfile.user.title | translate }}
      </div>
      <div class="body-text-2 my-account-detail-id" *ngIf="userProfile.user.executiveTitle">
        {{ 'hub.myAccount.executiveRank' | translate }}:
        {{ 'profile.execTitle.' + userProfile.user.executiveTitle | translate }}
      </div>
      <div
        class="body-text-2 my-account-detail-id"
        *ngIf="!userProfile.pc && (optInStatus$ | async)?.earningType as earningType"
      >
        <div *ngIf="userProfile.locale !== 'en_PH'">
          {{ 'hub.myAccount.earningStatus' | translate }}:
          {{ 'hub.profile.earningStatus.' + earningType | translate }}
        </div>
      </div>
    </div>

    <div class="body-text-2 my-account-detail-id">
      {{ 'hub.myAccount.id' | translate | interpolate : { username: userProfile.user.username } }}
    </div>
  </div>
</div>
