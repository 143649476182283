// eslint-disable-next-line max-classes-per-file
import { CmsData } from '../cms/cms.model';

export class UsanaNewsItem {
  // initialize `summary` and `imageSrc775x775` with some necessary defaults
  summary = new UsanaNewsItemObject({ dataType: 'summary' });
  imageSrc775x775 = new UsanaNewsItemObject({ dataType: 'imageSrc775x775' });
  linkData = new UsanaNewsItemObject({ dataType: 'linkData' });
  title = new UsanaNewsItemObject({ dataType: 'title' });
  ImageAlt?: UsanaNewsItemObject;
  linkType?: UsanaNewsItemObject;

  constructor(input: any) {
    for (const item of Object.keys(input)) {
      // if it's a news item we need to set defaults for any missing CMS data
      this[item] = new UsanaNewsItemObject(input[item]);
    }
    return this;
  }
}

export class UsanaNewsItemObject implements CmsData {
  // these defaults aren't meaningful, but they are better than undefined
  data = '';
  dataType = '';
  id = 0;
  itemId = '';
  locale = 'en';

  constructor(input: any = null) {
    Object.assign(this, input);
    // if there is no thumbnail and the item object is a `imageSrc775x775` add a blank image
    if (this.dataType === 'imageSrc775x775' && this.data === '') {
      this.data = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    }
    this.data = this.data === null ? '' : this.data;
    return this;
  }
}
