<div class="cardWrapper cardWrapperHorizontal">
  <div class="innerHorizontalWrapper">
    <div class="cardItemHorizontal">
      <u-cards-horizontal
        [attr.cmsItemId]="cmsItemObj.item.id"
        [imgUrl]="cmsItemObj.data.imageSrc575x323?.data"
        [alt]="cmsItemObj.data.ImageAlt?.data"
        [title]="cmsItemObj.data.title?.data"
        [summary]="cmsItemObj.data.summaryArea160Count?.data"
        [linkText]="cmsItemObj.data.linkText?.data"
        [link]="cmsItemObj.linkUrl"
        [target]="target"
      ></u-cards-horizontal>
    </div>
  </div>
</div>
