import { Component, OnInit } from '@angular/core';
import { CmsBaseItemDirective } from '@common/cms/items/cms-item-base.directive';
import { WindowSizingService } from '@common/services/window-size.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'u-mini-card-carousel',
  templateUrl: './mini-card-carousel.component.html',
  styleUrls: ['./mini-card-carousel.component.scss'],
})
export class MiniCardCarouselComponent extends CmsBaseItemDirective implements OnInit {
  itemsPerSlide;

  constructor(protected readonly store: Store, private windowSizingService: WindowSizingService) {
    super(store);
  }

  ngOnInit() {
    this.itemsPerSlide = this.cmsItemObj.children.length < 3 || this.windowSizingService.isMobileSize() ? 2 : 3;
  }

  trackByIndex(index) {
    return index;
  }
}
