<universal-modal-base *ngIf="data">
  <ng-container modalHeader>
    <h2>{{ data.notification.category.displayText }}</h2>
  </ng-container>

  <ng-container modalBody>
    <div class="notification-content">
      <div class="notification-modal-icon notification-time">
        <span class="notification-category-icon">
          <fa-icon [icon]="data.icon"></fa-icon>
        </span>

        <span>{{ data.notification.notificationTime | usanaDate : 'medium' }}</span>
      </div>

      <div class="pt-3 pb-3">
        <div
          class="body-text-2 notification-body"
          *ngFor="let notificationData of data.notification.content; trackBy: trackByIndex"
        >
          <span
            [innerHTML]="'<span>' + notificationData.text + '</span>' | unsafe : 'html'"
            *ngIf="notificationData.part === 'BODY'"
          >
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</universal-modal-base>
