<ng-container *ngIf="cmsItemObj.linkUrl; else elseBlock">
  <a [href]="cmsItemObj.linkUrl" [target]="linkTarget" (click)="onNavClickEvent($event)" [classList]="cmsLinkClass">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-template #elseBlock>
  <div [class]="cmsLinkClass">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
