import { Component, OnInit } from '@angular/core';
import { CmsBaseContainerDirective } from '@common/cms/containers/cms-base-container.directive';
import { CmsColumnedItem } from '@common/cms/containers/columned/cms-columned-item';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { CmsItemEntry } from '@common/cms/state/cms.state.model';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'u-cms-columned-container',
  templateUrl: './cms-columned-container.component.html',
  styleUrls: ['./cms-columned-container.component.scss'],
})
export class CmsColumnedContainerComponent extends CmsBaseContainerDirective implements OnInit {
  columns: CmsColumnedItem[];

  constructor(protected readonly store: Store) {
    super(store);
  }

  ngOnInit(): void {
    this.addSubscription(
      this.store
        .select(CmsSelectors.getCmsItemByIdFn)
        .pipe(map((fn) => fn(this.cmsItemObj.item.id)))
        .subscribe((cmsItem: CmsItemEntry) => {
          if (!cmsItem || cmsItem.isLoading) {
            this.columns = undefined; // no value we are loading or missing
          } else {
            const colCount = parseInt(cmsItem.data.data?.colCount?.data, 10);

            this.columns = this.decideColumnClasses(colCount, cmsItem.data.data?.colLayout?.data).map(
              (colClass, colIdx) => {
                // for each column find the items with the corresponding xCoord
                const itemIds = cmsItem.data.children
                  .filter((child) => child.relationship.xCoord === colIdx)
                  .map((child) => child.item.id);
                return {
                  cssClass: colClass,
                  // pre calculate this
                  trackBy: `${colIdx}_${itemIds.join('-')}`,
                  itemIds: itemIds,
                };
              }
            );
          }
        })
    );
  }

  trackByColumn(idx, item): string {
    return item.trackBy;
  }

  private decideColumnClasses(colCount: number, colLayout: string): string[] {
    switch (colCount) {
      case 1:
        return ['col-12'];
      case 2:
        switch (colLayout) {
          case '30-70':
            return ['col-md-4', 'col-md-8'];
          case '70-30':
            return ['col-md-8', 'col-md-4'];
          case '50-50':
            return ['col-md-6', 'col-md-6'];
        }
        break;
      case 3:
        return new Array(3).fill('col-md-4');
    }

    // no class.
    return new Array(colCount).fill('');
  }
}
