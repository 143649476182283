import { Directive, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CmsBaseDirective } from '@common/cms/cms-base.directive';
import { SLIDE_INTERVAL } from '@common/cms/state/cms.constants';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { Store } from '@ngxs/store';
import { WINDOW_TOKEN } from '@usana/ux/common';

@Directive()
export class CmsBaseIntervalContainerDirective extends CmsBaseDirective implements OnInit, OnDestroy {
  @Input() rootId: string;
  activeEditItemId: string;
  slideIdx: number;

  protected slideInterval;

  constructor(protected readonly store: Store, @Inject(WINDOW_TOKEN) protected readonly window: any) {
    super(store);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addSubscription(
      this.store.select(CmsSelectors.getActiveEditItemId).subscribe((activeEditItemId) => {
        this.activeEditItemId = activeEditItemId;
      })
    );
    if (this.cmsItemObj.children && this.cmsItemObj.children.length > 0) {
      this.changeActiveSlide(0);
      this.initSlideInterval();
    }
  }

  /**
   * call this if a thumbnail is clicked to immediately advance to that slide
   * @param idx
   */
  forceActiveSlide(idx: number): void {
    this.changeActiveSlide(idx);
    this.initSlideInterval();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.window.clearInterval(this.slideInterval);
  }

  private changeActiveSlide(idx: number): void {
    this.slideIdx = idx;
  }

  private initSlideInterval(): void {
    this.window.clearInterval(this.slideInterval); // destroy existing if it exists
    this.slideInterval = this.window.setInterval(() => {
      // if we aren't editing.
      if (this.activeEditItemId !== this.cmsItemObj.item.id) {
        let newPosition = this.slideIdx + 1;
        if (newPosition >= this.cmsItemObj.children.length) {
          // if at the end lets wrap
          newPosition = 0;
        }
        this.changeActiveSlide(newPosition);
      }
    }, SLIDE_INTERVAL);
  }

  previousSlide(activeSlideIndex: number): void {
    const nextSlideIndex = activeSlideIndex - 1 <= -1 ? this.cmsItemObj.children.length - 1 : activeSlideIndex - 1;
    this.changeActiveSlide(nextSlideIndex);
    this.initSlideInterval();
  }

  nextSlide(activeSlideIndex: number): void {
    const nextSlideIndex = activeSlideIndex + 1 >= this.cmsItemObj.children.length ? 0 : activeSlideIndex + 1;
    this.changeActiveSlide(nextSlideIndex);
    this.initSlideInterval();
  }
}
