<div id="upline-leader-page">
  <h1 class="title mb-5">{{ 'hub.uplineLeaderReport.uplineLeaderPlacement' | translate }}</h1>

  @if (upLinePlacementReport && !upLinePlacementReport.hasError) {
    <div id="upline-leader-report-container">
      @for (uplineReport of upLinePlacementReport.reportData; track $index) {
        <div class="card">
          <div class="card-body">
            <h4 class="list-group-title">{{ uplineReport.groupTitle | translate }}</h4>

            @for (report of uplineReport.data; track $index) {
              <div class="list-item">
                <span class="pe-4">{{ report.label | translate }}&#58;</span>
                <span>{{ report.value }}</span>
              </div>
            }
          </div>
        </div>
      }
    </div>
  } @else if (upLinePlacementReport?.hasError) {
    <div class="upline-report-loading-error" data-automation="uplineReportErrorLoading">
      <h4>{{ 'hub.dlm.error-loading' | translate }}</h4>
    </div>
  }
</div>
