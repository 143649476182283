import { Component, Input } from '@angular/core';
import { FpvWidgetTagsModel } from '@common/fpv-widget/fpvWidgetTags.model';
import { FPVReportModel } from '@common/reports/reports.model';

@Component({
  selector: 'u-fpv-widget',
  templateUrl: './fpv-widget.component.html',
  styleUrls: ['./fpv-widget.component.scss'],
})
export class FpvWidgetComponent {
  @Input() fpvReport: FPVReportModel;
  @Input() loadingFpvReport;
  @Input() fpvWidgetTags: FpvWidgetTagsModel;

  trackByIndex(index) {
    return index;
  }
}
