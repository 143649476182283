import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../../environments/environment';

@NgModule({
  imports: [CommonModule],
  providers: [
    Apollo,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const http = httpLink.create({
          uri: environment.hubGatewayUrl,
        });

        const middleware = new ApolloLink((operation, forward) => {
          if (operation.variables) {
            operation.variables = JSON.parse(JSON.stringify(operation.variables), (key, value) =>
              key === '__typename' ? undefined : value
            );
          }
          return forward(operation);
        });

        return {
          cache: new InMemoryCache(),
          link: middleware.concat(http),
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class ApolloHubModule {}
