import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HubCommonModule } from '@common/common.module';
import { WithCredentialsInterceptor } from '@common/interceptors/with-credentials.interceptor';
import { UsanaLocaleService } from '@common/services/locale.service';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule, NgxsModuleOptions } from '@ngxs/store';
import { ConfirmationModalModule } from '@shared/confirmation-modal/confirmation-modal.module';
import { FooterModule } from '@shared/footer/footer.module';
import { HubInterruptModule } from '@shared/hub-interrupt/hub-interrupt.module';
import { UsanaLoggerModule } from '@shared/logger/logger.module';
import { UsanaToastModule } from '@shared/toast/toast.module';
import { URL_SITE_DOMAIN, UrlModule } from '@usana/ux/common/url';
import { IMAGE_SERVICE_BACKEND_URL, IMAGE_SERVICE_PUBLIC_URL } from '@usana/ux/image-service-browser';
import { LOGIN_REALM } from '@usana/ux/sso-auth';
import { GRAPH_URL_TRANSLATION } from '@usana/ux/translations';
import { AppState } from '@usanaHub/app/state/app.state';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { environment } from '../../../../environments/environment';
import { ApolloHubModule } from '../../../common/src/apollo/apollo-hub.module';
import { CmsComponentService } from '../../../common/src/cms/services/cms-component.service';
import { AuthResponseInterceptor } from '../../../common/src/interceptors/auth-response.interceptor';
import { TranslationModule } from '../../../common/src/translation/translation.module';
import { UsanaUplineLeaderReportModule } from '../../../common/src/upline-leader-placement-report/usana-upline-leader-report.module';
import { BaoyingAppHubRoutingModule } from './baoying-app-hub-routing.module';
import { BaoyingHubComponent } from './baoying-app-hub/baoying-app-hub.component';
import { BaoyingHubLayoutComponent } from './baoying-app-hub/baoying-hub-layout/baoying-hub-layout.component';
import { BaoyingIconsModule } from './baoying-icons/baoying-icons.module';
import { BaoyingVolumeReportModule } from './baoying-volume/baoying-volume-report/baoying-volume-report.module';
import { CbecRedirectionService } from './cbec/cbec-redirection.service';
import { BAOYING_SUPPORTED_LOCALE_MAP } from './constants/baoying-supported-locales';
import { BaoyingHomePageModule } from './pages/home-page/baoying-home-page/baoying-home-page.module';

export const ngxsConfig: NgxsModuleOptions = {
  developmentMode: !environment.production,
};

@NgModule({
  declarations: [BaoyingHubComponent, BaoyingHubLayoutComponent],
  imports: [
    BrowserModule,
    NgxsModule.forRoot([AppState], ngxsConfig),
    NgxsActionsExecutingModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    UsanaLoggerModule,
    FooterModule,
    HubCommonModule,
    ApolloHubModule,
    UsanaToastModule,
    BaoyingVolumeReportModule,
    ConfirmationModalModule,
    UsanaUplineLeaderReportModule,
    BaoyingHomePageModule,
    TranslationModule,
    HubInterruptModule,
    BaoyingAppHubRoutingModule,
    BaoyingIconsModule,
    UrlModule,
  ],
  providers: [
    BsLocaleService,
    DatePipe,
    [
      { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: AuthResponseInterceptor, multi: true },
    ],
    { provide: LOCALE_ID, useValue: 'zh-CN' },
    {
      provide: LOGIN_REALM,
      useValue: 'baoying',
    },
    CbecRedirectionService,
    { provide: CmsComponentService, useClass: CmsComponentService },
    { provide: GRAPH_URL_TRANSLATION, useValue: environment.i18nServiceUrl + '/graphql' },
    { provide: IMAGE_SERVICE_BACKEND_URL, useValue: environment.imageServiceBackendUrl },
    { provide: IMAGE_SERVICE_PUBLIC_URL, useValue: environment.imageServicePublicUrl },
    { provide: URL_SITE_DOMAIN, useValue: environment.siteDomain },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class BaoyingAppHubModule implements DoBootstrap {
  constructor(
    private localeService: UsanaLocaleService,
    cbecRedirectionService: CbecRedirectionService,
  ) {
    if (!cbecRedirectionService.isInitialized()) {
      console.log('failed to initialize the cbec redirection');
    }
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    appRef.bootstrap(BaoyingHubComponent);
    this.localeService.setLocaleMap(BAOYING_SUPPORTED_LOCALE_MAP);
  }
}
