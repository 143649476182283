import { PacesetterWidgetStateModel } from '@common/pacesetter/state/pacesetter-widget.model';
import { PacesetterWidgetState } from '@common/pacesetter/state/pacesetter-widget.state';
import { Selector } from '@ngxs/store';
import { AppUser } from '../../authentication/model/user-profile';
import { UserProfileSelectors } from '../../authentication/state/user-profile.selectors';
import { PACESETTER_STATUS_TYPES } from '../pacesetter-widget.constants';
import { PacesetterWidgetModel } from '../pacesetter-widget.model';

export class PacesetterWidgetSelectors {
  @Selector([PacesetterWidgetState])
  public static getPacesetterWidget(state: PacesetterWidgetStateModel): PacesetterWidgetModel {
    return state?.pacesetterWidget;
  }

  @Selector([PacesetterWidgetSelectors.getPacesetterWidget])
  public static isPaceSetterWidgetVisible(state: PacesetterWidgetModel): boolean {
    const showPacesetterTab = state?.pacesetter && state?.pacesetter.status !== PACESETTER_STATUS_TYPES.HIDDEN;
    const showPlatinumTab = state?.platinum && state?.platinum.status !== PACESETTER_STATUS_TYPES.HIDDEN;

    return showPacesetterTab || showPlatinumTab;
  }

  @Selector([PacesetterWidgetSelectors.getPacesetterWidget, UserProfileSelectors.getUser])
  public static getHomePaceSetterWidgetData(state: PacesetterWidgetModel, user: AppUser): PacesetterWidgetModel {
    let paceSetterWidgetData: PacesetterWidgetModel;

    if (state) {
      paceSetterWidgetData = { ...state, pacesetter: { ...state.pacesetter }, platinum: { ...state.platinum } };

      paceSetterWidgetData.userName = user?.recognitionName || user?.firstName;

      paceSetterWidgetData.pacesetter.isFinalizedLife =
        paceSetterWidgetData.pacesetter.status === PACESETTER_STATUS_TYPES.FINALIZED_LIFE;
      paceSetterWidgetData.platinum.isFinalizedLife =
        paceSetterWidgetData.platinum.status === PACESETTER_STATUS_TYPES.FINALIZED_LIFE;

      paceSetterWidgetData.pacesetter.isFinalizedAnnual =
        paceSetterWidgetData.pacesetter.status === PACESETTER_STATUS_TYPES.FINALIZED_ANNUAL;
      paceSetterWidgetData.platinum.isFinalizedAnnual =
        paceSetterWidgetData.platinum.status === PACESETTER_STATUS_TYPES.FINALIZED_ANNUAL;

      paceSetterWidgetData.pacesetter.isFinalized =
        paceSetterWidgetData.pacesetter.isFinalizedAnnual || paceSetterWidgetData.pacesetter.isFinalizedLife;
      paceSetterWidgetData.platinum.isFinalized =
        paceSetterWidgetData.platinum.isFinalizedAnnual || paceSetterWidgetData.platinum.isFinalizedLife;

      paceSetterWidgetData.pacesetter.isQualified =
        paceSetterWidgetData.pacesetter.status === PACESETTER_STATUS_TYPES.QUALIFIED;
      paceSetterWidgetData.platinum.isQualified =
        paceSetterWidgetData.platinum.status === PACESETTER_STATUS_TYPES.QUALIFIED;

      paceSetterWidgetData.pacesetter.isInProgress =
        paceSetterWidgetData.pacesetter.status === PACESETTER_STATUS_TYPES.IN_PROGRESS;
      paceSetterWidgetData.platinum.isInProgress =
        paceSetterWidgetData.platinum.status === PACESETTER_STATUS_TYPES.IN_PROGRESS;

      paceSetterWidgetData.pacesetter.isQualPeriodOver = !!paceSetterWidgetData.pacesetter.qualPeriodOver;
      paceSetterWidgetData.platinum.isQualPeriodOver = !!paceSetterWidgetData.platinum.qualPeriodOver;

      paceSetterWidgetData.pacesetter.isHidden =
        paceSetterWidgetData.pacesetter.status === PACESETTER_STATUS_TYPES.HIDDEN;
      paceSetterWidgetData.platinum.isHidden = paceSetterWidgetData.platinum.status === PACESETTER_STATUS_TYPES.HIDDEN;

      // convert to string so interpolate does not read as false when 0
      paceSetterWidgetData.platinum.daysRemaining = String(paceSetterWidgetData.platinum.daysRemaining);
      paceSetterWidgetData.pacesetter.daysRemaining = String(paceSetterWidgetData.pacesetter.daysRemaining);
    }

    return paceSetterWidgetData;
  }

  /**
   * This selector adds the 'isAnnual' property based on user application date.
   * There may be an issue here in the future when affiliates are converted to associates.
   * When that happens, we may need to use a different data point to determine if the user is in annual qualification.
   */
  @Selector([PacesetterWidgetSelectors.getPacesetterWidget])
  public static getDashboardPaceSetterWidgetData(state: PacesetterWidgetModel): PacesetterWidgetModel {
    return state;
  }
}
