import { Component, Input } from '@angular/core';
import { CmsItemModel } from '@common/cms/cms.model';

@Component({
  selector: 'u-cms-link-item',
  templateUrl: './cms-link-item.component.html',
})
export class CmsLinkItemComponent {
  @Input() cmsItemObj: CmsItemModel;
}
