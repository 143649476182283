import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HtmlToPlainTextPipe } from '@shared/pipes/html-to-plain-text/html-to-plain-text.pipe';

@NgModule({
  declarations: [HtmlToPlainTextPipe],
  imports: [CommonModule],
  exports: [HtmlToPlainTextPipe],
  providers: [HtmlToPlainTextPipe],
})
export class HtmlToPlainTextModule {}
