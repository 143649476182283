import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUser } from '@common/authentication/model/user-profile';
import { Logger, LoggerService } from '@shared/logger/logger.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getCustomerType } from '../../../usana/src/app/state/app.utils';
import { SystemAlert } from './state/system-alerts.state.model';

@Injectable({
  providedIn: 'root',
})
export class SystemAlertService {
  log: Logger;

  constructor(private loggerService: LoggerService, private http: HttpClient) {
    this.log = this.loggerService.getLogger('SystemAlertService');
  }

  getSystemAlerts(user: AppUser, locale: string): Observable<SystemAlert[]> {
    const customerType = getCustomerType(user);

    return this.http.get('/mvc/alerts/system/' + locale + '/all/' + customerType + '/').pipe(
      catchError((err) => {
        this.log.error(err);
        return [];
      }),
      map((alerts: SystemAlert[]) => alerts)
    );
  }
}
