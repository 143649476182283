import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContestApiService } from '@common/contest/service/contest-api.service';
import { TranslationModule } from '@common/translation/translation.module';
import { NgxsModule } from '@ngxs/store';
import { ContestServiceState } from './state/contest-service.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([ContestServiceState]), TranslationModule],
  providers: [ContestApiService],
})
export class ContestServiceModule {}
