import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MODAL_LOADING_SPINNER_STYLE } from '@common/constants/modal-loading-spinner-style';
import { TASK_TRACKER_COLUMN_BREAKPOINTS } from '../../constants/task-tracker-widget-column-breakpoints.constants';
import { TaskTrackerColumnBreakpoint } from '../../models/task-tracker-column-breakpoint';
import { TaskTrackerResult } from '../../models/task-tracker-result';
import { TaskTrackerTask } from '../../models/task-tracker-task';

@Component({
  selector: 'u-task-tracker-widget',
  templateUrl: './task-tracker-widget.component.html',
  styleUrls: ['./task-tracker-widget.component.scss'],
})
export class TaskTrackerWidgetComponent {
  @Input() showMore: boolean;
  @Input() taskTrackerResult: TaskTrackerResult;
  @Input() pageSize: number;
  @Input() hideCompletedLink: boolean;

  @Output() readonly showMoreToggleClicked = new EventEmitter<boolean>();

  readonly loadingSpinnerStyle = MODAL_LOADING_SPINNER_STYLE;
  readonly progressPercentReplaceToken = '%PROGRESS_PERCENTAGE%';
  readonly currentShownTasksCountReplaceToken = '%CURRENT_SHOWN_TASKS_COUNT%';
  readonly totalTasksCountReplaceToken = '%TOTAL_TASKS_COUNT%';

  onShowMoreToggleClicked(): void {
    this.showMoreToggleClicked.emit();
  }

  get taskListLength(): number {
    return this.taskTrackerResult?.programProgress?.tasks?.length;
  }

  get canShowLink(): boolean | string {
    return (
      !this.hideCompletedLink &&
      this.taskTrackerResult.programProgress.isCompleted &&
      this.taskTrackerResult.programProgress.completedLinkUrl &&
      this.taskTrackerResult.programProgress.completedLinkTextTag
    );
  }

  get currentColumnSizeMapping(): TaskTrackerColumnBreakpoint {
    let sizeMapping = TASK_TRACKER_COLUMN_BREAKPOINTS[0];

    TASK_TRACKER_COLUMN_BREAKPOINTS.every((current) => {
      const isInRange = this.pageSize >= current.minPageWidth;
      sizeMapping = isInRange ? current : sizeMapping;

      return isInRange;
    });

    return sizeMapping;
  }

  get currentColumnCount(): number {
    return this.currentColumnSizeMapping.columnCount;
  }

  get defaultVisibleTaskCount(): number {
    return this.currentColumnSizeMapping.defaultVisibleTaskCount;
  }

  trackByIndex(_idx: number, taskCompletion: TaskTrackerTask): number {
    return taskCompletion.completedId;
  }
}
