import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslationModule } from '@common/translation/translation.module';
import { HtmlToPlainTextModule } from '@shared/pipes/html-to-plain-text/html-to-plain-text.module';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { UniversalModalModule } from '@usana/ux/universal-components/modal';
import { CommonIconsModule } from '../common-icons/common-icons.module';
import { UsanaNewsModalComponent } from './usana-news-modal/usana-news-modal.component';
import { UsanaNewsWidgetComponent } from './usana-news-widget/usana-news-widget.component';

@NgModule({
  declarations: [UsanaNewsWidgetComponent, UsanaNewsModalComponent],
  imports: [
    CommonModule,
    CommonIconsModule,
    UnsafeModule,
    HtmlToPlainTextModule,
    InterpolationPipeModule,
    TranslationModule,
    UniversalModalModule,
    RouterModule,
  ],
  exports: [UsanaNewsWidgetComponent, UsanaNewsModalComponent],
})
export class UsanaNewsModule {}
