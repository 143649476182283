export const PACESETTER_STATUS_TYPES = {
  HIDDEN: 'HIDDEN',
  IN_PROGRESS: 'IN_PROGRESS',
  QUALIFIED: 'QUALIFIED',
  FINALIZED_ANNUAL: 'FINALIZED_ANNUAL',
  FINALIZED_LIFE: 'FINALIZED_LIFE',
};

export const IMG_URL = {
  QUALIFIED_OR_FINALIZED: 'assets/images/Fireworks-CellSent-GIF_jif-to-GIF_smaller.gif',
  IN_PROGRESS: 'assets/images/6006_WaytoGo_smalLER.gif',
};

export const DAY_REMAINING_TRANSLATION_TAG = {
  DAY: 'dlm.widget.pacesetter.dayRemaining',
  DAYS: 'dlm.widget.pacesetter.daysRemaining',
};
