import { faFileAlt, faFileChartLine, faShoppingCart, faUndoAlt, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { QuickLinksModel } from '@shared/quick-links/quick-links.model';

export const BAOYING_QUICK_LINKS_DATA: Array<QuickLinksModel> = [
  {
    id: 'enroll',
    url: '/frame/shopSecureForward.jsp?url=oe',
    addUserToken: false,
    target: '_blank',
    fontAwesomeIcons: faUserPlus,
    translationTag: 'hub.home.enroll',
    orderBy: 1,
  },
  {
    id: 'shop',
    url: '/ux/mall/#/home?source=HUB',
    addUserToken: false,
    target: '_blank',
    fontAwesomeIcons: faShoppingCart,
    translationTag: 'hub.home.shop',
    orderBy: 2,
  },
  {
    id: 'myAutoOrders',
    url: '/ux/mall/#/customer/autoOrder?source=HUB',
    addUserToken: false,
    target: '_blank',
    fontAwesomeIcons: faUndoAlt,
    translationTag: 'hub.home.my-auto-orders',
    orderBy: 3,
  },
  {
    id: 'forms',
    url: '/hub/#/content/Forms',
    addUserToken: false,
    target: '_blank',
    fontAwesomeIcons: faFileAlt,
    translationTag: 'hub.home.forms',
    orderBy: 4,
  },
  {
    id: 'volumeReport',
    url: '/hub/#/baoyingVolume',
    addUserToken: false,
    target: '_blank',
    fontAwesomeIcons: faFileChartLine,
    translationTag: 'hub.home.volume-report',
    orderBy: 5,
  },
];
