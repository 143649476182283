import { ReportsState } from '@common/reports/state/reports.state';
import { ReportsStateModel } from '@common/reports/state/reports.state.model';
import { UPLINE_LEADER_REPORT_NAME } from '@common/upline-leader-placement/upline-leader-placement.constants';
import { UplineLeaderPlacementReport } from '@common/upline-leader-placement/upline-leader-placement.model';
import { Selector } from '@ngxs/store';
import { AppSelectors } from '@usanaHub/app/state/app.selectors';
import { MAP_BAOYING_UPLINE_REPORT_FN } from '../constants/baoying-upline-report-fields';
import { MAP_USANA_UPLINE_REPORT_FN } from '../constants/usana-upline-report-fields';

export class UsanaUplineReportSelectors {
  @Selector([ReportsState, AppSelectors.getIsBaoying])
  public static mapUsanaUplineReport(state: ReportsStateModel, isBaoying: boolean): UplineLeaderPlacementReport {
    let uplineLeaderReport: UplineLeaderPlacementReport;

    const uplineReportData = state?.dataEntries[UPLINE_LEADER_REPORT_NAME];
    const uplineReportDocument = uplineReportData?.data?.document[0];

    if (uplineReportDocument) {
      const reportDataFn = isBaoying ? MAP_BAOYING_UPLINE_REPORT_FN : MAP_USANA_UPLINE_REPORT_FN;
      const reportData = reportDataFn(uplineReportDocument);

      uplineLeaderReport = {
        hasError: uplineReportData.error,
        reportData: reportData,
      };
    }

    return uplineLeaderReport;
  }
}
