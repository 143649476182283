import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { UniversalModalModule } from '@usana/ux/universal-components/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UsanaIconsModule } from '../usana-icons/usana-icons.module';
import { ChangeMarketIconComponent } from './change-market-icon/change-market-icon.component';
import { ChangeMarketModalComponent } from './change-market-modal/change-market-modal.component';
import { ChangeMarketWarningComponent } from './change-market-warning/change-market-warning.component';
import { MarketListComponent } from './market-list/market-list.component';
import { MarketSelectorComponent } from './market-selector/market-selector.component';

@NgModule({
  declarations: [
    ChangeMarketIconComponent,
    ChangeMarketWarningComponent,
    MarketSelectorComponent,
    MarketListComponent,
    ChangeMarketModalComponent,
  ],
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    InterpolationPipeModule,
    TranslationModule,
    UsanaIconsModule,
    UniversalModalModule,
  ],
  exports: [ChangeMarketIconComponent],
})
export class ChangeMarketModule {}
