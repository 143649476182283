import { Component } from '@angular/core';
import { CmsBaseContainerDirective } from '@common/cms/containers/cms-base-container.directive';
import { Store } from '@ngxs/store';

@Component({
  selector: 'u-related-stories-container',
  templateUrl: './related-stories-container.component.html',
  styleUrls: ['./related-stories-container.component.scss'],
})
export class RelatedStoriesContainerComponent extends CmsBaseContainerDirective {
  constructor(protected readonly store: Store) {
    super(store);
  }
}
