import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsModule } from '@common/cms/cms.module';
import { BreadCrumbModule } from '@shared/bread-crumb/bread-crumb.module';
import { CardsHorizontalModule } from '@shared/cards-horizontal/cards-horizontal.module';
import { CardsModule } from '@shared/cards/cards.module';
import { CommonIconsModule } from '../../common-icons/common-icons.module';
import { RelatedStoriesContainerComponent } from './related-stories/related-stories-container/related-stories-container.component';
import { RelatedStoriesItemComponent } from './related-stories/related-stories-item/related-stories-item.component';
import { TrainingSubCategoryContainerComponent } from './training-sub-category/training-sub-category-container/training-sub-category-container.component';
import { TrainingSubCategoryItemComponent } from './training-sub-category/training-sub-category-item/training-sub-category-item.component';

const components = [
  RelatedStoriesItemComponent,
  RelatedStoriesContainerComponent,
  TrainingSubCategoryContainerComponent,
  TrainingSubCategoryItemComponent,
];

@NgModule({
  declarations: components,
  imports: [BreadCrumbModule, CommonModule, CardsModule, CardsHorizontalModule, CmsModule, CommonIconsModule],
})
export class TrainingCardsModule {}
