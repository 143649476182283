<u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
<div class="panel panel-default">
  <div class="panel-heading">
    <fa-icon [icon]="TABLE_HEADER_ICON"></fa-icon>
    {{ cmsItemObj.data.Title?.data }}
  </div>
  <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
    <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
    <u-cms-item [itemId]="childItem.item.id"></u-cms-item>
  </ng-container>
</div>
<u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
