import { Component } from '@angular/core';
import { faUserAlt } from '@fortawesome/pro-solid-svg-icons';
import {
  UniversalModalOptions,
  UniversalModalPosition,
  UniversalModalService,
} from '@usana/ux/universal-components/modal';
import { MyAccountModalComponent } from './my-account-modal/my-account-modal.component';

@Component({
  selector: 'u-my-account-icon',
  templateUrl: './my-account-icon.component.html',
  styleUrls: ['./my-account-icon.component.scss'],
})
export class MyAccountIconComponent {
  readonly MY_ACCOUNT_ICON = faUserAlt;

  constructor(private uModalService: UniversalModalService) {}

  openMyAccountModal() {
    const modalOptions: UniversalModalOptions = {
      modalPosition: UniversalModalPosition.RIGHT,
    };

    this.uModalService.openCustom(modalOptions, MyAccountModalComponent);
  }
}
