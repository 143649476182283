import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { SetUserProfile } from '@common/authentication/state/user-profile.actions';
import { CmsRelationship } from '@common/cms/cms.model';
import { ReloadCmsItem } from '@common/cms/state/cms.actions';
import { ResetCmsDataEntries } from '@common/cms/state/cms.actions';
import { Store } from '@ngxs/store';
import { WINDOW_TOKEN } from '@usana/ux/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CmsEditService {
  constructor(private http: HttpClient, private store: Store, @Inject(WINDOW_TOKEN) readonly window: any) {
    // the edit.jsp page calls window.opener.openerCallback(openerCallbackParam) when it's saved
    this.window.reloadCmsRootItem = (rootId) => {
      this.store.dispatch(new ReloadCmsItem(rootId));
    };
  }

  /**
   * Create a new relationship and copy the data of an item from one locale to another
   * @param parentId The parent object whose children to copy
   * @param fromLocale The locale to copy the data and relationships from
   * @param toLocale The locale to copy the parent to
   * @param withChildData whether or not to update any existing stored data with the from data
   */
  copyParentToLocale(parentId, fromLocale, toLocale, withChildData): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(
      `/mvc/cm/Manager/CopyParentToLocale/${parentId}?fromLocale=${fromLocale}&toLocale=${toLocale}&withChildData=${withChildData}`,

      { headers: headers, responseType: 'text' }
    );
  }

  /**
   * Create a new relationship and copy the data of an item from one locale to another
   * @param relId the relationship id
   * @param fromLocale The locale to copy data from, this is optional
   * @param targetLocale The locale of the parent
   */
  copyItemToLocale(relId, fromLocale, targetLocale): Observable<any> {
    return this.http.get(`/mvc/cm/Manager/CopyToLocale/${relId}?locale=${targetLocale}&fromLocale=${fromLocale}`);
  }

  editChild(relId, openerCallback, openerCallbackParam) {
    const ckver = window.localStorage.getItem('ckver') ? window.localStorage.getItem('ckver') : '4';
    this.cmPopup(
      `/mvc/cm/Manager/Edit/ItemRel/${relId}?ckver=${ckver}&site=${this.getSite()}${this.getOpenerCallback(
        openerCallback,
        openerCallbackParam,
        '&'
      )}`
    );
  }

  /**
   * Open the edit for some item in the given locale
   * @param itemId
   * @param locale
   * @param openerCallback a callback to be executed when the popup window is closed
   * @param openerCallbackParam The first (and only) parameter that will be passed to the opener callback
   */
  editItem(itemId, locale, openerCallback, openerCallbackParam) {
    this.cmPopup(
      `/mvc/cm/Manager/Edit/Item?itemId=${encodeURIComponent(
        itemId
      )}&locale=${locale}&site=${this.getSite()}${this.getOpenerCallback(openerCallback, openerCallbackParam, '&')}`
    );
  }

  /**
   * Popup a window that is 75% as big as it's parent and has limited decorations
   * @param url the url to open
   */
  cmPopup(url) {
    this.window.open(
      url,
      '_blank',
      'height=' +
        this.window.innerHeight * 0.75 +
        ',width=' +
        this.window.innerWidth * 0.75 +
        ',scrollbars=1,left=20,top=20,resizable=yes,toolbar=no,menubar=no,directories=no,status=1'
    );
  }

  /**
   * Figure out what the current site is
   * @returns the site of the current window
   */
  getSite() {
    return this.getSiteFromPath(this.window.location.pathname);
  }

  getSiteFromPath(path) {
    return path === '/' ? 'dotCom' : path.split('/')[1];
  }

  /**
   * Figure out what our main context is on the site
   * @returns the main context of the opener
   */
  getSiteFromOpener() {
    return this.getSiteFromPath(this.window.opener.location.pathname);
  }

  /**
   * This function escapes any funky characters in item ids so that it will actually find the nodes
   * @param string The id to escape
   * @returns The escaped id
   */
  escapeSpecialSelectorChars(string) {
    // escape special characters in the id's so that we can always guarantee we get the right id
    return string.replace(/([ !"#$%&'()*+,\.\/:;<=>?@[\]^`{|}~])/g, '\\$1');
  }

  addToContainer(itemId, locale, column, openerCallback, openerCallbackParam) {
    this.cmPopup(
      `/mvc/cm/Manager/Add?parentId=${itemId}&locale=${locale}&column=${column}&site=${this.getSite()}${this.getOpenerCallback(
        openerCallback,
        openerCallbackParam,
        '&'
      )}`
    );
  }

  getOpenerCallback(openerCallback, openerCallbackParam, appendCharacter) {
    let callBackParams = !!openerCallback ? appendCharacter + 'openerCallback=' + openerCallback : '';
    callBackParams += !!openerCallbackParam ? '&openerCallbackParam=' + openerCallbackParam : '';
    return callBackParams;
  }

  /**
   * Remove a given relationship. This does not delete the item itself, just the relationship.
   * @param relationshipId The relationship id to publish
   */
  removeCm(relationshipId): Observable<any> {
    return this.http.get(`/mvc/cm/Manager/Remove/${relationshipId}`);
  }

  /**
   * This is a simple wrapper for window.function.reload(true) to be used for callbacks and such
   */
  reloadLocation() {
    this.window.location.reload();
  }

  /**
   * This function will toggle page edit mode in the cms
   */
  togglePageEdit() {
    this.http
      .get('/mvc/cm/Manager/TogglePageEdit', this.getHeaders())
      .pipe(
        map(
          (response: any) => {
            this.store.dispatch(new ResetCmsDataEntries());
            this.store.dispatch(new SetUserProfile(response));
          },
          (error) => {
            console.log('The request failed: ', error);
          }
        )
      )
      .subscribe();
  }

  publishItem(cmItem): Observable<CmsRelationship> {
    return this.http.post('/mvc/cm/Manager/Publish', cmItem.relationship).pipe(
      map(
        (response: CmsRelationship) => response,
        (error) => {
          console.log('There was an error publishing the cmItem', cmItem);
          console.log('The response was', error);
          throw new Error('There was an error publishing the item');
        }
      )
    );
  }

  /**
   * This method will move a cms item up one position
   * @param relationshipId the id of the cmsItem to update.
   * @param direction this must be one of CmsService.DIRECTIONS
   */
  moveCmsItem(relationshipId, direction) {
    return this.http.post('/mvc/cm/Manager/move/' + direction + '/' + relationshipId, {});
  }

  getHeaders() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    };
  }
}
