import { Injectable } from '@angular/core';
import { ContestApiService } from '@common/contest/service/contest-api.service';
import {
  CompleteContestServiceRegistration,
  LoadContestServiceRegistration,
} from '@common/contest/state/contest-service.actions';
import { ContestServiceStateModel } from '@common/contest/state/contest-service.state.model';
import { Action, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@State<ContestServiceStateModel>({
  name: 'contestService',
  defaults: {
    registrations: {},
  },
})
@Injectable({
  providedIn: 'root',
})
export class ContestServiceState {
  constructor(private readonly contestApiService: ContestApiService) {}

  @Action(LoadContestServiceRegistration)
  loadRegistration(ctx: StateContext<ContestServiceStateModel>, action: LoadContestServiceRegistration) {
    const state = ctx.getState();

    if (!state.registrations[action.contestId]) {
      // patch that we are loading
      ctx.patchState({
        registrations: {
          [action.contestId]: {
            loading: true,
            response: null,
          },
        },
      });
      // brand new registration thing lets load it.
      return this.contestApiService.isRegistered(action.contestId).pipe(
        map((response) =>
          ctx.patchState({
            registrations: {
              [action.contestId]: {
                loading: false,
                response: response,
              },
            },
          })
        ),
        catchError(() => of(ctx.patchState(this.genericCatchError(action.contestId))))
      );
    } else {
      return ctx.patchState({
        registrations: state.registrations,
      });
    }
  }

  @Action(CompleteContestServiceRegistration)
  completeRegistration(ctx: StateContext<ContestServiceStateModel>, action: CompleteContestServiceRegistration) {
    return this.contestApiService.registerForContest(action.contestId).pipe(
      map((response) =>
        ctx.patchState({
          registrations: {
            [action.contestId]: {
              loading: false,
              response: response,
            },
          },
        })
      ),
      catchError(() => of(ctx.patchState(this.genericCatchError(action.contestId))))
    );
  }

  private genericCatchError(contestId: number) {
    // previous logic shows the unavailable in your market error
    // lets keep that
    return {
      registrations: {
        [contestId]: {
          loading: false,
          response: {
            responseObject: {
              contestId: contestId,
              registered: false,
              canRegister: false,
            },
            message: 'myUsana.contestOptIn.error2',
            type: null,
          },
        },
      },
    };
  }
}
