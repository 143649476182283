export const CmsConstants = {
  DEBUG: false,
  DIRECTIONS: {
    UP: 'up',
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    DOWN: 'down',
    BOTTOM: 'bottom',
  },
  PUBLISH_STATUS: {
    NONE: 0,
    PUBLISHED: 1,
    SUCCESS_PUBLISHING: 2,
    ERROR_PUBLISHING: 3,
  },
  MENU_SVG_PATHS: {
    'business-hub-nav-block': {
      svgPath1: 'M0 0h24v24H0z',
      svgPath2: 'M10 20h4V4h-4v16zm-6 0h4v-8H4v8zM16 9v11h4V9h-4z',
    },
    'shop-hub-nav-block': {
      svgPath1: 'M0 0h24v24H0zm18.31 6l-2.76 5z',
      svgPath2:
        'M11 9h2V6h3V4h-3V1h-2v3H8v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-9.83-3.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4h-.01l-1.1 2-2.76 5H8.53l-.13-.27L6.16 6l-.95-2-.94-2H1v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.13 0-.25-.11-.25-.25z',
    },
    'events-hub-nav-block': {
      svgPath1: 'M0 0h24v24H0z',
      svgPath2:
        'M16.53 11.06L15.47 10l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 17l5.94-5.94zM19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z',
    },
    'training-hub-nav-block': {
      svgPath1: 'M0 0h24v24H0z',
      svgPath2:
        'M 11.25 11.8125 C 11.25 12.121094 10.996094 12.375 10.6875 12.375 L 7.3125 12.375 C 7.003906 12.375 6.75 12.121094 6.75 11.8125 L 6.75 10.125 L 0 10.125 L 0 15.1875 C 0 16.085938 0.789062 16.875 1.6875 16.875 L 16.3125 16.875 C 17.210938 16.875 18 16.085938 18 15.1875 L 18 10.125 L 11.25 10.125 Z M 16.3125 4.5 L 13.5 4.5 L 13.5 2.8125 C 13.5 1.914062 12.710938 1.125 11.8125 1.125 L 6.1875 1.125 C 5.289062 1.125 4.5 1.914062 4.5 2.8125 L 4.5 4.5 L 1.6875 4.5 C 0.789062 4.5 0 5.289062 0 6.1875 L 0 9 L 18 9 L 18 6.1875 C 18 5.289062 17.210938 4.5 16.3125 4.5 Z M 11.25 4.5 L 6.75 4.5 L 6.75 3.375 L 11.25 3.375 Z M 11.25 4.5 ',
    },
    'media-and-internet-hub-nav-block': {
      svgPath1: 'M0 0h24v24H0z',
      svgPath2:
        'M8.15384615,2 L5.07692308,2 C3.38461538,2 2,3.38461538 2,5.07692308 L2,8.15384615 C2,9.84615385 3.38461538,11.2307692 5.07692308,11.2307692 L8.15384615,11.2307692 C9.84615385,11.2307692 11.2307692,9.84615385 11.2307692,8.15384615 L11.2307692,5.07692308 C11.2307692,3.38461538 9.84615385,2 8.15384615,2 Z M18.9230769,2 L15.8461538,2 C14.1538462,2 12.7692308,3.38461538 12.7692308,5.07692308 L12.7692308,8.15384615 C12.7692308,9.84615385 14.1538462,11.2307692 15.8461538,11.2307692 L18.9230769,11.2307692 C20.6153846,11.2307692 22,9.84615385 22,8.15384615 L22,5.07692308 C22,3.38461538 20.6153846,2 18.9230769,2 Z M8.15384615,12.7692308 L5.07692308,12.7692308 C3.38461538,12.7692308 2,14.1538462 2,15.8461538 L2,18.9230769 C2,20.6153846 3.38461538,22 5.07692308,22 L8.15384615,22 C9.84615385,22 11.2307692,20.6153846 11.2307692,18.9230769 L11.2307692,15.8461538 C11.2307692,14.1538462 9.84615385,12.7692308 8.15384615,12.7692308 Z M18.9230769,12.7692308 L15.8461538,12.7692308 C14.1538462,12.7692308 12.7692308,14.1538462 12.7692308,15.8461538 L12.7692308,18.9230769 C12.7692308,20.6153846 14.1538462,22 15.8461538,22 L18.9230769,22 C20.6153846,22 22,20.6153846 22,18.9230769 L22,15.8461538 C22,14.1538462 20.6153846,12.7692308 18.9230769,12.7692308 Z',
    },
    'news-hub-nav-block': {
      svgPath1: 'M0 0h24v24H0z',
      svgPath2:
        'M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z',
    },
    'recognition-hub-nav-block': {
      svgPath1: 'M0 0h24v24H0z',
      svgPath2: 'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z',
    },
  },
};
