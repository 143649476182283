import { Injectable } from '@angular/core';
import { QuickLinksModel } from './quick-links.model';

@Injectable({
  providedIn: 'root',
})
export class QuickLinksService {
  private QUICK_LINKS_ARRAY: QuickLinksModel[] = [];

  get quickLinks() {
    return this.QUICK_LINKS_ARRAY;
  }

  set quickLinks(quickLinks: QuickLinksModel[]) {
    this.QUICK_LINKS_ARRAY = quickLinks;
  }
}
