<div>
  @if(singleNotification$ | async; as notification) {
  <div class="notification-details">
    <div class="notification-category-icon">
      <fa-icon [icon]="NOTIFICATION_ICON_MAP[notification?.category?.category] || ENVELOPE_ICON"></fa-icon>
    </div>
    <div class="notification-subject-container">
      <div class="notification-title">{{ notification.category.displayText }}</div>
      <div
        class="notification-subject text-start"
        *ngFor="let notificationContent of notification.content; trackBy: trackByIndex"
      >
        <span *ngIf="notificationContent.part === 'SUBJECT'">
          {{ notificationContent.text }}
        </span>
      </div>
      <div class="notification-time">
        <span>{{ notification.notificationTime | usanaDate : 'medium' }}</span>
      </div>
    </div>
  </div>

  <div class="notification-content">
    <div class="pt-3 pb-3">
      <div
        class="body-text-2 notification-body"
        *ngFor="let notificationData of notification.content; trackBy: trackByIndex"
      >
        <span
          [innerHTML]="'<span>' + notificationData.text + '</span>' | unsafe : 'html'"
          *ngIf="notificationData.part === 'BODY'"
        >
        </span>
      </div>
    </div>
  </div>
  } @else {
  <div class="text-center no-notifications">
    {{ 'notifications.landing.noNewNotifications' | translate }}
  </div>
  }
</div>
