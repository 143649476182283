import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsModule } from '@common/cms/cms.module';
import { HomeState } from '@common/home/state/home.state';
import { NgxsModule } from '@ngxs/store';
import { HomeBannerComponent } from './home-banner/home-banner.component';

@NgModule({
  declarations: [HomeBannerComponent],
  imports: [CommonModule, CmsModule, NgxsModule.forFeature([HomeState])],
  exports: [HomeBannerComponent],
})
export class HomeModule {}
