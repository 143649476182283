import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrderByPropertyPipe } from './order-by-property.pipe';

@NgModule({
  declarations: [OrderByPropertyPipe],
  imports: [CommonModule],
  providers: [OrderByPropertyPipe],
  exports: [OrderByPropertyPipe],
})
export class OrderByPropertyPipeModule {}
