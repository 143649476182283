import { gql } from 'apollo-angular';

export const PRODUCT_SPOTLIGHT_QUERY = gql`
  query getProductByUsanaId($usanaId: [String]) {
    getProductByUsanaId(usanaId: $usanaId) {
      id
      marketProductId
      productGroupId
      usanaId
      legacyProductKey
      productQuantityCount
      productQuantityCountTranslationTag
      productDescription {
        id
        shortDescription
        longDescription
        name
        fullDisplayName
        language
        modifiedBy
      }
      productImages {
        id
        imagePath
        type {
          id
          imageType
          imageSize
          width
          height
        }
        imageOrder
        modifiedBy
      }
      price {
        priceDisplay
        currency
        volume
        price
      }
      retailPrice {
        priceDisplay
        currency
        volume
        price
      }
      pcPrice {
        priceDisplay
        currency
        volume
        price
      }
      aoPrice {
        priceDisplay
        currency
        volume
        price
      }
      warehouse {
        priceDisplay
        currency
        volume
        price
      }
      productVariants {
        typeId
        valueId
        variationType
        variationTypeDisplay
        variationValue
        variationValueDisplay
        typeOrder
        valueOrder
      }
      vatTaxRate
      shopModes
      productTags {
        id
        parentId
        name
        order
        imagePath
        country
      }
      editable
      eligibleForFreeShipping
      featured
      rank
    }
  }
`;
