<div class="nav-item-container">
  <u-popover [message]="title" placement="left" class="mobileSideNavPopover">
    <div>
      <svg class="icon centered" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path [attr.d]="svgPath1" fill="none" />
        <path [attr.d]="svgPath2" />
      </svg>
    </div>
  </u-popover>

  <ng-container *ngIf="(navLevel$ | async) === NavigationLevel.FIRST">
    <div class="mobile-nav-text">
      <span class="txt">{{ title }}</span>
    </div>
    <span class="mobile arrow-icon">
      <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
    </span>
  </ng-container>
</div>
