import { Component, inject } from '@angular/core';
import { NotificationSelectors } from '@common/notifications/state/notification.selectors';
import { IconDefinition, faBell } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NotificationListModalComponent } from '../notification-list-modal/notification-list-modal.component';
import { NotificationModalService } from '../services/notification-modal.service';

@Component({
  selector: 'u-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
})
export class NotificationBellComponent {
  unreadNotificationCount$: Observable<number> = inject(Store).select(NotificationSelectors.getUnreadNotificationCount);

  readonly NOTIFICATION_BELL_ICON: IconDefinition = faBell;

  constructor(private notificationModalService: NotificationModalService) {}

  openModal(): void {
    this.notificationModalService.openNotificationModal<NotificationListModalComponent>(NotificationListModalComponent);
  }
}
