import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserProfileState } from '@common/authentication/state/user-profile.state';
import { NgxsModule } from '@ngxs/store';
import { UsanaDatePipe } from './usana-date.pipe';

@NgModule({
  declarations: [UsanaDatePipe],
  imports: [CommonModule, NgxsModule.forFeature([UserProfileState])],
  exports: [UsanaDatePipe],
})
export class UsanaDatePipeModule {}
