<div *ngIf="fpvReport$ | async as fpvReport">
  <div class="clearfix" [ngClass]="{ 'pb-4': fpvReport.show }">
    <u-fpv-widget
      *ngIf="fpvReport.show"
      [fpvReport]="fpvReport.pagedDocument"
      [loadingFpvReport]="loadFpvReport$ | async"
      [fpvWidgetTags]="baoyingFpvWidgetTags"
    ></u-fpv-widget>
  </div>
</div>
