<div class="clearfix">
  <u-widget [widgetData]="getWidgetData()">
    <div *ngIf="volumeReport && volume">
      <div class="card-body scroll-x">
        <div class="slider-row">
          <strong class="slider-row-carat" [ngClass]="{ disabled: !validNewIndex(-1) }" (click)="moveVolume(-1)">
            <
          </strong>
          <span *ngIf="volume[2].v === 0">{{ 'babycare.hub.parentCenter' | translate }}</span>
          <span *ngIf="volume[2].v !== 0">{{ 'babycare.hub.childCenter' | translate }}{{ volume[2].v }}</span>
          <strong class="slider-row-carat" [ngClass]="{ disabled: !validNewIndex(1) }" (click)="moveVolume(1)">
            >
          </strong>
        </div>
        <div *ngIf="!hasVolumeToShow" class="widget-loading-error">
          <h4>{{ 'hub.dlm.widget.no-volume' | translate }}</h4>
        </div>
        <table *ngIf="hasVolumeToShow" class="table table-borderless mb-0">
          <thead class="thead-light">
            <tr>
              <th scope="col">{{ 'dlm-3087' | translate }}</th>
              <th scope="col">{{ volume[12].v | number : '1.2-2' }}</th>
              <th scope="col">{{ volume[15].v | number : '1.2-2' }}</th>
              <th scope="col">{{ volume[14].v | number : '1.2-2' }}</th>
              <th scope="col">{{ volume[13].v | number : '1.2-2' }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span class="grey-dot"></span>{{ 'dlm.dlmReports.volume' | translate }}</td>
              <td>{{ volume[4].v | number : '1.2-2' }}</td>
              <td>{{ volume[7].v | number : '1.2-2' }}</td>
              <td>{{ volume[6].v | number : '1.2-2' }}</td>
              <td>{{ volume[5].v | number : '1.2-2' }}</td>
            </tr>
            <tr>
              <td class="borderless-row-override">
                <span class="blue-dot"></span>{{ 'dlm.dlmReports.carryover' | translate }}
              </td>
              <td class="borderless-row-override">{{ volume[8].v | number : '1.2-2' }}</td>
              <td class="borderless-row-override">{{ volume[11].v | number : '1.2-2' }}</td>
              <td class="borderless-row-override">{{ volume[10].v | number : '1.2-2' }}</td>
              <td class="borderless-row-override">{{ volume[9].v | number : '1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pt-5" *ngIf="hasVolumeToShow">
          <u-data-view [name]="dataViewName" [metadata]="metadata" [data]="barChartData"></u-data-view>
        </div>
      </div>
    </div>
    <div *ngIf="hasError" class="widget-loading-error">
      <h4>{{ 'hub.dlm.widget.error-loading' | translate }}</h4>
    </div>

    <div *ngIf="loadBaoyingVolumeReport$ | async">
      <u-loading-spinner></u-loading-spinner>
    </div>
  </u-widget>
</div>
