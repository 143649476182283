<div
  *ngIf="!isWidgetHidden && pacesetterData$ | async as pacesetterData"
  id="pacesetterWidget"
  class="pacesetterWidget pb-4"
>
  <div class="card dlm-widget-box">
    <div class="card-header">
      <div class="row no-margin">
        <div class="col-12 px-0">
          <div class="dlm-widget-chart-title">
            {{ 'dlm.widget.pacesetter.title' | translate }}
            <div class="widget-header-icon" [popover]="popHeaderTemplate" [outsideClick]="true" placement="bottom">
              <fa-icon [icon]="INFO_CIRCLE_ICON"></fa-icon>
            </div>
            <ng-template #popHeaderTemplate
              ><div [innerHtml]="'dlm.widget.pacesetter.header.tooltip' | translate"></div
            ></ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row no-margin">
        <div class="col-12 py-4">
          <div class="pacesetter-tab-switch-box">
            <div
              id="pacesetterTab"
              (click)="switchPaceSetterView('pacesetter')"
              [ngClass]="{ active: showPacesetterTab }"
              class="pacesetter-title-switch"
            >
              {{ 'dlm.widget.pacesetter.tab.pacesetter' | translate }}
            </div>
            <div
              id="platinumTab"
              (click)="switchPaceSetterView('platinum')"
              [ngClass]="{ active: showPlatinumTab }"
              class="pacesetter-title-switch"
            >
              {{ 'dlm.widget.pacesetter.tab.platinum' | translate }}
            </div>
          </div>
        </div>
      </div>

      <!-- Pacesetter tab-->
      <div id="pacesetterBox" *ngIf="showPacesetterTab">
        <div class="clearfix">
          <div class="rowPacesetter">
            <div class="pacesetter-widget-image-container">
              <img
                class="pacesetter-widget-image"
                [src]="imgPicker(pacesetterData.pacesetter)"
                width="100%"
                height="100%"
              />
            </div>
            <div class="px-4 py-5 pacesetter-display-column">
              <div class="vertical-center">
                <div
                  class="w-100 pb-4 text-center"
                  *ngIf="pacesetterData.pacesetter.isInProgress && !pacesetterData.pacesetter.isQualPeriodOver"
                >
                  <span>{{ 'dlm.widget.pacesetter.qualifyBy' | translate }}</span>
                </div>
                <div class="w-100 pacesetter-data-container text-center">
                  <span *ngIf="pacesetterData.pacesetter.isInProgress && !pacesetterData.pacesetter.isQualPeriodOver">
                    {{ pacesetterData.pacesetter.endDate | fullDateTimeWithTimezone }}
                  </span>
                  <span *ngIf="pacesetterData.pacesetter.isFinalized && !pacesetterData.pacesetter.isQualPeriodOver"
                    ><span class="widget-header-icon widget-icon-blue">
                      <fa-icon [icon]="CIRCLE_CHECK_ICON"></fa-icon>
                    </span>
                    {{ 'dlm.widget.pacesetter.qualificationIsFinalized' | translate }}
                  </span>
                  <span *ngIf="pacesetterData.pacesetter.isQualified && !pacesetterData.pacesetter.isQualPeriodOver"
                    ><span class="widget-header-icon widget-icon-blue">
                      <fa-icon [icon]="CIRCLE_CHECK_ICON"></fa-icon>
                    </span>
                    {{ 'dlm.widget.pacesetter.qualificationIsQualified' | translate }}
                  </span>
                  <span *ngIf="pacesetterData.pacesetter.isQualPeriodOver">
                    {{ 'dlm.widget.pacesetter.qualPeriodOver' | translate }}
                  </span>
                </div>
                <div
                  class="w-100 pacesetter-important-label text-center"
                  *ngIf="pacesetterData.pacesetter.isInProgress && !pacesetterData.pacesetter.isQualPeriodOver"
                >
                  {{
                    getDaysRemainingTranslation(pacesetterData.pacesetter)
                      | translate
                      | interpolate : { daysRemaining: pacesetterData.pacesetter.daysRemaining }
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-5">
          <div class="rowPacesetter">
            <div class="pacesetter-widget-points-progress">
              <div class="row pb-3 d-flex align-items-center">
                <div class="col-12 text-center ps-0 pe-2">
                  <div
                    class="widget-header-icon"
                    [popover]="popPacesetterVolumePointsProgressTemplate"
                    [outsideClick]="true"
                    placement="bottom left"
                  >
                    <fa-icon [icon]="INFO_CIRCLE_ICON"></fa-icon>
                  </div>
                  {{ 'dlm.widget.pacesetter.volumePointsProgress' | translate }}
                </div>
                <ng-template #popPacesetterVolumePointsProgressTemplate
                  ><div [innerHtml]="'dlm.widget.pacesetter.volumePointsProgress.tooltip' | translate"></div
                ></ng-template>
              </div>
              <div class="cleafix">
                <div class="w-100 text-center">
                  <div class="pacesetter-max-points">
                    {{ pacesetterData.pacesetter.totalVolume }}/{{ pacesetterData.pacesetter.requiredVolume }}
                  </div>
                  <progressbar
                    [value]="pacesetterData.pacesetter.totalVolume"
                    [max]="pacesetterData.pacesetter.requiredVolume"
                  ></progressbar>
                </div>
              </div>
            </div>

            <div class="pacesetter-widget-members-recruited">
              <div class="row pb-3 d-flex align-items-center">
                <div class="col-12 text-center ps-2 pe-0">
                  <div class="widget-header-icon" [popover]="popPacesetterRecruitedTemplate" [outsideClick]="true">
                    <fa-icon [icon]="INFO_CIRCLE_ICON"></fa-icon>
                  </div>
                  {{ 'dlm.widget.pacesetter.teamMembersRecruited' | translate }}
                </div>
                <ng-template #popPacesetterRecruitedTemplate
                  ><div [innerHtml]="'dlm.widget.pacesetter.teamMembersRecruited.tooltip' | translate"></div
                ></ng-template>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <span
                    *ngFor="
                      let x of [].constructor(pacesetterData.pacesetter.requiredSponsored);
                      let indexOfElement = index;
                      trackBy: trackByIndex
                    "
                    class="sponsored-people"
                    [ngClass]="{
                      sponsored: changePeopleBlue(indexOfElement, pacesetterData.pacesetter.numberSponsored)
                    }"
                  >
                    <fa-icon [icon]="USER_ICON"></fa-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-5">
          <div class="col-12 text-center encouragment-message-text">
            <span *ngIf="pacesetterData.pacesetter.isInProgress && !pacesetterData.pacesetter.isQualPeriodOver">{{
              'dlm.widget.pacesetter.inProgressOnYourWay' | translate
            }}</span>
            <span *ngIf="pacesetterData.pacesetter.isQualified && !pacesetterData.pacesetter.isQualPeriodOver">{{
              'dlm.widget.pacesetter.qualifiedYouDidIt'
                | translate
                | interpolate : { username: pacesetterData.userName }
            }}</span>
            <span *ngIf="pacesetterData.pacesetter.isFinalizedAnnual && !pacesetterData.pacesetter.isQualPeriodOver">{{
              'dlm.widget.pacesetter.finalizedAnnualCongratulations'
                | translate
                | interpolate : { username: pacesetterData.userName }
            }}</span>
            <span *ngIf="pacesetterData.pacesetter.isFinalizedLife && !pacesetterData.pacesetter.isQualPeriodOver">{{
              'dlm.widget.pacesetter.finalizedLifeCongratulations'
                | translate
                | interpolate : { username: pacesetterData.userName }
            }}</span>
          </div>
        </div>
      </div>

      <!--Platinum tab-->
      <div id="platinumBox" *ngIf="!pacesetterData.platinum.isHidden && showPlatinumTab">
        <div class="clearfix">
          <div class="rowPacesetter">
            <div class="pacesetter-widget-image-container">
              <img
                class="pacesetter-widget-image"
                [src]="imgPicker(pacesetterData.platinum)"
                width="100%"
                height="100%"
              />
            </div>
            <div class="px-4 py-5 pacesetter-display-column">
              <div class="vertical-center">
                <div
                  class="w-100 pb-4 text-center"
                  *ngIf="pacesetterData.platinum.isInProgress && !pacesetterData.platinum.isQualPeriodOver"
                >
                  <span>{{ 'dlm.widget.pacesetter.qualifyBy' | translate }}</span>
                </div>
                <div class="w-100 pacesetter-data-container text-center">
                  <span *ngIf="pacesetterData.platinum.isInProgress && !pacesetterData.platinum.isQualPeriodOver"
                    >{{ pacesetterData.platinum.endDate | fullDateTimeWithTimezone }}
                  </span>
                  <span *ngIf="pacesetterData.platinum.isFinalized && !pacesetterData.platinum.isQualPeriodOver">
                    <span class="widget-header-icon widget-icon-blue">
                      <fa-icon [icon]="CIRCLE_CHECK_ICON"></fa-icon>
                    </span>
                    {{ 'dlm.widget.pacesetter.qualificationIsFinalized' | translate }}
                  </span>
                  <span *ngIf="pacesetterData.platinum.isQualified && !pacesetterData.platinum.isQualPeriodOver">
                    <span class="widget-header-icon widget-icon-blue">
                      <fa-icon [icon]="CIRCLE_CHECK_ICON"></fa-icon>
                    </span>
                    {{ 'dlm.widget.pacesetter.qualificationIsQualified' | translate }}
                  </span>
                  <span *ngIf="pacesetterData.platinum.isQualPeriodOver">
                    {{ 'dlm.widget.pacesetter.qualPeriodOver' | translate }}
                  </span>
                </div>
                <div
                  class="w-100 pacesetter-important-label text-center"
                  *ngIf="pacesetterData.platinum.isInProgress && !pacesetterData.platinum.isQualPeriodOver"
                >
                  {{
                    getDaysRemainingTranslation(pacesetterData.platinum)
                      | translate
                      | interpolate : { daysRemaining: pacesetterData.platinum.daysRemaining }
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cleafix pt-5">
          <div class="clearfix rowPacesetter">
            <div class="pacesetter-widget-points-progress">
              <div class="row pb-3 d-flex align-items-center">
                <div class="col-12 text-center ps-0 pe-2">
                  <div
                    class="widget-header-icon"
                    [popover]="popPlatinumVolumePointsProgressTemplate"
                    [outsideClick]="true"
                    placement="bottom left"
                  >
                    <fa-icon [icon]="INFO_CIRCLE_ICON"></fa-icon>
                  </div>
                  {{ 'dlm.widget.pacesetter.volumePointsProgress' | translate }}
                </div>

                <ng-template #popPlatinumVolumePointsProgressTemplate
                  ><div [innerHtml]="'dlm.widget.platinum.volumePointsProgress.tooltip' | translate"></div
                ></ng-template>
              </div>
              <div class="cleafix">
                <div class="w-100 text-center">
                  <div class="pacesetter-max-points">
                    {{ pacesetterData.platinum.totalVolume }}/{{ pacesetterData.platinum.requiredVolume }}
                  </div>
                  <progressbar
                    [value]="pacesetterData.platinum.totalVolume"
                    [max]="pacesetterData.platinum.requiredVolume"
                  >
                  </progressbar>
                </div>
              </div>
            </div>

            <div class="pacesetter-widget-members-recruited">
              <div class="row pb-3 d-flex align-items-center">
                <div class="col-12 text-center ps-2 pe-0">
                  <div class="widget-header-icon" [popover]="popPlatinumRecruitedTemplate" [outsideClick]="true">
                    <fa-icon [icon]="INFO_CIRCLE_ICON"></fa-icon>
                  </div>
                  {{ 'dlm.widget.pacesetter.teamMembersRecruited' | translate }}
                </div>
                <ng-template #popPlatinumRecruitedTemplate
                  ><div [innerHtml]="'dlm.widget.platinum.teamMembersRecruited.tooltip' | translate"></div
                ></ng-template>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <span
                    *ngFor="
                      let x of [].constructor(pacesetterData.platinum.requiredSponsored);
                      let indexOfElement = index;
                      trackBy: trackByIndex
                    "
                    class="sponsored-people"
                    [ngClass]="{
                      sponsored: changePeopleBlue(indexOfElement, pacesetterData.platinum.numberSponsored)
                    }"
                  >
                    <fa-icon [icon]="USER_ICON"></fa-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="clearfix pt-5">
          <div class="w-100 text-center encouragment-message-text">
            <span *ngIf="pacesetterData.platinum.isInProgress && !pacesetterData.platinum.isQualPeriodOver">{{
              'dlm.widget.platinum.inProgressOnYourWay' | translate
            }}</span>
            <span *ngIf="pacesetterData.platinum.isQualified && !pacesetterData.platinum.isQualPeriodOver">{{
              'dlm.widget.platinum.qualifiedYouDidIt' | translate | interpolate : { username: pacesetterData.userName }
            }}</span>
            <span *ngIf="pacesetterData.platinum.isFinalizedAnnual && !pacesetterData.platinum.isQualPeriodOver">{{
              'dlm.widget.platinum.finalizedAnnualCongratulations'
                | translate
                | interpolate : { username: pacesetterData.userName }
            }}</span>
            <span *ngIf="pacesetterData.platinum.isFinalizedLife && !pacesetterData.platinum.isQualPeriodOver">{{
              'dlm.widget.platinum.finalizedLifeCongratulations'
                | translate
                | interpolate : { username: pacesetterData.userName }
            }}</span>
          </div>
        </div>
      </div>

      <div id="hiddenBox" class="hidden-msg-box" *ngIf="pacesetterData.platinum.isHidden">
        <div class="row text-center">
          <h4>{{ 'dlm.widget.platinum.notEligibleMsg' | translate }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
