<div class="space-b-32">
  <div class="change-market-disclaimer-text">
    <div class="col-left">
      <fa-icon [icon]="['fas', 'exclamation-triangle']" class="market-change-warning-icon"></fa-icon>
    </div>
    <div class="col-right">
      <div class="containerTxt">
        {{ 'myUsana.changeCountry.p2' | translate }}
      </div>
    </div>
  </div>
</div>
