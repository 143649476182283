import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmsUsanaNewsLandingComponent } from '../../../common/src/cms/news/usana-news-landing.component';
import { CmsPageComponent } from '../../../common/src/cms/page/cms-page.component';
import { Error401Component } from '../../../common/src/error-pages/error401.component';
import { Error404Component } from '../../../common/src/error-pages/error404.component';
import { NotificationDashboardComponent } from '../../../common/src/notifications/notification-dashboard/notification-dashboard.component';
import { NotificationSettingsContainerComponent } from '../../../common/src/notifications/notification-settings/notification-settings-container/notification-settings-container.component';
import { NotificationSingleItemComponent } from '../../../common/src/notifications/notification-single-item/notification-single-item.component';
import { userProfileResolverFn } from '../../../common/src/resolvers/user-profile.resolver';
import { UsanaUplineLeaderReportComponent } from '../../../common/src/upline-leader-placement-report/usana-upline-leader-report/usana-upline-leader-report.component';
import { BaoyingFpvReportComponent } from './baoying-fpv/baoying-fpv-report/baoying-fpv-report.component';
import { BaoyingVolumeReportComponent } from './baoying-volume/baoying-volume-report/baoying-volume-report.component';
import { nonPcOnlyCanActivate } from './guards/non-pc-only.activate';
import { BaoyingHomePageComponent } from './pages/home-page/baoying-home-page/baoying-home-page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    component: BaoyingHomePageComponent,
  },
  {
    path: '',
    canActivate: [nonPcOnlyCanActivate],
    children: [
      {
        path: 'content',
        children: [
          {
            component: CmsPageComponent,
            path: '**',
          },
        ],
      },
      {
        path: 'usanaNews',
        component: CmsUsanaNewsLandingComponent,
      },
      {
        path: 'notification',
        component: NotificationDashboardComponent,
      },
      {
        path: 'notification/settings',
        component: NotificationSettingsContainerComponent,
        resolve: { userProfile: userProfileResolverFn },
      },
      {
        path: 'notification/single',
        component: NotificationSingleItemComponent,
      },
      {
        path: 'Main',
        loadChildren: () =>
          import('../../../shared/src/purple-pages/purple-pages.module').then((m) => m.PurplePagesModule),
      },
      {
        path: 'baoyingVolume',
        component: BaoyingVolumeReportComponent,
      },
      {
        path: 'baoyingFPV',
        component: BaoyingFpvReportComponent,
      },
      {
        path: 'baoyingAboutMe',
        loadChildren: () => import('./baoying-about-me/baoying-about-me.module').then((m) => m.BaoyingAboutMeModule),
      },
      {
        path: 'uplineLeaderPlacementReport',
        component: UsanaUplineLeaderReportComponent,
        resolve: { userProfile: userProfileResolverFn },
      },
      {
        path: 'imageUpload',
        loadChildren: () =>
          import('../../../common/src/image-upload/image-upload.module').then((m) => m.ImageUploadModule),
      },
      {
        path: '404',
        component: Error404Component,
      },
      {
        path: '401',
        component: Error401Component,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class BaoyingAppHubRoutingModule {}
