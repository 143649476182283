import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { BaoyingFpvReportComponent } from './baoying-fpv-report.component';

@NgModule({
  declarations: [BaoyingFpvReportComponent],
  imports: [CommonModule, UnsafeModule, TranslationModule],
  exports: [BaoyingFpvReportComponent],
})
export class BaoyingFpvReportModule {}
