import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons';
import { BarChartMetadata, DEFAULT_BAR_CHART_OPTIONS } from '@shared/charts/bar-chart/bar-chart.metadata';
import { UsanaChart } from '@shared/charts/chart';
import { DataViewer } from '@shared/data-view/data-view.decorator';
import { Chart, ChartConfiguration, ChartData } from 'chart.js';

@Component({
  selector: 'u-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements AfterViewInit, UsanaChart {
  readonly PLAY_CIRCLE_ICON = faPlayCircle;

  @Input() metadata: BarChartMetadata;
  @Input() data: ChartData;
  @Output() readonly paginate = new EventEmitter<number>();

  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  currentPage = 0;

  barChart: any;

  ngAfterViewInit() {
    if (this.metadata && this.data) {
      this.setMetadata(this.metadata);
      this.setData(this.data);
      this.renderChart();
    }
  }

  get isFirstPage() {
    return this.currentPage === 0;
  }

  get pageAmount() {
    return this.data ? this.data.labels.length : 0;
  }

  renderChart() {
    this.barChart = new Chart(this.canvas.nativeElement, { ...(this.metadata.options as ChartConfiguration) });
  }

  onChartClick() {}

  setData(data: ChartData) {
    if (data && this.metadata) {
      this.metadata.options.data = data;

      if (this.barChart) {
        this.barChart.destroy();
        this.renderChart();
      }
    }
  }

  setMetadata(metadata: BarChartMetadata) {
    // @ts-ignore
    this.metadata.options = { ...DEFAULT_BAR_CHART_OPTIONS, ...metadata.options };
    this.metadata.categories = metadata.categories;
    this.metadata.hasNextPage = metadata.hasNextPage;
  }

  paginateBack() {
    this.currentPage = this.currentPage - this.pageAmount;
    this.paginate.emit(this.currentPage);
  }

  paginateForward() {
    this.currentPage = this.currentPage + this.pageAmount;
    this.paginate.emit(this.currentPage);
  }

  trackByCycle(index, cycle) {
    return cycle;
  }

  trackByIndex(index) {
    return index;
  }
}
