import { ContestServiceState } from '@common/contest/state/contest-service.state';
import {
  ContestDetail,
  ContestRegistration,
  ContestServiceStateModel,
} from '@common/contest/state/contest-service.state.model';
import { Selector } from '@ngxs/store';

export class ContestServiceSelectors {
  @Selector([ContestServiceState])
  public static getRegistrations(state: ContestServiceStateModel): {
    [contestId: number]: ContestDetail<ContestRegistration>;
  } {
    return state.registrations;
  }

  @Selector([ContestServiceSelectors.getRegistrations])
  public static getRegistrationByIdFn(registrations: {
    [contestId: number]: ContestDetail<ContestRegistration>;
  }): (contestId: number) => ContestDetail<ContestRegistration> {
    return (contestId: number) => registrations[contestId];
  }
}
