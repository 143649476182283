<ng-container *ngIf="requestAttrs">
  <u-container-edit-menu [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-container-edit-menu>
  <div class="ps-4" *ngIf="cmsItemObj.data.showBreadcrumbs?.data === 'true'">
    <u-bread-crumb
      [children]="[
        { name: 'hub.breadcrumb.training', path: '#/training', isTranslationTag: true },
        { name: cmsItemObj.data.title?.data }
      ]"
    ></u-bread-crumb>
  </div>
  <div class="row ps-4">
    <h2 class="training-container-title">{{ cmsItemObj.data.title?.data }}</h2>
  </div>
  <div
    class="row"
    [ngClass]="cmsItemObj.data.trainingCardSize?.data === vertical ? 'cms-training-cards' : 'cms-related-stories'"
  >
    <ng-container *ngFor="let childItem of cmsItemObj.children; let idx = index; trackBy: trackByIndexAndItemId">
      <ng-container *ngIf="childItem.item.type === 'training-task-tracker-widget'; else trainingCard">
        <div class="training-task-tracker" [ngClass]="{ 'cms-child-edit-mode': requestAttrs.pageEdit }">
          <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
          <u-cms-item [cmsItemObj]="childItem" [itemId]="childItem.item.id" navClickEvent="nav_training"></u-cms-item>
        </div>
      </ng-container>
      <ng-template #trainingCard>
        <div
          [ngClass]="[
            requestAttrs.pageEdit ? 'cms-child-edit-mode' : '',
            cmsItemObj.data.trainingCardSize?.data === vertical
              ? 'training-cards-container'
              : 'related-stories-container'
          ]"
          id="{{ cmsItemObj.item.id }}"
        >
          <u-cms-child-edit-menu [cmsItemObj]="childItem" [rootId]="rootId"></u-cms-child-edit-menu>
          <u-cms-item [itemId]="childItem.item.id" navClickEvent="nav_training"></u-cms-item>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <u-cms-add-button [cmsItemObj]="cmsItemObj" [rootId]="rootId"></u-cms-add-button>
</ng-container>
