import { Component, OnDestroy } from '@angular/core';
import { AppUser } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CmsItemModel } from '@common/cms/cms.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { UsanaAnalyticsService } from '@usana/ux/analytics';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'u-usana-news-modal',
  templateUrl: './usana-news-modal.component.html',
  styleUrls: ['./usana-news-modal.component.scss'],
})
export class UsanaNewsModalComponent implements OnDestroy {
  data: { usanaNewsItem: CmsItemModel };
  requestAttrs: AppUser;

  private destroy$ = new Subject<void>();

  constructor(public modal: NgbActiveModal, private store: Store, private analyticsService: UsanaAnalyticsService) {
    this.store
      .select(UserProfileSelectors.getUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: AppUser) => {
        this.requestAttrs = user;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  captureClickExpandView(): void {
    this.modal.close();
    this.analyticsService.sendEventToAllSystems('click_link', {
      url: '/content/' + this.data.usanaNewsItem.parent.id,
      label: 'News Story expand view link',
      description: this.data.usanaNewsItem.data.title.data,
    });
  }

  captureClick(event): void {
    this.analyticsService.sendEventToAllSystems('click_link', {
      url: event.target.href,
      label: 'News Story modal internal link',
      description: event.target.innerText,
    });
  }
}
