<div *ngIf="contestStatsResponse$ | async as contestStatsResponse">
  <ng-container *ngIf="!contestStatsResponse.loading">
    <div
      *ngIf="contestStatsResponse.data?.contestStats as stats"
      [ngSwitch]="stats.type"
      class="contest-stats-container"
    >
      <div *ngSwitchCase="CONTEST_TYPES.CVP" class="contest-stats contest-stats-cvp">
        <div class="contest-stats-row">
          <h3 class="contest-stat-header">
            {{ stats.responseObject.description }} {{ 'dotcom.contests.statbuilder.statsTitle' | translate }}
          </h3>
        </div>

        <div class="contest-stats-row">
          {{ 'dotcom.contests.statbuilder.weeksRemaining' | translate }}
          {{ stats.responseObject.weeksLeftInContest | number }}
        </div>

        <div class="contest-stats-row">
          {{ 'dotcom.contests.statbuilder.daysRemaining' | translate }}
          {{ stats.responseObject.daysLeftInContest | number }}
        </div>

        <div class="contest-stats-row">
          <div>
            {{ 'dotcom.contests.statbuilder.prevousCVP' | translate }}
            {{ stats.responseObject.pastCvpTotal | number }}
          </div>

          <div>
            {{ 'dotcom.contests.statbuilder.currentCVP' | translate }}
            {{ stats.responseObject.currentCvpTotal | number }}
          </div>

          <div>
            {{ 'dotcom.contests.statbuilder.nextGoal' | translate }}
            {{ stats.responseObject.pointsNeeded | number }}
          </div>
        </div>

        <div class="contest-stats-row">
          {{ 'dotcom.contests.statbuilder.weeklyGoal' | translate }}
          {{ stats.responseObject.weeklyGoal | number }}
        </div>

        <div class="contest-stats-row">
          {{ stats.responseObject.prizeLevel?.tag | translate }}
        </div>
      </div>

      <div *ngSwitchCase="CONTEST_TYPES.SVP" class="contest-stats contest-stats-svp">
        <div class="contest-stats-row">
          <h3 class="contest-stat-header">
            {{ stats.responseObject.description }}
          </h3>
        </div>

        <div class="contest-stats-row">
          <div>{{ 'dotcom.contests.svpbuilder.currentSVP' | translate }} {{ stats.responseObject.svp }}</div>

          <div>{{ 'dotcom.contests.svpbuilder.svpNeeded' | translate }} {{ stats.responseObject.pointsNeeded }}</div>

          <div>
            {{ 'dotcom.contests.svpbuilder.weeksRemaining' | translate }}
            {{ stats.responseObject.weeksLeftInContest }}
          </div>

          <div>
            {{ 'dotcom.contests.svpbuilder.daysRemaining' | translate }} {{ stats.responseObject.daysLeftInContest }}
          </div>
        </div>

        <div class="contest-stats-row">
          {{ stats.responseObject.prizeLevel?.tag | translate }}
        </div>
      </div>
    </div>
  </ng-container>
</div>
