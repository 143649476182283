import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeModule } from '@common/home/home.module';
import { ImportantDatesModule } from '@common/important-dates/important-dates.module';
import { NotificationWidgetModule } from '@common/notifications/notification-widget/notification-widget.module';
import { PacesetterWidgetModule } from '@common/pacesetter/pacesetter-widget.module';
import { TranslationModule } from '@common/translation/translation.module';
import { UsanaNewsModule } from '@common/usana-news/usana-news.module';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { QuickLinksModule } from '@shared/quick-links/quick-links.module';
import { BaoyingFpvModule } from '../../../baoying-fpv/baoying-fpv.module';
import { BaoyingVolumeWidgetModule } from '../../../baoying-volume/baoying-volume-widget/baoying-volume-widget.module';
import { BaoyingHomePageComponent } from './baoying-home-page.component';
import { PcBlockedModalComponent } from './pc-blocked-modal/pc-blocked-modal.component';

@NgModule({
  declarations: [BaoyingHomePageComponent, PcBlockedModalComponent],
  imports: [
    CommonModule,
    HomeModule,
    UsanaNewsModule,
    QuickLinksModule,
    PacesetterWidgetModule,
    ImportantDatesModule,
    InterpolationPipeModule,
    NotificationWidgetModule,
    BaoyingFpvModule,
    BaoyingVolumeWidgetModule,
    TranslationModule,
  ],
  exports: [BaoyingHomePageComponent],
})
export class BaoyingHomePageModule {}
