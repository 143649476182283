import { Component, Input, OnInit } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { CmsEditService } from '@common/cms/services/cms-edit.service';
import { CmsSelectors } from '@common/cms/state/cms.selectors';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'u-cms-add-button',
  templateUrl: './cms-add-button.component.html',
  styleUrls: ['./cms-add-button.component.scss'],
})
export class CmsAddButtonComponent implements OnInit {
  userProfile$: Observable<UserProfile> = this.store.select(UserProfileSelectors.getUserProfile);
  activeEditItemId$: Observable<string> = this.store.select(CmsSelectors.getActiveEditItemId);

  @Input() buttonText;
  @Input() hideLabel;
  @Input() cmsItemObj;
  @Input() column;
  @Input() rootId;
  targetColumn = 0;

  constructor(private cmsEditService: CmsEditService, private store: Store) {}

  ngOnInit(): void {
    this.setButtonText();
    this.setColumn();
  }

  setButtonText() {
    this.buttonText =
      (this.buttonText ? 'Add ' + this.buttonText : 'Add') +
      (this.hideLabel ? '' : ' to: ' + this.cmsItemObj.item.name);
  }

  setColumn() {
    if (this.column) {
      this.targetColumn = this.column;
    }
  }

  showAddButton(cmsItem, event) {
    event.stopPropagation();

    const targetLocale = !!cmsItem.relationship
      ? cmsItem.locale
      : this.store.selectSnapshot(UserProfileSelectors.getLocale);

    this.cmsEditService.addToContainer(
      cmsItem.item.id,
      targetLocale,
      this.targetColumn,
      'reloadCmsRootItem',
      this.rootId
    );
  }
}
