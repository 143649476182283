import { Injectable } from '@angular/core';
import { UsanaDatePipe } from '@common/custom-locale-pipes/usana-date-pipe/usana-date.pipe';
import { Contest, GrowthType } from '@usana/ux/contest';
import { CONTEST_WIDGET_QUERY } from '@usanaHub/app/contest-widget/constants/contest-widget-query';
import { ContestWidgetResponse, OptInResponse } from '@usanaHub/app/contest-widget/constants/contest-widget-response';
import {
  CONTEST_TRANSLATIONS,
  STAT_LEVEL_TRANSLATIONS,
} from '@usanaHub/app/contest-widget/constants/contest-widget.constant';
import { ContestLoading, ContestTranslationsArray } from '@usanaHub/app/contest-widget/contest-widget.model';
import { ContestWidgetTranslationService } from '@usanaHub/app/contest-widget/services/contest-widget-translation.service';
import { Apollo, MutationResult, QueryRef } from 'apollo-angular';
import { ContestDates } from 'node_modules/@usana/ux/contest/lib/model/contest-widget.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContestWidgetService {
  contestData: Contest[];
  contestTranslations: ContestTranslationsArray = {};
  loadingTranslations: ContestLoading = {};
  optInLoading: ContestLoading = {};
  contestDates: ContestDates = {};

  constructor(
    private apollo: Apollo,
    private contestTranslationService: ContestWidgetTranslationService,
    private usanaDatePipe: UsanaDatePipe
  ) {}

  get contestDataValue(): Contest[] {
    return this.contestData;
  }

  set contestDataValue(value: Contest[]) {
    this.contestData = value;
  }

  optInContest(contestId: number): Observable<MutationResult<OptInResponse>> {
    return this.apollo.mutate({
      mutation: CONTEST_WIDGET_QUERY.OPT_INTO_CONTEST,
      variables: {
        contestId: contestId,
      },
    });
  }

  getContestData(): QueryRef<ContestWidgetResponse> {
    return this.apollo.watchQuery<ContestWidgetResponse>({
      query: CONTEST_WIDGET_QUERY.GET_ALL_CONTEST_STATS,
    });
  }

  async getContestDataTranslations(): Promise<void[]> {
    this.contestData.forEach((contest) => {
      this.loadingTranslations[contest.contestId] = true;
      this.contestDates[contest.contestId] = {
        startDate: this.getContestDateString(contest.startDate),
        endDate: this.getContestDateString(contest.endDate),
      };
    });

    const promises = this.contestData.map(async (contest) => {
      try {
        await this.getContestTranslations(contest);
        this.optInLoading[contest.contestId] = false;
        this.loadingTranslations[contest.contestId] = false;
      } catch (error) {
        console.error(`Error occurred while getting translations for contest ${contest.contestId}:`, error);
      }
    });

    return Promise.all(promises);
  }

  async getContestTranslations(contest: Contest): Promise<void> {
    if (!this.contestTranslations[contest.contestId]) {
      this.contestTranslations[contest.contestId] = JSON.parse(JSON.stringify(CONTEST_TRANSLATIONS));
    }

    await this.contestTranslationService.getOptInTranslations(contest, this.contestTranslations[contest.contestId]);

    if (contest.optedIn) {
      await this.handleOptedInContest(contest);
    }
    return;
  }

  async handleOptedInContest(contest: Contest): Promise<void> {
    await this.contestTranslationService.getBaseContestTranslations(
      contest,
      this.contestTranslations[contest.contestId]
    );

    if (GrowthType.SVP === contest.growthType || GrowthType.DVP === contest.growthType) {
      await this.contestTranslationService.getSVPContestTranslations(
        contest,
        this.contestTranslations[contest.contestId]
      );
    }

    if (GrowthType.CVP === contest.growthType || GrowthType.DVP === contest.growthType) {
      await this.contestTranslationService.getCVPContestTranslations(
        contest,
        this.contestTranslations[contest.contestId]
      );
    }

    if (contest.statLevels) {
      await this.handleStatLevels(contest);
    }
  }

  async handleStatLevels(contest: Contest): Promise<void> {
    for await (const statLevel of contest.statLevels) {
      if (statLevel.contestLevel !== 0) {
        this.contestTranslations[contest.contestId].statLevelTranslations[statLevel.contestLevel] = JSON.parse(
          JSON.stringify(STAT_LEVEL_TRANSLATIONS)
        );
        await this.contestTranslationService.getStatLevelTranslations(
          statLevel,
          contest.growthType,
          this.contestTranslations[contest.contestId].statLevelTranslations[statLevel.contestLevel]
        );
      }
    }
  }

  getContestDateString(date: number): string {
    return this.usanaDatePipe.transform(date, 'shortDate');
  }
}
