import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { UniversalModalModule } from '@usana/ux/universal-components/modal';
import { CommonIconsModule } from '../common-icons/common-icons.module';
import { MyAccountDetailsComponent } from './my-account-details/my-account-details.component';
import { MyAccountIconComponent } from './my-account-icon/my-account-icon.component';
import { MyAccountModalComponent } from './my-account-icon/my-account-modal/my-account-modal.component';
import { MyAccountListComponent } from './my-account-list/my-account-list.component';

@NgModule({
  declarations: [MyAccountListComponent, MyAccountIconComponent, MyAccountDetailsComponent, MyAccountModalComponent],
  imports: [CommonModule, InterpolationPipeModule, TranslationModule, CommonIconsModule, UniversalModalModule],
  exports: [MyAccountIconComponent],
})
export class MyAccountModule {}
