<universal-modal-base>
  <ng-container modalBody>
    <div>
      <h4>{{ 'hub.logout.timeout.message' | translate | interpolate : { countDown: countDown } }}</h4>

      <h4>{{ 'hub.logout.timeout.doYouWantToContinue' | translate }}</h4>
    </div>
  </ng-container>

  <ng-container modalFooter>
    <universal-modal-buttons>
      <universal-button (click)="logout()" [data]="{ type: 'tertiary', id: 'session-timeout-logout-button' }">
        {{ 'hub.logout.doLogout' | translate }}
      </universal-button>

      <universal-button (click)="ok()" [data]="{ type: 'primary', id: 'session-timeout-continue-button' }">
        {{ 'hub.logout.doNotLogout' | translate }}
      </universal-button>
    </universal-modal-buttons>
  </ng-container>
</universal-modal-base>
