import { Component } from '@angular/core';
import {
  ClearSwitchUserAlertClicked,
  ReloadSwitchedUserClicked,
  SwitchBack,
  SwitchUser,
} from '@common/authentication/state/user-profile.actions';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { Store } from '@ngxs/store';

@Component({
  selector: 'u-switch-user',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.scss'],
})
export class SwitchUserComponent {
  userId: string;

  switchUserAlert$ = this.store.select(UserProfileSelectors.getSwitchUserAlert);
  userProfile$ = this.store.select(UserProfileSelectors.getUserProfile);

  constructor(private store: Store) {}

  dismissSwitchUserAlert(): void {
    this.store.dispatch(new ClearSwitchUserAlertClicked());
  }

  switchToUser(): void {
    this.dismissSwitchUserAlert();

    if (!this.userId) {
      // must supply user
      return;
    }

    this.store.dispatch(new SwitchUser(this.userId));
  }

  switchBack(): void {
    this.store.dispatch(new SwitchBack());
  }

  onReloadPageClicked(): void {
    this.store.dispatch(new ReloadSwitchedUserClicked());
  }
}
