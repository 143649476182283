import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
  faQuestionCircle,
  faSearch,
  faTimes,
  faTrashAlt,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class CommonIconsModule {
  readonly COMMON_ICONS: IconDefinition[] = [
    faInfoCircle,
    faQuestionCircle,
    faCheckCircle,
    faExclamationTriangle,
    faTimes,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faArrowRight,
    faArrowLeft,
    faArrowUp,
    faArrowDown,
    faSearch,
    faTrashAlt,
    faBan,
    faCircle,
    faCheck,
  ];

  constructor(faLibrary: FaIconLibrary) {
    faLibrary.addIcons(...this.COMMON_ICONS);
  }
}
