import { CmsItemEntry, CmsStateModel } from '@common/cms/state/cms.state.model';
import { StateOperator } from '@ngxs/store';
import cloneDeep from 'lodash/cloneDeep';

export const initializeCmsItemLoad =
  (collection: keyof CmsStateModel, cmsItemName: string): StateOperator<CmsStateModel> =>
  (state: CmsStateModel) => {
    const entry: CmsItemEntry = {
      isLoading: true,
      error: false,
      data: null,
    };

    return modifyDataEntry(state, collection, cmsItemName, entry);
  };

/**
 * used for a cms response to build out the cms item tree and add all of them to the state
 * @param cmsItemName the top level rootId
 * @param data the response data for that root Item.
 */
export const setCmsItemDataToState =
  (cmsItemName: string, data: any): StateOperator<CmsStateModel> =>
  (state: CmsStateModel) => {
    const cmsIds = [];
    const entries = {};

    // let's flat map the cms item.
    const flatMapCms = {};
    flatMapCmsChildren(data, flatMapCms);
    for (const cmsItemId of Object.keys(flatMapCms)) {
      entries[cmsItemId] = {
        isLoading: false,
        error: false,
        data: flatMapCms[cmsItemId],
      };
      cmsIds.push(cmsItemId);
    }

    return {
      ...state,
      cmsDataEntries: {
        ...state.cmsDataEntries,
        ...entries,
      },
      updatedCmsIds: cmsIds,
    };
  };

export const setCmsItemEntryToState =
  (itemId: string, cmsItemEntry: CmsItemEntry): StateOperator<CmsStateModel> =>
  (state: CmsStateModel) => ({
    ...state,
    cmsDataEntries: {
      ...state.cmsDataEntries,
      [itemId]: cmsItemEntry,
    },
  });

export const handleCmsItemLoadError =
  (collection: keyof CmsStateModel, cmsItemName: string) => (state: CmsStateModel) => {
    const entry: CmsItemEntry = {
      isLoading: false,
      error: true,
      data: null,
    };

    return modifyDataEntry(state, collection, cmsItemName, entry);
  };

export const getCmsItemIdsFromFlatMap = (state: CmsStateModel, itemId: string): string[] => {
  const cmsEntry = state.cmsDataEntries[itemId];
  if (!cmsEntry) {
    return [];
  }
  const { childIds } = cmsEntry.data;

  return (
    !childIds
      ? []
      : childIds.map((childId) => getCmsItemIdsFromFlatMap(state, childId)).reduce((pre, cur) => pre.concat(cur))
  ).concat(itemId);
};

export const buildCmsItemFromFlatMap = (entries: { [key: string]: CmsItemEntry }, itemId: string): CmsItemEntry => {
  const cmsEntry = entries[itemId];
  if (!cmsEntry) {
    // if we don't have an entry return undefined so the cms loads it.
    return undefined;
  }
  if (!cmsEntry.data) {
    // if our data hasn't loaded just return the construct since we don't have anything to mess with
    return cmsEntry;
  }
  const { isLoading, error } = cmsEntry;

  const data = convertChildIdsToChildren(entries, itemId);

  return {
    isLoading,
    error,
    data,
  };
};

export function convertChildIdsToChildren(entries: { [key: string]: CmsItemEntry }, itemId: string) {
  const cmsEntry = entries[itemId];
  if (!cmsEntry || !cmsEntry.data) {
    return undefined;
  }
  if (!cmsEntry.data.childIds) {
    // if we have no childIds just return the data
    return cmsEntry.data;
  }

  try {
    const { childIds, ...restOfData } = cmsEntry.data;

    return {
      ...restOfData,
      children: !childIds
        ? null
        : childIds.map((childId) => convertChildIdsToChildren(entries, childId)).filter((c) => !!c),
    };
  } catch (er) {
    console.error(er);
    return undefined;
  }
}

function modifyDataEntry(
  state: CmsStateModel,
  collection: keyof CmsStateModel,
  cmsItemName: string,
  dataEntry: CmsItemEntry
) {
  const stateCopy: CmsStateModel = cloneDeep(state);
  const entries = stateCopy.cmsDataEntries;
  entries[cmsItemName] = dataEntry;
  stateCopy.cmsDataEntries = entries as any;
  return stateCopy;
}

// this function exists to help us take a cms tree
// and flat map it, that is rather than having
// 1 entry in the state with all the data and children
// we have 1 + N entries, where N is the number of children
// this makes it so that when child cms Items try to load their data
// there is an entry in the cms for them so rather
function flatMapCmsChildren(data: any, cmsFlatMap: { [itemId: string]: any }) {
  // base case no children
  if (!data.children || data.children.length === 0) {
    cmsFlatMap[data.item.id] = data;
  } else {
    for (const child of data.children) {
      flatMapCmsChildren(child, cmsFlatMap);
    }
    cmsFlatMap[data.item.id] = {
      ...data,
      children: [],
      childIds: data.children.map((c) => c.item.id),
    };
  }
}
