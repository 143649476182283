import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SupportedMarkets, UserSupportedLocale } from '../change-market/change-market.model';

@Injectable({ providedIn: 'root' })
export class AvailableLocalesService {
  constructor(private http: HttpClient) {}

  getAvailableLocales(): Observable<SupportedMarkets> {
    return this.http
      .get('/mvc/cm/AvailableLocales', { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((response: SupportedMarkets) => response));
  }

  getUserSwitchableLocales(): Observable<UserSupportedLocale[]> {
    return this.http.get('/mvc/cm/userSwitchableLocales').pipe(map((response: UserSupportedLocale[]) => response));
  }
}
