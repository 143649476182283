import { Component, inject, Input } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { EarningsOptInStatus } from '@common/cms/earnings-opt-in/state/earnings-opt-in.model';
import { EarningsOptInSelectors } from '@common/cms/earnings-opt-in/state/earnings-opt-in.selectors';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'u-my-account-details',
  templateUrl: './my-account-details.component.html',
  styleUrls: ['./my-account-details.component.scss'],
})
export class MyAccountDetailsComponent {
  optInStatus$: Observable<EarningsOptInStatus> = inject(Store).select(EarningsOptInSelectors.getEarningsOptInStatus);

  @Input() userProfile: UserProfile;
}
