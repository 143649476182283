import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FullDateTimeWithTimezoneModule } from '@common/custom-locale-pipes/full-date-time-with-timezone/full-date-time-with-timezone.module';
import { PacesetterWidgetState } from '@common/pacesetter/state/pacesetter-widget.state';
import { TranslationModule } from '@common/translation/translation.module';
import { NgxsModule } from '@ngxs/store';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CommonIconsModule } from '../common-icons/common-icons.module';
import { PacesetterWidgetComponent } from './pacesetter-widget/pacesetter-widget.component';

@NgModule({
  declarations: [PacesetterWidgetComponent],
  imports: [
    CommonModule,
    NgxsModule.forFeature([PacesetterWidgetState]),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    InterpolationPipeModule,
    FullDateTimeWithTimezoneModule,
    TranslationModule,
    CommonIconsModule,
  ],
  exports: [PacesetterWidgetComponent],
})
export class PacesetterWidgetModule {}
