import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CmsBaseDirective } from '../cms-base.directive';
import { CmsSelectors } from '../state/cms.selectors';

@Component({
  selector: 'u-cms-usana-news-landing-item',
  templateUrl: './usana-news-landing-item.component.html',
  styleUrls: ['./usana-news-landing-item.component.scss'],
})
export class CmsUsanaNewsLandingItemComponent extends CmsBaseDirective {
  activeEditItemId$: Observable<string> = this.store.select(CmsSelectors.getActiveEditItemId);

  constructor(protected readonly store: Store) {
    super(store);
  }
}
