import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CmsBaseItemDirective } from '@common/cms/items/cms-item-base.directive';
import {
  CompleteContestServiceRegistration,
  LoadContestServiceRegistration,
} from '@common/contest/state/contest-service.actions';
import { ContestServiceSelectors } from '@common/contest/state/contest-service.selectors';
import { ContestDetail, ContestRegistration } from '@common/contest/state/contest-service.state.model';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'u-cms-contest-opt-in-item',
  templateUrl: './cms-contest-opt-in.component.html',
  styleUrls: ['./cms-contest-opt-in.component.scss'],
})
export class CmsContestOptInComponent extends CmsBaseItemDirective implements OnInit {
  contestRegDetail: ContestDetail<ContestRegistration>;
  saving: boolean;

  @ViewChild('optInCheck', { static: false }) optInCheckInput: ElementRef;

  constructor(protected readonly store: Store) {
    super(store);
    this.saving = false;
  }

  ngOnInit() {
    super.ngOnInit();

    const contestId = +this.getDataForAttribute('contestId');

    if (!isNaN(contestId)) {
      this.addSubscription(
        this.store
          .select(ContestServiceSelectors.getRegistrationByIdFn)
          .pipe(map((fn: (contestId: number) => ContestDetail<ContestRegistration>) => fn(contestId)))
          .subscribe((cd) => {
            this.saving = false;
            this.contestRegDetail = cd;
          })
      );
      this.store.dispatch(new LoadContestServiceRegistration(contestId));
    }
  }

  // return whether or not the checkbox is checked
  // NOTE: we do this rather than angular forms because the cms module
  // does not use FormsModule and for one checkbox it doesn't seem worth bringing
  // all of that in.
  optInChecked(): boolean {
    return this.optInCheckInput?.nativeElement?.checked;
  }

  optIn(): void {
    if (this.optInChecked()) {
      this.saving = true;
      this.store.dispatch(
        new CompleteContestServiceRegistration(this.contestRegDetail.response.responseObject.contestId)
      );
    }
  }
}
