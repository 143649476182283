import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReportsService } from '@common/reports/reports.service';
import { ReportsState } from '@common/reports/state/reports.state';
import { NgxsModule } from '@ngxs/store';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxsModule.forFeature([ReportsState])],
  providers: [ReportsService],
})
export class ReportsModule {}
