import { Component } from '@angular/core';
import { AppUser } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { ChangeOpenNavigationLevel } from '@common/navigation/state/navigation.actions';
import { NavigationSelectors } from '@common/navigation/state/navigation.selectors';
import { NavigationLevel } from '@common/navigation/state/navigation.state.model';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { CustomerTypeCodes } from '../../../constants/customer-type-codes';

@Component({
  selector: 'u-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  displaySideNavigation$: Observable<boolean> = this.store.select(NavigationSelectors.getDisplayHubNavigation);
  navLevel$: Observable<NavigationLevel> = this.store.select(NavigationSelectors.getNavLevel);
  user$: Observable<AppUser> = this.store.select(UserProfileSelectors.getUser);

  readonly CustomerTypeCodes = CustomerTypeCodes;
  readonly NavigationLevel = NavigationLevel;

  readonly IS_BAOYING = environment.isBaoying;

  constructor(private store: Store) {}

  onResize() {
    this.onClose();
  }

  onClose() {
    this.store.dispatch(new ChangeOpenNavigationLevel(NavigationLevel.NONE));
  }
}
