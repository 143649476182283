import { MAX_REPORT_PAGE_SIZE } from '@common/reports/reports.constants';
import { ReportViewTypes } from '@common/reports/reports.enum';

export const UPLINE_LEADER_REPORT_NAME = 'upLineLeaderReport';

export const UPLINE_LEADER_DEFAULT_VALUES = {
  defaultView: ReportViewTypes.Sheet,
  pageSize: MAX_REPORT_PAGE_SIZE,
  pageNum: 1,
  businessCenter: '',
  displayLevels: 4,
};

export const BUSINESS_CENTER_1 = '.001';
