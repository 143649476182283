<section *ngIf="hasDataForAttribute('Title')" class="cms-titled-item-title">
  <div class="row no-margin">
    <div class="col-md-10 offset-md-1 px-0">
      <h1 [innerHTML]="cmsItemObj.data.Title?.data | interpolate : this | unsafe : 'html'"></h1>
    </div>
  </div>
</section>
<section *ngIf="hasDataForAttribute('bannerImage')">
  <div class="row no-margin">
    <div class="col-md-10 offset-md-1 px-0">
      <div class="cms-banner-image">
        <img
          class="cms-group-page-banner"
          [src]="cmsItemObj.data.bannerImage?.data"
          [alt]="cmsItemObj.data.bannerImage?.data"
        />
        <div class="cms-banner-caption" *ngIf="hasDataForAttribute('bannerImageText')">
          <h2>{{ cmsItemObj.data.bannerImageText?.data }}</h2>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="cms-titled-item-story">
  <div class="row no-margin">
    <div class="col-md-10 offset-md-1 mt-5 px-0">
      <div class="row">
        <div class="col" [innerHTML]="cmsItemObj.data.content?.data | interpolate : this | unsafe : 'html'"></div>
      </div>
    </div>
  </div>
</section>
