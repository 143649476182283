<u-my-account-details [userProfile]="userProfile"></u-my-account-details>

<div class="pt-5"></div>
<div *ngFor="let myAccount of myAccount; trackBy: trackById">
  <div class="col-12 py-2 px-5" *ngIf="showLink(myAccount)">
    <a
      [id]="myAccount.id"
      class="body-text-3 profile-link-text"
      [href]="myAccount.url"
      [target]="myAccount.target"
      (click)="close()"
    >
      {{ myAccount.translationTag | translate }}
    </a>
  </div>
</div>
<div class="col-12 py-2 px-5">
  <a href="javascript:void(0);" class="body-text-3 profile-link-text" (click)="logout()">
    {{ 'myUsana.logOff' | translate }}
  </a>
</div>
