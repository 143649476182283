<div *ngIf="importantDateKeys.length">
  <carousel class="slider important-dates">
    <slide *ngFor="let key of importantDateKeys; trackBy: trackByImportantDateKey">
      <div class="body-text-2 wrap-text">
        <div
          class="important-dates-icon"
          tooltip="{{ 'hub.home.importantDates.' + key + '.tooltip' | translate }}"
          placement="bottom"
          containerClass="important-dates-tooltip"
          container="body"
        >
          <fa-icon [icon]="['fas', 'circle-info']" class="nav-icons"></fa-icon>
        </div>
        {{ 'hub.home.importantDates.' + key | translate }} {{ importantDates[key] | usanaDate : 'mediumDate' }}
      </div>
    </slide>
  </carousel>
</div>
