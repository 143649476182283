import { ReportDataEntry, ReportEntry, ReportsStateModel } from '@common/reports/state/reports.state.model';
import { StateOperator } from '@ngxs/store';
import { ReportEntryTypes } from './report-collections';

export const initializeReportLoad =
  (collection: ReportEntryTypes, reportName: string): StateOperator<ReportsStateModel> =>
  (state: ReportsStateModel) => {
    const entry: ReportDataEntry = {
      isLoading: true,
      error: false,
      data: null,
    };

    return modifyDataEntry(state, collection, reportName, entry);
  };

export const setReportDataToState =
  (collection: ReportEntryTypes, reportName: string, data: any): StateOperator<ReportsStateModel> =>
  (state: ReportsStateModel) => {
    const entry: ReportDataEntry = {
      isLoading: false,
      error: false,
      data: data,
    };

    return modifyDataEntry(state, collection, reportName, entry);
  };

export const clearReportParams =
  (collection: ReportEntryTypes, reportName: string): StateOperator<ReportsStateModel> =>
  (state: ReportsStateModel) =>
    modifyDataEntry(state, collection, reportName, undefined);

export const handleReportLoadError =
  (collection: ReportEntryTypes, reportName: string) => (state: ReportsStateModel) => {
    const entry: ReportDataEntry = {
      isLoading: false,
      error: true,
      data: { error: 'hub.error-loading', reportName: reportName } as any,
    };

    return modifyDataEntry(state, collection, reportName, entry);
  };

const modifyDataEntry = (
  state: ReportsStateModel,
  collection: ReportEntryTypes,
  reportName: string,
  dataEntry: ReportEntry
) => {
  const newEntries = {
    ...state[collection],
    [reportName]: dataEntry,
  };

  const newState = {
    ...state,
    [collection]: newEntries,
  };

  return newState;
};
