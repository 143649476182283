import { Component, EventEmitter, Output } from '@angular/core';
import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { Store } from '@ngxs/store';

@Component({
  selector: 'u-nav-header',
  templateUrl: './navigation-header.component.html',
  styleUrls: ['./navigation-header.component.scss'],
})
export class NavigationHeaderComponent {
  @Output() readonly closeNav = new EventEmitter();
  userProfile: UserProfile;

  constructor(private store: Store) {
    this.store.select(UserProfileSelectors.getUserProfile).subscribe((userProfile) => {
      this.userProfile = userProfile;
    });
  }
  onClose() {
    this.closeNav.emit();
  }
}
