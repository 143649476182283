import { Component, OnInit } from '@angular/core';
import { Notification } from '@common/notifications/notification.model';
import { LoadArchivedNotifications, LoadCurrentNotifications } from '@common/notifications/state/notification.actions';
import { NotificationSelectors } from '@common/notifications/state/notification.selectors';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'u-notification-dashboard',
  templateUrl: './notification-dashboard.component.html',
  styleUrls: ['./notification-dashboard.component.scss'],
})
export class NotificationDashboardComponent implements OnInit {
  currentNotifications$: Observable<Notification[]> = this.store.select(NotificationSelectors.getCurrentNotifications);
  archivedNotifications$: Observable<Notification[]> = this.store.select(
    NotificationSelectors.getArchivedNotifications
  );
  loadArchivedNotifications$: Observable<ActionsExecuting> = this.store.select(
    actionsExecuting([LoadArchivedNotifications])
  );
  loadCurrentNotifications$: Observable<ActionsExecuting> = this.store.select(
    actionsExecuting([LoadCurrentNotifications])
  );

  viewType: 'archived' | 'notification';

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch([new LoadArchivedNotifications()]);
  }

  switchNotificationView(type: 'archived' | 'notification') {
    this.viewType = type;
  }

  get isArchivedView() {
    return this.viewType === 'archived';
  }
}
