import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NOTIFICATION_ICON_MAP } from '@common/notifications/notification.constants';
import { Notification, ViewNotificationArgs } from '@common/notifications/notification.model';
import { faEnvelope, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'u-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss'],
})
export class NotificationListItemComponent implements OnInit {
  @Input() notification: Notification;
  @Output() readonly viewNotification = new EventEmitter<ViewNotificationArgs>();
  @Output() readonly deleteNotification = new EventEmitter();
  @Output() readonly closeModal = new EventEmitter();

  icon: IconDefinition;

  ngOnInit() {
    const iconDefinition = NOTIFICATION_ICON_MAP[this.notification.category.category];
    this.icon = iconDefinition || faEnvelope;
  }

  openNotificationDetailsModal(): void {
    this.viewNotification.emit({ notification: this.notification, icon: this.icon });
  }

  onDelete($event) {
    $event.stopPropagation();
    this.deleteNotification.emit(this.notification);
  }

  trackByIndex(index: number): number {
    return index;
  }
}
