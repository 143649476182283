import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { AppUser } from '@common/authentication/model/user-profile';
import { WindowSizingService } from '@common/services/window-size.service';
import { FooterColumn, FooterGovernmentLogo, FooterLogo, FooterSocialMedia } from './footer.model';

@Component({
  selector: 'u-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() columns: FooterColumn[];
  @Input() footerImg: FooterLogo;
  @Input() disclaimers: string[];
  @Input() socialMediaTemplate: TemplateRef<any>;
  @Input() socialMediaLinks: FooterSocialMedia;
  @Input() user: AppUser;
  @Input() footerGovLogos?: FooterGovernmentLogo;

  locale: string;
  footerSectionExpanded: { [sectionId: string]: boolean } = {};

  constructor(private windowSizingService: WindowSizingService) {}

  ngOnInit() {
    this.locale = this.getHyphenatedLocale(this.user);
  }

  isMobileSize() {
    return this.windowSizingService.isMobileSize();
  }

  getHyphenatedLocale(user: Partial<AppUser>) {
    if (user) {
      const language = user.locale.split('_')[0];
      return language + '-' + user.country;
    }
  }

  trackById(index: number, footer: { [key: string]: any }): number {
    return footer.id;
  }

  trackByDisclaimer(index: number, disclaimer: string): string {
    return disclaimer;
  }

  showForCountry(countries: string[]): boolean {
    if (countries.indexOf('!') > -1) {
      return countries.indexOf(this.user.country) > -1;
    } else {
      return countries.indexOf(this.user.country) < 0;
    }
  }
}
