import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationListItemModule } from '@common/notifications/notification-list-item/notification-list-item.module';
import { NotificationWidgetComponent } from '@common/notifications/notification-widget/notification-widget.component';
import { TranslationModule } from '@common/translation/translation.module';
import { LoadingSpinnerModule } from '@shared/loading-spinner/loading-spinner.module';
import { UniversalButtonModule } from '@usana/ux/universal-components/button';
import { WidgetModule } from '@usana/ux/widget';
import { NotificationWidgetContentComponent } from './notification-widget-content/notification-widget-content.component';

@NgModule({
  declarations: [NotificationWidgetComponent, NotificationWidgetContentComponent],
  imports: [
    CommonModule,
    WidgetModule,
    NotificationListItemModule,
    LoadingSpinnerModule,
    TranslationModule,
    RouterModule,
    UniversalButtonModule,
  ],
  exports: [NotificationWidgetComponent, NotificationWidgetContentComponent],
})
export class NotificationWidgetModule {}
