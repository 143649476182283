import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationsModule } from '@common/notifications/notifications.module';
import { TranslationModule } from '@common/translation/translation.module';
import { HubInterruptComponent } from '@shared/hub-interrupt/hub-interrupt.component';
import { KycRequiredModalComponent } from '@shared/hub-interrupt/kyc/kyc-required-modal.component';
import { NotificationSmsInterruptComponent } from '@shared/hub-interrupt/notification-sms/notification-sms-interrupt.component';
import { LoadingSpinnerModule } from '@shared/loading-spinner/loading-spinner.module';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { UnsafeModule } from '@shared/pipes/unsafe/unsafe.module';
import { UniversalButtonModule, UniversalLoadingButtonModule } from '@usana/ux/universal-components/button';
import { UniversalModalModule } from '@usana/ux/universal-components/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NotificationGenericInterruptComponent } from './notification-generic/notification-generic-interrupt.component';

@NgModule({
  declarations: [
    HubInterruptComponent,
    KycRequiredModalComponent,
    NotificationSmsInterruptComponent,
    NotificationGenericInterruptComponent,
  ],
  exports: [HubInterruptComponent],
  imports: [
    CommonModule,
    TranslationModule,
    ModalModule.forRoot(),
    UnsafeModule,
    NotificationsModule,
    InterpolationPipeModule,
    LoadingSpinnerModule,
    AlertModule.forRoot(),
    UniversalLoadingButtonModule,
    UniversalButtonModule,
    UniversalModalModule,
  ],
})
export class HubInterruptModule {}
