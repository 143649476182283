<div class="pb-4">
  <u-bread-crumb
    [children]="[
      { name: 'hub.news.usana-news', path: '#/usanaNews', isTranslationTag: true },
      { name: cmsItemObj.data.title?.data }
    ]"
  ></u-bread-crumb>
</div>
<div class="usanaNewsFull">
  <section class="cms-titled-item-title news-body-content py-0">
    <div class="row">
      <h1
        [innerHTML]="cmsItemObj.data.title?.data | interpolate : this | unsafe"
        class="col-md-8 offset-md-2 pt-2 fw-bold"
      ></h1>
    </div>
    <div class="clearfix row pt-2">
      <div class="col-2 col-md-1 offset-md-2 news-thumbnail-full">
        <img
          class="img-responsive"
          src="{{ cmsItemObj.data.imageSrc240x240?.data }}"
          alt="{{ cmsItemObj.data.ImageAlt?.data }}"
        />
      </div>
      <div class="col-10 col-md-9">
        <div
          class="news-title pt-2"
          *ngIf="cmsItemObj.data.author?.data"
          [innerHTML]="cmsItemObj.data.author?.data | interpolate : this | unsafe"
        ></div>
      </div>
    </div>
    <div class="row py-4">
      <div
        [innerHTML]="cmsItemObj.data.content1?.data | interpolate : this | unsafe : 'html'"
        class="col-md-8 offset-md-2"
      ></div>
      <div class="col-12 col-md-8 offset-md-2 py-5">
        <img
          class="img-responsive"
          src="{{ cmsItemObj.data.imageSrc300x200?.data }}"
          alt="{{ cmsItemObj.data.ImageAlt?.data }}"
        />
      </div>
      <div
        [innerHTML]="cmsItemObj.data.content2?.data | interpolate : this | unsafe : 'html'"
        class="col-md-8 offset-md-2"
      ></div>
    </div>
  </section>
</div>
