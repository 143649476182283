import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { MyAccountService } from '@common/my-account/my-account.service';
import { Store } from '@ngxs/store';
import { QuickLinksService } from '@shared/quick-links/quick-links.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageTitleService } from '../../../../common/src/services/page-title.service';
import { InitializeApp } from '../../../../usana/src/app/state/app.actions';
import { AppSelectors } from '../../../../usana/src/app/state/app.selectors';
import { BAOYING_MY_ACCOUNT_LIST } from '../constants/baoying-my-account.constants';
import { BAOYING_QUICK_LINKS_DATA } from '../constants/baoying-quick-links.constants';

@Component({
  selector: 'b-hub-root',
  templateUrl: './baoying-app-hub.component.html',
  styleUrls: ['./baoying-app-hub.component.scss'],
})
export class BaoyingHubComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean> = this.store.select(AppSelectors.getIsLoading);
  user$ = this.store.select(UserProfileSelectors.getUser);
  locale$ = this.store.select(UserProfileSelectors.getLocale);

  readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private quickLinksService: QuickLinksService,
    private myAccountService: MyAccountService,
    private pageTitleService: PageTitleService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new InitializeApp());

    this.quickLinksService.quickLinks = BAOYING_QUICK_LINKS_DATA;
    this.myAccountService.myAccount = BAOYING_MY_ACCOUNT_LIST;

    this.locale$.pipe(takeUntil(this.destroy$)).subscribe((_locale) => {
      this.pageTitleService.setTitle();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
