import { UserProfile } from '@common/authentication/model/user-profile';
import { UserProfileState } from '@common/authentication/state/user-profile.state';
import { buildCmsItemFromFlatMap } from '@common/cms/state/cms.operators';
import { CmsState } from '@common/cms/state/cms.state';
import { CmsCurrentPage, CmsItemEntry, CmsStateModel } from '@common/cms/state/cms.state.model';
import { Selector } from '@ngxs/store';

export class CmsSelectors {
  @Selector([UserProfileState])
  static getPageEdit(state: UserProfile): boolean {
    return state?.pageEdit;
  }

  @Selector([CmsState, CmsSelectors.getPageEdit])
  static getActiveEditItemId(state: CmsStateModel, pageEdit: boolean): string {
    return pageEdit ? state?.activeEditItemId : '';
  }

  @Selector([CmsState])
  static getCmsDataEntries(state: CmsStateModel) {
    return state?.cmsDataEntries;
  }

  @Selector([CmsState])
  static getUpdatedCmsIds(state: CmsStateModel) {
    return state?.updatedCmsIds;
  }

  @Selector([CmsSelectors.getUpdatedCmsIds])
  static cmsItemIsUpdatedFn(updatedCmsIds: string[]): (itemId: string) => boolean {
    return (itemId: string) => updatedCmsIds?.includes(itemId);
  }

  @Selector([CmsSelectors.getCmsDataEntries])
  static getCmsItemByIdFn(entries: { [key: string]: CmsItemEntry }): (itemId: string) => CmsItemEntry {
    return (itemId: string) => buildCmsItemFromFlatMap(entries, itemId);
  }

  @Selector([CmsState])
  static getCmsCurrentPage(state: CmsStateModel): CmsCurrentPage {
    return state?.currentPage;
  }
}
