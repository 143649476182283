import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InterpolationPipeModule } from '@shared/pipes/interpolate/interpolate.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ToastDisplayComponent } from './toast-display.component';

@NgModule({
  declarations: [ToastDisplayComponent],
  imports: [CommonModule, AlertModule.forRoot(), InterpolationPipeModule, TranslationModule, FontAwesomeModule],
  providers: [],
  exports: [ToastDisplayComponent],
})
export class UsanaToastModule {}
