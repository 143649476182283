import { Pipe, PipeTransform } from '@angular/core';
import { TaskTrackerTask } from '../models/task-tracker-task';

@Pipe({
  name: 'orderTasks',
})
export class OrderTasksPipe implements PipeTransform {
  transform(input: TaskTrackerTask[]): TaskTrackerTask[] {
    const result: TaskTrackerTask[] = [];

    if (input?.length) {
      const incompleteTasks = input.filter((task) => !task.isCompleted);
      const completedTasks = input.filter((task) => task.isCompleted);

      const mostRecentCompletedTask = completedTasks.sort(this.compareTasksByDateFn).pop();
      if (mostRecentCompletedTask) {
        result.push(mostRecentCompletedTask);
      }

      result.push(...incompleteTasks.sort(this.compareTasksByDisplayOrderFn));
      result.push(...completedTasks.sort(this.compareTasksByDisplayOrderFn));
    }

    return result;
  }

  compareTasksByDateFn(task1: TaskTrackerTask, task2: TaskTrackerTask) {
    let result = 0;

    if (task1.completedDate && task2.completedDate) {
      result = task1.completedDate > task2.completedDate ? 1 : -1;
    }

    return result;
  }

  compareTasksByDisplayOrderFn(task1: TaskTrackerTask, task2: TaskTrackerTask) {
    return task1.displayOrder - task2.displayOrder;
  }
}
