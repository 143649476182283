import { FooterColumn, FooterLogo, FooterSocialMedia } from '@shared/footer/footer.model';

export const BAOYING_SOCIAL_MEDIA_LINKS: FooterSocialMedia = {
  hideCountry: [],
  title: 'hub.footer.title.follow-usana',
  links: [
    {
      id: 0,
      qrText: 'hub.footer.qr.baoying.company',
      qrImage: 'assets/images/baoyingwx.jpg',
      iconClass: 'svg-inline--fa fa-weixin fa-w-18 fa-lg',
      hideCountry: [],
      // eslint-disable-next-line max-len
      svg: 'M385.2 167.6c6.4 0 12.6.3 18.8 1.1C387.4 90.3 303.3 32 207.7 32 100.5 32 13 104.8 13 197.4c0 53.4 29.3 97.5 77.9 131.6l-19.3 58.6 68-34.1c24.4 4.8 43.8 9.7 68.2 9.7 6.2 0 12.1-.3 18.3-.8-4-12.9-6.2-26.6-6.2-40.8-.1-84.9 72.9-154 165.3-154zm-104.5-52.9c14.5 0 24.2 9.7 24.2 24.4 0 14.5-9.7 24.2-24.2 24.2-14.8 0-29.3-9.7-29.3-24.2.1-14.7 14.6-24.4 29.3-24.4zm-136.4 48.6c-14.5 0-29.3-9.7-29.3-24.2 0-14.8 14.8-24.4 29.3-24.4 14.8 0 24.4 9.7 24.4 24.4 0 14.6-9.6 24.2-24.4 24.2zM563 319.4c0-77.9-77.9-141.3-165.4-141.3-92.7 0-165.4 63.4-165.4 141.3S305 460.7 397.6 460.7c19.3 0 38.9-5.1 58.6-9.9l53.4 29.3-14.8-48.6C534 402.1 563 363.2 563 319.4zm-219.1-24.5c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.8 0 24.4 9.7 24.4 19.3 0 10-9.7 19.6-24.4 19.6zm107.1 0c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.5 0 24.4 9.7 24.4 19.3.1 10-9.9 19.6-24.4 19.6z',
      alt: '葆婴有限公司',
    },
    {
      id: 1,
      qrText: 'hub.footer.qr.baoying.company',
      qrImage: 'assets/images/baoying-wb.jpg',
      iconClass: 'svg-inline--fa fa-weibo fa-w-16 fa-lg',
      hideCountry: [],
      // eslint-disable-next-line max-len
      svg: 'M407 177.6c7.6-24-13.4-46.8-37.4-41.7-22 4.8-28.8-28.1-7.1-32.8 50.1-10.9 92.3 37.1 76.5 84.8-6.8 21.2-38.8 10.8-32-10.3zM214.8 446.7C108.5 446.7 0 395.3 0 310.4c0-44.3 28-95.4 76.3-143.7C176 67 279.5 65.8 249.9 161c-4 13.1 12.3 5.7 12.3 6 79.5-33.6 140.5-16.8 114 51.4-3.7 9.4 1.1 10.9 8.3 13.1 135.7 42.3 34.8 215.2-169.7 215.2zm143.7-146.3c-5.4-55.7-78.5-94-163.4-85.7-84.8 8.6-148.8 60.3-143.4 116s78.5 94 163.4 85.7c84.8-8.6 148.8-60.3 143.4-116zM347.9 35.1c-25.9 5.6-16.8 43.7 8.3 38.3 72.3-15.2 134.8 52.8 111.7 124-7.4 24.2 29.1 37 37.4 12 31.9-99.8-55.1-195.9-157.4-174.3zm-78.5 311c-17.1 38.8-66.8 60-109.1 46.3-40.8-13.1-58-53.4-40.3-89.7 17.7-35.4 63.1-55.4 103.4-45.1 42 10.8 63.1 50.2 46 88.5zm-86.3-30c-12.9-5.4-30 .3-38 12.9-8.3 12.9-4.3 28 8.6 34 13.1 6 30.8.3 39.1-12.9 8-13.1 3.7-28.3-9.7-34zm32.6-13.4c-5.1-1.7-11.4.6-14.3 5.4-2.9 5.1-1.4 10.6 3.7 12.9 5.1 2 11.7-.3 14.6-5.4 2.8-5.2 1.1-10.9-4-12.9z',
      alt: '葆婴有限公司',
    },
    {
      id: 2,
      qrText: 'hub.footer.qr.baoying.miniprogram.shopping',
      qrImage: 'assets/images/miniprogram-shopping.jpg',
      iconClass: 'svg-inline--fa fa-w-16 fa-lg',
      hideCountry: [],
      // eslint-disable-next-line max-len
      svg: 'M256,29 C381.368638,29 483,130.631362 483,256 C483,381.368638 381.368638,483 256,483 C130.631362,483 29,381.368638 29,256 C29,130.631362 130.631362,29 256,29 Z M313.43788,127.509434 C273.049087,127.509434 239.897161,158.343378 236.6769,197.594753 L236.420485,197.595197 L236.420485,308.128054 L236.405451,309.192794 C235.835399,329.35459 219.126609,345.554031 198.56212,345.554031 C174.804385,345.554031 158.102426,332.333923 158.102426,314.343018 C158.102426,295.128458 167.281094,285.521435 191.244033,278.586189 C201.626627,275.581305 207.593523,264.776432 204.571469,254.45283 C201.549414,244.129228 190.682799,238.196234 180.300204,241.201118 C141.063232,252.556907 118.943396,275.709041 118.943396,314.343018 C118.943396,356.039338 154.887978,384.490566 198.56212,384.490566 C238.950913,384.490566 272.102839,353.656622 275.3231,314.405247 L275.579515,314.404803 L275.579515,203.871946 L275.594549,202.807206 C276.164601,182.64541 292.873391,166.445969 313.43788,166.445969 C337.195615,166.445969 353.897574,179.666077 353.897574,197.656982 C353.897574,216.871542 344.718906,226.478565 320.755967,233.413811 C310.373373,236.418695 304.406477,247.223568 307.428531,257.54717 C310.450586,267.870772 321.317201,273.803766 331.699796,270.798882 C370.936768,259.443093 393.056604,236.290959 393.056604,197.656982 C393.056604,155.960662 357.112022,127.509434 313.43788,127.509434 Z',
      alt: '葆婴官方商城小程序',
    },
    {
      id: 3,
      qrText: 'hub.footer.qr.baoying.app',
      qrImage: 'assets/images/app-download-qrcode.png',
      iconClass: 'svg-inline--fa fa-android fa-w-16 fa-lg',
      hideCountry: [],
      // eslint-disable-next-line max-len
      svg: 'M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55',
      alt: '葆婴APP',
    },
  ],
};

export const BAOYING_FOOTER_IMAGE: FooterLogo = {
  path: 'assets/images/footer_logo_baoying.png',
  isLarge: true,
  alt: 'Baoying logo',
};

export const BAOYING_FOOTER_DISCLAIMERS = ['hub.footer.baoying.disclaimer', 'baoying.hub.footer.information'];

export const BAOYING_FOOTER_COLUMNS: Array<FooterColumn> = [
  {
    id: 'column1',
    title: 'hub.footer.title.company',
    links: [
      {
        id: 0,
        title: 'hub.footer.home',
        url: 'hub.footer.home-link',
        hideCountry: [],
      },
      {
        id: 1,
        title: 'hub.footer.products',
        url: 'hub.footer.products-link',
        hideCountry: [],
      },
      {
        id: 2,
        title: 'hub.footer.press',
        url: 'hub.footer.press-link',
        hideCountry: [],
      },
      {
        id: 3,
        title: 'hub.footer.about',
        url: 'hub.footer.about-link',
        hideCountry: [],
      },
      {
        id: 4,
        title: 'hub.footer.science',
        url: 'hub.footer.science-link',
        hideCountry: [],
      },
      {
        id: 5,
        title: 'hub.footer.foundation',
        url: 'hub.footer.foundation-link',
        hideCountry: [],
      },
      {
        id: 6,
        title: 'hub.footer.baoying.charity',
        url: 'hub.footer.baoying.charity-link',
        hideCountry: [],
      },
      {
        id: 7,
        title: 'hub.footer.charity.news',
        url: 'hub.footer.charity.news-link',
        hideCountry: [],
      },
    ],
  },
  {
    id: 'column2',
    title: 'hub.footer.title.quick-links',
    links: [
      {
        id: 0,
        title: 'hub.footer.dr-wentz',
        url: 'hub.footer.dr-wentz-link',
        hideCountry: [],
      },
      {
        id: 1,
        title: 'hub.footer.privacy.clause',
        url: 'hub.footer.privacy.clause-link',
        hideCountry: [],
      },
      {
        id: 2,
        title: 'hub.footer.baoying.state.market',
        url: 'hub.footer.baoying.state.market-link',
        hideCountry: [],
      },
      {
        id: 3,
        title: 'hub.footer.baoying.branch',
        url: 'hub.footer.baoying.branch-link',
        hideCountry: [],
      },
      {
        id: 4,
        title: 'hub.footer.compliance',
        url: 'hub.footer.compliance-link',
        hideCountry: [],
      },
      {
        id: 5,
        title: 'hub.footer.old.babycare',
        url: 'hub.footer.old.babycare-link',
        hideCountry: [],
      },
    ],
  },
  {
    id: 'column3',
    title: 'hub.footer.title.connect',
    links: [
      {
        id: 0,
        title: 'hub.footer.contact-us',
        url: 'hub.footer.contact-us-link',
        hideCountry: [],
      },
      {
        id: 1,
        title: 'hub.footer.login',
        url: 'hub.footer.login-link',
        hideCountry: [],
      },
      {
        id: 2,
        title: 'hub.footer.about-us',
        url: 'hub.footer.about-us-link',
        hideCountry: [],
      },
    ],
  },
];
