export const CMS_KEY_DATA = 'data":"';
export const CMS_KEY_SITE = 'site":"';
export const CMS_KEY_TARGET = 'target":"';
export const CMS_URL = '/hub/#/content/';
export const CMS_TARGET = {
  SELF: '_self',
  BLANK: '_blank',
};

export const SLIDE_INTERVAL = 5000;

export const CMS_CONFIRM_BUTTON_TAG = 'hub.cms.confirmButton';
export const CMS_CANCEL_BUTTON_TAG = 'hub.cms.cancelButton';
