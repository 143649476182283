import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslationModule } from '@common/translation/translation.module';
import { NgxsModule } from '@ngxs/store';
import { BaoyingVolumeReportState } from '../state/baoying-volume-report.state';
import { BaoyingVolumeReportComponent } from './baoying-volume-report.component';

@NgModule({
  declarations: [BaoyingVolumeReportComponent],
  imports: [CommonModule, NgxsModule.forFeature([BaoyingVolumeReportState]), TranslationModule],
  exports: [BaoyingVolumeReportComponent],
})
export class BaoyingVolumeReportModule {}
