<ng-container
  *ngIf="
    !euroMarketsCountries((userProfile$ | async)?.user?.country) ||
    (userProfile$ | async)?.user?.customerType === CustomerTypeCodes.employee
  "
>
  <button id="change-market-button" (click)="openChangeMarketModal()">
    <span
      class="flag-icon flag-icon-topnav {{ (userProfile$ | async)?.locale }} current-market-flag"
      *ngIf="!euroMarkets((userProfile$ | async)?.locale) && !(userProfile$ | async)?.pc"
    >
      &nbsp;
    </span>

    <fa-icon
      *ngIf="euroMarkets((userProfile$ | async)?.locale) || (userProfile$ | async)?.pc"
      [icon]="GLOBE_ICON"
      class="nav-icons"
    ></fa-icon>
    <span class="switch-market-text">{{ 'cart.locale.switcher.change' | translate }}</span>
  </button>
</ng-container>
