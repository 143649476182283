import { Component } from '@angular/core';
import { UniversalModalOptions } from '@usana/ux/universal-components/modal';
import { CmsItemModel } from '../../cms.model';

@Component({
  selector: 'u-cms-hub-side-nav-modal',
  templateUrl: './cms-hub-side-nav-modal.component.html',
})
export class CmsHubSideNavModalComponent {
  data: { rootId: string; cmsItemObj: CmsItemModel };
  modalOptions: UniversalModalOptions;
}
