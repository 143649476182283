import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfileSelectors } from '@common/authentication/state/user-profile.selectors';
import { MarkAsRead } from '@common/notifications/state/notification.actions';
import { NotificationSelectors } from '@common/notifications/state/notification.selectors';
import { Store } from '@ngxs/store';
import { HubInterruptData, InterruptType } from '@shared/hub-interrupt/hub-interrupt.model';
import { UniversalModalOptions, UniversalModalService } from '@usana/ux/universal-components/modal';
import { combineLatest, of, Subscription } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'u-hub-interrupt',
  template: '<span></span>',
})
export class HubInterruptComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  private hideSub: Subscription;
  private interrupts: HubInterruptData[];
  private interruptIdx: number;
  private modaling: boolean;

  constructor(private store: Store, private uModalService: UniversalModalService, private httpClient: HttpClient) {}

  ngOnInit() {
    this.interrupts = [];
    this.interruptIdx = 0;

    this.sub = combineLatest([
      this.store.select(NotificationSelectors.getInterruptNotifications).pipe(
        map((notifications) => {
          if (notifications.length > 0) {
            return {
              type:
                notifications[0].content[0].part === 'SMS_INTERRUPT'
                  ? InterruptType.NOTIFICATION_SMS
                  : InterruptType.NOTIFICATION_GENERIC,
              data: notifications[0],
            };
          } else {
            return null;
          }
        })
      ),
      this.store.select(UserProfileSelectors.getUser).pipe(
        flatMap((user) => {
          if (user && 'IN' === user.country) {
            // only for india at the moment
            // yes I am avoiding apollo because there is no reason to pull it in at this moment in my opinion
            return this.httpClient
              .post(
                environment.hubGatewayUrl,
                {
                  query:
                    'query getCustomer($customerId: Int) { getCustomer(customerId: $customerId) { id applicationDate customerDocsVerification { isVerified }}}',
                  variables: { customerId: +user.username },
                },
                {
                  withCredentials: true,
                }
              )
              .pipe(
                map((response: any) => {
                  if (response.data && response.data.getCustomer) {
                    const customer = response.data.getCustomer;
                    const daysSinceApp =
                      (new Date().getTime() - new Date(customer.applicationDate).getTime()) / (1000 * 3600 * 24);
                    // application date more than 30 days ago and missing the doc verification OR verified is false
                    if (
                      daysSinceApp > 30 &&
                      (!customer.customerDocsVerification || !customer.customerDocsVerification.isVerified)
                    ) {
                      return {
                        type: InterruptType.KYC_DOC_UPLOAD,
                      };
                    }
                  }
                  return null; // data is missing or they are good
                })
              );
          } else {
            return of(null);
          }
        })
      ),
    ]).subscribe((r: HubInterruptData[]) => {
      // filter the ones that have already been added.
      const existingTypes = this.interrupts.map((i) => i.type);
      r.forEach((it) => {
        // we can't do a modal twice because that would be silly
        if (it != null && !existingTypes.includes(it.type)) {
          this.interrupts.push(it);
        }
      });

      // if we aren't modaling lets start
      if (!this.modaling) {
        this.loadNextModal();
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
      delete this.sub;
    }
    if (this.hideSub) {
      this.hideSub.unsubscribe();
      delete this.hideSub;
    }
  }

  private async loadNextModal() {
    if (this.interrupts.length > this.interruptIdx) {
      this.modaling = true;
      const interrupt = this.interrupts[this.interruptIdx];

      let modalOptions: UniversalModalOptions = {
        backdrop: 'static',
        data: {
          notification: interrupt.data,
        },
      };

      switch (interrupt.type) {
        case InterruptType.KYC_DOC_UPLOAD:
          const { KycRequiredModalComponent } = await import('./kyc/kyc-required-modal.component');

          modalOptions = {
            ...modalOptions,
            removeXButton: true,
            keyboard: false,
            beforeDismiss: () => false,
          };

          this.openModal(modalOptions, KycRequiredModalComponent);
          break;
        case InterruptType.NOTIFICATION_SMS:
          const { NotificationSmsInterruptComponent } = await import(
            './notification-sms/notification-sms-interrupt.component'
          );

          this.openModal(modalOptions, NotificationSmsInterruptComponent);
          break;
        case InterruptType.NOTIFICATION_GENERIC:
          const { NotificationGenericInterruptComponent } = await import(
            './notification-generic/notification-generic-interrupt.component'
          );

          this.openModal(modalOptions, NotificationGenericInterruptComponent, true);
          break;
        default:
          console.log('missing interrupt type', interrupt.type);
      }
    } else {
      this.modaling = false;
    }
  }

  private openModal(modalOptions: UniversalModalOptions, c: any, markAsReadOnOpen?: boolean): void {
    if (markAsReadOnOpen) {
      this.store.dispatch(new MarkAsRead([modalOptions.data.notification]));
    }

    this.uModalService.openCustom(modalOptions, c).closed.subscribe((): void => {
      this.interruptIdx++;
      this.loadNextModal();
    });
  }
}
