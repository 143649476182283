import { Selector } from '@ngxs/store';
import { BaoyingVolumeReportState } from './baoying-volume-report.state';
import { BaoyingVolumeReportStateModel } from './baoying-volume-report.state.model';

export class BaoyingVolumeReportSelectors {
  @Selector([BaoyingVolumeReportState])
  public static getCommissionWeeks(state: BaoyingVolumeReportStateModel): number[] {
    return state.commissionWeeks;
  }

  @Selector([BaoyingVolumeReportState])
  public static getCommissionWeek(state: BaoyingVolumeReportStateModel): number {
    return state.commissionWeek;
  }

  @Selector([BaoyingVolumeReportState])
  public static getVolume(state: BaoyingVolumeReportStateModel) {
    return state.volume;
  }
}
