<div class="pagination-controls">
  <div class="prev-arrow">
    <div *ngIf="!isFirstPage" (click)="paginateBack()" class="fontawesome-icons">
      <fa-icon [icon]="PLAY_CIRCLE_ICON"></fa-icon>
    </div>
  </div>
  <div class="next-arrow">
    <div
      *ngIf="metadata?.hasNextPage"
      (click)="paginateForward()"
      class="fontawesome-icons"
      [attr.data-automation]="'teammanager.forward-button'"
    >
      <fa-icon [icon]="PLAY_CIRCLE_ICON"></fa-icon>
    </div>
  </div>
</div>

<div *ngIf="metadata && metadata.categories && metadata.categories.length" class="widget-box">
  <div class="dlm-widget-dataset-bc-container" *ngFor="let cycle of metadata.categories; trackBy: trackByCycle">
    <div class="dlm-widget-dataset-bc-label label-position {{ cycle }}">
      {{ cycle }}
    </div>
  </div>
</div>

<div *ngIf="metadata && data" style="display: block">
  <canvas #canvas class="chart chart-bar" id="canvas" aria-label="Volume Widget" role="img">
    {{ barChart }}
  </canvas>
</div>
