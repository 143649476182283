export const PRODUCTS_TRAINING_MARKETS = [
  'en_AU',
  'zh_AU',
  'en_CA',
  'fr_CA',
  'zh_CA',
  'kr_KR',
  'en_MY',
  'zh_MY',
  'zh_NZ',
  'en_NZ',
  'en_PH',
  'en_SG',
  'zh_SG',
  'en_US',
  'zh_US',
  'es_US',
];
