import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportHelp, ReportRequestBody, SavedReportParams } from '@common/reports/reports.model';
import { Observable, throwError } from 'rxjs';
import { ReportViewTypes } from './reports.enum';

@Injectable()
export class ReportsService {
  private static handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  constructor(private http: HttpClient) {}

  getReportParams(report?: string, locale?: string) {
    report = report || '';
    const headers = this.getHeaders(locale || 'en', '4.0');
    return this.http.get(`/dlm2/service/form/params/${report}`, headers);
  }

  getSavedReportParams(reportId?: string, locale?: string): Observable<SavedReportParams> {
    const headers = this.getHeaders(locale || 'en', '4.0');
    return this.http.get<SavedReportParams>(`/dlm2/service/report/params/${reportId || ''}`, headers);
  }

  getReport(report?: string, locale?: string, version?: string, requestBody?: ReportRequestBody) {
    report = report || '';
    const headers = this.getHeaders(locale || 'en', version || '3.0');

    const view = requestBody?.defaultView;
    const reportType = view === ReportViewTypes.Tree ? 'hierarchicalTree' : '';

    return this.http.post(`/dlm2/service/report/${report}/${reportType}`, requestBody, headers);
  }

  getHeaders(locale: string, version: string) {
    return {
      headers: {
        Accept: 'application/json;charset=UTF-8',
        'Content-Type': 'application/json;charset=UTF-8',
        Version: version === undefined ? '2.0' : version,
        'Content-Language': locale,
      },
    };
  }

  getReportHelp(reportName: string, locale: string): Observable<ReportHelp> {
    const headers = this.getHeaders(locale || 'en', '2.0');
    return this.http.get<ReportHelp>(`/dlm2/service/report/reportHelp/${reportName}`, headers);
  }
}
