import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HtmlToPlainTextModule } from '@shared/pipes/html-to-plain-text/html-to-plain-text.module';
import { OrderByPropertyPipeModule } from '@shared/pipes/order-by-property/order-by-property.module';
import { ReplaceModule } from './replace/replace.module';
import { UnsafeModule } from './unsafe/unsafe.module';

@NgModule({
  imports: [CommonModule, UnsafeModule, ReplaceModule],
  exports: [UnsafeModule, ReplaceModule, HtmlToPlainTextModule, OrderByPropertyPipeModule],
})
export class UsanaPipesModule {}
