// eslint-disable-next-line max-classes-per-file
import { CmsBaseDirective } from '@common/cms/cms-base.directive';
import { CmsBaseContainerDirective } from '@common/cms/containers/cms-base-container.directive';
import { CmsBaseItemDirective } from '@common/cms/items/cms-item-base.directive';
import { PurplePageComponent } from '@shared/purple-pages/purple-page/purple-page.component';
import { CmsBindingDefinition } from '../cms.model';

export class CmsComponentBindingDefinition implements CmsBindingDefinition {
  componentClass: any;

  constructor(componentClass) {
    this.componentClass = componentClass;
  }

  bindData(componentRef, cmsItem, navClickEvent): CmsBaseDirective {
    if (componentRef != null) {
      let component;
      if (cmsItem.data.type.container) {
        component = componentRef.instance as CmsBaseContainerDirective;
        component.rootId = cmsItem.data.item.id;
      } else {
        component = componentRef.instance as CmsBaseItemDirective;
      }
      component.cmsItemObj = cmsItem.data;
      component.navClickEvent = navClickEvent;
      return component;
    }
    return null;
  }
}

export class CmsPurplePageBindingDefinition implements CmsBindingDefinition {
  componentClass: any;

  constructor(componentClass) {
    this.componentClass = componentClass;
  }

  bindData(componentRef, cmsItem, navClickEvent): any {
    if (componentRef != null) {
      const component = componentRef.instance as PurplePageComponent;
      component.fullPath = `/mvc/cm/view?Id=${cmsItem.data.item.id}&relLocale=${cmsItem.data.relationship.locale}`;
      return component;
    }
    return null;
  }
}
